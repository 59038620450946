//import React, {Component} from 'react';
import Configs from '../SiteSpecificConfig';

   
export function SiteConfigurationContext() {
  let returnResult = {};
  var windowsUrl = window.location.hostname;
  for (var urlName in Configs.SiteConfigurationMapping)
  {
    if (windowsUrl.includes("localhost") && windowsUrl.includes(urlName))
    {
      return returnResult = (Configs.SiteConfigurationMapping[urlName]);
    }
    else if (windowsUrl==urlName)
    {
      return returnResult = (Configs.SiteConfigurationMapping[urlName]);
    }  
  }
  return returnResult;
}    

export default SiteConfigurationContext;