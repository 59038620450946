import React from 'react';
import Configs from 'Configuration';
//Style sheets
import SiteConfigurationContext from 'Utils/SiteConfigurationContext.jsx';
//import FhtMain from "FhtMain";
import dashboardStyle from 'components/Card/dashboardStyle.jsx';
import 'components/css/circularProgressStyle.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'underscore';
import BatchSiteConfigurationContext from 'Utils/BatchSiteConfigurationContext.jsx';
import AlgorithmSiteConfigurationContext from 'Utils/AlgorithmSiteConfigurationContext.jsx';

import FhtFetchUrl from 'Utils/FhtFetchUrl.jsx';

//nivo graphs
import { ResponsiveLine } from '@nivo/line';

//const treatThisValueAsZero = 0.1;
const colorSchemeforGraphs = 'nivo'; //spectral, nivo, pastel1, set3

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  completed: {
    display: 'inline-block'
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));
var IsTorch = false;
class ReviewLineGraph extends React.Component {
  constructor(props) {
    super(props);
    this.batchSiteConfigurationContext = BatchSiteConfigurationContext();
    this.algorithmSiteConfigurationContext = AlgorithmSiteConfigurationContext();    
    this.state = {
      loading: false,
      data: [],
      siteConfigurationContext: SiteConfigurationContext(),
      cohortConfirmationDialog: false,
      FullPatientLineGraphData: null,
      ReceivedCohortName: Configs.noCohortSelected,
      ClickedLegend: false,
      LegendsDisabledOrEnabled: {},
      LegendIsClicked: false,
      hiddenIds: {},
      tickValues: [0],
      ReceivedToggleButtonValue: -1
    };
    if (this.batchSiteConfigurationContext['IsTorch']=='true')
    {
      IsTorch = true;
    }    
    this.handleGetReviewCohortStagesSummaryAPIThrottled = _.debounce(
      getLineGraphCohortHistoryAPI,
      1000,
      true
    );
    //this.initialiseTickValues();
  }

  //It appears we can't customise the legend. So if we remove the specific category from the data, it also disappears from the legend. :(
  //This means we need to write our own custom legend.
  onLegendClicked = e => {
    console.log('legend clicked. e is:', e.id);
    //we need to generate new graphData without calling the APIData.

    let newLegendDict = this.state.LegendsDisabledOrEnabled;
    Object.keys(this.state.LegendsDisabledOrEnabled).forEach(function (key) {
      if (key == e.id) {
        newLegendDict[key] = !newLegendDict[key];
      }
    });
    this.setState({ LegendsDisabledOrEnabled: newLegendDict, LegendIsClicked: true });
  };
  //Hack to make the graph display the numbers correctly on the Y-axis
  initialiseTickValues = () => {
    var tempArray = [];
    console.log('initialising tick values');
    for (var i = 1; i < 200; i++) {
      tempArray.push(i);
    }
    //eslint-disable-next-line
    this.state.tickValues = tempArray;
  };

  render() {
    let ForDisplayLinegraphData = [
      {
        id: 'Waiting for data',
        data: [
          { x: '01-01-2018', y: 0 },
          { x: '01-02-2018', y: 0 },
          { x: '01-03-2018', y: 0 }
        ]
      }
    ];

    let cohortName = this.props.props.state.cohortBeingReviewedName;
    let toggledButtonValue = this.props.props.state.toggleButtonValue;
    if (
      (this.state.ReceivedCohortName !== cohortName &&
        cohortName !== Configs.noCohortSelected &&
        this.state.loading === false) ||
      this.state.ReceivedToggleButtonValue !== toggledButtonValue
    ) {
      this.setState(
        {
          ReceivedCohortname: cohortName,
          loading: true,
          ReceivedToggleButtonValue: toggledButtonValue
        },
        () => {
          this.handleGetReviewCohortStagesSummaryAPIThrottled(this);
        }
      );
    }

    if (this.state.LegendIsClicked === true) {
      this.setState({ LegendIsClicked: false });
    } else if (
      this.state.FullPatientLineGraphData !== undefined &&
      this.state.FullPatientLineGraphData !== null
    ) {
      ForDisplayLinegraphData = this.state.FullPatientLineGraphData;
    }
    let colorData = [
      {
        color: '#e9692c'
      },
      {
        color: '#7F7F7F'
      },
      {
        color: '#00B050'
      },
      {
        //"color": "#002060"
        color: '#5b75ab'
      },
      {
        color: '#00B0F0'
      },
      {
        color: '#F6FC96'
      },
      {
        color: '#FFC000'
      },
    ];
    //Extremely odd bug - can't return {linegraphRender} - very likely to do with the reactgridlayout?
    console.log(ForDisplayLinegraphData);

    return (
      <ResponsiveLine
        data={ForDisplayLinegraphData}
        enablePoints={false}
        margin={{ top: 50, right: 150, bottom: 70, left: 60 }}
        xScale={{ type: 'time', format: '%d-%m-%Y' }}
        xFormat="time:%Y-%m-%d"
        //xScale={{ type: 'point' }}
        //yScale={{ type: 'linear', min: 0, max: 'auto', stacked: false, reverse: false }}
        yScale={{ type: 'linear', stacked: false, min: 0, max: 'auto' }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          legend: 'Date',
          legendOffset: 60,
          legendPosition: 'middle',
          tickValues: 6,
          format: "%b %Y",
        }}
        axisLeft={{
          orient: 'left',
          legend: 'Total',
          legendOffset: -40,
          legendPosition: 'middle',
          type: 'number',
          format: '.0f',
          tickValues: 5
          //tickValues: this.state.tickValues,
        }}
        //colors={{ scheme: colorSchemeforGraphs }}
        colors={colorData.map(c => c.color)}
        colorBy="index"
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 132,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 120,
            itemHeight: 30,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ],
            onClick: e => this.onLegendClicked(e)
          }
        ]}
      />
    );
  }
}
function getLineGraphCohortHistoryAPI(params) {
  console.log('getLineGraphCohortHistoryAPI called');
  let cohortName = params.props.props.state.cohortBeingReviewedName;

  var localBatchConfigContext = BatchSiteConfigurationContext();
  const shouldIncludeDeferRecommendation = localBatchConfigContext['CohortRecallDefer'] === 'true';
  if (cohortName === Configs.noCohortSelected) {
    console.log('No cohort selected');
  } else {
    //eslint-disable-next-line
    params.setState({
      ReceivedCohortName: cohortName,
      matchingRecommendations: params.props.props.state.toggleButtonValue
    });
    let body = {
      name: cohortName,
      matchingRecommendations: params.props.props.state.toggleButtonValue,
      useCohortRecallDefer: shouldIncludeDeferRecommendation
    };
    const api = FhtFetchUrl(Configs.getLineGraphCohortHistory);
    api
      .post(body)
      .then(response => {
        if (response.returnGraphData !== undefined) {
          //we need to set a dictionary for the legend to enable/disable.
          let tempDict = {};
          let cleanedupReturnGraphData = [];
          //let newDict = [];
          let tempHolderData = [];
          var localBatchConfigContext = BatchSiteConfigurationContext();
          console.log ("reviewlinegraph response:", response);

          //Very HACKY solution so that we don't need to update the webapi
          Object.keys(response.returnGraphData).forEach(function (key) {
            if (response.returnGraphData[key].id == 'NoAction') {
              tempHolderData = response.returnGraphData[key].data;
            }

          });   
          
          Object.keys(response.returnGraphData).forEach(function (key) {
            if (!shouldIncludeDeferRecommendation && response.returnGraphData[key].id === 'DeferRecommendation')
              {
                return;
              }
            if (response.returnGraphData[key].id == 'NoAction') {
            }
            /*
            else if (localBatchConfigContext['CohortRecallDefer'] === 'true' && response.returnGraphData[key].id === 'DeferRecommendation'){

            } 
            */           
            else if (response.returnGraphData[key].id == 'ActionComplete') {
              response.returnGraphData[key].data = tempHolderData;
              cleanedupReturnGraphData.push(response.returnGraphData[key]);
              tempDict[response.returnGraphData[key]] = true;
            }
            else
            {
              cleanedupReturnGraphData.push(response.returnGraphData[key]);
              tempDict[response.returnGraphData[key]] = true;
            }
          });  
          console.log('response.returnGraphData:',response.returnGraphData);
          console.log('cleanedupReturnGraphData:',cleanedupReturnGraphData);
          //eslint-disable-next-line
          params.setState({
            FullPatientLineGraphData: cleanedupReturnGraphData,
            LegendsDisabledOrEnabled: tempDict,
            loading: false
          });
        }
      })
      .catch(error => {
        var msg = String(error);
        params.props.props.props.GlobalFunctionDisplaySnackbarMessage(
          msg,
          Configs.snackbarVariants.error
        );
        params.setState({ loading: false });
      });
  }
}
export default ReviewLineGraph;
