import React, { Component } from "react";

class QIActivityToDoScreen extends Component {

  constructor(props) {
    super(props);

    this.state = {
    }
  }


  render() {

    //let {form} = this.
    return (
        <div>
          <h2> Quality Improvement To Do List </h2>
        </div>
    );
  }
}


export default (QIActivityToDoScreen);