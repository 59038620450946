import React from 'react';

import Configs from 'Configuration';

import { AgGridReact } from 'ag-grid-react';

//utils
import ProposedActionCellRenderer from 'Utils/ProposedActionCellRenderer.utils.jsx';

//Style sheets
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Print from '@material-ui/icons/Print';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import SiteConfigurationContext from 'Utils/SiteConfigurationContext.jsx';
import CancelIcon from '@material-ui/icons/Cancel';
import FhtMain from 'FhtMain';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FhtFetchUrl from 'Utils/FhtFetchUrl.jsx';
import PatientDetailScreen from 'Views/PatientDetailScreen/pages/PatientDetailScreen';
import ChildMessageRenderer from './ChildMessageRenderer.jsx';
import * as _ from 'underscore';
import CommentDialog from 'FhtMainComponents/CommentDialog.jsx';

const marginRight = {
  marginRight: '1REM'
};

const paddingTop = {
  padding: '2REM 2REM'
};

const smallPaddingRight = {
  paddingRight: '0.5REM'
};

const icon = {
  marginTop: '5px'
};
const approvalType = {
  APPROVEDEFER: 'a',
  APPROVERECALL: 'b'
};

class PatientApprovalScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      displayTutorial: false,
      deferMonths: 1,
      deferReason: 'N/A',
      deferDialogVisible: false,
      deferPermanentChecked: false,
      disableSpinDefer: false,
      isChecked: false,
      width: window.innerWidth,
      height: window.innerHeight * 0.7,
      siteConfigurationContext: SiteConfigurationContext(),
      approvalMode: approvalType.APPROVERECALL,
      detailCellRenderer: 'myDetailCellRenderer',
      detailRowHeight: 800,
      frameworkComponents: { myDetailCellRenderer: PatientDetailScreen , childMessageRenderer: ChildMessageRenderer},
      forWebAPIFilterSearchCohortId: -1,
      forWebAPIFilterWorkflowId: -1,
      forWebAPIDeferWorkflowId: -1,
      POCUserId: -1,
      PatientsApproval: -1,
      aggcontext: { componentParent: this },
      currentPatientId: 0,
      showCommentDialog: false,
      comments: null,
    };
    //this.apiData();
    this.throttleApproveClick = _.debounce(this.onSubmitRecallForApproval, 200, true);
  }

  apiData() {
    console.log('CohortID for approvalScreen:', this.state.forWebAPIFilterSearchCohortId);
    let url = '';
    /*
    if(this.state.POCUserId !== -1 && this.state.PatientsApproval !== -1) {
      if(this.state.approvalMode == approvalType.APPROVEDEFER && this.state.PatientsApproval == 0) {
        url = Configs.getApprovalRecallListForPOC + '?POCUserID=' + this.state.POCUserId + '&patientApproval=1';
      }
      else if(this.state.approvalMode == approvalType.APPROVERECALL && this.state.PatientsApproval == 1) {
        url = Configs.getApprovalRecallListForPOC + '?POCUserID=' + this.state.POCUserId + '&patientApproval=0';
      }
      else {
        url = Configs.getApprovalRecallListForPOC + '?POCUserID=' + this.state.POCUserId + '&patientApproval=' + this.state.PatientsApproval;
      }
    }
    else 
    */
    {
      switch (this.state.approvalMode) {
        case approvalType.APPROVERECALL:
          url = Configs.getApprovalRecallListForCohort;
          break;

        case approvalType.APPROVEDEFER:
          url = Configs.getApprovalDeferListForCohort;
          break;
        default:
          console.log('Invalid mode selected.');
      }
  }

    let body = { CohortId: this.state.forWebAPIFilterSearchCohortId, Name: 'Ignore' };
    /*
    if(this.state.POCUserId !== -1 && this.state.PatientsApproval !== -1) {
      body = {};
    }
    */
    const api = FhtFetchUrl(url);
    api
      .post(body)
      .then(response => {
        console.log(response);
        this.gridApi.deselectAll();
        /*
        if(this.state.POCUserId != -1 && (response.userName == null || response.userName == '')){
          this.props.GlobalFunctionDisplaySnackbarMessage(
            'Please link user to emrusername in FHT',
            Configs.snackbarVariants.warning
          );
        }
        */
        this.setState({ data: response.data, loading: false });
      })
      .catch(error => {
        var msg = String(error);

        this.props.GlobalFunctionDisplaySnackbarMessage(
          'Error:' + msg,
          Configs.snackbarVariants.error
        );

        this.setState({ data: [], loading: false });
      });
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;


    if (this.props.location.search != '' && this.props.location.search != null) {
      let searchParams = this.props.location.search.split('?')[1].split('&');

      let tempCohortIdValue = -1;
      let tempWorkflowStageId = -1;
      let tempDeferWorkflowId = -1;
      searchParams.forEach(s => {
        let keySearch = s.split('=')[0];
        let searchValue = s.split('=')[1];
        if (keySearch === 'cohortid') {
          tempCohortIdValue = searchValue;
        }
        if (keySearch === 'POCUserId') {
          this.setState({POCUserId: searchValue})
        }
        if (keySearch === 'PatientsApproval') {
          this.setState({PatientsApproval: searchValue})
        }
      });
      if (tempCohortIdValue !== -1) {
        this.setState(
          {
            forWebAPIFilterSearchCohortId: tempCohortIdValue,
            forWebAPIFilterWorkflowId: tempWorkflowStageId,
            forWebAPIDeferWorkflowId: tempDeferWorkflowId
          },
          () => {
            //let datasource = new FHTDatasource(this, 'fromOnGridReady');
            //this.gridApi.setServerSideDatasource(datasource);
            this.apiData();
          }
        );
      }
      else if(this.state.POCUserId !== -1 && this.state.POCUserId !== 0 && this.state.PatientsApproval !== -1) {
        if(this.state.PatientsApproval == 0) {
          this.setState({ approvalMode: approvalType.APPROVERECALL }, () => {
            this.apiData();
          });
        }
        else if(this.state.PatientsApproval == 1) {
          this.setState({ approvalMode: approvalType.APPROVEDEFER }, () => {
            this.apiData();
          });
        }
      }
    } else this.apiData();
    this.closeToolPanel();
  };

  onColumnResized(event) {
    if (event.finished) {
      this.gridApi.resetRowHeights();
    }
  }

  closeToolPanel = () => {
    this.gridApi.closeToolPanel();
  };

  onBtPrint = () => {
    this.setPrinterFriendly();
    setTimeout(() => {
      window.print();
      this.setNormal();
    }, 2000);
  };

  setPrinterFriendly = () => {
    let eGridDiv = document.querySelector('.my-grid');
    eGridDiv.style.width = '';
    eGridDiv.style.height = '';
    this.gridApi.setDomLayout('print');
  };

  setNormal = () => {
    let eGridDiv = document.querySelector('.my-grid');
    eGridDiv.style.height = '800px';
    this.gridApi.setDomLayout(null);
  };

  onBtExport = () => {
    let options = {
      fileName: 'Recall Patient'
    };
    this.gridApi.exportDataAsCsv(options);
  };

  submitRequestToServer = url => {
    let sRows = this.gridApi.getSelectedRows();
    let holdAllTempRecords = [];
    let messageId = [1];
    let TriageForServer = {};
    switch (this.state.approvalMode) {
      case approvalType.APPROVERECALL:
        sRows.forEach(row => {
          if (row.patientExtractId != null) {
            holdAllTempRecords.push({
              patientId: row.patientExtractId,
              messageId: messageId
            });
          }
        });
        TriageForServer = { triageItems: holdAllTempRecords };
        break;
      case approvalType.APPROVEDEFER:
        console.log('Submitting for approve defer');
        sRows.forEach(row => {
          console.log('row:', row);
          if (row.patientExtractId != null) {
            holdAllTempRecords.push({
              PatientId: row.patientExtractId,
              DeferId: row.patientDeferId
            });
          }
        });
        TriageForServer = { ApprovedDeferPatientItems: holdAllTempRecords };
        break;
      default:
        console.log('Invalid mode selected.');
    }
    console.log('TriageForServer:', TriageForServer);
    let metaData = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'cache-control': 'no-cache',
        Authorization: 'Bearer ' + FhtMain.GlobalContext.userToken
      },
      body: JSON.stringify(TriageForServer)
    };
    fetch(url, metaData)
      .then(r => r.json())
      .then(() => {
        this.apiData();
      })
      .catch(e => {
        console.log('error');
        console.log(e);
      });
  };

  onSubmitRecallForApproval = () => {
    let url = '';
    switch (this.state.approvalMode) {
      case approvalType.APPROVERECALL:
        url = `${
          this.state.siteConfigurationContext[Configs.SiteConfigurationMappingKeys.serverapi]
        }${Configs.submitApprovedTriage}`;
        break;

      case approvalType.APPROVEDEFER:
        url = `${
          this.state.siteConfigurationContext[Configs.SiteConfigurationMappingKeys.serverapi]
        }${Configs.submitApprovedDefer}`;
        break;
      default:
        console.log('Invalid mode selected.');
    }
    this.submitRequestToServer(url);
  };

  onDismissRecallForApproval = () => {
    let url = '';
    switch (this.state.approvalMode) {
      case approvalType.APPROVERECALL:
        url = `${
          this.state.siteConfigurationContext[Configs.SiteConfigurationMappingKeys.serverapi]
        }${Configs.dismissRecallApproval}`;
        break;

      case approvalType.APPROVEDEFER:
        url = `${
          this.state.siteConfigurationContext[Configs.SiteConfigurationMappingKeys.serverapi]
        }${Configs.dismissDeferApproval}`;
        break;
      default:
        console.log('Invalid mode selected.');
    }
    this.submitRequestToServer(url);
  };

  onBtnOpenCommentDialog = (patientId) => {
    debugger;
    console.log('patientId, this.state.forWebAPIFilterSearchCohortId')
    debugger
      this.setState({ currentPatientId: patientId});
      this.setState({showCommentDialog:true});
  }
  showMessagesFromComment = (msg, type) => {
    this.props.GlobalFunctionDisplaySnackbarMessage(msg, type);
  };

  onSelectionChanged = () => {
    this.setState({
      isChecked: this.gridApi.getSelectedRows().length > 0
    });
  };

  blurFilters = () => {
    return this.state.displayTutorial ? { filter: 'blur(8px)' } : {};
  };

  defaultColDef = () => {
    return {
      editable: false,
      width: 150,
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true
    };
  };

  aggridColumns = () => {
    var gridColumnRecall = [];

    switch (this.state.approvalMode) {
      case approvalType.APPROVERECALL:
        gridColumnRecall = [
          {
            field: 'patientExtractId',
            headerName: 'Id',
            filter: 'agNumberColumnFilter',
            width: 300,
            hide: true
          },
          {
            field: '',
            headerName: 'Comments',
            //filter: 'agNumberColumnFilter',
            width: 200,
            cellRendererFramework: ChildMessageRenderer,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            //hide: true
          },
          {
            field: 'patientSurname',
            headerName: 'Surname',
            filter: 'agTextColumnFilter',
            width: 300,
            cellRenderer: 'agGroupCellRenderer',
            // checkboxSelection: true,
            // headerCheckboxSelection: true,
            // headerCheckboxSelectionFilteredOnly: true
          },
          
          {
            field: 'patientFirstname',
            headerName: 'Firstname',
            filter: 'agTextColumnFilter',
            width: 300
          },
          { field: 'patientAge', headerName: 'Age', filter: 'agNumberColumnFilter' },
          { field: 'patientGender', headerName: 'Gender', filter: 'agTextColumnFilter' },
          //no workflow status here because it is not applicable - we are in the awaiting approval screen.
          { field: 'preferredDoctor', headerName: 'Usual doctor', filter: 'agTextColumnFilter' },
          {
            field: 'patientNextAppointment',
            headerName: 'Next Appointment',
            filter: 'agDateColumnFilter',
            valueGetter: function getDateGetter(params) {
              if (params.data != null) return getDate(params.data.patientNextAppointment);
              return '';
            }
          },
          {
            field: 'patientLastAppointment',
            headerName: 'Last Appointment',
            filter: 'agDateColumnFilter',
            valueGetter: function getDateGetter(params) {
              if (params.data != null) return getDate(params.data.patientLastAppointment);
              return '';
            }
          },
          {
            field: 'patientAtsi',
            headerName: 'First Nation',
            filter: 'agTextColumnFilter',
            valueGetter: function yesNoGetter(params) {
              if (params.data != null) return getYesNo(params.data.patientAtsi);
              return '';
            }
          },
          {
            field: 'patientSmoker',
            headerName: 'Smoker',
            filter: 'agTextColumnFilter',
            valueGetter: function yesNoGetter(params) {
              if (params.data != null) return getYesNo(params.data.patientSmoker);
              return '';
            }
          },
          {
            field: 'patientMobileNumber',
            headerName: 'Contact Number',
            filter: 'agTextColumnFilter',
            width: 300
          },
          {
            field: 'patientAddress',
            headerName: 'Address',
            filter: 'agTextColumnFilter',
            width: 300
          },
          { field: 'patientEmail', headerName: 'Email', filter: 'agTextColumnFilter', width: 300 },
          {
            field: 'recallRecallReason',
            headerName: 'Recall Reason',
            filter: 'agTextColumnFilter'
          },
          {
            field: 'recallDateCreated',
            headerName: 'Created date',
            filter: 'agDateColumnFilter',
            width: 200,
            valueGetter: function getDateGetter(params) {
              if (params.data != null) return getDate(params.data.recallDateCreated);
              return '';
            }
          }
        ];
        break;

      case approvalType.APPROVEDEFER:
        gridColumnRecall = [
          {
            field: '',
            headerName: 'Comments',
            //filter: 'agNumberColumnFilter',
            width: 200,
            cellRendererFramework: ChildMessageRenderer,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            //hide: true
          },
          {
            field: 'patientExtractId',
            headerName: 'Id',
            filter: 'agNumberColumnFilter',
            width: 300,
            hide: true
          },
          {
            field: 'patientExtractSurname',
            headerName: 'Surname',
            filter: 'agTextColumnFilter',
            width: 300,
            cellRenderer: 'agGroupCellRenderer',
            // checkboxSelection: true,
            // headerCheckboxSelection: true,
            // headerCheckboxSelectionFilteredOnly: true
          },

          {
            field: 'patientExtractFirstname',
            headerName: 'Firstname',
            filter: 'agTextColumnFilter',
            width: 300
          },
          { field: 'patientExtractAge', headerName: 'Age', filter: 'agNumberColumnFilter' },
          { field: 'patientExtractGender', headerName: 'Gender', filter: 'agTextColumnFilter' },
          //no workflow status here because it is not applicable - we are in the awaiting approval screen.
          {
            field: 'patientExtractUsualDoctor',
            headerName: 'Usual doctor',
            filter: 'agTextColumnFilter'
          },
          {
            field: 'patientExtractNextAppointment',
            headerName: 'Next Appointment',
            filter: 'agDateColumnFilter',
            valueGetter: function getDateGetter(params) {
              if (params.data != null) return getDate(params.data.patientExtractNextAppointment);
              return '';
            }
          },
          {
            field: 'patientExtractLastAppointment',
            headerName: 'Last Appointment',
            filter: 'agDateColumnFilter',
            valueGetter: function getDateGetter(params) {
              if (params.data != null) return getDate(params.data.patientExtractLastAppointment);
              return '';
            }
          },
          {
            field: 'patientExtractAtsi',
            headerName: 'First Nation',
            filter: 'agTextColumnFilter',
            valueGetter: function yesNoGetter(params) {
              if (params.data != null) return getYesNo(params.data.patientExtractAtsi);
              return '';
            }
          },
          {
            field: 'patientExtractSmoker',
            headerName: 'Smoker',
            filter: 'agTextColumnFilter',
            valueGetter: function yesNoGetter(params) {
              if (params.data != null) return yesNoGetter(params.data.patientExtractSmoker);
              return '';
            }
          },

          {
            field: 'patientDeferDateEnd',
            headerName: 'Defer end date',
            filter: 'agDateColumnFilter',
            width: 300,
            valueGetter: function getDateGetter(params) {
              if (params.data != null) return getDate(params.data.patientDeferDateEnd);
              return '';
            }
          },
          {
            field: 'patientDeferDateCreated',
            headerName: 'Created date',
            filter: 'agDateColumnFilter',
            width: 300,
            valueGetter: function getDateGetter(params) {
              if (params.data != null) return getDate(params.data.patientDeferDateCreated);
              return '';
            }
          },
          {
            field: 'patientDeferReason',
            headerName: 'Defer reason',
            filter: 'agTextColumnFilter',
            width: 300
          }
        ];
        break;
      default:
        console.log('Invalid mode selected.');
    }

    return gridColumnRecall;
  };

  componentsDef = () => {
    return { ProposedActionCellRenderer: ProposedActionCellRenderer('N/A') };
  };

  //Render Functions
  renderAgGrid = () => {
    return (
            
      <AgGridReact
        onGridReady={this.onGridReady}
        defaultColDef={this.defaultColDef()}
        rowSelection="multiple"
        onSelectionChanged={this.onSelectionChanged}
        floatingFilter={true}
        singleClickEdit={true}
        columnDefs={this.aggridColumns()}
        groupSelectsChildren={true}
        sideBar={true}
        groupIncludeFooter={true}
        rowData={this.state.data}
        headerHeight={40}
        components={this.componentsDef()}
        masterDetail={true}
        detailCellRenderer={this.state.detailCellRenderer}
        detailRowHeight={this.state.detailRowHeight}
        detailRowWidth={this.state.detailRowWidth}
        groupDefaultExpanded={0}
        frameworkComponents={this.state.frameworkComponents}
        context={this.state.aggcontext}
        onColumnResized={this.onColumnResized}
      ></AgGridReact>
      
    );
  };
  OnApproveCategoryChanged = (e, newFormat) => {
    console.log("newFormat:",newFormat);
    console.log("formatted string:",newFormat[newFormat.length - 1]);
    
    if (newFormat[newFormat.length - 1] == approvalType.APPROVERECALL || newFormat[newFormat.length - 1] == approvalType.APPROVEDEFER)
    {
    this.setState({ approvalMode: newFormat[newFormat.length - 1] }, () => {
      this.apiData();
    });
    }
  
  };
  render() {
    console.log ("this.state.approvalMode:",this.state.approvalMode);
    let buttonApproveLabel = 'Unassigned';
    let buttonCancelLabel = 'Unassigned';
    switch (this.state.approvalMode) {
      case approvalType.APPROVERECALL:
        buttonApproveLabel = 'Approve for Recall';
        buttonCancelLabel = 'Cancel Recall';
        break;
      case approvalType.APPROVEDEFER:
        buttonApproveLabel = 'Approve for Defer';
        buttonCancelLabel = 'Cancel Defer';
        break;
      default:
        console.log('Invalid mode selected.');
    }
    return (
      <div>
        <div className="grid-container" style={this.blurFilters()}>
          <Paper>
            <div style={paddingTop}>
              <span>
                <span style={{ textAlign: 'left', fontSize: 18, fontWeight: 'bold' }}>
                  Patients Awaiting Approval
                </span>
                <span style={{ width: '5REM', height: 'auto', display: 'inline-block' }}></span>

                <ToggleButtonGroup
                  value={this.state.approvalMode}
                  onChange={(e,value) => this.OnApproveCategoryChanged(e,value)}
                  arial-label="text formatting"
                  color="secondary"
                  style={{ width: 'fit-content', marginBottom: '1REM' }}
                >
                  <ToggleButton value={approvalType.APPROVERECALL} aria-label="bold">
                    Approve Recall
                  </ToggleButton>
                  <ToggleButton value={approvalType.APPROVEDEFER} aria-label="bold">
                    Approve Defer
                  </ToggleButton>
                </ToggleButtonGroup>
                <br />
              </span>
              <Button
                variant="outlined"
                color="inherit"
                style={marginRight}
                onClick={this.throttleApproveClick}
                disabled={!this.state.isChecked}
              >
                <span style={smallPaddingRight}>
                  <CheckCircleOutline style={icon} />
                </span>
                <span>
                {buttonApproveLabel}
                </span>
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                style={{ paddingRight: '1REM', float: 'right' }}
                onClick={this.onDismissRecallForApproval}
                disabled={!this.state.isChecked}
              >
                <span style={smallPaddingRight}>
                  <CancelIcon style={icon} />
                </span>
                {buttonCancelLabel}
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                style={{ paddingRight: '1REM', float: 'right' }}
                onClick={this.onBtExport}
              >
                <span style={smallPaddingRight}>
                  <Print style={icon} />
                </span>
                Export/Print
              </Button>
            </div>
          </Paper>
          <Paper style={{ backgroundColor: 'transparent', height: '100%' }}>
            <div
              id="agRecallGrid"
              className="ag-theme-material my-grid"
              style={{ height: `${this.state.height}px` }}
            >
              {this.renderAgGrid()}
              
            </div>
          </Paper>
          <CommentDialog header=""
          visible={this.state.showCommentDialog}
          //footer={deferDialogFooter}
          //onSubmit={e => this.onBtnSubmitDefer()}
          onHide={e => this.setState({showCommentDialog: false})}
          maxWidth="false"
          open={this.state.showCommentDialog}
          patientId={this.state.currentPatientId}
          cohortId={this.state.forWebAPIFilterSearchCohortId}
          showMessages={this.showMessagesFromComment}
        >
        </CommentDialog>
        </div>
      </div>
    );
  }
}
function getDate(params) {
  // eslint-disable-next-line
  if (params == null || params == undefined) return '';
  let arr = params.split('T')[0].split('-');
  let convertDate = `${arr[2]}-${arr[1]}-${arr[0]}`;
  // return params.split("T")[0];
  return convertDate;
}

function getYesNo(integerValue) {
  let result = 'No';
  if (integerValue === 1) result = 'Yes';
  return result;
}

function getSmokerStatus(integerValue) {
  let result = 'Not recorded';
  if (integerValue === 0) 
    result = 'Nonsmoker'
  else if (integerValue === 1) 
    result = 'Current smoker';
  else if (integerValue === 2) 
    result = 'exsmoker'
  return result;
}

export default PatientApprovalScreen;
