import React, { Component } from "react";
import Configs from "../../Configuration"
import * as _ from 'underscore';

import moment from "moment";


import { DashboardFeedbackCohortCurrentStageGraph,
         DashboardCohortAllChangeInStatusLineChart,
         DashboardMonitoringRmStatusEachMonthLineChart } from "./DashboardCohortGraphs"

import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Dropdown } from 'semantic-ui-react'
import RGL, { WidthProvider } from 'react-grid-layout';
import Grid from '@material-ui/core/Grid';
import './DashboardCohortCSS.css';
import { LineScale } from 'react-pure-loaders';
import Button from '@material-ui/core/Button';

// import {BrowserRouter as Router, Route, Link, Redirect} from 'react-router-dom';
// import Button from '@material-ui/core/Button';
// import { FormControl } from "@material-ui/core";
// import { AtRiskButton, PossibleButton, IntensifyAndInitiateButton, 
//         InitiateAndIntensifyButton, RequiresCodingButton, LoadAllGraphsButton } from "./CKDVariablesButtons";
// import DatePickerChooser from "./DatePicker";

//////////////////////////////////////////////
// TAG: COHORT DATA
// TAG: FUNCTION TO ADD IN ZEROS TO DATA IF ITS NOT PRESENT IN DATABASE
// THIS IS THE ARRAY THAT WILL HOLD ALL STAGE TYPES
// const data = ["testOne", "testTwo", "testThree"]

// This is the LINQ QUERY RETURNED DATA
// const newData = [{
//   "testOne" : 1},
//   {
//   "testTwo" : 3
//   }
  
// ]

// const returnedData = []

// for (var z = 0; z < data.length; z ++) {
  
// for (var i = 0 ; i < newData.length; i ++) {

//   if (Object.keys(newData[i]) == data[z]) {
//     console.log("Test")
//     returnedData.push(newData[i])
//     break
//   } else if (i == newData.length - 1) {
//     returnedData.push({[data[z]] : 0})
//     console.log("Pushing variable not there")
//   }
// }
// }
// console.log(returnedData)
//////////////////////////////////////////////

const ReactGridLayout = WidthProvider(RGL);

class DashboardFeedbackScreen extends Component {

  constructor(props) {
    super(props);
    let newDateObject = new Date();
    let curentDate = moment(new Date(newDateObject.getFullYear(), newDateObject.getMonth(), newDateObject.getDate(), 0, 0, 0, 0));

        //////////////////////////////////////////////
    // TAG: COHORT GROUP BY
    console.log('Our data is fetched');
    // this.queryDatabaseOne()
    let url;
    // Change the URL here to choose new function/routes to be called.
    url = "http://localhost:57502/AllCurrentCohortStages";
    let urlObj = Configs.urlGetObj;
    //let urlHeader = Object.assign({'Authorization': 'Bearer ' + AppSequential.GlobalContext.userToken}, Configs.urlHeader.headers);
    //let urlHeader = Object.assign(
    //  console.log("headers:",Configs);
    urlObj = Object.assign({ headers: Configs.urlHeader.headers }, urlObj);
    fetch(url, urlObj).then(r => r.json())
      .then((response1) => {
        //console.log("summary response:", response1);
        //console.log("Set Unique Cohort Name State")
        this.setState({
          uniqueCohortNames: response1.allCohortNamesJSON,
          originalCohortNames: response1.allCohortNamesJSON
        })

        this.createCohortNameFilters(JSON.parse(this.state.uniqueCohortNames))

      })
      .catch((e) => {
        console.log("dashboardSummaryCKDNumbers returned errors.");
      }
      );
    //////////////////////////////////////////////

    //////////////////////////////////////////////
    // TAG: COHORT GROUP BY
    console.log('Our data is fetched');
    // this.queryDatabaseOne()
    // let url;
    // Change the URL here to choose new function/routes to be called.
    url = "http://localhost:57502/CohortCurrentStage";
    // let urlObj = Configs.urlGetObj;
    //let urlHeader = Object.assign({'Authorization': 'Bearer ' + AppSequential.GlobalContext.userToken}, Configs.urlHeader.headers);
    //let urlHeader = Object.assign(
    //  console.log("headers:",Configs);
    urlObj = Object.assign({ headers: Configs.urlHeader.headers }, urlObj);
    fetch(url, urlObj).then(r => r.json())
      .then((response2) => {
        //console.log("summary response:", response2);

        this.setState({
          cohortJSONWebAPIArray: response2,
          originalJSONWebAPI: response2
        })
        //console.log("Finish setting state")
        //console.log(JSON.stringify(this.state.cohortJSONWebAPIArray))
      })
      .catch((e) => {
        console.log("dashboardSummaryCKDNumbers returned errors.");
      }
      );
    //////////////////////////////////////////////

        //////////////////////////////////////////////
    // TAG: COHORT CHECK CHANGE IN STATUS
    console.log('Our data is fetched');
    // this.queryDatabaseOne()
    // let url;
    // Change the URL here to choose new function/routes to be called.
    url = "http://localhost:57502/CohortPatientStatusChange";
    // let urlObj = Configs.urlGetObj;
    //let urlHeader = Object.assign({'Authorization': 'Bearer ' + AppSequential.GlobalContext.userToken}, Configs.urlHeader.headers);
    //let urlHeader = Object.assign(
    //  console.log("headers:",Configs);
    urlObj = Object.assign({ headers: Configs.urlHeader.headers }, urlObj);
    fetch(url, urlObj).then(r => r.json())
      .then((response3) => {
        console.log("CohortPatientStatusChange")
        console.log("summary response:", response3);

        var holdAllCohortNames = []
        var resultantDictionary = []
        var holdAllCohortKeyNames = []

        for (var key in response3.patientStageChange) {

          if (holdAllCohortNames.includes(key) === false) {
            holdAllCohortNames.push(key)
          }

        }

        //console.log(holdAllCohortNames)

        for (var cohortStringName in holdAllCohortNames) {
          //console.log(holdAllCohortNames[cohortStringName])
          var convertToArray = JSON.parse(response3.patientStageChange[holdAllCohortNames[cohortStringName]])

          for (var jsonObject in convertToArray) {
            // console.log("CHECK INSIDe")
            // console.log(convertToArray[jsonObject])
            if (holdAllCohortNames.includes(holdAllCohortNames[cohortStringName] + "-"+ convertToArray[jsonObject].RmMessage) === false) {
              resultantDictionary.push({
                "id": String(holdAllCohortNames[cohortStringName] + "-"+ convertToArray[jsonObject].RmMessage),
                "data": []
              })
              holdAllCohortNames.push(holdAllCohortNames[cohortStringName] + "-"+ convertToArray[jsonObject].RmMessage)
            }
          }

        }

        //console.log("RESULTANT DICTIONARY")
        //console.log(resultantDictionary)

        for(var nextCohortStringName in holdAllCohortNames) {

          try {
            var nextConvertToArray = JSON.parse(response3.patientStageChange[holdAllCohortNames[nextCohortStringName]])
            for(var nextJSONObject in nextConvertToArray) {
            
              for(var finalJSONObject in resultantDictionary) {
                //console.log("THIS IS GOOD")
                if(String(holdAllCohortNames[nextCohortStringName]) +"-" +  String(nextConvertToArray[nextJSONObject].RmMessage) === resultantDictionary[finalJSONObject].id) {
                  //console.log("THIS IS GOOD")
                  resultantDictionary[finalJSONObject].data.push(
                    {
                      "x": nextConvertToArray[nextJSONObject].Date,
                      "y": nextConvertToArray[nextJSONObject].Count
                    }
                  )
                  //console.log(resultantDictionary)
                }
  
              }
            }
          } catch(a) {

            const regex = /[A-Za-z0-9]+/g; 

            const months = 
                  [ 'January',  'February', 'March',    'April'
                  , 'May',       'June',    'July',     'August'
                  , 'September', 'October', 'November', 'December'
                  ] 

            for (var cohortKey in resultantDictionary) {
              //console.log(resultantDictionary[cohortKey].id)
              holdAllCohortKeyNames.push(resultantDictionary[cohortKey].id)

              let resp = resultantDictionary[cohortKey].data
              .map( elm=>( {...elm,t:elm.x.match(regex) }))
              .map( elm=> ({x:elm.x, y:elm.y, d:`${elm.t[1]}-${ ('0'+months.findIndex(m=>m==elm.t[0])).slice(-2) }`}))
              .sort( (a,b)=>a.d.localeCompare(b.d) )
              .map( elm=>({x:elm.x, y:elm.y}))

              //resp.forEach(elm=> console.log(JSON.stringify(elm)))

              resultantDictionary[cohortKey].data = []
              //console.log(resultantDictionary[cohortKey].data)
              resultantDictionary[cohortKey].data = resp
              //console.log(resultantDictionary[cohortKey].data)
              // console.log("SORTED ARRAY")
              // console.log(resp)
            }

            this.setState({
              cohortJSONData: resultantDictionary,
              allCohortAndRmMessage : holdAllCohortKeyNames
            })

            this.createCohortChangeInStatusFilters(holdAllCohortKeyNames)
            //console.log("COHORTJSONDATA")
            //console.log(this.state.cohortJSONData)
            //console.log(this.state.cohortJSONData)
            break
          }
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("dashboardSummaryCKDNumbers returned errors.");
      }
      );
    //////////////////////////////////////////////
    //////////////////////////////////////////////
    // TAG: RECORDING EACH RM MESSAGE PER MONTH
    console.log('Our data is fetched');
    // this.queryDatabaseOne()
    // let url;
    // Change the URL here to choose new function/routes to be called.
    url = "http://localhost:57502/RecordingRmMessagesEachMonth";
    // let urlObj = Configs.urlGetObj;
    //let urlHeader = Object.assign({'Authorization': 'Bearer ' + AppSequential.GlobalContext.userToken}, Configs.urlHeader.headers);
    //let urlHeader = Object.assign(
    //  console.log("headers:",Configs);
    urlObj = Object.assign({ headers: Configs.urlHeader.headers }, urlObj);
    fetch(url, urlObj).then(r => r.json())
      .then((response4) => {

        this.setState({
          newCohortJSONWebAPIArray: response4
        })

        //console.log("RecordingRmMessagesEachMonth")
        //console.log("summary response:", response4);

        var newHoldAllCohortNames = []
        var newResultantDictionary = []
        var newHoldAllCohortKeyNames = []

        for (var key in response4.accumulativeCohortRmMessageRecording) {

          if (newHoldAllCohortNames.includes(key) === false) {
            newHoldAllCohortNames.push(key)
          }

        }

        //console.log(newHoldAllCohortNames)

        for (var cohortStringName in newHoldAllCohortNames) {
          //console.log(newHoldAllCohortNames[cohortStringName])
          var convertToArray = JSON.parse(response4.accumulativeCohortRmMessageRecording[newHoldAllCohortNames[cohortStringName]])

          for (var jsonObject in convertToArray) {
            // console.log("CHECK INSIDe")
            // console.log(convertToArray[jsonObject])
            if (newHoldAllCohortNames.includes(newHoldAllCohortNames[cohortStringName] + "-"+ convertToArray[jsonObject].RmMessage) === false) {
              newResultantDictionary.push({
                "id": String(newHoldAllCohortNames[cohortStringName] + "-"+ convertToArray[jsonObject].RmMessage),
                "data": []
              })
              newHoldAllCohortNames.push(newHoldAllCohortNames[cohortStringName] + "-"+ convertToArray[jsonObject].RmMessage)
            }
          }

        }

        console.log("RESULTANT DICTIONARY")
        console.log(JSON.stringify(newResultantDictionary))
        console.log(newResultantDictionary)

        for(var nextCohortStringName in newHoldAllCohortNames) {

          try {
            var nextConvertToArray = JSON.parse(response4.accumulativeCohortRmMessageRecording[newHoldAllCohortNames[nextCohortStringName]])
            for(var nextJSONObject in nextConvertToArray) {
            
              for(var finalJSONObject in newResultantDictionary) {
                //console.log("THIS IS GOOD")
                if(String(newHoldAllCohortNames[nextCohortStringName]) +"-" +  String(nextConvertToArray[nextJSONObject].RmMessage) === newResultantDictionary[finalJSONObject].id) {
                  //console.log("THIS IS GOOD")
                  newResultantDictionary[finalJSONObject].data.push(
                    {
                      "x": nextConvertToArray[nextJSONObject].Date,
                      "y": nextConvertToArray[nextJSONObject].Count
                    }
                  )
                  //console.log(newResultantDictionary)
                }
  
              }
            }
          } catch(a) {


            console.log("THIS HAS BEEN CACHED")

            const regex = /[A-Za-z0-9]+/g; 

            const months = 
                  [ 'January',  'February', 'March',    'April'
                  , 'May',       'June',    'July',     'August'
                  , 'September', 'October', 'November', 'December'
                  ] 

            console.log("nonmodified", JSON.stringify(newHoldAllCohortKeyNames))

            for (var cohortKey in newResultantDictionary) {
              //console.log(newResultantDictionary[cohortKey].id)
              newHoldAllCohortKeyNames.push(newResultantDictionary[cohortKey].id)

              let resp = newResultantDictionary[cohortKey].data
              .map( elm=>( {...elm,t:elm.x.match(regex) }))
              .map( elm=> ({x:elm.x, y:elm.y, d:`${elm.t[1]}-${ ('0'+months.findIndex(m=>m==elm.t[0])).slice(-2) }`}))
              .sort( (a,b)=>a.d.localeCompare(b.d) )
              .map( elm=>({x:elm.x, y:elm.y}))

              //resp.forEach(elm=> console.log(JSON.stringify(elm)))

              newResultantDictionary[cohortKey].data = []
              //console.log(resultantDictionary[cohortKey].data)
              newResultantDictionary[cohortKey].data = resp
              //console.log(resultantDictionary[cohortKey].data)
              // console.log("SORTED ARRAY")
              // console.log(resp)
            }

            console.log("modified", JSON.stringify(newHoldAllCohortKeyNames))

            this.setState({
              newCohortJSONData: newResultantDictionary,
              newAllCohortAndRmMessage : newHoldAllCohortKeyNames
            })

            this.createNewCohortChangeInStatusFilters(newHoldAllCohortKeyNames)
            //console.log("New Cohort JSON Data")
            //console.log(this.state.newCohortJSONData)
            //console.log(this.state.cohortJSONData)
            break
          }
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("dashboardSummaryCKDNumbers returned errors.");
      }
      );



    //////////////////////////////////////////////
    //////////////////////////////////////////////
    // TAG: Three Dimensional Data
    console.log('Our data is fetched');
    // this.queryDatabaseOne()
    // let url;
    // Change the URL here to choose new function/routes to be called.
    url = "http://localhost:57502/ThreeDimensionalData";
    // let urlObj = Configs.urlGetObj;
    //let urlHeader = Object.assign({'Authorization': 'Bearer ' + AppSequential.GlobalContext.userToken}, Configs.urlHeader.headers);
    //let urlHeader = Object.assign(
    //  console.log("headers:",Configs);
    urlObj = Object.assign({ headers: Configs.urlHeader.headers }, urlObj);
    fetch(url, urlObj).then(r => r.json())
      .then((response5) => {

        //console.log("ThreeDimensionalData")
        //console.log("summary response:", response5);

        this.setState({
          threeDimensionalData: response5
        })

        //console.log("THIS IS THREE DIMENSIONAL DATA")
        //console.log(this.state.threeDimensionalData)
         })
      .catch((e) => {
        console.log(e)
        console.log("dashboardSummaryCKDNumbers returned errors.");
      }
      );



    //////////////////////////////////////////////
    //////////////////////////////////////////////

    this.state = {

      holdAllDoubleQueries: [],

      cohortJSONWebAPIArray: "",
      uniqueCohortNames: "",
      cohortFilterOptions: "",
      cohortFilterOptionsDropdown: [],
      latestJSONData: "",
      originalJSONWebAPI: "",
      originalCohortNames: "",

      cohortJSONData: "",
      allCohortAndRmMessage: [],
      cohortChangeInStatusFilterOptions: "",
      cohortChangeInStatusFilterOptionsDropdown: [],
      updatedCohortJSONData: "",

      newCohortJSONData: "",
      newAllCohortAndRmMessage: [],
      newCohortChangeInStatusFilterOptions: "",
      newCohortChangeInStatusFilterOptionsDropdown: [],
      newUpdatedCohortJSONData: "",

      threeDimensionalData: ""

    };

    console.log("incoming props for aggrid:", this.props);
    //this.props.GlobalFunctionDisplaySnackbarMessage("This is the feedback screen.", Configs.snackbarVariants.success);
    // this.showCurrentGraph = this.showCurrentGraph.bind(this);
    this.ifLoadingJSONWebAPI = this.ifLoadingJSONWebAPI.bind(this);
    this.handleCohortFilterChange = this.handleCohortFilterChange.bind(this);
    this.createCohortNameFilters = this.createCohortNameFilters.bind(this);
    this.ifLoadingAllCohortGraph = this.ifLoadingAllCohortGraph.bind(this);
    this.createCohortChangeInStatusFilters = this.createCohortChangeInStatusFilters.bind(this);
    this.handleCohortChangeInStatusFilterChange = this.handleCohortChangeInStatusFilterChange.bind(this);

    this.ifLoadingSecondAllCohortGraph = this.ifLoadingSecondAllCohortGraph.bind(this);
    this.createNewCohortChangeInStatusFilters = this.createNewCohortChangeInStatusFilters.bind(this);
    this.handleNewCohortChangeInStatusFilterChange = this.handleNewCohortChangeInStatusFilterChange.bind(this);
  }

  createFilterOptions(dictionaryToParse, dictionaryKey, diseaseType) {
    for (var stringToBeParsed in dictionaryToParse) {
      //console.log(stringToBeParsed)
      var initialSplit = String(stringToBeParsed).split(',')
      var combinedString = initialSplit.join(' + ')
      this.state.options[String(dictionaryKey)].push({ key: String(stringToBeParsed), text: String(diseaseType) + ": " + String(stringToBeParsed), value: String(diseaseType) + ": " + String(combinedString) }
      )
    }
  }

  createCohortNameFilters(dictionaryToParse) {
    //console.log("Heat Map Options being created")
    //console.log(dictionaryToParse.length)
    for (var i = 0; i < dictionaryToParse.length; i++) {
      console.log(dictionaryToParse[i])
      // var initialSplit = String(dictionaryToParse[i]).split(',')
      // var combinedString = initialSplit.join(' + ')
      this.state.cohortFilterOptionsDropdown.push({ key: String(dictionaryToParse[i]), text: String(dictionaryToParse[i]), value: String(dictionaryToParse[i]) }
      )
    }
  }

  createCohortChangeInStatusFilters(dictionaryToParse) {
    //console.log("Heat Map Options being created")
    //console.log(dictionaryToParse.length)
    for (var i = 0; i < dictionaryToParse.length; i++) {
      //console.log(dictionaryToParse[i])
      // var initialSplit = String(dictionaryToParse[i]).split(',')
      // var combinedString = initialSplit.join(' + ')
      this.state.cohortChangeInStatusFilterOptionsDropdown.push({ key: String(dictionaryToParse[i]), text: String(dictionaryToParse[i]), value: String(dictionaryToParse[i]) }
      )
    }
  }

  createNewCohortChangeInStatusFilters(dictionaryToParse) {
    //console.log("Heat Map Options being created")
    //console.log(dictionaryToParse.length)
    for (var i = 0; i < dictionaryToParse.length; i++) {
      //console.log(dictionaryToParse[i])
      // var initialSplit = String(dictionaryToParse[i]).split(',')
      // var combinedString = initialSplit.join(' + ')
      this.state.newCohortChangeInStatusFilterOptionsDropdown.push({ key: String(dictionaryToParse[i]), text: String(dictionaryToParse[i]), value: String(dictionaryToParse[i]) }
      )
    }
  }

  
  handleAllCohortNameChange = (_p, { value }) => {
    this.setState({
      currentlyChosenCohortName: value
    });
  };

  handleAllRmMessageChange = (_p, { value }) => {
    this.setState({
      currentlyChosenRmMessageName: value
    });
  };

  handleCohortChangeInStatusFilterChange = (_p, { value }) => {
      var changeInDictionary = []
      //console.log("CHANGE IN FILTER STATUS OPTIONS")
      //console.log(value)
      //this.wait(7000);

      for (var i = 0; i < value.length; i++) { 

        for (var z = 0; z < this.state.cohortJSONData.length; z++) {

          if (value[i] == this.state.cohortJSONData[z].id) {
            changeInDictionary.push(this.state.cohortJSONData[z])
          }
        }
      }

      //console.log("CHANGE IN DICTIONARY")
      //console.log(JSON.stringify(changeInDictionary))
      this.setState({ 
        updatedCohortJSONData: JSON.parse(JSON.stringify(changeInDictionary))
      })

  }

  handleNewCohortChangeInStatusFilterChange = (_p, { value }) => {
    var changeInDictionary = []
    //console.log("CHANGE IN FILTER STATUS OPTIONS")
    //console.log(value)
    //this.wait(7000);
    //console.log("LATEST LATEST COHORT JSON DATA")
    //console.log(this.state.newCohortJSONData)

    for (var i = 0; i < value.length; i++) { 

      for (var z = 0; z < this.state.newCohortJSONData.length; z++) {

        if (value[i] == this.state.newCohortJSONData[z].id) {
          changeInDictionary.push(this.state.newCohortJSONData[z])
        }
      }
    }

    //console.log("CHANGE IN DICTIONARY")
    //console.log(JSON.stringify(changeInDictionary))
    this.setState({ 
      newUpdatedCohortJSONData: JSON.parse(JSON.stringify(changeInDictionary))
    })

    //console.log("UPDATING COHORT JSON DATA")
    //console.log(this.state.newUpdatedCohortJSONData)

}

  handleCohortFilterChange = (_p, { value }) => {
    this.setState({ cohortFilterOptions: value + '' });
    //console.log("VLAUE")
    //console.log(value)
    // console.log(JSON.parse(JSON.stringify(value)))
    //console.log("UNIQUE COHORT NAMES")
    
    var buildString = ""
    var loopThrough = this.state.cohortJSONWebAPIArray.holdAllCohortAndCurrentStage
    var holdAllCohortAndCurrentStage = {}

    for (var i = 0; i < value.length; i++) { 
      //console.log("LOOPING THROUGH value")
      //console.log(loopThrough[value[i]])
      holdAllCohortAndCurrentStage[value[i]]  = loopThrough[value[i]]
    }

    //console.log("BUILDING DICTIONARY")
    //console.log(JSON.stringify({holdAllCohortAndCurrentStage}))
    // console.log("HOLD ALL POSSIBLE VALUES")
    // console.log(holdAllCohortAndCurrentStage)
    // console.log("LOOPING THROUGJH ALL VALUES")
    // console.log("ALL COHORT AND CURRENT STAGE")
    // console.log(JSON.parse(JSON.stringify({holdAllCohortAndCurrentStage})))
    // //var holdAllCohortAndCurrentStage = buildString.substring(0, buildString.length - 1) + "}}";
    // console.log("BUILDING STRING")
    // console.log(buildString)
    // var JSONParse = JSON.parse(JSON.stringify({holdAllCohortAndCurrentStage}))
    // console.log("TYPE OF JSON")
    // console.log(typeof JSONParse)
    // console.log("PARSING JSON")
    // console.log(JSONParse)
    //console.log("COMPARING STRINGS JSON")
    //console.log(this.state.cohortJSONWebAPIArray)
    //console.log(JSON.parse(JSON.stringify({holdAllCohortAndCurrentStage})))
    
     this.setState({
      latestJSONData: JSON.parse(JSON.stringify({holdAllCohortAndCurrentStage})),
       uniqueCohortNames: value
    })

    //console.log("END OF LINE")
  };

  handleNewCohortFilterChange = (_p, { value }) => {
    this.setState({ newCohortFilterOptions: value + '' });
    //console.log("VLAUE")
    //console.log(value)
    // console.log(JSON.parse(JSON.stringify(value)))
    //console.log("UNIQUE COHORT NAMES")
    
    var buildString = ""
    var loopThrough = this.state.newCohortJSONWebAPIArray.accumulativeCohortRmMessageRecording
    var accumulativeCohortRmMessageRecording = {}

    for (var i = 0; i < value.length; i++) { 
      //console.log("LOOPING THROUGH value")
      //console.log(loopThrough[value[i]])
      accumulativeCohortRmMessageRecording[value[i]]  = loopThrough[value[i]]
    }

    //console.log("BUILDING DICTIONARY")
    //console.log(JSON.stringify({holdAllCohortAndCurrentStage}))
    // console.log("HOLD ALL POSSIBLE VALUES")
    // console.log(holdAllCohortAndCurrentStage)
    // console.log("LOOPING THROUGJH ALL VALUES")
    // console.log("ALL COHORT AND CURRENT STAGE")
    // console.log(JSON.parse(JSON.stringify({holdAllCohortAndCurrentStage})))
    // //var holdAllCohortAndCurrentStage = buildString.substring(0, buildString.length - 1) + "}}";
    // console.log("BUILDING STRING")
    // console.log(buildString)
    // var JSONParse = JSON.parse(JSON.stringify({holdAllCohortAndCurrentStage}))
    // console.log("TYPE OF JSON")
    // console.log(typeof JSONParse)
    // console.log("PARSING JSON")
    // console.log(JSONParse)
    //console.log("COMPARING STRINGS JSON")
    //console.log(this.state.cohortJSONWebAPIArray)
    //console.log(JSON.parse(JSON.stringify({holdAllCohortAndCurrentStage})))
    
     this.setState({
      newLatestJSONData: JSON.parse(JSON.stringify({accumulativeCohortRmMessageRecording})),
      newUniqueCohortNames: value
    })

    //console.log("END OF LINE")
  };

  

  ifLoadingJSONWebAPI() {
    if (this.state.cohortJSONWebAPIArray == "" && this.state.cohortJSONData == "" ) {
      return <div> <LineScale color={'black'} loading={true} /> </div>
    } else {
      return <div align="center">
      <ReactGridLayout className="layout" cols={12} rowHeight={75} width={1900}>
        <div className="GridClass-link searchBar" key="aaa" data-grid={{ x: 0, y: 0, w: 12, h: 7, static: true, isResizable: true }}>
          <div className="graphBorders" style={{ height: 500, width: "100%" }}>
            <p className="titleColor" align="center"> Patient cohort classifications </p>
            <DashboardFeedbackCohortCurrentStageGraph parentJSONData={this.state.cohortJSONWebAPIArray} cohortNames = {this.state.uniqueCohortNames}
            updatedJSON = {this.state.latestJSONData} originalParentJSON = {this.state.originalJSONWebAPI}
            originalCohortNamesForUse = {this.state.originalCohortNames} />
            <Dropdown onChange={this.handleCohortFilterChange} placeholder='Click to Filter Graph' fluid multiple selection search
              options={this.state.cohortFilterOptionsDropdown} /> 
          </div>
        </div>


        <div className="GridClass-link searchBarTwo" key="aab" data-grid={{ x: 0, y: 7, w: 12, h: 7, static: true, isResizable: true }}>
        {this.ifLoadingAllCohortGraph()}
        </div>

        <div className="GridClass-link searchBarThree" key="aad" data-grid={{ x: 0, y: 14, w: 12, h: 7, static: true, isResizable: true }}>
      {this.ifLoadingSecondAllCohortGraph()}
      </div>
        
        
      </ReactGridLayout>
      </div>
    }
  }

  ifLoadingAllCohortGraph() {
    //console.log("PRINTINT COHORT RM MESSAGES")
    //console.log(this.state.allCohortAndRmMessage)
    //console.log(this.state.cohortJSONData)
    if (this.state.cohortJSONData == "" ) {
      return <div> <LineScale color={'black'} loading={true} /> </div>
    } else {
      return <div className="graphBorders" style={{ height: 500, width: "100%" }}>
        <p className="titleColor" align="center"> Patient cohort change in status </p>
        <DashboardCohortAllChangeInStatusLineChart secondOriginalParentJSON = {this.state.cohortJSONData}
        updatedParentJSONData = {this.state.updatedCohortJSONData} />

        <Dropdown onChange = {this.handleCohortChangeInStatusFilterChange} placeholder='Click to Filter Graph' fluid multiple selection search
              options={this.state.cohortChangeInStatusFilterOptionsDropdown} /> 

      </div>

    }
  }

  ifLoadingSecondAllCohortGraph() {
    //console.log("PRINTINT COHORT RM MESSAGES")
    //console.log(this.state.allCohortAndRmMessage)
    //console.log(this.state.cohortJSONData)
    if (this.state.newCohortJSONData == "" ) {
      return <div> <LineScale color={'black'} loading={true} /> </div>
    } else {
      return <div className="graphBorders" style={{ height: 500, width: "100%" }}>
        <p className="titleColor" align="center"> Another monitoring cohort changes </p>
        <DashboardMonitoringRmStatusEachMonthLineChart originalParentJSON = {this.state.newCohortJSONData}
        updatedParentJSONData = {this.state.newUpdatedCohortJSONData}  />

        <Dropdown onChange={this.handleNewCohortChangeInStatusFilterChange} placeholder='Click to Filter Graph' fluid multiple selection search
              options={this.state.newCohortChangeInStatusFilterOptionsDropdown} /> 
        </div>
      
    }
  }

  render() {
    return (
      <div className="practiceDashboardBackgroundColor">
        <ReactGridLayout className="layout" cols={12} rowHeight={30} width={1900}>
          <div className="practiceDashboardHeadingBackgroundColor" key="a" data-grid={{ x: 0, y: 0, w: 12, h: 2, static: true, isResizable: true }}>
            <div className="centerFrontPageText">
              <h1> Cohort Dashboard </h1>
            </div>
          </div>

          {/* How the dates are formatted can be used in LINQ query but not sure how to pass the date to the web api. */}
          <div align="center" key="b" data-grid={{ x: 0, y: 2, w: 12, h: 20, static: true, autoSize: true }}>
            {this.ifLoadingJSONWebAPI()}
          </div>
        </ReactGridLayout>
      </div>
    );
  }
}

export default (DashboardFeedbackScreen);