import React, { useState, useEffect } from "react";
import {
  usePDF,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink
} from '@react-pdf/renderer';
import { Button } from "@material-ui/core";

const styles = StyleSheet.create({
  body: {
    paddingTop: 65,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'Times-Roman'
  },
  view: {
    margin: 12
  },
  subView: {
    marginBottom: 10
  },
  title: {
    fontSize: 18,
    textAlign: 'left',
    margin: 12,
    color: "#23af8c"
  },
  subtitle: {
    fontSize: 14
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  text: {
    fontSize: 12,
    textAlign: 'justify'
  },
  list: {
    fontSize: 10,
    textAlign: 'justify',
    paddingLeft: 10,
    marginTop: 5
  },
  box: {
    marginTop: 8,
    paddingTop: 10,
    paddingBottom: 10,
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: '#bfbfbf',
    borderWidth: 1,
  },
  titleLine: {
    borderBottom: 1,
    borderStyle: "solid",
    borderColor: '#000000',
    width: '90%',
    margin: 10
  },
  pageTable: {
    display: "table",
    width: "auto"
  },
  pageTableLeftColumn: {
    width: "20%",
    textAlign: "left"
  },
  pageTableRightColumn: {
    width: "75%",
    textAlign: "left"
  },
  halfTableColumn: {
    width: "40%",
    textAlign: "left",
    flexWrap: "wrap"
  },
  pageTableRow: {
    marginTop: 10,
    flexDirection: "row"
  },
  pageTableCell: {
    fontSize: 10,
    borderStyle: "solid",
    borderColor: '#bfbfbf',
    borderWidth: 0.8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 4,
    paddingBottom: 4
  },
  pageTableCellHeader: {
    fontSize: 10,
    fontWeight: 500,
    paddingTop: 4
  },
  pageTableCellMemo: {
    fontSize: 8,
    color: "#a0a0a0",
    fontStyle: "italic",
    paddingTop: 10
  },
  table: {
    marginTop: 8,
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderColor: '#bfbfbf',
    flexWrap: "wrap",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColHeader: {
    width: "20%",
    borderStyle: "solid",
    borderColor: '#bfbfbf',
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  actionTableCol: {
    width: "35%",
    borderStyle: "solid",
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  actionTableColHeader: {
    width: "35%",
    borderStyle: "solid",
    borderColor: '#bfbfbf',
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  actionShortTableCol: {
    width: "15%",
    borderStyle: "solid",
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  actionShortTableColHeader: {
    width: "15%",
    borderStyle: "solid",
    borderColor: '#bfbfbf',
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    margin: "auto",
    margin: 5,
    fontSize: 10
  },
  tableCellHeader: {
    margin: "auto",
    margin: 5,
    fontSize: 11,
    fontWeight: 500
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  image: {
    width: "70%",
    padding: 10
  },
  centerImage: {
    alignItems: "center",
    flexGrow: 1
  },

});

const QIActivityPdf = (props) => {
  // Must pass the entire PDF Document component into document:, using a seperate component does not work.
  // This is due to passing in props from the parent QIActivityScreenAllActivitiesTable and it not updating correctly.
  const [instance, updateInstance] = usePDF({
    document:
      <Document>
        <Page style={styles.body}>
          <View style={styles.centerImage}>
            <Image style={styles.image} source="/images/pdfLogo.png"></Image>
          </View>
          <View style={styles.pageTable}>
            <View style={styles.titleLine}></View>
          </View>
          <View style={styles.pageTable}>
            <View style={styles.pageTableRow}>
              <View style={styles.pageTableLeftColumn}>
                <Text style={styles.pageTableCellHeader}>QI ACTIVITY ID:</Text>
              </View>
              <View style={styles.pageTableRightColumn}>
                <Text style={styles.pageTableCell}>{props.data.qiActivityId}</Text>
              </View>
            </View>
            <View style={styles.pageTableRow}>
              <View style={styles.pageTableLeftColumn}>
                <Text style={styles.pageTableCellHeader}>ACTIVITY</Text>
              </View>
              <View style={styles.pageTableRightColumn}>
              </View>
            </View>
            <View style={styles.pageTableRow}>
              <View style={styles.pageTableLeftColumn}>
                <Text style={styles.pageTableCellHeader}>Title</Text>
              </View>
              <View style={styles.pageTableRightColumn}>
                <Text style={styles.pageTableCell}>{props.data.title}</Text>
              </View>
            </View>
            <View style={styles.pageTableRow}>
              <View style={styles.pageTableLeftColumn}>
                <Text style={styles.pageTableCellHeader}>Description</Text>
              </View>
              <View style={styles.pageTableRightColumn}>
                <Text style={styles.pageTableCell}>{props.data.description != "" ? props.data.description : "-"}</Text>
              </View>
            </View>
            <View style={styles.pageTableRow}>
              <View style={styles.pageTableLeftColumn}>
              </View>
              <View style={styles.pageTableRightColumn}>
                <Text style={styles.pageTableCellMemo}>What do you hope to achieve in the activity?</Text>
              </View>
            </View>
            <View style={styles.pageTableRow}>
              <View style={styles.pageTableLeftColumn}>
                <Text style={styles.pageTableCellHeader}>AIM</Text>
              </View>
              <View style={styles.pageTableRightColumn}>
                <Text style={styles.pageTableCell}>{props.data.aim != "" ? props.data.aim : "-"}</Text>
              </View>
            </View>
            <View style={styles.pageTableRow}>
              <View style={styles.pageTableLeftColumn}>
                <Text style={styles.pageTableCellHeader}>GOALS</Text>
              </View>
              <View style={styles.pageTableRightColumn}>
                <Text style={styles.pageTableCellMemo}>What are your SMART goals to achieve your aims?</Text>
              </View>
            </View>
            <View style={styles.pageTableRow}>
              <View style={styles.pageTableLeftColumn}>
                <Text style={styles.pageTableCellHeader}>Description</Text>
              </View>
              <View style={styles.pageTableRightColumn}>
                <Text style={styles.pageTableCell}>{props.data.goal != "" ? props.data.goal : "-"}</Text>
              </View>
            </View>
            <View style={styles.pageTableRow}>
              <View style={styles.pageTableLeftColumn}>
                <Text style={styles.pageTableCellHeader}>RECOMMENDATION</Text>
              </View>
              <View style={styles.pageTableRightColumn}>
                <View style={styles.pageTableCell}>
                  {/* Only show props.recommendations enabled for site */}
                  {props.recommendations.length > 0 ? props.recommendations
                    .filter(el => el.fhtEnabledAnalysisDiseaseId in props.activeDiseaseIDs)
                    .map((r, index) => (
                      <Text key={index}>{r.fhtEnabledAnalysisDescription}</Text>
                    ))
                    : <Text > - </Text>
                  }
                </View>
              </View>
            </View>
            <View style={styles.pageTableRow}>
              <View style={styles.pageTableLeftColumn}>
                <Text style={styles.pageTableCellHeader}>PARTICIPANTS</Text>
              </View>
              <View style={styles.pageTableRightColumn}>
                <View style={styles.pageTableCell}>
                  <Text style={styles.pageTableCellHeader}>Participant Table</Text>
                  {props.participants.length > 0 ?
                    <View style={styles.table}>
                      <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                          <Text style={styles.tableCellHeader}>Name</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                          <Text style={styles.tableCellHeader}>Email</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                          <Text style={styles.tableCellHeader}>Role</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                          <Text style={styles.tableCellHeader}>ACCRM ID</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                          <Text style={styles.tableCellHeader}>RACGP ID</Text>
                        </View>
                      </View>
                      {props.participants.map((r, index) => (
                        <View style={styles.tableRow} key={index}>
                          <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{r.qiPaticipantEnabledFullName}</Text>
                          </View>
                          <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{r.qiPaticipantEnabledEmail}</Text>
                          </View>
                          <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{r.qiPaticipantEnabledRoleName}</Text>
                          </View>
                          <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{r.qiPaticipantEnabledAccrmId}</Text>
                          </View>
                          <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{r.qiPaticipantEnabledRACGPNumber}</Text>
                          </View>
                        </View>
                      ))}
                    </View> : <Text style={styles.list}> - </Text>
                  }
                </View>
              </View>
            </View>
            <View style={styles.pageTableRow}>
              <View style={styles.pageTableLeftColumn}>
                <Text style={styles.pageTableCellHeader}>FACILITATOR</Text>
              </View>
              <View style={styles.pageTableRightColumn}>
              </View>
            </View>
            <View style={styles.pageTableRow}>
              <View style={styles.pageTableLeftColumn}>
                <Text style={styles.pageTableCellHeader}>Activity Facilitator</Text>
              </View>
              <View style={styles.pageTableRightColumn}>
                <Text style={styles.pageTableCell}>
                  {props.data.facilitatorObj ? props.data.facilitatorObj.qiPaticipantEnabledName : "-"}
                </Text>
              </View>
            </View>
            <View style={styles.pageTableRow}>
              <View style={styles.pageTableLeftColumn}>
                <Text style={styles.pageTableCellHeader}>Activity Start Date</Text>
              </View>
              <View style={styles.pageTableRightColumn}>
                <Text style={styles.pageTableCell}>
                  {props.data.startDate != "" ? props.data.startDate : "-"}
                </Text>
              </View>
            </View>
            <View style={styles.pageTableRow}>
              <View style={styles.pageTableLeftColumn}>
                <Text style={styles.pageTableCellHeader}>Activity End Date</Text>
              </View>
              <View style={styles.pageTableRightColumn}>
                <Text style={styles.pageTableCell}>
                  {props.data.endDate != "" ? props.data.endDate : "-"}
                </Text>
              </View>
            </View>
            <View style={styles.pageTableRow}>
              <View style={styles.pageTableLeftColumn}>
                <Text style={styles.pageTableCellHeader}>ACTIONS</Text>
              </View>
              <View style={styles.pageTableRightColumn}>
              </View>
            </View>
            <View style={styles.pageTableRow}>
              <View style={styles.pageTableLeftColumn}>
                <Text style={styles.pageTableCellHeader}>
                  <Text style={styles.pageTableCellMemo}> Recommended Actions need to be completed if practice is participating in RACGP accredited activities.
                  </Text>
                </Text>
              </View>
              <View style={styles.pageTableRightColumn}>
                <View style={styles.pageTableCell}>
                  <Text style={styles.pageTableCellHeader}>Standard Actions</Text>
                  {props.actions.standard.length > 0 ?
                    <View style={styles.table}>
                      <View style={styles.tableRow}>
                        <View style={styles.actionTableColHeader}>
                          <Text style={styles.tableCellHeader}>Action Description</Text>
                        </View>
                        <View style={styles.actionTableColHeader}>
                          <Text style={styles.tableCellHeader}>Comment</Text>
                        </View>
                        <View style={styles.actionShortTableColHeader}>
                          <Text style={styles.tableCellHeader}>Action Champion</Text>
                        </View>
                        <View style={styles.actionShortTableColHeader}>
                          <Text style={styles.tableCellHeader}>Action Delegate</Text>
                        </View>
                        {/* <View style={styles.actionShortTableColHeader}> 
                    <Text style={styles.tableCellHeader}>Successful Rate</Text> 
                  </View>  */}
                      </View>
                      {props.actions.standard.map((r, index) => (
                        <View style={styles.tableRow} key={index}>
                          <View style={styles.actionTableCol}>
                            <Text style={styles.tableCell}>{r.qiActivityActionDescription}</Text>
                          </View>
                          <View style={styles.actionTableCol}>
                            <Text style={styles.tableCell}>{r.qiActivityActionComment}</Text>
                          </View>
                          <View style={styles.actionShortTableCol}>
                            <Text style={styles.tableCell}>{r.qiActivityActionChampion ? r.qiActivityActionChampion.qiPaticipantEnabledName : " "}</Text>
                          </View>
                          <View style={styles.actionShortTableCol}>
                            <Text style={styles.tableCell}>{r.qiActivityActionDelegate ? r.qiActivityActionDelegate.qiPaticipantEnabledName : " "}</Text>
                          </View>
                          {/* <View style={styles.actionShortTableCol}> 
                      <Text style={styles.tableCell}>{r.qiActivityActionSuccessRate}</Text> 
                    </View>  */}
                        </View>
                      ))}
                    </View>
                    : <Text style={styles.list}> - </Text>}
                </View>
              </View>
            </View>
            <View style={styles.pageTableRow}>
              <View style={styles.pageTableLeftColumn}>
              </View>
              <View style={styles.pageTableRightColumn}>
                <View style={styles.pageTableCell}>
                  <Text style={styles.pageTableCellHeader}>Practice Generated Action Items</Text>
                  {props.actions.custom.length > 0 ?
                    <View style={styles.table}>
                      <View style={styles.tableRow}>
                        <View style={styles.actionTableColHeader}>
                          <Text style={styles.tableCellHeader}>Action Description</Text>
                        </View>
                        <View style={styles.actionTableColHeader}>
                          <Text style={styles.tableCellHeader}>Comment</Text>
                        </View>
                        <View style={styles.actionShortTableColHeader}>
                          <Text style={styles.tableCellHeader}>Action Champion</Text>
                        </View>
                        <View style={styles.actionShortTableColHeader}>
                          <Text style={styles.tableCellHeader}>Action Delegate</Text>
                        </View>
                        {/* <View style={styles.actionShortTableColHeader}> 
                    <Text style={styles.tableCellHeader}>Successful Rate</Text> 
                  </View>  */}
                      </View>
                      {props.actions.custom.map((r, index) => (
                        <View style={styles.tableRow} key={index}>
                          <View style={styles.actionTableCol}>
                            <Text style={styles.tableCell}>{r.qiActivityActionDescription}</Text>
                          </View>
                          <View style={styles.actionTableCol}>
                            <Text style={styles.tableCell}>{r.qiActivityActionComment}</Text>
                          </View>
                          <View style={styles.actionShortTableCol}>
                            <Text style={styles.tableCell}>{r.qiActivityActionChampion ? r.qiActivityActionChampion.qiPaticipantEnabledName : " "}</Text>
                          </View>
                          <View style={styles.actionShortTableCol}>
                            <Text style={styles.tableCell}>{r.qiActivityActionDelegate ? r.qiActivityActionDelegate.qiPaticipantEnabledName : " "}</Text>
                          </View>
                          {/* <View style={styles.actionShortTableCol}> 
                      <Text style={styles.tableCell}>{r.qiActivityActionSuccessRate}</Text> 
                    </View>  */}
                        </View>
                      ))}
                    </View>
                    : <Text style={styles.list}> - </Text>}
                </View>
              </View>
            </View>
            <View style={styles.pageTableRow}>
              <View style={styles.pageTableLeftColumn}>
                <Text style={styles.pageTableCellHeader}>COHORT</Text>
              </View>
              <View style={styles.pageTableRightColumn}>
                <Text style={styles.pageTableCell}>
                  {props.cohortName != undefined ? props.cohortName : "-"}
                </Text>
              </View>
            </View>
            <View style={styles.pageTableRow}>
              <View style={styles.pageTableLeftColumn}>
                <Text style={styles.pageTableCellHeader}>REFLECTIONS</Text>
              </View>
              <View style={styles.pageTableRightColumn}>
                <Text style={styles.pageTableCellMemo}>TIP: Comment on, or review progress of, your cohort outcome measures, including any enablers, barriers and system changes implemented/to be implemented.</Text>
              </View>
            </View>
            <View style={styles.pageTableRow}>
              <View style={styles.pageTableLeftColumn}>
              </View>
              <View style={styles.pageTableRightColumn}>
                <View style={styles.pageTableCell}>
                  {props.reflections.length > 0 ? props.reflections.map((r, index) => (
                    <View key={index} style={styles.box}>
                      <Text style={styles.list}>
                        Meeting Date: {
                          new Date(r.date).getDate() + "/" + 
                          (new Date(r.date).getMonth() + 1) + "/" 
                          + new Date(r.date).getFullYear()
                        }
                      </Text>
                      <Text style={styles.list}>
                        Current Status: {r.qiActivityReflectionMeetingStatus}
                      </Text>
                      <Text style={styles.list}>
                        Comments: {r.qiActivityReflectionComment}
                      </Text>
                      <Text style={styles.list}>
                        Success Factors: {r.qiActivityReflectionSuccessFactor}
                      </Text>
                      <Text style={styles.list}>
                        Enablers/Barriers: {r.qiActivityReflectionEnablersBarries}
                      </Text>
                      {/* <Text style={styles.list}>
                Failure Factors: {r.qiActivityReflectionFailureFactor}
              </Text> */}
                      <Text style={styles.list}>
                        Cohort Outcome Measures that improved: {r.qiActivityReflectionImprovedMeasure}
                      </Text>
                      <Text style={styles.list}>
                        Cohort Outcome Measures that did not improve: {r.qiActivityReflectionNotImprovedMeasure}
                      </Text>
                      <Text style={styles.list}>
                        Proposed Practice System Changes: {r.qiActivityReflectionProposedPracticeChanges}
                      </Text>
                      <Text style={styles.list}>
                        Cohort Outcome Measures: {r.qiActivityReflectionMBR}
                      </Text>
                      <Text style={styles.list}>
                        Future Improvement: {r.qiActivityReflectionFutureImprovements}
                      </Text>
                    </View>
                  )) : <Text style={styles.list}> - </Text>}
                </View>
              </View>
            </View>
            <View style={styles.pageTableRow}>
              <View style={styles.pageTableLeftColumn}>
                <Text style={styles.pageTableCellHeader}>ACTIVITY STATUS</Text>
              </View>
              <View style={styles.pageTableRightColumn}>
                <Text style={styles.pageTableCell}>
                  {props.data.qiActivityStatus != "" ? props.data.qiActivityStatus : "-"}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
  });

  // Hook equivalent to componentMount
  // This is used to "refresh" the prop data passed into pdf component.
  // If we do not do this, empty data is used.
  useEffect(() => {
    updateInstance()
  });

  // This is the method to simulate clicking the download link.
  // Load the instance.url (PDF download link) into a href and "click it" to download it.
  // This was done because other methods, taking into account updateInstance and old data issue, do not work.
  function downloadPDF() {
    setTimeout(function () {
      const link = document.createElement('a');
      link.href = instance.url;
      document.body.appendChild(link);
      link.download = "qiActivityReport.pdf";
      link.click();
      document.body.removeChild(link);
    }.bind(this), 500)
  }

  if (instance.loading) return <div style={{ marginLeft: '1REM' }}> Loading ... </div>;

  return (
    <div>
      <Button style={{ marginLeft: '1REM' }} variant="contained" onClick={() => downloadPDF()}>
        Download Report
      </Button>
    </div>
  );
}

export default QIActivityPdf;