import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

class RecommendDialog extends React.Component {
	render() {
			const {open, onClose, title, children, guidelineOptions} = this.props;
			return (<Dialog
					open={open}
					onClose={onClose}

					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					fullWidth={true}
					maxWidth="md"
			>
					<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
					<DialogContent
					>
							{children}
					</DialogContent>
					{/* <DialogActions>
							<Button onClick={onClose} color="primary" autoFocus>
									Close
							</Button>
					</DialogActions> */}
			</Dialog>);
	}
}

export default RecommendDialog;