import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table';

import withMobileDialog from '@material-ui/core/withMobileDialog';

import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import Configs from 'Configuration';
import SiteConfigurationContext from "Utils/SiteConfigurationContext.jsx";
import BatchSiteConfigurationContext from 'Utils/BatchSiteConfigurationContext.jsx';
import FhtMain from 'FhtMain';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function parseJSON(response) {
    return new Promise((resolve) => response.json()
        .then((json) => resolve({
            status: response.status,
            ok: response.ok,
            json,
        })));
}

class UserManagementDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: [],
            columns: [
                { title: 'User Name', field: 'username' },
                { title: 'First Name', field: 'firstname' },
                { title: 'Last Name', field: 'lastname' },
                { title: 'Email', field: 'email' },
                {
                    title: 'Role',
                    field: 'roleId',
                    lookup: []
                },
                {
                title: 'EMR Username', 
                field: 'staffExtractEmrUsername' ,                
                //lookup: [] 
                },
                {
                    title: 'Enabled',
                    field: 'accountEnabled',
                    lookup:['No','Yes']
                }
            ],
            data: [],
            siteConfigurationContext:SiteConfigurationContext(),
            batchSiteConfigurationContext: BatchSiteConfigurationContext(),
        }
    }

    componentDidMount() {

        fetch(this.state.siteConfigurationContext[Configs.SiteConfigurationMappingKeys.serverapi] + Configs.getRoles, {
            //signal: this.abortController.signal,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'cache-control': 'no-cache',
                'Authorization': 'Bearer ' + FhtMain.GlobalContext.userToken
            },
        })
            .then(parseJSON)
            .then((response) => {
                if (response.ok) {
                    // console.log(response.json.data);
                   
                    var rolesList = response.json.data;
                    var roles = {};
                    for (var i = 0; i < rolesList.length; i++) {
                        roles[rolesList[i].rolesReferenceId] = rolesList[i].rolesReferenceName;
                    }
                    var columns = this.state.columns;
                    columns[4].lookup = roles;
                    this.setState({ columns, columns });
                    this.loadUsers();
                    this.loadEMRUsers();
                   
                    
                } else {
                    // console.log(response.json.message);
                    throw response.json.message;
                }
            })
            .catch((error) => {
                // console.log('error: ' + error);
                this.props.GlobalFunctionDisplaySnackbarMessage(String(error), Configs.snackbarVariants.error);
            })
            
           
    }

    loadEMRUsers(){
        fetch(this.state.siteConfigurationContext[Configs.SiteConfigurationMappingKeys.serverapi] + Configs.getStaffEMRUsers, {
            //signal: this.abortController.signal,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'cache-control': 'no-cache',
                'Authorization': 'Bearer ' + FhtMain.GlobalContext.userToken
            },
        })
            .then(parseJSON)
            .then((response) => {
                if (response.ok) {
                    // console.log(response.json.data);
                    var staffEMRList = response.json.data;
                    var emrUsers = {};
                    for (var i = 0; i < staffEMRList.length; i++) {
                        emrUsers[staffEMRList[i].staffExtractEmrUsername] = staffEMRList[i].staffExtractEmrUsername;
                        emrUsers[staffEMRList[i].StaffExtractFhtUserId] = staffEMRList[i].StaffExtractFhtUserId;
                    }
                    var columns = this.state.columns;
                    columns[5].lookup = emrUsers;
                    this.setState({ columns, columns });
                   //this.loadUsers();
                } else {
                    // console.log(response.json.message);
                    throw response.json.message;
                }
            })
            .catch((error) => {
                this.props.GlobalFunctionDisplaySnackbarMessage(String(error), Configs.snackbarVariants.error);
            })
            
    }
    loadUsers() {
        fetch(this.state.siteConfigurationContext[Configs.SiteConfigurationMappingKeys.serverapi] + Configs.getUsers, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'cache-control': 'no-cache',
                'Authorization':'Bearer ' + FhtMain.GlobalContext.userToken
            },
        })
            .then(parseJSON)
            .then((response) => {
                if (response.ok) {
                    console.log(response.json.data)
                    var users = response.json.data;
                    var column = [];
                    for (var i = 0; i < users.length; i++) {
                        var data = {
                            "id": users[i].id,
                            "username": users[i].username,
                            "firstname": users[i].firstName,
                            "lastname": users[i].lastname,
                            "email": users[i].email,
                            "roleId": users[i].roleId,
                            "staffExtractEmrUsername": users[i].staffExtractEmrUsername,
                            "accountEnabled":users[i].accountEnabled,
                        }
                        column.push(data);
                    }
                    this.setState({ data: column });
                } else {
                    // console.log(response.json.message);
                    throw response.json.message;
                }
            })
            .catch((error) => {
                this.props.GlobalFunctionDisplaySnackbarMessage(String(error), Configs.snackbarVariants.error);
            })
    }

    render() {
        return (
            <Dialog fullScreen={false}
                fullWidth={true}
                maxWidth="md"
                onClose={this.props.CallbackForAllDialogsClose}
                aria-labelledby="customized-dialog-title"
                open={true}
            >
                <DialogTitle id="customized-dialog-title" onClose={this.props.CallbackForAllDialogsClose}>
                    User Management
                    </DialogTitle>
                <DialogContent>
                    <div id="management">
                        <MaterialTable
                            title="Users"
                            columns={this.state.columns}
                            data={this.state.data}
                            icons={tableIcons}
                            deleteText="Are you sure delete this user?"
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise(resolve => {
                                        var params = { Username: newData.username, Firstname: newData.firstname, Lastname: newData.lastname, Email: newData.email, Role: newData.roleId , StaffExtractFhtUserId : newData.id, StaffExtractEmrUsername: newData.staffExtractEmrUsername,
                                                        AccountEnabled: newData.accountEnabled};
                                        console.log(params);
                                        
                                        fetch(this.state.siteConfigurationContext[Configs.SiteConfigurationMappingKeys.serverapi] + Configs.editUsers , {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'cache-control': 'no-cache',
                                                'Authorization': 'Bearer ' + FhtMain.GlobalContext.userToken
                                            },
                                            body: JSON.stringify(params)
                                        })
                                            .then(parseJSON)
                                            .then((response) => {
                                                if (response.ok) {
                                                    resolve();
                                                    let data = this.state.data;
                                                    data[data.indexOf(oldData)] = newData;
                                                    this.setState({ data, data });
                                                    this.loadUsers();
                                                    this.props.GlobalFunctionDisplaySnackbarMessage(response.json.message, Configs.snackbarVariants.success);
                                                } else {
                                                    // console.log(response.json.message);
                                                    //this.props.GlobalFunctionDisplaySnackbarMessage(response.json.message, Configs.snackbarVariants.error);
                                                    throw response.json.message;
                                                }
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                this.props.GlobalFunctionDisplaySnackbarMessage(error, Configs.snackbarVariants.error);
                                            })
                                    }),
                                onRowDelete: oldData =>
                                    new Promise(resolve => {
                                        var params = { username: oldData.username};
                                        fetch(this.state.siteConfigurationContext[Configs.SiteConfigurationMappingKeys.serverapi] + Configs.deleteUser,{ // + "?username=" + oldData.username , {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'cache-control': 'no-cache',
                                                'Authorization': 'Bearer ' + FhtMain.GlobalContext.userToken
                                            },
                                            body: JSON.stringify(params)
                                        })
                                            .then(parseJSON)
                                            .then((response) => {
                                                if (response.ok) {
                                                    resolve();
                                                    const data = this.state.data;                                          
                                                    data.splice(data.indexOf(oldData), 1);
                                                    this.setState({ data, data });
                                                    this.loadUsers();
                                                    this.props.GlobalFunctionDisplaySnackbarMessage(response.json.message, Configs.snackbarVariants.success);
                                                } else {
                                                    // console.log(response.json.message);
                                                    //this.props.GlobalFunctionDisplaySnackbarMessage(response.json.message, Configs.snackbarVariants.error);
                                                    resolve();
                                                    this.loadUsers();
                                                    throw response.json.message;
                                                    
                                                }
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                this.props.GlobalFunctionDisplaySnackbarMessage(error, Configs.snackbarVariants.error);
                                            })
                                    }),
                            }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.CallbackForAllDialogsClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withMobileDialog()(UserManagementDialog);