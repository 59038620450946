import React, { useEffect } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Configs from 'Configuration';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center'
  },
}));

function Analysis(props) {
	const classes = useStyles();
	const risks = props.enableFeature ? Configs.risks[props.type] : Configs[props.type];
	const triggers = props.triggers;
	const triggerDesc = props.triggerDesc;
	const actions = props.actions;
	let groupedRisks = {};
	let sources = {};
	let recommendations = {
		title: '',
		components: []
	};
	if(risks) {

		triggers.map(t => {
			sources[t.fhtPatientAnalysisTriggersRefTestId] = true;
		});

		Object.keys(risks).map(key => {
			let group = risks[key].group;
			if(group){
				if(!groupedRisks[group]) {
					groupedRisks[group] = {
						active: false,
						components: {}
					}
				}
				groupedRisks[group].components[key] = {
					active: sources[key] || false,
					text: risks[key].text
				}
				if(sources[key]) groupedRisks[group].active = true;
			} else {
				let id = Object.keys(groupedRisks).length;
				groupedRisks[id] = {
					active: sources[key] || false,
					components: {}
				}
				groupedRisks[id].components[key] = {
					active: sources[key] || false,
					text: triggerDesc[key] || risks[key].text
				}
			}
		});

	}
	
	return (
		<div style={{backgroundColor: '#d5f6ff', padding: '10px'}}>
			<h3>{Configs.analysisTitle[props.type]}</h3>
			<div className={classes.root}>
				<Paper className={classes.paper} style={{border: '1px solid', borderColor: '#a9a9a9'}}>
					<Grid container spacing={2}>
						{Object.keys(groupedRisks).map(key => {
							return (
								<Grid item xs={6} key={key}>
									<Paper className={classes.paper} style={{border:'1px solid'}} style={{border:'1px solid', borderColor: groupedRisks[key].active ? '#ff0000' : '#a9a9a9'}}>
										{Object.keys(groupedRisks[key].components).map(c => {
											return (
												<div key={c} style={{margin: '5px 0', color: groupedRisks[key].components[c].active ? '#ff0000' : '#a9a9a9', fontWeight: groupedRisks[key].components[c].active ? 'bolder': 'normal'}}>
													{groupedRisks[key].components[c].text}
												</div>
											)
										})}
									</Paper>
								</Grid>
							)
						})}
					</Grid>
				</Paper>
			</div>
			{/* {recommendations.components.length > 0 && */}
			{actions.length > 0 &&
				<div>
					<div style={{textAlign: 'center', color: '#ff0000', marginTop: '10px'}}>
						<ArrowDownIcon/>
					</div>
					<div style={{marginBottom: '10px'}}><h3>{recommendations.title}</h3></div>
					<Paper className={classes.paper} style={{border: '1px solid', borderColor: '#a9a9a9'}}>
						<div>
							<Grid container spacing={1}>
								{
									actions.map((r, index) => {
										return (
											<Grid item xs={12} key={index}>
												<span style={{color: '#ff0000'}}>{r}</span>
											</Grid>)
									})
								}
								{/* {recommendations.components.map((r, index) => {
									return (
										<Grid item xs={12} key={index}>
											<span style={{color: '#ff0000'}}>{r}</span>
										</Grid>)
								})} */}
							</Grid>
						</div>
					</Paper>
				</div>
			}
		</div>
	);
}

export default Analysis;