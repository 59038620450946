import React from 'react';
import Configs from 'Configuration';
import Paper from '@material-ui/core/Paper';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'semantic-ui-css/semantic.min.css';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';
import YouTube from 'react-youtube';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';


const ResponsiveReactGridLayout = WidthProvider(Responsive);
const opts = {
  height: '150',
  width: '300',
  playerVars: {
    autoplay: 0
  }
};

const useStyles = makeStyles => ({
  root: {
    maxWidth: 345
  },
  media: {
    height: 100
  }
});

class HowToUse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleCardClick = linkTo => {
    console.log('opening to:', linkTo);
    //var win = window.open(linkTo, '_blank');
    var win = window.open(linkTo);
    //win.focus();
  };

  render() {
    let videoCounter = 1;
    let cardheight = 8;
    let cardwidth = 2;
    let xwidth = 2.4;
    let cardheightoffset = 0.5;

    var i;

    let youtubelinks = [
      {
        linkTo: 'https://www.youtube.com/watch?v=bNrKjyGMX0c',
        title: 'New User Registration',
        description: '',
        image: 'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtHowtoUseImages/NewRegistrationsYoutube.jpg'
      },
      {
        linkTo: 'https://youtu.be/ekEaTGCG1-Q',
        title: 'Creating a Cohort',
        description: '',
        image: 'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtHowtoUseImages/CreateCohortYoutube.jpg'
      },
      {
        linkTo: 'https://youtu.be/hi3uULETDwU',
        title: 'Reviewing a cohort',
        description: '',
        image: 'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtHowtoUseImages/ReviewcohortYoutube.jpg'
      },
      
      {
        linkTo: 'https://youtu.be/yAPLO8cGBZI',
        title: 'Monitoring deferred patients',
        description: '',
        image: 'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtHowtoUseImages/deferredYoutube.jpg'
      },
      {
        linkTo: 'https://youtu.be/V_hv4dKMQkE',
        title: 'The Point of Care tool',
        description: '',
        image: 'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtHowtoUseImages/PointofCareYoutube.jpg'
      },
      {
        linkTo: 'https://www.youtube.com/watch?v=99BxraehNoA',
        title: 'QI Activity participants',
        description: '',
        image: 'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtHowtoUseImages/participantlistYoutube.jpg'
      },
      {
        linkTo: 'https://www.youtube.com/watch?v=CRcIcpu9AME',
        title: 'Creating a QI Activity',
        description: '',
        image: 'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtHowtoUseImages/CreateQIActivitiesYoutube.jpg'
      },
      {
        linkTo: 'https://www.youtube.com/watch?v=bYFETtfzosU',
        title: 'Reflection for your QI activity',
        description: '',
        image: 'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtHowtoUseImages/CreatingQIReflectionsYoutube.jpg'
      }
    ];

    let videoItemsDictionary = [];
    for (i = 0; i < youtubelinks.length; i++) {
      let ystartingposition = 0;
      let xstartingposition = 1.4;
      let itemsPerRow = 4;
      let xposition = (i % itemsPerRow) * xwidth + xstartingposition;
      let yposition = ystartingposition + cardheight * Math.floor(i / 4);
      videoItemsDictionary.push({
        videoCounter: i,
        linkTo: youtubelinks[i].linkTo,
        imageLink: youtubelinks[i].image,
        xposition: xposition,
        yposition: yposition,
        title: youtubelinks[i].title,
        description: youtubelinks[i].description
      });
    }
    console.log(videoItemsDictionary);
    let videoLinksCode = videoItemsDictionary.map(videoItem => {
      let keyVideo = 'videolink' + videoItem.videoCounter;
      let width = 3;
      return (
        <div
          key={keyVideo}
          style={{}}
          data-grid={{
            x: videoItem.xposition,
            y: videoItem.yposition,
            w: cardwidth,
            h: cardheight,
            static: true
          }}
        >
          <Card>
            <CardActionArea
              onClick={() => {
                this.handleCardClick(videoItem.linkTo);
              }}
            >
              <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}></div>
              <CardMedia
                style={{ height: '10REM' }}
                image={videoItem.imageLink}
                title={videoItem.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {videoItem.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {videoItem.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      );
    });

    let readLinks = [
      {
        image: 'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/Quickguideimage.jpg',
        title: 'FHT Quick Guide',
        description: '',
        linkTo: 'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FHT+Quick+Guide+V7_13+June+2023.pdf',
        searchKey: 'na'
      },
      {
        image: 'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/Userguideimage.jpg',
        title: 'FHT User Guide',
        description: '',
        linkTo: 'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/Future+Health+Today+User+Guide+29+May+2023.pdf',
        searchKey: 'na'
      },
      {
        image: 'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/Userguideimage.jpg',
        title: 'How to use FHT for QI Activities',
        description: '',
        linkTo: 'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/How+to+use+FHT+for+quality+improvement+activities+v5+4+Jul+2023.pdf',
        searchKey: 'na'
      }
    ];
    let readItemsDictionary = [];
    for (i = 0; i < readLinks.length; i++) {
      let ystartingposition = 0;
      let xstartingposition = 1.4;
      let itemsPerRow = 4;
      let xposition = (i % itemsPerRow) * xwidth + xstartingposition;
      let yposition = ystartingposition + cardheight * Math.floor(i / 4);
      readItemsDictionary.push({
        readCounter: i,
        imageLink: readLinks[i].image,
        xposition: xposition,
        yposition: yposition,
        title: readLinks[i].title,
        description: readLinks[i].description,
        linkTo: readLinks[i].linkTo,
        searchKey: readLinks[i].searchKey
      });
    }
    console.log (readItemsDictionary);
    let readLinksCode = readItemsDictionary.map(guidelineItem => {
      let keyGuideline = 'readlink' + guidelineItem.readCounter;
      return (
        <div
          key={keyGuideline}
          style={{}}
          data-grid={{
            x: guidelineItem.xposition,
            y: guidelineItem.yposition,
            w: cardwidth,
            h: cardheight,
            static: true
          }}
        >
          <Card>
            <CardActionArea
              onClick={() => {
                this.handleCardClick(guidelineItem.linkTo);
              }}
            >
              <CardMedia
                style={{ height: '10REM' }}
                image={guidelineItem.imageLink}
                title={guidelineItem.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {guidelineItem.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {guidelineItem.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      );
    });

    return (
      <div>
        <div style={{ fontSize: '3REM', textAlign: 'center', paddingTop: '2REM' }}>
          How to use FHT
        </div>
        <hr
          style={{
            width: '10%',
            height: '0.2REM',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundImage: 'linear-gradient(to right, rgb(39, 149, 148), rgb(72, 175, 104))',
            border: 'none'
          }}
        />

        <div style={{ fontSize: '2REM', textAlign: 'center', paddingTop: '2REM' }}>
          <i class="circular book icon"></i>
          User Guide (Documentation)
        </div>
        <hr
          style={{
            width: '80%',
            height: '0.1REM',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundImage: 'linear-gradient(to right, rgb(39, 149, 148), rgb(72, 175, 104))',
            border: 'none'
          }}
        />

        <ResponsiveReactGridLayout
          className="reviewCohortLayout"
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          rowHeight={25}
          isResizable={false}
        >
          <div key="readlinks" style={{}} data-grid={{ x: 0.1, y: 0, w: 12, h: 8, static: true }}>
            <div>
              <ResponsiveReactGridLayout
                className="reviewCohortLayout"
                cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                rowHeight={25}
                isResizable={false}
              >
                {readLinksCode}
              </ResponsiveReactGridLayout>
            </div>
          </div>
        </ResponsiveReactGridLayout>

        <div style={{ fontSize: '2REM', textAlign: 'center', paddingTop: '2REM' }}>
          <i class="circular info icon"></i>
          User Guide (Video)
        </div>
        <hr
          style={{
            width: '80%',
            height: '0.1REM',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundImage: 'linear-gradient(to right, rgb(39, 149, 148), rgb(72, 175, 104))',
            border: 'none'
          }}
        />

        <ResponsiveReactGridLayout
          className="reviewCohortLayout"
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          rowHeight={25}
          isResizable={false}
        >
          <div
            key="youtubelinks"
            style={{}}
            data-grid={{ x: 0.1, y: 0, w: 12, h: 23, static: true }}
          >
            <div>
              <ResponsiveReactGridLayout
                className="reviewCohortLayout"
                cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                rowHeight={25}
                isResizable={false}
              >
                {videoLinksCode}
              </ResponsiveReactGridLayout>
            </div>
          </div>
        </ResponsiveReactGridLayout>
      </div>
    );
  }
}

export default withStyles(useStyles)(HowToUse);
