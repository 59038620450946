import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import withMobileDialog from '@material-ui/core/withMobileDialog';

import './Guideline.css'

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

class GuidelineDialog extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <Dialog  fullScreen={false}
                fullWidth={true}
                maxWidth="md"
                onClose={this.props.CallbackForAllDialogsClose}
                aria-labelledby="customized-dialog-title"
                open={true}
            >
                <DialogTitle id="customized-dialog-title" onClose={this.props.CallbackForAllDialogsClose}>
                    Guidelines
                    </DialogTitle>
                <DialogContent>
                    <div id="guideline">

                        <ol>
                            <li>General guidelines
                        <div>
                                    <p>Therapeutic Guidelines <a target="_blank" rel="noopener noreferrer" href="https://www.tg.org.au">www.tg.org.au</a></p>
                                    <p>Australian Medicines Handbook <a target="_blank" rel="noopener noreferrer" href="https://amhonline.amh.net.au">amhonline.amh.net.au</a></p>
                                    <p>Health Pathways Melbourne <a target="_blank" rel="noopener noreferrer" href="https://melbourne.healthpathways.org.au">melbourne.healthpathways.org.au</a>  (username: connected password: healthcare)</p>
                                </div>
                            </li>
                            <li>Chronic kidney disease
                        <div>
                                    <p>Kidney Health Australia: Chronic Kidney Disease Management Handbook <a target="_blank" rel="noopener noreferrer" href="https://kidney.org.au/health-professionals/prevent/chronic-kidney-disease-management-handbook">kidney.org.au/health-professionals/prevent/chronic-kidney-disease-management-handbook</a></p>
                                    <p>Kidney Health Australia: Consumer Information <a target="_blank" rel="noopener noreferrer" href="https://kidney.org.au/">kidney.org.au</a></p>
                                </div>
                            </li>
                            <li>Preventive Health
                        <div>
                                    <p>RACGP: The Red Book <a target="_blank" rel="noopener noreferrer" href="https://www.racgp.org.au/clinical-resources/clinical-guidelines/key-racgp-guidelines/view-all-racgp-guidelines/red-book/preamble/i-introduction">www.racgp.org.au/clinical-resources/clinical-guidelines/key-racgp-guidelines/view-all-racgp-guidelines/red-book/preamble/i-introduction</a></p>
                                </div>
                            </li>
                            <li>Cardiovascular disease
                        <div>
                                    <p>Heart Foundation: Hypertension</p>
                                    <ul>
                                        <li>Full guideline <a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/images/uploads/publications/PRO-167_Hypertension-guideline-2016_WEB.pdf">www.heartfoundation.org.au/images/uploads/publications/PRO-167_Hypertension-guideline-2016_WEB.pdf</a></li>
                                        <li>Summary guideline <a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/images/uploads/publications/Key_Messages_for_GPs_and_Nurses.pdf">www.heartfoundation.org.au/images/uploads/publications/Key_Messages_for_GPs_and_Nurses.pdf</a></li>
                                    </ul>

                                    <p>Heart Foundation: Absolute cardiovascular risk (including guidance on lipid management for primary prevention)</p>
                                    <ol>
                                        <li>CVD risk calculator: <a target="_blank" rel="noopener noreferrer" href="http://www.cvdcheck.org.au/">www.cvdcheck.org.au</a></li>
                                        <li>Full guideline: <a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/images/uploads/publications/Absolute-CVD-Risk-Full-Guidelines.pdf">www.heartfoundation.org.au/images/uploads/publications/Absolute-CVD-Risk-Full-Guidelines.pdf</a></li>
                                        <li>Quick reference guide: <a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/images/uploads/publications/Absolute-CVD-Risk-Quick-Reference-Guide.pdf">www.heartfoundation.org.au/images/uploads/publications/Absolute-CVD-Risk-Quick-Reference-Guide.pdf</a></li>
                                        <li>Consumer information: <a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/your-heart/know-your-risks">www.heartfoundation.org.au/your-heart/know-your-risks</a></li>
                                    </ol>

                                    <p>Heart Foundation: Heart failure</p>
                                    <ul>
                                        <li>Full guideline: <a target="_blank" rel="noopener noreferrer" href="https://www.heartlungcirc.org/article/S1443-9506(18)31777-3/fulltext">www.heartlungcirc.org/article/S1443-9506(18)31777-3/fulltext</a></li>
                                        <li>Diagnostic work up of a patient with suspected heart failure: <a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/images/uploads/publications/HF_Figure_2_Diagnostic_workup_of_a_patients_with_suspected_heart_failure.jpg">www.heartfoundation.org.au/images/uploads/publications/HF_Figure_2_Diagnostic_workup_of_a_patients_with_suspected_heart_failure.jpg</a></li>
                                        <li>Management of patients with heart failure with reduced ejection fraction: <a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/images/uploads/publications/HF_Figure_3_Management_of_patients_with_heart_failure_and_reduced_ejection_fraction.jpg">www.heartfoundation.org.au/images/uploads/publications/HF_Figure_3_Management_of_patients_with_heart_failure_and_reduced_ejection_fraction.jpg</a></li>
                                        <li>Heart Failure Toolkit and resources: <a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/for-professionals/heart-failure-tools-and-resources">www.heartfoundation.org.au/for-professionals/heart-failure-tools-and-resources</a></li>
                                    </ul>

                                    <p>Heart Foundation: Hypercholesterolaemia and Familial hypercholesterolaemia</p>
                                    <ul>
                                        <li>Consumer information: Familial hypercholesterolaemia: <a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/images/uploads/publications/familial-hypercholesterolaemia.pdf">www.heartfoundation.org.au/images/uploads/publications/familial-hypercholesterolaemia.pdf</a></li>
                                        <li>Consumer information: Fats and cholesterol: <a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/healthy-eating/food-and-nutrition/fats-and-cholesterol">www.heartfoundation.org.au/healthy-eating/food-and-nutrition/fats-and-cholesterol</a></li>
                                    </ul>

                                    <p>
                                        Baker IDI Heart and Diabetes Institute: Secondary prevention of cardiovascular disease in type 2 diabetes: <a target="_blank" rel="noopener noreferrer" href="https://www.baker.edu.au/Assets/Files/B_Short%20form%20guideline%20for%20SecondaryPreventionofCVDinType2diabetes_4%20Dec%202015.pdf">www.baker.edu.au/Assets/Files/B_Short%20form%20guideline%20for%20SecondaryPreventionofCVDinType2diabetes_4%20Dec%202015.pdf</a>
                                    </p>
                                    <p>
                                        Heart Foundation: Secondary prevention of coronary heart disease <a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/images/uploads/publications/Reducing-risk-in-heart-disease.pdf">www.heartfoundation.org.au/images/uploads/publications/Reducing-risk-in-heart-disease.pdf</a>
                                    </p>
                                </div>
                            </li>
                            <li>Type 2 diabetes
                                <div>
                                    <p>RACGP: General Practice management of type 2 diabetes: <a target="_blank" rel="noopener noreferrer" href="https://www.racgp.org.au/clinical-resources/clinical-guidelines/key-racgp-guidelines/view-all-racgp-guidelines/management-of-type-2-diabetes/introduction/introduction">www.racgp.org.au/clinical-resources/clinical-guidelines/key-racgp-guidelines/view-all-racgp-guidelines/management-of-type-2-diabetes/introduction/introduction</a></p>
                                    <p>Australian Diabetes Society: Type 2 Diabetes Treatment Algorithm <a target="_blank" rel="noopener noreferrer" href="http://t2d.diabetessociety.com.au/plan/">t2d.diabetessociety.com.au/plan</a></p>
                                    <p>Baker IDI Heart and Diabetes Institute: Secondary prevention of cardiovascular disease in type 2 diabetes: <a target="_blank" rel="noopener noreferrer" href="https://www.baker.edu.au/Assets/Files/B_Short%20form%20guideline%20for%20SecondaryPreventionofCVDinType2diabetes_4%20Dec%202015.pdf">www.baker.edu.au/Assets/Files/B_Short%20form%20guideline%20for%20SecondaryPreventionofCVDinType2diabetes_4%20Dec%202015.pdf</a></p>
                                    <p>Diabetes Australia: Consumer resources: Living with diabetes <a target="_blank" rel="noopener noreferrer" href="https://www.diabetesaustralia.com.au/living-with-diabetes">www.diabetesaustralia.com.au/living-with-diabetes</a></p>
                                    <p>NDSS forms (Registration, medication changes, blood glucose strip 6 month extension): <a target="_blank" rel="noopener noreferrer" href="https://www.ndss.com.au/forms">www.ndss.com.au/forms</a></p>
                                </div>
                            </li>

                            <li>Medicare Benefits Scheme
                                <div>
                                    <p>MBS Online: <a target="_blank" rel="noopener noreferrer" href="http://www.mbsonline.gov.au/internet/mbsonline/publishing.nsf/Content/Home">www.mbsonline.gov.au/internet/mbsonline/publishing.nsf/Content/Home</a></p>
                                    <p>Frequently used general practice MBS Item numbers: <a target="_blank" rel="noopener noreferrer" href="http://www.brisbanenorthphn.org.au/content/Document/PHN%20MBS%20resource_final_2016_2.pdf">www.brisbanenorthphn.org.au/content/Document/PHN%20MBS%20resource_final_2016_2.pdf</a></p>
                                </div>
                            </li>
                            <li>Pharmaceutical benefits scheme
                        <div>
                                    <p>PBS: <a target="_blank" rel="noopener noreferrer" href="http://www.pbs.gov.au/pbs/home">www.pbs.gov.au/pbs/home</a></p>
                                </div>
                            </li>
                        </ol>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.CallbackForAllDialogsClose} color="primary">
                        Close
                        </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default  withMobileDialog() (GuidelineDialog);