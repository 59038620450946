import React, { Component } from "react";
import FhtFetchUrl from "Utils/FhtFetchUrl";
import Configs from "Configuration";
import {
  createTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles/';
import Tooltip from '@material-ui/core/Tooltip';
import MaterialTable from 'material-table';
import Card from '@material-ui/core/Card';

const muiTheme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1em"
      }
    }
  }
});

const useStyles = theme => ({
  formDetail: {
    width: '100%',
    marginTop: '2REM'
  },
  table: {
    margin: '2REM'
  },
  tableTitle: {
    marginLeft: '4REM',
    textAlign: 'left',
    width: 'max-content'
  }
});

class QIActivityActions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      // actions: this.props.actions
    }
  }

  renderActions = () => {
    const { classes } = this.props;
    return (
      <div className={classes.formDetail}>
        <Card variant="outlined">
          <div className={classes.table}>
            <Tooltip arrow disableFocusListener disableTouchListener
              title="Assign GPs to specific actions for this form. For system provided actions, choose standard actions. To define your own actions, choose customised actions.">
              <h4>Actions</h4>
            </Tooltip>
          </div>

          <div className={classes.tableTitle}>
            <Tooltip arrow disableFocusListener disableTouchListener title="To be completed by practice as part of the RACGP accredited activity">
              <h5>Recommended actions</h5>
            </Tooltip>

          </div>
          <div className={classes.table}>
            <MaterialTable columns={this.props.standardActionColumns}
              localization={{ header: { actions: '' } }}
              data={this.props.actions.standard}
              title=""
              editable={{
                onRowAdd: newData =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      if (newData.qiActivityActionDescriptionId === null || newData.qiActivityActionDescriptionId === undefined) {
                        this.props.globalMessage("Please select action", Configs.snackbarVariants.error);
                        reject();
                        return;
                      }
                      resolve();
                      const actions = this.props.actions;
                      const data = actions.standard.map(x => Object.assign({}, x));
                      this.actionChanged = true;
                      newData.qiActivityActionChampionName = newData.qiActivityActionChampionName === "0" ? "" : newData.qiActivityActionChampionName;
                      newData.qiActivityActionDelegateName = newData.qiActivityActionDelegateName === "0" ? "" : newData.qiActivityActionDelegateName;
                      data.push(newData);
                      actions.standard = data;
                      this.props.updateParentActions(true, false, actions)
                      this.setState({ actions: actions }, () => { });
                    }, 500)
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      resolve();
                      this.actionChanged = true;
                      const actions = this.props.actions;
                      const data = actions.standard.slice();
                      const index = data.indexOf(oldData);
                      newData.qiActivityActionChampionName = newData.qiActivityActionChampionName === "0" ? "" : newData.qiActivityActionChampionName;
                      newData.qiActivityActionDelegateName = newData.qiActivityActionDelegateName === "0" ? "" : newData.qiActivityActionDelegateName;
                      data[index] = newData;
                      actions.standard = data;
                      this.props.updateParentActions(true, false, actions)
                      this.setState({ actions: actions }, () => { });
                    }, 500);
                  }),
                onRowDelete: oldData =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      resolve();
                      this.actionChanged = true;
                      const actions = this.props.actions;
                      const data = actions.standard.slice();
                      const index = data.indexOf(oldData);
                      data.splice(index, 1);
                      actions.standard = data;
                      this.props.updateParentActions(true, false, actions)
                      this.setState({ actions: actions }, () => { });
                    }, 500);
                  })
              }}
            />
          </div>
          <div className={classes.tableTitle}>
            <h5>Custom Actions</h5>
          </div>
          <div className={classes.table}>
            <MaterialTable columns={this.props.customActionColumns}
              localization={{ header: { actions: '' } }}
              data={this.props.actions.custom}
              title=""
              editable={{
                onRowAdd: newData =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      if (newData.qiActivityActionDescription === null || newData.qiActivityActionDescription === undefined || newData.qiActivityActionDescription.trim() === "") {
                        this.props.globalMessage("Please enter action description", Configs.snackbarVariants.error);
                        reject();
                        return;
                      }
                      resolve();
                      const actions = this.props.actions;
                      const data = actions.custom.map(x => Object.assign({}, x));
                      this.actionChanged = true;
                      newData.qiActivityActionChampionName = newData.qiActivityActionChampionName === "0" ? "" : newData.qiActivityActionChampionName;
                      newData.qiActivityActionDelegateName = newData.qiActivityActionDelegateName === "0" ? "" : newData.qiActivityActionDelegateName;
                      data.push(newData);
                      actions.custom = data;
                      this.props.updateParentActions(true, false, actions)
                      this.setState({ actions: actions }, () => { });
                    }, 500)
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      if (newData.qiActivityActionDescription === null || newData.qiActivityActionDescription === undefined || newData.qiActivityActionDescription.trim() === "") {
                        this.props.globalMessage("Please enter action description", Configs.snackbarVariants.error);
                        reject();
                        return;
                      }
                      resolve();
                      this.actionChanged = true;
                      const actions = this.props.actions;
                      const data = actions.custom.slice();
                      const index = data.indexOf(oldData);
                      newData.qiActivityActionChampionName = newData.qiActivityActionChampionName === "0" ? "" : newData.qiActivityActionChampionName;
                      newData.qiActivityActionDelegateName = newData.qiActivityActionDelegateName === "0" ? "" : newData.qiActivityActionDelegateName;
                      data[index] = newData;
                      actions.custom = data;
                      this.props.updateParentActions(true, false, actions)
                      this.setState({ actions: actions }, () => {
                      });
                    }, 500);
                  }),
                onRowDelete: oldData =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      resolve();
                      this.actionChanged = true;
                      const actions = this.props.actions;
                      const data = actions.custom.slice();
                      const index = data.indexOf(oldData);
                      data.splice(index, 1);
                      actions.custom = data;
                      this.props.updateParentActions(true, false, actions)
                      this.setState({ actions: actions }, () => { });
                    }, 500);
                  })
              }}
            />
          </div>
        </Card>

      </div>

    );
  }

  render() {
    return (
      <div>
        {this.renderActions()}
      </div>
    );
  }
}


export default withStyles(useStyles)(QIActivityActions);