import Configs from "Configuration";
//Permanently disabled unless one item is checked.
export function checkAnyFiltersEnabled (params){
    if (params === null)
        return true;
    return checkAll(params);
}
export function checkFamilyHistoryHideStatus (params) {
    if (params === null)
        return true;

    return atRisk_All(params);
}
export function checkT1DAndT2DDiagnosed (params) {
    if (params === null)
    return true;

    return atRisk_Optimise_All(params);
}
export function checkHypertensionClassification (params) {
    if (params === null)
    return true;

    return atRisk_Optimise_All(params);  
}
export function checkCKDStageAssessment(params) {
    if (params === null)
    return true;

    return diagnosis_Optimise_All(params);
}
export function checkCVDDiagnosed (params) {
    if (params === null)
    return true;

    return atRisk_Optimise_All(params);
}
export function checkCVDRisk (params) {
    if (params === null)
    return true;

    return diagnosis_Optimise_All(params);
}
export function checkAcuteKidneyInjury (params) {
    if (params === null)
    return true;

    return atRisk_All(params);
}
export function checkManagementPlanStatus(params) {
    if (params === null)
    return true;

    return checkAll(params);    
}
export function checkSmoker(params) {
    if (params === null)
    return true;

    return atRisk_Optimise_All(params);
}
export function checkBMI(params) {
    if (params === null)
    return true;

    return atRisk_Optimise_All(params);         
}
export function checkATSI(params) {
    if (params === null)
    return true;
    return atRisk_All(params);      
}
export function checkTotalRiskFactors(params) {
    if (params === null)
    return true;

    return atRisk_All(params);
}
export function checkSmokers(params) {
    if (params === null)
        return true;
    return atRisk_Optimise_All(params);
}

export function checkDeferEnabled(params){
    if (params === null)
        return true;
    if (params.showDeferredPatients && params.multiCheckboxCKDFilterCurrentValue.length > 0)
        return false;
    return true;
}

export function checkShowOnlyActiveEnabled(params){
    if (params === null)
        return true;
    if (params.showOnlyActivePatients && params.multiCheckboxCKDFilterCurrentValue.length > 0)
        return false;
    return true;  
}

export function checkCompletedPatientsEnabled(params){
    if (params === null)
        return true;
    if (params.showCompletedPatients && params.multiCheckboxCKDFilterCurrentValue.length > 0)
        return false;
    return true;    
}

function atRisk_Optimise_All(params)
{
    if (params.checkBoxFilters == undefined || params.checkBoxFilters == null)
    return false;
    var temp = params.checkBoxFilters;
    for (const [key] of Object.entries(temp)){
        if ((key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterAll && temp[key]===true)|| (key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterAtRisk && temp[key]===true)
            || (key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterOptimiseBp && temp[key]===true)|| (key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterOptimiseStatin && temp[key]===true)
            || (key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterOptimiseBpCvd && temp[key]===true)|| (key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterIntensifyBp && temp[key]===true)
            || (key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterIntensifyStatin && temp[key]===true)
            )
            {
                return false;
            }
    }
    return true;      
}

function diagnosis_Optimise_All(params)
{
    if (params.checkBoxFilters == undefined || params.checkBoxFilters == null)
    return false;
    var temp = params.checkBoxFilters;
    for (const [key] of Object.entries(temp)){
        if ((key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterAll && temp[key]===true)|| (key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterReviewDiagnosis && temp[key]===true)
            || (key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterOptimiseStatin && temp[key]===true) || (key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterOptimiseBp && temp[key]===true)
            || (key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterOptimiseBpCvd && temp[key]===true) || (key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterIntensifyBp && temp[key]===true)
            || (key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterIntensifyStatin && temp[key]===true)
            )
            {
                return false;
            }
    }
    return true;      
}

function atRisk_All(params)
{
    if (params.checkBoxFilters == undefined || params.checkBoxFilters == null)
    return false;
    var temp = params.checkBoxFilters;
    for (const [key] of Object.entries(temp)){
        if ((key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterAll && temp[key]===true)|| (key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterAtRisk && temp[key]===true)
            )
            {
                return false;
            }
    }
    return true;      
}

function checkAll(params)
{
    if (params.checkBoxFilters == undefined || params.checkBoxFilters == null)
    return false;
    var temp = params.checkBoxFilters;
    for (const [key] of Object.entries(temp)){
        if ((key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterAll && temp[key]===true)|| (key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterAtRisk && temp[key]===true)
            || (key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterLikely && temp[key]===true ) || (key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterReviewDiagnosis && temp[key]===true)
            || (key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterOptimiseStatin && temp[key]===true) || (key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterOptimiseBp && temp[key]===true)
            || (key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterOptimiseBpCvd && temp[key]===true) || (key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterIntensifyBp && temp[key]===true)
            || (key === Configs.ckdPopulationFilters.checkBoxCkdRiskFilterIntensifyStatin && temp[key]===true)
            )
            {
                return false;
            }
    }
    return true;
}