import React from 'react';
import {
	withStyles
} from '@material-ui/core/styles/';
import Button from '@material-ui/core/Button';
import MaterialTable from 'material-table';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Configs from 'Configuration';
import FhtFetchUrl from 'Utils/FhtFetchUrl';

const useStyles = theme => ({
	root: {
		margin: '2REM',
		textAlign: 'center'
	},
	subContainer: {
		textAlign: 'center',
		padding: '2REM 3REM'
	},
	table: {
		margin: '2REM'
	},
	buttonMargin: {
		marginLeft: '1REM'
	},
	button: {
		margin: theme.spacing(1)
	},
	formDetail: {
		width: '100%',
		marginTop: '2REM'
	},
	commonTopMargin: {
		marginTop: '2REM',
		textAlign: 'center'
	},
	formContainer: {
		marginTop: '2REM',
		padding: '3REM'
	},
	chips: {
		display: "flex",
		flexWrap: "wrap"
	},
	chip: {
		margin: 2
	},
	inputText: {
		textAlign: 'left'
	},
	reflection: {
		marginTop: '2REM',
		textAlign: 'left',
	},
	appBar: {
		top: 'auto',
		bottom: 0,
		backgroundImage: 'linear-gradient(to right, rgb(39, 149, 148), rgb(72, 175, 104))'
	},
	card: {
		width: '100%',
		padding: '2REM'
	},
	tip: {
		fontSize: 'smaller',
		textAlign: 'left',
		marginTop: '1REM'
	},
	tableTitle: {
		marginLeft: '4REM',
		textAlign: 'left',
		width: 'max-content'
	}
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

class QIActivityScreenAllActivitiesTable extends React.Component {
	constructor(props) {
		super(props);
		this.participants = [];
		this.pageOptions = {
			pageSize: 10,
			pageSizeOptions: [10, 20, 30]
		};
		this.activityColumns = [
			{ title: "Title", field: 'qiActivityTitle', width: '30%' },
			{ title: "Start Date", field: 'startDate', width: '10%' },
			{ title: "End Date", field: "endDate", width: '10%' },
			{ title: "Facilitator", field: "facilitator", width: '15%' },
			{ title: "Cohort Name", field: "cohortName", width: '15%' },
			{ title: "Status", field: "qiActivityStatus", width: '10%' },
			{ title: "Create Date", field: "createDate", width: '10%' }
		];
		this.state = {
			activities: [],
			selectedActivity: [],
			participantObjs: {},
		};
		this.getEnabledParticipants();
	}

	// Modified code from QIActivityScreenDetail.
	// participantObjs is required in QIActivityScreenAllActivitiesTable.
	getEnabledParticipants = () => {
		const api = FhtFetchUrl(Configs.getParticipants);
		let participants = {};
		api.get().then(response => {
			response.map(r => {
				participants[r.qiPaticipantEnabledId] = (r.qiPaticipantEnabledActive === 1) ? r.qiPaticipantEnabledName : `${r.qiPaticipantEnabledName}[Inactive]`;
			});
			console.log("getEnabledParticipants participants", participants)
			this.participants = response;
			this.setState({
				participantObjs: participants
			})
		})
		this.getActivities();
	}

	getActivities = () => {
		const api = FhtFetchUrl(Configs.getQiActivities);
		api.get().then(response => {
			console.log("getActivities response", response)
			response.map((r, index) => {
				let sd = r.qiActivityStartDate ? new Date(r.qiActivityStartDate) : null;
				let ed = r.qiActivityEndDate ? new Date(r.qiActivityEndDate) : null;
				let cd = new Date(r.qiActivityCreateDate);
				r.title = r.qiActivityTitle == null ? " " : r.qiActivityTitle;
				r.description = r.qiActivityDescription == null ? " " : r.qiActivityDescription;
				r.goal = r.qiActivityGoal === null ? " " : r.qiActivityGoal;
				r.aim = r.qiActivityAim === null ? " " : r.qiActivityAim;
				// r.cohortName = this.cohortObjs[r.qiActivityCohortId];
				// r.cohortName = "TEST";
				r.cohortName = this.setCurrentCohortsForQIActivity(r.qiActivityId);
				// r.cohortObj = this.setCurrentActiveCohorts(r);
				// r.cohortObj = " ";
				r.facilitatorObj = this.setActivityFacilitator(r);
				r.facilitator = this.state.participantObjs[r.qiActivityFacilitator] || "";
				r.startDate = sd != null ? sd.getDate() + "/" + (sd.getMonth() + 1) + "/" + sd.getFullYear() : "";
				r.endDate = ed != null ? ed.getDate() + "/" + (ed.getMonth() + 1) + "/" + ed.getFullYear() : "";
				r.createDate = cd.getDate() + "/" + (cd.getMonth() + 1) + "/" + cd.getFullYear();
			});
			this.setState({ activities: response });
		});
	}

	setCurrentCohortsForQIActivity(qiActivityID) {
		// If we have a qiActivityId associated with Cohorts then this try works.
		// If the dictionary does not have the qiActivityId we catch it and do nothing,
		// leaving the selectedCohortsForQIActivity as a blank array.
		try {
			var cohortsForQIActivityDictionary = this.props.currentQIActivityAndCohortsID;
			var allCohortsDictionary = this.props.cohorts;
			var allCohortNames = "";
			var currentQIActivityIDCohortsArray = cohortsForQIActivityDictionary[qiActivityID]
			for (var i = 0; i < currentQIActivityIDCohortsArray.length; i++) {
				for (var j = 0; j < allCohortsDictionary.length; j++) {

					if (allCohortsDictionary[j].cohortId == currentQIActivityIDCohortsArray[i].qiActivityToCohortsCohortsID) {
						allCohortNames = allCohortsDictionary[j].cohortName + ", " + allCohortNames
					}
				}
			}
			// Slice the blank space and extra comma
			return allCohortNames.slice(0, -2)
		} catch {
			return ""
		}
	}

	// Copied and pasted.
	// QIActivityScreenAllActivitiesTable and ScreenDetail use same function.
	setActivityFacilitator = (activity) => {
		let facilitatorObj = this.participants.find(obj => {
			return obj.qiPaticipantEnabledId === activity.qiActivityFacilitator;
		});

		if (facilitatorObj == undefined)
			return {};

		return facilitatorObj || {};
	}

	handleCreateNewActivity = () => {
		this.props.setSummaryTable();
		var defaultFacilitator = {
			"qiPaticipantEnabledId": null,
			"qiPaticipantEnabledName": "",
			"qiPaticipantEnabledEmail": "",
			"qiPaticipantEnabledMobile": "",
			"qiPaticipantEnabledRACGPNumber": "",
			"qiPaticipantEnabledAccrmId": "",
			"qiPaticipantEnabledUserId": 0,
			"qiPaticipantEnabledRoleId": 0,
			"qiPaticipantEnabledActive": 0
		}
		let activity = {
			qiActivityTitle: "",
			qiActivityDescription: "",
			qiActivityAim: "",
			qiActivityGoal: "",
			qiActivityFacilitator: 0,
			qiActivityStartDate: null,
			qiActivityEndDate: null,
			// qiActivityCohortId: 0,
			qiActivityStatus: "",
			qiActivityCreateDate: new Date(),
			maxDate: new Date("01/01/2100"),
			minDate: new Date("01/01/1900"),
			facilitatorObj: defaultFacilitator
		};
		this.props.setQIActivityData(activity);
	}

	handleSelectActivity = (e, data) => {
		this.props.setQIActivityData(data)
		this.props.setSummaryTable();
	}

	renderSummary = () => {
		const { classes } = this.props;
		return (
			<div style={{ padding: "1REM" }}>
				<h2>Quality Improvement Activities</h2>
				<div>
					<Button variant="outlined" onClick={() => this.handleCreateNewActivity()}>Create new activity</Button>
				</div>
				<div className={classes.table}>
					<MaterialTable columns={this.activityColumns}
						title="Quality Improvement Activities"
						data={this.state.activities}
						actions={[
							{
								icon: 'save',
								tooltip: 'View/Edit Activity',
								onClick: (event, rowData) => this.handleSelectActivity(event, rowData)
							}
						]}
						components={{
							Action: props => (
								<Button
									onClick={(event) => props.action.onClick(event, props.data)}
									color="primary"
									variant="contained"
									style={{ textTransform: 'none' }}
									size="small"
								>
									View/Edit
								</Button>
							)
						}}
						options={this.pageOptions}
					/>
				</div>
			</div>
		);
	}

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				{this.renderSummary()}
			</div>
		)
	}
}

export default withStyles(useStyles)(QIActivityScreenAllActivitiesTable);