import React, { Component } from 'react';
import { Divider, Grid, Paper, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ReactHtmlParser from 'react-html-parser';
import { format } from 'date-fns';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import FhtMain from  'FhtMain';
 

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

export default class CommentsRendererPOC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //userName: sessionStorage.getItem("commentUser"),
      //fullName: sessionStorage.getItem("commentFullname"),
      userName: FhtMain.GlobalContext.userName,
      fullName: FhtMain.GlobalContext.fullName,
      
    }
  }

  componentDidMount() {
     // alert(this.props.POCUserID);

  }
  // componentWillReceiveProps({newprops}) {
  //   if(newprops)
  //     this.setState({data: newprops.Comments})
  // }

  deleteComment = (commentId, userName) => {
    this.props.context.componentParent.removeComment(commentId, userName);
  }

  editComment = (commentId, userName, comment) => {
    //alert(this.state.userName);
    this.props.context.componentParent.editComment(commentId, userName, comment);
  }

  render() {
    return (
     <Box style={{ padding: 14, width: "100%", position: "relative" }} className="App">
       <Box style={!this.props.overlay ? { display: 'none' } : { padding: 14, width: "100%", height: "100%", zIndex: 999, position: 'absolute',left:0, top:0, background: "rgba(255,255,255,.5)" }} className="App"></Box>
        {this.props.Comments && this.props.Comments.map(comment => ( 
          <Paper key = {comment.commentId} style={{ padding: "20px 20px", marginBottom: "10px" }}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid  justifycontent="left" item xs zeroMinWidth>  
                  <IconButton disabled={this.props.POCUserID != comment.pocUserId ? true : false} onClick={() => this.deleteComment(comment.commentId, comment.userName)} style={{ float: "right", margin: "0px", padding: "0px" }}><DeleteIcon/></IconButton>  
                  <IconButton disabled={this.props.POCUserID != comment.pocUserId ? true : false} onClick={() => this.editComment(comment.commentId, comment.userName, comment.comments)} style={{ float: "right", margin: "0px", padding: "0px" }}><EditIcon/></IconButton>  
                <div><h5 style={{ margin: 0, fontWeight:"Bold", textAlign: "left" }}>{comment.fullName}</h5> </div>
                           
                <p style={{ textAlign: "left" ,marginTop: "0.5REM" }}>
                  
                  {ReactHtmlParser(comment.comments)}
                </p>
                <p style={{ textAlign: "left", color: "gray" }}>
                  Posted a comment - {timeAgo.format(new Date(comment.createdDate), "dd-MMM-yyyy hh:mm:ss a")} {comment.updatedDate != null ? <span>-<span style={{color:"red"}}> edited</span></span> : ""}             
                </p>
              </Grid>
            </Grid>
            {/* <Divider variant="fullWidth" style={{ margin: "30px 0" }} /> */}
          </Paper>
        ))}
      </Box>
    );
  }
}