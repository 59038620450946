import React, { Component } from "react";
import { Responsive, WidthProvider } from 'react-grid-layout';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const opts = {
  height: '280',
  width: '450',
  playerVars: {
    autoplay: 0
  }
};
const useStyles = makeStyles => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: '10REM'
  }
});

class QIActivityResources extends Component {

  constructor(props) {
    super(props);

    this.state = {
      openDialog: false,
      displayGuidelines: null
    }
  }

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };
  handleCardClick = (searchKey, linkTo) => {
    console.log('Card clicked:', searchKey);
    let dialogBody = <div>Not Loaded</div>;
    switch (searchKey) {
      // case 'apna':
      //   dialogBody = (
      //   );
      //   this.setState({ displayGuidelines: dialogBody, openDialog: true });
      //   break;
      default:
        var win = window.open(linkTo, '_blank');
        win.focus();
    }
  };
  render() {

    const classes = useStyles();
    let cardheight = 8;
    let cardwidth = 2;
    let xwidth = 2.5;
    var i;

    let readLinks = [
      {
        image: 'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/QIECHOScreenshotImage.jpg',
        title: 'FHT Continuous Improvement Tool Kit',
        description: '',
        linkTo: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/qiToolKit.pdf",
        searchKey: 'na'
      },
      {
        image: 'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/mbs.jpg',
        title: 'How to use FHT for QI Activities',
        description: '',
        linkTo: "https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/How+to+use+FHT+for+quality+improvement+activities+v5+4+Jul+2023.pdf",
        searchKey: 'na'
      }
    ];
    let readItemsDictionary = [];

    // The QI resources, which is now being moved to the QIActivityResources page.
    for (i = 0; i < readLinks.length; i++) {
      let ystartingposition = 0;
      let xstartingposition = 1.4;
      let itemsPerRow = 4;
      let xposition = (i % itemsPerRow) * xwidth + xstartingposition;
      let yposition = ystartingposition + cardheight * Math.floor(i / 4);
      readItemsDictionary.push({
        readCounter: i,
        imageLink: readLinks[i].image,
        xposition: xposition,
        yposition: yposition,
        title: readLinks[i].title,
        description: readLinks[i].description,
        linkTo: readLinks[i].linkTo,
        searchKey: readLinks[i].searchKey
      });
    }
    let readLinksCode = readItemsDictionary.map(guidelineItem => {
      let keyGuideline = 'readlink' + guidelineItem.readCounter;
      return (
        <div
          key={keyGuideline}
          style={{}}
          data-grid={{
            x: guidelineItem.xposition,
            y: guidelineItem.yposition,
            w: cardwidth,
            h: cardheight,
            static: true
          }}
        >
          <Card className={classes.root}>
            <CardActionArea
              onClick={() => {
                this.handleCardClick(guidelineItem.searchKey, guidelineItem.linkTo);
              }}
            >
              <CardMedia
                style={{ height: '10REM' }}
                image={guidelineItem.imageLink}
                title={guidelineItem.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {guidelineItem.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {guidelineItem.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      );
    });

    //let {form} = this.
    return (
      <div>
        
          {/* This is an "old" QI resource that hasn't been discussed recently. Commented out if needed in future. */}
          {/* <p> https://www.safetyandquality.gov.au/our-work/shared-decision-making/shared-decision-making-symposium</p> */}



        <ResponsiveReactGridLayout
          className="reviewCohortLayout"
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          rowHeight={25}
          isResizable={false}
        >
          <div
            key="youtubelinks"
            style={{}}
            data-grid={{ x: 0.1, y: 0, w: 12, h: 23, static: true }}
          >

            <div style={{ fontSize: '3REM', textAlign: 'center', paddingTop: '2REM' }}>
              Quality Improvement Resources
            </div>

            <div>
              <div style={{ fontSize: '2REM', textAlign: 'center' }}>
                <i class="circular book icon"></i>
                Read
              </div>
              <hr
                style={{
                  width: '80%',
                  height: '0.1REM',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  backgroundImage:
                    'linear-gradient(to right, rgb(39, 149, 148), rgb(72, 175, 104))',
                  border: 'none'
                }}
              />
              <ResponsiveReactGridLayout
                className="reviewCohortLayout"
                cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                rowHeight={25}
                isResizable={false}
              >
                {readLinksCode}
              </ResponsiveReactGridLayout>
            </div>
          </div>
        </ResponsiveReactGridLayout>

        <Dialog
          onClose={e => this.handleCloseDialog()}
          aria-labelledby="simple-dialog-title"
          open={this.state.openDialog}
          maxWidth="md"
        >
          <DialogTitle style={{ textAlign: 'center' }} id="simple-dialog-title">
            <b>Resources</b>
          </DialogTitle>
          {this.state.displayGuidelines}
        </Dialog>



      </div>
    );
  }
}


export default (QIActivityResources);