import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import TextField from '@material-ui/core/TextField';


import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
// import LockOutlinedIcon from '@material-ui/icons/VpnKey';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Container from '@material-ui/core/Container';

import CircularProgress from '@material-ui/core/CircularProgress';

import Configs from 'Configuration';
import SiteConfigurationContext from "Utils/SiteConfigurationContext.jsx";
import FhtMain from "FhtMain";

const DialogTitle = withStyles(theme => ({
  root: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    // padding: theme.spacing(2),
  },
  closeButton: {
    // position: 'absolute',
    position: 'fixed',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButtonAbs: {
    position: 'absolute',
    // position: 'fixed',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))(props => {
  const { classes, onClose, fullScreen } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      {/* <Typography variant="h6">{children}</Typography> */}
      {onClose ? (
        <IconButton aria-label="Close" className={fullScreen ? classes.closeButton : classes.closeButtonAbs} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});



const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    //   marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    // color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
  },
});

class UserProfileDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,       
      loading: false,
      username: '',
      firstname: '',
      lastname: '',
      email: '',
      siteConfigurationContext:SiteConfigurationContext(),
    };
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  abortController = new AbortController();

  handleSubmit(event) {
    event.preventDefault();
    this.handleSaveMyProfile();
  }

  handleSaveMyProfile() {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    var params = { Email: this.state.email, Username: this.state.username, Firstname: this.state.firstname, Lastname: this.state.lastname };
    var badResponse = false;
    fetch(this.state.siteConfigurationContext[Configs.SiteConfigurationMappingKeys.serverapi] + Configs.updateMyProfile, {
      signal: this.abortController.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'cache-control': 'no-cache',
        'Authorization': 'Bearer ' + FhtMain.GlobalContext.userToken
      },
      body: JSON.stringify(params)
    })
     .then(response =>{
      console.log(response);
      // eslint-disable-next-line
      if (response.statusText != "OK"){
          badResponse = true;
      }
      return response.json();
      })
      .then(response=>{
        if (this.state.loading) {
          this.setState({ loading: false });
        }        
            if (badResponse === true)
            {
              throw (response.message);
            }
            else
            {
              this.props.GlobalFunctionDisplaySnackbarMessage(response.message, Configs.snackbarVariants.success);
              //this.props.onHandleSuccess(response.message, this.state.firstname + " " + this.state.lastname, this.state.firstname[0] + '' + this.state.lastname[0]);
            }
      })
      .catch((error) => {
        console.log(error);
        if (this.state.loading) {
          this.setState({ loading: false });
        }
        this.props.GlobalFunctionDisplaySnackbarMessage(String(error), Configs.snackbarVariants.error);
      })

  }


  componentDidMount() {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    var badResponse = false;
    fetch(this.state.siteConfigurationContext[Configs.SiteConfigurationMappingKeys.serverapi] + Configs.getUserInformation, {
      signal: this.abortController.signal,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        //Accept:'application/json',
        'cache-control': 'no-cache',
        'Authorization': 'Bearer ' + FhtMain.GlobalContext.userToken
      },
    })
     .then(response =>{
        console.log(response);
        // eslint-disable-next-line
        if (response.statusText != "OK"){
            badResponse = true;
        }
        return response.json();
      })
      .then(response=>{
        if (this.state.loading) {
          this.setState({ loading: false });
        }        
            if (badResponse === true)
            {
              throw (response.message);
            }
            else
            {
              this.setState({
                email: response.data.email,
                firstname: response.data.firstname,
                lastname: response.data.lastname,
                username: response.data.username
              });
              if (this.state.loading) {
                this.setState({ loading: false });
              }
            }
      })     
      .catch((error) => {
        // console.log('error: ' + error);
        if (this.state.loading) {
          this.setState({ loading: false });
        }
        this.props.GlobalFunctionDisplaySnackbarMessage(String(error), Configs.snackbarVariants.error);
      })
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  render() {
    const { classes } = this.props;
    const { username, email, firstname, lastname, loading } = this.state;
    return (
      <div>
        <Dialog fullScreen={false}
          onClose={this.props.CallbackForAllDialogsClose}
          aria-labelledby="customized-dialog-title"
          open={true}
          fullWidth={true}
        >
          <DialogTitle id="customized-dialog-title" fullScreen={false} onClose={this.props.CallbackForAllDialogsClose}>
          </DialogTitle>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <AccountCircle />
              </Avatar>
              <Typography component="h1" variant="h5">
                My Profile
                                </Typography>
              { //this.state.data !== null &&
                <form className={classes.form} onSubmit={this.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="username"
                        label="Username"
                        id="username"
                        autoComplete="username"
                        value={username}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        disabled={loading || username === ''}
                        name="firstName"
                        label="First Name"
                        id="firstName"
                        autoComplete="firstName"
                        value={firstname}
                        onChange={(event) => {
                          this.setState({ firstname: event.target.value });
                        }}

                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        disabled={loading || username === ''}
                        name="lastName"
                        label="Last Name"
                        id="lastName"
                        autoComplete="lastName"
                        value={lastname}
                        onChange={(event) => {
                          this.setState({ lastname: event.target.value });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        disabled={loading || username === ''}
                        name="email"
                        label="Email"
                        id="email"
                        autoComplete="email"
                        value={email}
                        onChange={(event) => {
                          this.setState({ email: event.target.value });
                        }}
                      />
                    </Grid>

                  </Grid>



                  <div className={classes.wrapper}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={loading || username === ''}
                    >
                      {loading ? 'Load' : 'Save'}
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </form>}
            </div>

          </Container>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(UserProfileDialog));