import Configs from '../SiteSpecificConfig';

   
export function BatchSiteConfigurationContext() {
  let returnResult = {};
  /*
  var windowsUrl = window.location.href;
  for (var urlName in Configs.SiteConfigurationMapping)
  {
    if (windowsUrl.includes(urlName))
    {
      return returnResult = Configs.BatchSiteConfigurationMapping[Configs.SiteConfigurationMapping[urlName]["Batch"]];  
    }  
  }
*/
  var windowsUrl = window.location.hostname;
  for (var urlName in Configs.SiteConfigurationMapping)
  {
    if (windowsUrl.includes("localhost") && windowsUrl.includes(urlName))
    {
      return returnResult = Configs.BatchSiteConfigurationMapping[Configs.SiteConfigurationMapping[urlName]["Batch"]];  
    }
    else if (windowsUrl==urlName)
    {
      return returnResult = Configs.BatchSiteConfigurationMapping[Configs.SiteConfigurationMapping[urlName]["Batch"]];  
    }  
  }


  return returnResult;
}

export default BatchSiteConfigurationContext;