import Configs from 'Configuration';
import SiteConfigurationContext from 'Utils/SiteConfigurationContext';
import BatchSiteConfigurationContext from 'Utils/BatchSiteConfigurationContext';
import FhtMain from 'FhtMain';

//Shared function by the project to fetch data via json
const FhtFetchUrl = (endpoint, customToken = "") => {
	const siteConfigurationContext = SiteConfigurationContext();
	const batchSiteConfigurationContext = BatchSiteConfigurationContext();
	const softwareType = batchSiteConfigurationContext['IsTorch'] == 'true' ? Configs.torchSoftwareType : Configs.fhtSoftwareType;
	
	let defaultHeader;
	if (customToken.length === 0) {
		defaultHeader = Object.assign({
			'Authorization': 'Bearer ' + FhtMain.GlobalContext.userToken,
			'X-Software-Type': softwareType
		}, Configs.urlHeader.headers);
	} else {
		defaultHeader = Object.assign({
			'Authorization': 'Bearer ' + customToken,
			'X-Software-Type': softwareType
		}, Configs.urlHeader.headers);
	}

	const customFetch = (
		url,
		method = "GET",
		body = false,
		headers = defaultHeader
	) => {
		const options = {
			method,
			headers
		};
		if (body) options.body = JSON.stringify(body);
		return fetch(url, options)
			.then(response => response.json())
			.catch(err => {
			console.log(err);
			});
	};

	const get = id => {
		const url = `${siteConfigurationContext[Configs.SiteConfigurationMappingKeys.serverapi]}${endpoint}${id ? `/${id}` : ''}`;
		return customFetch(url);
	}

	const post = (body = false) => {
		if (!body) throw new Error('failed');
		const url = `${siteConfigurationContext[Configs.SiteConfigurationMappingKeys.serverapi]}${endpoint}`;
		return customFetch(url, "POST", body);
	}

	const put = (id = false, body = false) => {
		if (!id || !body) {
			console.log('no body');
			throw new Error('failed');
		}
		// console.log(endpoint, id, body);
		const url = `${siteConfigurationContext[Configs.SiteConfigurationMappingKeys.serverapi]}${endpoint}/${id}`;
		return customFetch(url, "PUT", body);
	}

	const del = (id = false) => {
		console.log(id)
		if (!id) {
			console.log('no id');
			throw new Error('failed');
		}
		const url = `${siteConfigurationContext[Configs.SiteConfigurationMappingKeys.serverapi]}${endpoint}/${id}`;
		return customFetch(url, "DELETE");
	}

	return { get, post, put, del };
}

export default FhtFetchUrl;