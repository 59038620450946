import React from 'react';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers/';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import FhtMain from 'FhtMain';
import FhtFetchUrl from 'Utils/FhtFetchUrl.jsx';
import Configs from 'Configuration';

export default class DeferCohortRecommendationsScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRecTestIDsToUnDefer: [],
            deferReason: "",
            deferUntilDate: null,
            activeRecommendationsRender: [],
        };
        this.onSubmitUnDeferCohortRecommendations = this.onSubmitUnDeferCohortRecommendations.bind(this);
    }
    componentDidMount() {
        
        let activeRecommendations = [];
        var selectedRows = this.props.selectedRows;
        var algTestIDsToDescriptionDict = this.props.algTestIDsToDescriptionDict;
        activeRecommendations = this.props.activeRecommendations;
        // Filter to only have FHT recommendations.
        var filteredAlgTestIDsToDescriptionDict = Object.fromEntries(Object.entries(algTestIDsToDescriptionDict)
                                                                    .filter(([k,v]) => v.fhtEnabledAnalysisSoftwareType != "2"));
        
        var localActiveRecommendationsRender = [
            <div key={"deferText"} style={{ paddingBottom: "1REM" }}>
                Please select the recommendations you would like to Undefer
            </div>
        ]

        // Get all active recommendations for selected patients
        for (var i = 0; i < selectedRows.length; i++) {
            try {
                
                var patientRecommendations = activeRecommendations;
                // Only add TestIDs if they are not already in our activeRecommendations array.
                for (var j = 0; j < patientRecommendations.length; j++) {
                    if (activeRecommendations.includes(patientRecommendations[j]) || patientRecommendations[j] == null)
                        continue;
                    activeRecommendations.push(patientRecommendations[j]);
                }
            } catch (e) {                 
                console.log(e)
            }
        }

        let uniqueTestIDs = {};

        // Loop through all active recommendations for selected patients.
        for (var i = 0; i < activeRecommendations.length; i++) {
            try {
                // Split the current recommendation if it contains a comma
                var activeRec = activeRecommendations[i];
                var activeRecParts = activeRec.split(',');
                
                // Iterate through each part and add test ID to uniqueTestIDs object
                for (var j = 0; j < activeRecParts.length; j++) {
                    var activeRecTestID = Number(activeRecParts[j]);
        
                    // Add the test ID to uniqueTestIDs if it exists in filteredAlgTestIDsToDescriptionDict and if it's not already in uniqueTestIDs
                    if (activeRecTestID in filteredAlgTestIDsToDescriptionDict && !(activeRecTestID in uniqueTestIDs)) {
                        uniqueTestIDs[activeRecTestID] = filteredAlgTestIDsToDescriptionDict[activeRecTestID].fhtEnabledAnalysisDescription;
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }
        
        // Push the unique test IDs with their descriptions into our array render.
        Object.keys(uniqueTestIDs).forEach((testID, index) => {
            const testDescription = uniqueTestIDs[testID];
            localActiveRecommendationsRender.push(
                <div style={{ padding: "0.5REM" }} key={index}>
                    <Grid container>
                        <Grid item xs={1}>
                            <Checkbox
                                value={testID}
                                onClick={(e) => this.handleUserActionMenuDeferCohortRecommendation(
                                    e,
                                    this.state.selectedRecTestIDsToUnDefer,
                                )}
                            />
                        </Grid>
                        <Grid item xs={11}>
                            {testDescription}
                        </Grid>
                    </Grid>
                </div>
            );
        });

        
        this.setState({
            activeRecommendationsRender: localActiveRecommendationsRender
        })
    }
    onSubmitUnDeferCohortRecommendations() {
        let selectedIds = this.state.selectedRecTestIDsToUnDefer;
        let undeferPatients = [];
        let selectedPatients = this.props.selectedRows;
        
        selectedPatients.map(patient => {
            // Loop through selected test IDs to undefer for each patient
            selectedIds.forEach(testId => {
                undeferPatients.push({
                    PatientId: patient.patientExtractId,
                    RecommendationId: patient.recommendationId,
                    recommendationUpdateBy: FhtMain.GlobalContext.userName,
                    RecommendationTestId: testId // Make sure each test ID is added individually
                });
            });
        });
    
        let body = { patientsDeferredList: undeferPatients };
        const api = FhtFetchUrl(Configs.submitRecommendationUnDefer);
        api.post(body).then(r => {
            this.props.GlobalFunctionDisplaySnackbarMessage(
                "Successfully deferred recommendation(s) for selected patient(s)",
                Configs.snackbarVariants.success
            );
            this.props.onBtCancelDeferCohortRecUserActionDialog();
            window.location.reload();
        }).catch(error => {
            var msg = String(error);
            this.props.GlobalFunctionDisplaySnackbarMessage(
                "Error deferring recommendation(s) for selected patient(s). Error: " + msg,
                Configs.snackbarVariants.error
            );
        });
    };

    handleUserActionMenuDeferCohortRecommendation(
        event,
        selectedRecTestIDsToUnDefer,
    ) {
        var deepCopyDeferCohortRecsSelectedTestIDs = JSON.parse(JSON.stringify(selectedRecTestIDsToUnDefer))

        // Add to selectedRecTestIDsToUnDefer if check box ticked
        // and selectedRecTestIDsToUnDefer does not contain ID.
        if (event.target.checked && !deepCopyDeferCohortRecsSelectedTestIDs.includes(event.target.value)) {
            deepCopyDeferCohortRecsSelectedTestIDs.push(event.target.value)
            this.handleDeferCohortRecsSelectedTestIDs(deepCopyDeferCohortRecsSelectedTestIDs)
        }

        // Remove from selectedRecTestIDsToUnDefer if check box not ticked
        // and selectedRecTestIDsToUnDefer does contain ID.
        if (!event.target.checked && deepCopyDeferCohortRecsSelectedTestIDs.includes(event.target.value)) {
            this.handleDeferCohortRecsSelectedTestIDs(deepCopyDeferCohortRecsSelectedTestIDs.filter(x => x !== event.target.value))
        }
    }

    handleDeferCohortRecsSelectedTestIDs(updatedSelectedTestIDs) {
        this.setState({
            selectedRecTestIDsToUnDefer: updatedSelectedTestIDs
        })
    }
    
    render() {
        return (
            <div>
                {this.state.activeRecommendationsRender}
                    <div>
                        {/* You can also undefer individual recommendations via the Patient Summary Recommendations submenu. */}
                        {/* <div>
                            Click
                            <span style={{ paddingLeft: "0.5REM", paddingRight: "0.5REM" }}>
                                <a href="#" onClick={() => this.props.onBtCancelDeferCohortRecUserActionDialog()}>
                                    Continue
                                </a>
                            </span>
                            to confirm
                        </div> */}
                    </div>
                    <Button
                        variant="contained"
                        fullWidth
                        style={{ margin: '24px 0px 16px' }}
                        color="primary"
                       
                        onClick={() => this.onSubmitUnDeferCohortRecommendations()}
                    >
                        Confirm
                    </Button>
                   
                    <Button
                        variant="contained"
                        fullWidth
                        style={{ margin: '24px 0px 16px' }}
                        color="primary"
                        onClick={() => this.props.onBtCancelDeferCohortRecUserActionDialog()}
                    >
                        Cancel
                    </Button>
                </div>
            
        );
    }
}
