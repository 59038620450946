import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import withMobileDialog from '@material-ui/core/withMobileDialog';


const DialogTitle = withStyles(theme => ({
    root: {
        // borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        // padding: theme.spacing(2),
    },
    closeButton: {
        // position: 'absolute',
        position: 'fixed',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    closeButtonAbs: {
        position: 'absolute',
        // position: 'fixed',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}))(props => {
    const { classes, onClose, fullScreen } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            {/* <Typography variant="h6">{children}</Typography> */}
            {onClose ? (
                <IconButton aria-label="Close" className={fullScreen ? classes.closeButton : classes.closeButtonAbs} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});



const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        //   marginTop: theme.spacing(4),
        marginBottom: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class CDialog extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.onSubmit();
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Dialog fullScreen={this.props.fullScreen}
                    onClose={this.props.onHide}
                    aria-labelledby="customized-dialog-title"
                    open={this.props.visible}
                    fullWidth={true}
                >
                    <DialogTitle id="customized-dialog-title" fullScreen={this.props.fullScreen} onClose={this.props.onHide}>
                    </DialogTitle>
                    <Container component="main" maxWidth={this.props.maxWidth}>
                        <CssBaseline />
                        <div className={classes.paper}>
                            <Avatar className={classes.avatar}>
                                {this.props.headerIcon}
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                {this.props.header}
                                </Typography>
                            <form className={classes.form} onSubmit={this.handleSubmit}>
                            {this.props.children}
                   
                 
                            {this.props.footer}
                            </form>
                        </div>
                    </Container>
                </Dialog>
            </div>
        );
    }
}

export default withMobileDialog()(withStyles(styles)(CDialog));