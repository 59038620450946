import React, { Component } from "react";
import {
  createTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles/';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import Configs from "Configuration";
import MaterialTable from 'material-table';

const muiTheme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1em"
      }
    }
  }
});

const useStyles = theme => ({
  formDetail: {
    width: '100%',
    marginTop: '2REM'
  },
  table: {
    margin: '2REM'
  },
});

class QIActivityParticipants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      participantColumns: this.props.participantColumns,
      selectedParticipants: this.props.selectedParticipants,
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.formDetail}>
        <Card variant="outlined">
          <div className={classes.table}>
            <Tooltip arrow disableFocusListener disableTouchListener
              title="Choose which GPs will be participating in this form, from your participants list.">
              <h4>Participants</h4>
            </Tooltip>
          </div>
          <MaterialTable columns={this.state.participantColumns}
            data={this.state.selectedParticipants}
            title=""
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    console.log(newData);
                    if (newData.qiPaticipantEnabledName == null || newData.qiPaticipantEnabledName == undefined) {
                      this.props.globalMessage("Please select participant", Configs.snackbarVariants.error);
                      reject();
                      return;
                    }
                    const data = this.state.selectedParticipants.map(x => Object.assign({}, x));
                    let item = this.props.participants.find(obj => obj.qiPaticipantEnabledId === parseInt(newData.qiPaticipantEnabledName, 10));
                    const ind = data.findIndex(x => x.qiPaticipantEnabledId === item.qiPaticipantEnabledId);
                    if (ind > -1) {
                      this.props.globalMessage("This participant already exists", Configs.snackbarVariants.error);
                      reject();
                      return;
                    }
                    resolve();
                    var participantChanged = true;
                    newData.qiPaticipantEnabledEmail = item.qiPaticipantEnabledEmail;
                    newData.qiPaticipantEnabledRoleName = item.qiPaticipantEnabledRoleName;
                    newData.qiPaticipantEnabledId = item.qiPaticipantEnabledId;
                    newData.qiPaticipantEnabledFullName = item.qiPaticipantEnabledName;
                    data.push(newData);
                    this.props.updateParentSelectedParticipants(participantChanged, data);
                    this.setState({ selectedParticipants: data }, () => { });
                  }, 500)
                }),
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    var participantChanged = true;
                    const data = this.state.selectedParticipants.slice();
                    const index = data.indexOf(oldData);
                    data.splice(index, 1);
                    this.props.updateParentSelectedParticipants(participantChanged, data);
                    this.setState({ selectedParticipants: data }, () => { });
                  }, 500);
                })
            }} />
        </Card>
      </div>
    );
  }
}


export default withStyles(useStyles)(QIActivityParticipants);