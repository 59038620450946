import React from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import Link from '@material-ui/core/Link';
import ToolTip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import CheckIcon from '@material-ui/icons/Check';
import UpdateIcon from '@material-ui/icons/Update';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import IconButton from '@material-ui/core/IconButton';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import TextField from '@material-ui/core/TextField';
import SnackbarContentWrapper from 'components/SnackbarContentWrapper';
import Snackbar from '@material-ui/core/Snackbar';
import DateFnsUtils from '@date-io/date-fns';
import MaterialTable from 'material-table';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import NestedMenuItem from "material-ui-nested-menu-item";
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
}from '@material-ui/pickers/';

import ProofDialog from '../components/ProofDialog';
import RecommendDialog from '../components/RecommendDialog';
import Configs from 'Configuration';
import Analysis from '../components/Analysis';

import SiteConfigurationContext from 'Utils/SiteConfigurationContext.jsx';
import { ResponsiveLine } from '@nivo/line';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import FhtMain from "FhtMain";
import FhtFetchUrl from 'Utils/FhtFetchUrl';
import { register } from 'serviceWorker';

import BatchSiteConfigurationContext from 'Utils/BatchSiteConfigurationContext.jsx';
import AlgorithmSiteConfigurationContext from 'Utils/AlgorithmSiteConfigurationContext.jsx';

// const styles = theme => ({
//   toggleContainer: {
//     display: 'inline-flex',
//     alignItems: 'center',
//     justifyContent: 'flex-start',
//     margin: '10px 10px 10px 10px',
//     background: theme.palette.background.default
//   },
//   menu: {
//     backgroundColor: 'red !important'
//   },
  
// });

const useStyles = theme => ({
	formDetail: {
		width: '100%',
		marginTop: '2REM'
	},
	buttonMargin: {
		marginLeft: '1REM'
	},
	
});

let id = 0;
function createData(name, date) {
  id += 1;
  return { id, name, date };
}

class PatientDetailScreen extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.batchSiteConfigurationContext = BatchSiteConfigurationContext();
    this.algorithmSiteConfigurationContext = AlgorithmSiteConfigurationContext();
		// this.globalMessage = props.GlobalFunctionDisplaySnackbarMessage;
    this.selectedRecommendation = null;
    this.userName = FhtMain.GlobalContext.userName;
    this.siteConfigurationContext = SiteConfigurationContext();
    this.deferReason = "";
    this.lodgedRecommendation = "";
    this.triggerSources = [];
    this.queryId = null;
		this.snackbarMessage = "";
    this.snackbarVariant = "success";
    this.queryStatus= {
      1: "Open",
      2: "Close"
    }
    this.triggerDescription = {};
    this.state = {
      layout: [],
      addChartMultiValue: null,
      addChartValue: [],
      addChartOpen: false,
      formats: [],
      eventDrop: false,
      editable: false,
      open: false,
      name: '',
      rmMessage: '',
      errorState: false,
      selectedItem: 'eGFR',
      selectedAction: "",
      triageData: {},
      medications: [],
      measures: [],
      conditions: [],
      displayConditions: [],
      mbsItems: [],
      triggers: [],
      desc: '',
      suggestions: [
        { label: 'eGFR', items: { value: ['eGFR'], title: ['eGFR']}, disabled: true },
        { label: 'uACR', items: { value: ['uACR'], title: ['uACR']}, disabled: true },
        { label: 'SBP, DBP', items: { value: ['SBP', 'DBP'], title: ['SBP', 'DBP']}, disabled: true },
        {
          label: 'TRIG, TC, LDL, HDL',
          items: { value: ['TRIG', 'CHOL', 'LDL', 'HDL'], title: ['TRIG', 'TC', 'LDL', 'HDL']},
          disabled: true
        },
        { label: 'HbA1c', items: { value: ['HbA1c'], title: ['HbA1c']}, disabled: true },
				{ label: 'BSL, BSLF', items: { value: ['BSL', 'BSLF'],title: ['BSL', 'BSLF']},  disabled: true },
        { label: 'PSA', items: { value: ['PSA'], title: ['PSA']}, disabled: true },
        { label: 'Free-to-Total PSA', items: { value: ['Free-to-Total PSA'], title: ['Free-to-Total PSA']}, disabled: true },
        { label: 'PLT (Platelet) count', items: { value: ['PLT (Platelet) count'], title: ['PLT (Platelet) count']}, disabled: true },     
        { label: 'HB', items: { value: ['HB'], title: ['HB']}, disabled: true },
        { label: 'MCV', items: { value: ['MCV'], title: ['MCV']}, disabled: true },
        { label: 'MCH', items: { value: ['MCH'], title: ['MCH']}, disabled: true },
        { label: 'FERRITIN', items: { value: ['FERRITIN'], title: ['FERRITIN']}, disabled: true },

        { label: 'Serum creatinine', items: { value: ['Serum creatinine'], title: ['Serum creatinine']}, disabled: true },
        { label: 'uCreatinine', items: { value: ['uCreatinine'], title: ['uCreatinine']}, disabled: true },
        { label: 'uAlbumin', items: { value: ['uAlbumin'], title: ['uAlbumin']}, disabled: true },
        { label: 'ALT', items: { value: ['ALT'], title: ['ALT']}, disabled: true },
        { label: 'AST', items: { value: ['AST'], title: ['AST']}, disabled: true },
        { label: 'GGT', items: { value: ['GGT'], title: ['GGT']}, disabled: true },
        { label: 'BMI', items: { value: ['BMI'], title: ['BMI']}, disabled: true },
        { label: 'BNP', items: { value: ['BNP'], title: ['BNP']}, disabled: true },
        { label: 'NTProBNP', items: { value: ['NTPROBNP'], title: ['NT-ProBNP']}, disabled: true },
        { label: 'GTT1HR', items: { value: ['GTT1HR'], title: ['GTT1HR']}, disabled: true },
        { label: 'GTT2HR', items: { value: ['GTT2HR'], title: ['GTT2HR']}, disabled: true },
        
        { label: 'Serum potassium', items: { value: ['Serum potassium'], title: ['Serum potassium']}, disabled: true },
      ],
      queryTable: [
				{ title: "Pantient Name", field: 'local.query.lodgeRecommendationPatientName'},
				{ title: "Recommendation", field: 'local.description.fhtEnabledAnalysisDescription'},
				{ title: "Comment", field: 'local.query.lodgeRecommendationComment'},
				{ title: "Resolution", field: 'solution.fhtLodgeRecommendationJustification'},
				{ title: "Status", field: 'solution.fhtLodgeRecommendationStatus', lookup: this.queryStatus},
      ],
      expand: false,
      graphData: null,
      graphMax: null,
      isSinglePoint: false,
      pathologyMapping: {
        eGFR: 'eGFR',
        uACR: 'uACR',
        SBP: 'SBP',
        DBP: 'DBP',
        TRIG: 'TRIG',
        CHOL: 'TC',
        LDL: 'LDL',
        HDL: 'HDL',
        HbA1c: 'HbA1c',
        BSL: 'BSL',
        BSLF: 'BSLF',
        PSA: 'PSA',
        'Free-to-Total PSA': 'Free-to-Total PSA',
        'PLT (Platelet) count': 'PLT (Platelet) count',
        HB: 'HB',
        MCV: 'MCV',
        MCH: 'MCH',
        FERRITIN: 'FERRITIN',
        'Serum creatinine': 'Serum creatinine',
        uCreatinine: 'uCreatinine',
        uAlbumin: 'uAlbumin',
        ALT: 'ALT',
        AST: 'AST',
        GGT: 'GGT',
        BMI: 'BMI',
        BNP: 'BNP',
        NTPROBNP: 'NTPROBNP',
        GTT1HR: 'GTT1HR',
        GTT2HR: 'GTT2HR',
        'Serum potassium': 'Serum potassium',
      },
      anchorEl: null,
      anchorDs: null,
      actionOpen: false,
      actionId: 0,
      openSnackbar: false,
      queryListOpen: false,
      deferUntilDate: null,
      selectedQuery: [],
      allQuery: [],
      query: "",
      err: false,
      errMsg: "",
      patientId: "",
      viewAll: false,
      menuPosition: null,
      copyText: "",
      isActive: {},
      hideInactiveCondition: false,
    };
  }

  abortController = new AbortController();

  apiDataForPOC = (id, accessToken) => {
    debugger;
    this.patientId = id;
    this.setState({
      patientId: id
    });
    const api = FhtFetchUrl(`${Configs.patientDetails}/${id}`, accessToken);
    api.get()
      .then(data => {
        let today = new Date().setHours(0,0,0,0);
        
        let _isActive = {};

        data.fhtPatientAnalysisData.map(r => {
          if(r.active) {
            _isActive[r.testId] = true;
          }else{
            _isActive[r.testId] = false;
            let localUpdateDate = new Date(`${r.status.recommendationUpdateDate}Z`)
            let updateDate = new Date(localUpdateDate).setHours(0,0,0,0);
            r.status.updateDate = this.formatDate(updateDate);
            if(r.status.recommendationEndDate !== null){
              let localDate = new Date(`${r.status.recommendationEndDate}Z`);
              let ud = new Date(localDate).setHours(0,0,0,0);

              
              r.status.formatDate = this.formatDate(ud);
              if(ud < today){
                //if it pass the until date, however the status still hasnt update by algorithms, 
                //display this recommendation as active
                _isActive[r.testId] = true;
                r.active = true;
                r.status.recommendationStatus = 0;
              }
            }
          }
        
      });

      // Modify patientExtractCurrentStatus to have front-end Active or Inactive status
      // when opening patient data.
      if (data.patientExtract.patientExtractCurrentStatus == 1)
        data.patientExtract.patientExtractCurrentStatus = "Active"
      else if (data.patientExtract.patientExtractCurrentStatus == 0)
        data.patientExtract.patientExtractCurrentStatus = "Inactive"
      else if (data.patientExtract.patientExtractCurrentStatus == 2)
        data.patientExtract.patientExtractCurrentStatus = "Deceased"

      if (this.batchSiteConfigurationContext['PatientDetailPregnancyStatus'] == "true") {
        if (data.patientExtract.patientExtractIsPregnant != null) {
          if (data.patientExtract.patientExtractIsPregnant == 0)
            data.patientExtract.patientExtractIsPregnant = "Not pregnant"
          if (data.patientExtract.patientExtractIsPregnant == 1)
            data.patientExtract.patientExtractIsPregnant = "Pregnant"
        }          
      }

      let res = [];
      this.setState({
        isActive: _isActive,
        patient: data.patientExtract,
        triageData: data.triageExtract,
        risks: data.fhtPatientAnalysisData,
        conditions: data.conditions,
        displayConditions: data.conditions,
        measures: data.measures,
        medications: data.medications,
        mbsItems: data.mbsItems,
        triggers: data.triggers,
        open: this.props.rmMessages !== undefined,
        name: this.props.rmMessages
      });
    })
    .catch(error => {
      console.log('error1', id, error);
      this.setState({ errorState: true, errorMsg: error });
    });
  };


  apiData = id => {
    this.patientId = id;
    this.setState({
      patientId: id
    });
    
    const api = FhtFetchUrl(`${Configs.patientDetails}/${id}`);
    api.get()
      .then(data => {
        let today = new Date().setHours(0,0,0,0);
        
        let _isActive = {};

        data.fhtPatientAnalysisData.map(r => {
          if(r.active) {
            _isActive[r.testId] = true;
          }else{
            _isActive[r.testId] = false;
            let localUpdateDate = new Date(`${r.status.recommendationUpdateDate}Z`)
            let updateDate = new Date(localUpdateDate).setHours(0,0,0,0);
            r.status.updateDate = this.formatDate(updateDate);
            if(r.status.recommendationEndDate !== null){
              let localDate = new Date(`${r.status.recommendationEndDate}Z`);
              let ud = new Date(localDate).setHours(0,0,0,0);

              
              r.status.formatDate = this.formatDate(ud);
              if(ud < today){
                //if it pass the until date, however the status still hasnt update by algorithms, 
                //display this recommendation as active
                _isActive[r.testId] = true;
                r.active = true;
                r.status.recommendationStatus = 0;
              }
            }
          }
        
      });

      // Modify patientExtractCurrentStatus to have front-end Active or Inactive status
      // when opening patient data.
      if (data.patientExtract.patientExtractCurrentStatus == 1)
        data.patientExtract.patientExtractCurrentStatus = "Active"
      else if (data.patientExtract.patientExtractCurrentStatus == 0)
        data.patientExtract.patientExtractCurrentStatus = "Inactive"
      else if (data.patientExtract.patientExtractCurrentStatus == 2)
        data.patientExtract.patientExtractCurrentStatus = "Deceased"

      if (this.batchSiteConfigurationContext['PatientDetailPregnancyStatus'] == "true") {
        if (data.patientExtract.patientExtractIsPregnant != null) {
          if (data.patientExtract.patientExtractIsPregnant == 0)
            data.patientExtract.patientExtractIsPregnant = "Not pregnant"
          if (data.patientExtract.patientExtractIsPregnant == 1)
            data.patientExtract.patientExtractIsPregnant = "Pregnant"
        }          
      }

      let res = [];
      this.setState({
        isActive: _isActive,
        patient: data.patientExtract,
        triageData: data.triageExtract,
        risks: data.fhtPatientAnalysisData,
        conditions: data.conditions,
        displayConditions: data.conditions,
        measures: data.measures,
        medications: data.medications,
        mbsItems: data.mbsItems,
        triggers: data.triggers,
        open: this.props.rmMessages !== undefined,
        name: this.props.rmMessages
      });
    })
    .catch(error => {
      console.log('error1', id, error);
      this.setState({ errorState: true, errorMsg: error });
    });
  };

  componentWillUnmount() {}

  componentDidMount() {
    let accessToken;
    try {
        const searchParams = new URLSearchParams(this.props.location.search);
        if (searchParams.has('accessToken')) {
            accessToken = searchParams.get('accessToken');
        } else {
            accessToken = null;
        }
    } catch (error) {
        console.error("Error parsing URL parameters:", error);
        accessToken = null;
    }

    if (accessToken === null) {
        this.loadPatientData();
    } else {
        this.loadPatientPOCData(); 
    }
}
  loadPatientPOCData() {
    let id;
    let accessToken;
    const searchParams = new URLSearchParams(this.props.location.search);
    if (searchParams.has('accessToken')) {
      accessToken = searchParams.get('accessToken');
    } else {
      // handle case where accessToken is not in the URL
      accessToken = null;
    }
    if (this.props.location) {
      // id = this.props.location.search.split('=')[1];
      const urlParams = new URLSearchParams(window.location.search);
 
      id = urlParams.get('id');
    } else {
      if (
        this.props.data !== null &&
        this.props.data !== undefined &&
        this.props.data.triageData !== undefined &&
        this.props.data.triageData !== null &&
        this.props.data.triageData.triageExtractPatientId !== null &&
        this.props.data.triageData.triageExtractPatientId !== undefined
      ) {
        id = this.props.data.triageData.triageExtractPatientId;
      } else if ( //temporary hack until we standardize the grids into one format.
        this.props.data.patientExtractId !== null &&
        this.props.data.patientExtractId !== undefined
      )
        id = this.props.data.patientExtractId;
      else {
        id = this.props.childSubmitPatientId;
      }
    }
    // let id = 0;
    this.setState({ errorState: false, patient: null });
    this.apiDataForPOC(id, accessToken);
    this.getTriggerDesc();
  }

  loadPatientData() {
    let id;
    if (this.props.location) {
      id = this.props.location.search.split('=')[1];
    } else {
      if (
        this.props.data !== null &&
        this.props.data !== undefined &&
        this.props.data.triageData !== undefined &&
        this.props.data.triageData !== null &&
        this.props.data.triageData.triageExtractPatientId !== null &&
        this.props.data.triageData.triageExtractPatientId !== undefined
      ) {
        id = this.props.data.triageData.triageExtractPatientId;
      } else if ( //temporary hack until we standardize the grids into one format.
        this.props.data.patientExtractId !== null &&
        this.props.data.patientExtractId !== undefined
      )
        id = this.props.data.patientExtractId;
      else {
        id = this.props.childSubmitPatientId;
      }
    }
    // let id = 0;
    this.setState({ errorState: false, patient: null });
    this.apiData(id);
    this.getTriggerDesc();
  }

  getTriggerDesc = () => {
    const api = FhtFetchUrl(Configs.getTriggerDescription);
    api.get().then(response => {
      response.map(r => {
        this.triggerDescription[r.fhtEnabledTriggersId] = r.fhtEnabledTriggersDescription;
      });
    });
  }

  getRecommendationQuery = () => {
    const api = FhtFetchUrl(`${Configs.getRecommendationResolution}/web`);
		api.get().then(response => {
      console.log(response);
      let _query = response.filter(r => {
        return r.local.query.lodgeRecommendationPatientId === this.state.patientId;
      });
      console.log(_query);
      this.setState({
        allQuery: response,
        selectedQuery: _query,
      });
		});
  }
  
  snackbarWrapper = (variant, msg) => {
		this.snackbarVariant = variant;
		this.snackbarMessage = msg;
		this.setState({openSnackbar: true});
	}

  handleProofOpen = (name, desc) => {
    this.setState(state => ({ open: true, name: name, desc: desc }));
  };

  handleProofClose = () => {
    this.setState({ open: false });
  };

  handleExpandOpen = (graphData, max, isSinglePoint) => {
    this.setState({ expand: true, graphData, graphMax: max, isSinglePoint });
  };

  handleExpandClose = () => {
    this.setState({ expand: false });
  };

  handleItemClick = key => {
    this.setState({ selectedItem: key });
  };

  handleMenuOpen = (e, row) => {
    this.selectedRecommendation = row;
    this.setState({anchorEl: e.currentTarget});
  }

  handleUndeferMenuOpen = (e, row) => {
    this.selectedRecommendation = row;
    this.setState({anchorDs: e.currentTarget});
  }

  handleRecommendationActionOpen = (e, id) => {
    let actionId = e.target.id;
    const api = FhtFetchUrl(`${Configs.getLodgeRecommendation}/${this.selectedRecommendation.patientAnalysisId}/web`);
		api.get().then(response => {
      let query = response ? response.lodgeRecommendationComment : "";  
      this.queryId = response ? response.lodgeRecommendationId : null;
      let _triggers = this.state.triggers;
      let result = [];
      _triggers.map(t => {
        if(t.fhtPatientAnalysisTriggersFhtEnabledAnalysisId == this.selectedRecommendation.testId){
          result.push(t.fhtPatientAnalysisTriggersRefTestId);
        }
      });
        this.triggerSources = result;
        this.setState({
          query: query,
          actionOpen: true,
          // anchorEl: null,
          selectedAction: actionId,
          actionId: id,
        });
    });
  }

  handleRecommendationActionClose = () => {

    this.setState({
      actionOpen: false,
      // anchorEl: null,
      selectedAction: "",
      actionId: 0,
      deferUntilDate: null,
      // anchorDs: null,
    });
    this.lodgedRecommendation = "";
    this.deferReason = "";
  }

  handleMenuClose = () => {
    this.setState({anchorEl: null});
  }

  handleUndeferMenuClose = () => {
    this.setState({anchorDs: null});
  }

  handleChangeRecommendationStatus = (status, reason, endDate) => {
    let rc = this.selectedRecommendation;
    let updateDate = new Date();
    let data = {
      recommendationPatientId: this.patientId,
      recommendationTestId: rc.testId,
      recommendationStatus: status,
      recommendationTriageExtractId: rc.triageExtractId,
      recommendationUpdateBy: this.userName,
      recommendationUpdateDate: updateDate,
      recommendationPatientAnalysisId: rc.patientAnalysisId,
      recommendationReason: reason,
      recommendationEndDate: endDate,
    };
    const api = FhtFetchUrl(Configs.updateRecommendationStatus);
    let _isActive = this.state.isActive;
    api.post(data).then(r => {
      let _risk = this.state.risks;
      let ind = this.state.risks.findIndex(x => x.patientAnalysisId === this.selectedRecommendation.patientAnalysisId);
      if(status > 0){
        data.updateDate = this.formatDate(updateDate);
        if(endDate){
          data.formatDate = this.formatDate(endDate);
        }
        _isActive[rc.testId] = false;
        _risk[ind].active = false;
        _risk[ind].status = data;
      } else {
        _isActive[rc.testId] = true;
        _risk[ind].active = true;
        _risk[ind].status = null;
      }
      this.setState({
        risks: _risk,
        isActive: _isActive
      }, () => {console.log(this.state.isActive)});
      
      
      this.snackbarWrapper(Configs.snackbarVariants.success, "Update recommendation status success");
      this.loadPatientData();
    });
    this.handleMenuClick();
    this.handleRecommendationActionClose();
  }

  handleDeferRecommendation = () => {
    let today = new Date();
    today.setHours(0,0,0,0);
    //let endDate = this.state.deferUntilDate;
   
    if(this.deferReason.value === "" || this.state.deferUntilDate === null || this.state.deferUntilDate == "Invalid Date"){
      this.setState({
        err: true,
        errMsg: "Please enter reason and valid defer until date"
      });
      return;
    } else if(this.state.deferUntilDate.getFullYear() > 2099){
      this.setState({
        err: true,
        errMsg: "Maximum date is 31/12/2099"
      });
      return;
    } else if(today.getTime() > this.state.deferUntilDate.getTime()){
      this.setState({
        err: true,
        errMsg: "Please enter non historic date"
      });
      return;
    } 

    this.setState({
      err: false,
      errMsg: ""
    });
    this.handleChangeRecommendationStatus(2, this.deferReason.value, this.state.deferUntilDate);
  }

  handleLodgeRecommendation = () => {
    let patientId = this.state.patient.patientExtractId;
    let patientName = `${this.state.patient.patientExtractFirstname} ${this.state.patient.patientExtractSurname}`;
    let data = {
      lodgeRecommendationId: this.queryId || 0,
      lodgeRecommendationPatientId: patientId,
      lodgeRecommendationPatientAnalysisId: this.selectedRecommendation.patientAnalysisId,
      lodgeRecommendationTriageExtractId: this.selectedRecommendation.triageExtractId,
      lodgeRecommendationPatientName: patientName,
      lodgeRecommendationDate: new Date(),
      lodgeRecommendationFhtUser: this.userName,
      lodgeRecommendationSource: 'web',
      lodgeRecommendationTestId: this.selectedRecommendation.testId,
      lodgeRecommendationTriggers: this.triggerSources.toString(),
      lodgeRecommendationComment: this.lodgedRecommendation.value
    };
    this.handleMenuClick();
    this.handleRecommendationActionClose();
    const api = FhtFetchUrl(Configs.lodgeRecommendation);
		api.post(data).then(response => {
      this.snackbarWrapper(Configs.snackbarVariants.success, "Lodge Recommendation Query success");
		});
  }

  handleDeferDateChange = (e) => {
    this.setState({deferUntilDate: e});
  }

  handleViewQueryList = (e) => {
    this.getRecommendationQuery();
    this.setState({
      queryListOpen: true
    });
  }

  handleViewAll = () => {
    this.setState({
      viewAll: true,
      selectedQuery: this.state.allQuery
    });
  }

  handleViewSelectedPatient = () => {
    let _query = this.state.allQuery.filter(r => {
      return r.local.query.lodgeRecommendationPatientId === this.state.patientId;
    });
    this.setState({
      viewAll: false,
      selectedQuery: _query,
    });
  }

  handleRecommendationListClose = () => {
    this.setState({
      queryListOpen: false,
      selectedQuery: [],
      viewAll: false,
    })
  }

  handleMenuClick = () => {
    this.setState({
      menuPosition: null
    })
  }

  handleMainMenuClick = (event, row) => {
    this.selectedRecommendation = row;
    this.setState({
      name: row.testId
    });
    if(this.state.menuPosition){
      return;
    }
    event.preventDefault();
    let pos = {
      top: event.pageY,
      left: event.pageX
    };
    this.setState({
      menuPosition: pos
    });
  }

  toggleDisplayCondition = () =>{
    console.log(this.state.conditions);
    console.log(this.state.displayConditions);
    this.setState({
      hideInactiveCondition: !this.state.hideInactiveCondition
    }, () => {

      if(this.state.hideInactiveCondition) {
        let _activeCondition = this.state.conditions.filter(x => x.conditionExtractResolved === null);
        console.log(_activeCondition);
        this.setState({
          displayConditions: _activeCondition
        });
      }else{
        this.setState({
          displayConditions: this.state.conditions
        });
      }
    });
    // let conditions = this.state.conditions;
  }

  calculateAge = dob => {
    let ageDiff = Date.now() - new Date(dob);
    let age = new Date(ageDiff);
    return Math.abs(age.getUTCFullYear() - 1970);
  };

  formatDate = date => {
    let d = new Date(date);
    return {
      date: d.getDate(),
      mm: d.getMonth() + 1,
      month: Configs.month[d.getMonth()],
      year: d.getFullYear()
    };
  };

  formatGraphData = data => {
    let dataSets = [];
    let singlePoint = true;
    let years = {};
    Object.keys(data).map(key => {
      let dataSet = {
        id: key,
        data: []
      };
      data[key].map(row => {
        years[row.calendar.year] = 1;
        dataSet.data.push({
          x: `${row.calendar.year}-${row.calendar.mm}-${row.calendar.date}`,
          y: row.value
        });
      });

      if (dataSet.data.length > 0) {
        dataSets.push(dataSet);
      }
    });

    if (Object.keys(years).length > 1) {
      singlePoint = false;
    }
    return { dataSets, singlePoint };
  };

  getMax = data => {
    let max = -999999999999;
    Object.keys(data).map(key => {
      data[key].map(row => {
        max = Math.max(max, row.value);
      });
    });
    // max += 20;
    return max;
  };

  renderExpandGraph = name => {
    return (
      <ProofDialog
        open={this.state.expand}
        title={this.state.selectedItem}
        onClose={this.handleExpandClose}
      >
        <div style={{ height: '500px' }}>
          {this.renderGraph(this.state.graphData, this.state.graphMax, this.state.isSinglePoint)}
        </div>
      </ProofDialog>
    );
  };

  renderRecommendationQueryList = () => {
    const { classes } = this.props;	
    return (
      <div>
        <RecommendDialog
          open={this.state.queryListOpen}
          title="Recommendation List"
          onClose={this.handleRecommendationListClose}
        >
          <div style={{ height: '600px'}}>
            <MaterialTable
              columns={this.state.queryTable} 
              data={this.state.selectedQuery} 
              title="Recommendation Queries"
              > 
            </MaterialTable>
            <div className={classes.formDetail} >
              { this.state.viewAll ? 
                <Button variant="outlined" onClick={() => this.handleViewSelectedPatient()}>VIEW THIS PATIENT</Button> : 
                <Button variant="outlined" onClick={() => this.handleViewAll()}>VIEW ALL</Button>
              }
              <Button variant="outlined" className={classes.buttonMargin} onClick={() => this.handleRecommendationListClose()}>CLOSE</Button>
            </div>
          </div>
        </RecommendDialog>      
     
      </div>
    );
  }

  renderRecommendationAction = () => {
    console.log("ACTION ID", this.state.actionId);
    return (
      <div>
        
      <RecommendDialog
        open={this.state.actionOpen}
        title={this.state.selectedAction}
        onClose={this.handleRecommendationActionClose}
      >
        <div style={{ height: '500px' }}>
          {this.state.actionId === 2 && this.renderDeferRecommendation()}
          {this.state.actionId === 3 && this.renderLodgeRecommendation()}
        </div>
      </RecommendDialog>      
   
    </div>
    );
  }

  renderDeferRecommendation = () => {
    const { classes } = this.props;	
    return (
      <div>
        {/* <p>
          Please provide the reason of defering below that will be send along with the clinical information.
        </p> */}
        <TextField className={classes.formDetail} variant="outlined" multiline rows={10} placeholder="Defer Reason" inputRef={input => (this.deferReason = input)}/>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker inputVariant="outlined" 
            variant="inline" 
            margin="normal" 
            label="Defer Until Date" 
            value={this.state.deferUntilDate} 
            format="dd/MM/yyyy" 
            minDate={new Date()}
            onChange={(e) => this.handleDeferDateChange(e)} />
        </MuiPickersUtilsProvider>
        <div className={classes.formDetail} >
          <Button variant="outlined" onClick={() => this.handleDeferRecommendation()}>SAVE</Button>
          <Button variant="outlined" className={classes.buttonMargin} onClick={() => this.handleRecommendationActionClose()}>CANCEL</Button>
        </div>
        <div className={classes.formDetail}>
          {this.state.err && 
            <div style={{color:"red"}}>{this.state.errMsg}</div>
          }
        </div>
      </div>
    );
  }

  renderLodgeRecommendation = () => {
    const { classes } = this.props;	
    return (
      <div>
        <p>
          Lodging a recommendation query will automatically send relevant anonymised clinical data for this patient
          to the FHT support team so that this recommendation can be investigated and a response to your query provided.
          You will be able to see a record of all lodged queries in your Recommendation Query list.
        </p>
        <p>
          You may provide an optional comment below that will be send along with the clinical information.
        </p>
        <TextField className={classes.formDetail} variant="outlined" multiline rows={10} defaultValue={this.state.query} inputRef={input => (this.lodgedRecommendation = input)}/>
        <div className={classes.formDetail} >
          <Button variant="outlined" onClick={() => this.handleLodgeRecommendation()}>SEND</Button>
          <Button variant="outlined" className={classes.buttonMargin} onClick={() => this.handleRecommendationActionClose()}>CANCEL</Button>
        </div>
      </div>
    );
  }

  renderProof = () => {
    //determine what actions will be displayed based on either recommendatin or triggers
    this.actionArray = [];
    this.selectedTriggers = [];
    let _recommendation = this.state.risks.filter(x => x.testId == this.state.name)[0];
    this.selectedTriggers = this.state.triggers.filter(x => x.fhtPatientAnalysisTriggersFhtEnabledAnalysisId == this.state.name);

    if(_recommendation.action != null){
      this.actionArray.push(_recommendation.action);
    }else{
      if(this.selectedTriggers.length > 0){
        this.selectedTriggers.map(a => {
          if(a.action != null){
            this.actionArray.push(a.action);
          }
        });
      }
    }

    return (
      <div>
        {this.state.name ? (
          <Analysis
            type={this.state.name}
            triggers={this.selectedTriggers}
            triggerDesc = {this.triggerDescription}
            enableFeature = {true}
            actions = {this.actionArray}
          ></Analysis>
        ) : (
          <div>N/A</div>
        )}
      </div>
    );
  };

  renderGender = genderCode => {
    let gender;
    switch (genderCode) {
      case 'f':
      case 'F':
        gender = <span className="Female">Female</span>;
        break;
      case 'm':
      case 'M':
        gender = <span className="Male">Male</span>;
        break;
      default:
        gender = <span className="UnknowGender">Gender N/A</span>;
        break;
    }
    return gender;
  };

  renderCalendar = (aDate, expired) => {
    let d = new Date(aDate);
    let date = d.getDate();
    let month = Configs.month[d.getMonth()];
    let year = d.getFullYear();
    return (
      <Paper
        style={{
          width: '100%',
          //backgroundColor: expired ? '#e0e0e0' : '#3f51b5',
          backgroundColor: expired ? '#e0e0e0' : '#279593',
          color: '#fff',
          marginLeft: '5px',
          marginRight: '5px'
        }}
      >
        <div style={{ fontSize: '1em' }}>{year}</div>
        <div style={{ fontSize: '0.7em' }}>
          {date} {month}
        </div>
      </Paper>
    );
  };

  renderGraph = (nivoData, max, isSinglePoint) => {
    let unit = Configs.units[this.state.selectedItem.split(',')[0]];
    return (
      <ResponsiveLine
        data={nivoData}
        margin={{ top: 50, right: 110, bottom: 50, left: 110 }}
        xScale={{ type: 'time', format: '%Y-%m-%d', precision: 'day' }}
        xFormat="time:%d-%m-%Y"
        // yScale={{ type: 'linear', stacked: false, min: 0, max: max }}
        yScale={{ type: 'linear', stacked: false, min: 0, max: 'auto' }}
        lineWidth={3}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          format: '%Y',
          tickValues: isSinglePoint ? 1 : 'every year',
          legendOffset: -12
        }}
        axisLeft={{
          legend: unit,
          legendOffset: -50,
          tickValues: 5,
          legendPosition: 'middle'
        }}
        colors={{ scheme: 'nivo' }}
        pointSize={10}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
        tooltip={({ point }) => {
          return (
            <div
              style={{
                border: '1px solid #e0e0e0',
                backgroundColor: point.color,
                padding: '0 10px'
              }}
            >
              <p>
                {point.data.xFormatted}: <strong>{point.data.y}</strong>
              </p>
            </div>
          );
        }}
      ></ResponsiveLine>
    );
  };

  renderPatientSummary = () => {
    const { patient } = this.state;
    return (
      <div
        style={{ height: '20px', padding: '15px', marginBottom: '15px', backgroundColor: 'white' }}
      >
        {this.state.patient && (
          <div
            className="PatientDemography"
            style={{ marginLeft: '10px', display: 'inline-block' }}
          >
            <b>
              {patient.firstname} {patient.surname}
            </b>
            , {this.renderGender(patient.gender)}, {this.calculateAge(patient.dob)} years old
            {patient.smoker === true ? ', Smoker' : ''}
            {patient.atsi === 1 ? ', First Nations' : ''}
            {patient.bmi !== null ? ', BMI ' + patient.bmi : ''}
          </div>
        )}
      </div>
    );
  };

  renderPatientSummaryById = () => {
    const { patient } = this.state;
    return (
      <div
        style={{ height: '20px', padding: '15px', marginBottom: '15px', backgroundColor: 'white' }}
      >
        {this.state.patient && (
          <div
            className="PatientDemography"
            style={{ marginLeft: '10px', display: 'inline-block' }}
          >
            <b>
              {patient.patientExtractFirstname} {patient.patientExtractSurname}
            </b>
            , {this.renderGender(patient.patientExtractGender)}, {patient.patientExtractAge} years old
            {patient.patientExtractSmokeStatus === 1 ? ', Smoker' : ''}
            {patient.patientExtractAtsi === 1 ? ', First Nations' : ''}
            {patient.patientExtractCurrentStatus === "Inactive" ? ', Inactive' : ''}
            {patient.patientExtractCurrentStatus === "Active" ? ', Active' : '' }
            {patient.patientExtractCurrentStatus === "Deceased" ? ', Deceased' : '' }
            {this.batchSiteConfigurationContext['PatientDetailPregnancyStatus'] == "true" 
              && patient.patientExtractIsPregnant != null ? `, ${patient.patientExtractIsPregnant}` : ''}
            {this.state.triageData.triageExtractBmi !== null ? ', BMI ' + this.state.triageData.triageExtractBmi : ''}
          </div>
        )}
      </div>
    );
  };

  renderProofDialog = () => {
    let _profGuidelines = Configs.ProfessionalGuideLinesById[this.state.name];
    let _patientGuidelines = Configs.PatientGuideLinesById[this.state.name];
    let profGuidelines = _profGuidelines || [];
    let patientGuidelines = _patientGuidelines || [];
    return (
      <ProofDialog
        open={this.state.open}
        title={this.state.desc}
        onClose={this.handleProofClose}
        // guidelineOptions= {{prof: profGuidelines, patient: patientGuidelines}}
      >
        {this.renderProof()}
      </ProofDialog>
    );
  };
  renderGridDetails = () => {
    return (
      <div style={{ width: '100%', height: '800px', padding: '0 20px' }}>
        <Grid container spacing={1}>
          <Grid container item xs={12} spacing={2} style={{ height: '300px' }}>
            <Grid item xs={3} style={{ height: '100%' }}>
              <Card style={{ height: '100%' }}>
                <CardHeader
                  style={{ backgroundColor: '#e0e0e0', height: '10%' }}
                  titleTypographyProps={{ variant: 'h6' }}
                  title={this.state.risks && `Recommendation (${this.state.risks.length})`}
                  ////Believed that error happened as AWSURL point to local machine instead of AWS, this button can be used when webapi on site is updated to 
                  // action={
                  //   <IconButton aria-label="Recommend Query List" style={{padding: 0}} onClick={(e) => this.handleViewQueryList()}><PlaylistAddCheckIcon/></IconButton>
                  // }
                />
                <CardContent style={{ padding: 0, height: '90%', overflowY: 'auto' }}>
                  <Table
                    style={{ width: '100%', whiteSpace: 'normal', wordWrap: 'break-word' }}
                    size="small"
                  >
                     {this.state.risks && (
                      <TableBody>
                        {this.state.risks.map((row, index) => (
                          <TableRow key={index}>
                          <TableCell component="th" style={{ paddingLeft: '5px', width: '100%' }}>
                            <Grid container disabled={true}>
                               
                                <Grid item xs={1} style={{ textAlign: 'center', paddingTop: '5px' }} id={index}> 
                                  <div onClick={(e) => this.handleMainMenuClick(e, row)} ><MoreVertIcon/>{row.active}</div>
                                  <Menu
                                    open={!!this.state.menuPosition}
                                    onClose={() => this.setState({menuPosition: null})}
                                    anchorReference="anchorPosition"
                                    anchorPosition={this.state.menuPosition}>
                                      {
                                        this.state.name && <MenuItem id="Mark this Recommendation as Actioned" disabled={!this.state.isActive[this.state.name]} onClick={(e) =>this.handleChangeRecommendationStatus(1)}><CheckIcon style={{ marginRight: '5px'}}/>Mark this Recommendation as Actioned</MenuItem> 
                                      }
                                      {
                                        this.state.name &&<MenuItem id="Defer this Recommendation" disabled={!this.state.isActive[this.state.name]} onClick={(e) =>this.handleRecommendationActionOpen(e, 2)}><UpdateIcon style={{ marginRight: '5px'}}/>Defer this Recommendation </MenuItem>
                                      }
                                      {
                                        <MenuItem id="Lodge a Recommendation Query" onClick={(e) => this.handleRecommendationActionOpen(e, 3)}><PriorityHighIcon style={{ marginRight: '5px'}}/> Lodge a Recommendation Query</MenuItem>
                                      }
                                      {
                                        this.state.name && 
                                        (Configs.ProfessionalGuideLinesById[this.state.name] !== undefined && Configs.ProfessionalGuideLinesById[this.state.name].length > 0 ? 
                                        <NestedMenuItem onClick={(e) => this.setState({menuPosition: null})} parentMenuOpen={!!this.state.menuPosition} label={<div><ImportContactsIcon style={{ marginRight: '5px'}}/> Open Practitioner Guidelines</div>} >    
                                          {Configs.ProfessionalGuideLinesById[this.state.name].map((value,ind) => (
                                            <MenuItem key={ind}
                                              onClick={() => {
                                                this.setState({menuPosition: null});
                                                window.open(value.link, "_blank");
                                              }}
                                            >{value.title}</MenuItem>
                                          ))}
                                        </NestedMenuItem> : 
                                        <MenuItem style={{color: "#ccc"}}><MenuBookIcon style={{ marginRight: '5px'}} />Open Practitioner Guidelines</MenuItem>
                                          )
                                      }
                                      {
                                        this.state.name && 
                                          (Configs.PatientGuideLinesById[this.state.name] !== undefined && Configs.PatientGuideLinesById[this.state.name].length > 0 ? 
                                            <NestedMenuItem onClick={(e) => this.setState({menuPosition: null})} parentMenuOpen={!!this.state.menuPosition} label={<div><MenuBookIcon style={{ marginRight: '5px'}}/> Open consumer resources</div>} >    
                                            {Configs.PatientGuideLinesById[this.state.name].length > 0 && Configs.PatientGuideLinesById[this.state.name].map((value,ind) => (
                                              <MenuItem key={ind}
                                                onClick={() => {
                                                  this.setState({menuPosition: null});
                                                  window.open(value.link, "_blank");
                                                }}
                                              >{value.title}</MenuItem>
                                            ))}
                                          </NestedMenuItem> : 
                                          <MenuItem style={{color: "#ccc"}}><MenuBookIcon style={{ marginRight: '5px'}} />Open consumer resources</MenuItem>
                                          )
                                      }
                                      {
                                        this.selectedRecommendation && 
                                        <MenuItem id="Copy to clipboard" >
                                          <CopyToClipboard text={`Recommendation : ${this.selectedRecommendation.description}`} onCopy={() => this.setState({menuPosition: null})}>
                                            <span><FileCopyIcon style={{ marginRight: '5px'}}/> Copy to Clipboard</span>
                                          </CopyToClipboard>
                                        </MenuItem>
                                      }
                                    {
                                      this.state.name && <MenuItem disabled={this.state.isActive[this.state.name]} onClick={() => this.handleChangeRecommendationStatus(0)}><RotateLeftIcon style={{ marginRight: '5px'}}/> Reset Recommendation to Active</MenuItem>
                                    }
                                  </Menu>
                                </Grid>
                              <Grid item xs={2} style={{ textAlign: 'center' }}>
                                {this.renderCalendar(this.state.triageData.triageExtractDateCreated)}
                              </Grid>
                              <Grid item xs={9} style={{}}>
                                <div style={{ marginLeft: '10px', fontSize: '12px' }} >
                                  <Link disabled={true}
                                    style={{ cursor: 'pointer' }}
                                    name={row.description}
                                    onClick={this.handleProofOpen.bind(this, row.testId, row.description)}
                                  >
                                    {
                                     row.active ? 
                                      <div>{row.description}</div>:
                                      <div style={{color: "#ccc"}}>{row.description} 
                                        {row.status.recommendationStatus === 1 ?
                                          <span> [{row.status.updateDate.date}/{row.status.updateDate.month}/{row.status.updateDate.year}: Actioned]</span> : 
                                          row.status.formatDate ?
                                          <span>
                                            [{row.status.updateDate.date}/{row.status.updateDate.month}/{row.status.updateDate.year}: Deferred until {row.status.formatDate.date}/{row.status.formatDate.month}/{row.status.formatDate.year}: {row.status.recommendationReason}]
                                          </span> :
                                          <span>
                                            [{row.status.updateDate.date}/{row.status.updateDate.month}/{row.status.updateDate.year}: Deferred: {row.status.recommendationReason}]
                                          </span>
                                        })</div>
                                     
                                  }
                                     
                                  </Link>
                                </div>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                     )}
                  </Table>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3} style={{ height: '100%' }}>
              <Card style={{ height: '100%' }}>
                <CardHeader
                  style={{ backgroundColor: '#e0e0e0', height: '10%' }}
                  titleTypographyProps={{ variant: 'h6' }}
                  title={
                    `Medication (${this.state.medications.length})` 
                  }
                />
                <CardContent style={{ padding: '0 0 10px 0', height: '90%', overflowY: 'auto' }}>
                  <Table
                    style={{ width: '100%', whiteSpace: 'normal', wordWrap: 'break-word' }}
                    size="small"
                  > 
                  { FhtMain.GlobalContext.userRole != Configs.roles.LimitedTorchUser ?
                    <TableBody>
                      {this.state.medications.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" style={{ paddingLeft: '5px', width: '100%' }}>
                            <Grid container>
                              <Grid item xs={2} style={{ textAlign: 'center' }}>
                                {this.renderCalendar(row.medicationExtractLastPrescribed || row.medicationExtractFirstPrescribed, row.medicationExtractUnprescribed)}
                              </Grid>
                              <Grid item xs={9} style={{}}>
                                <div style={{ marginLeft: '10px', fontSize: '12px' }}>
                                  {row.medicationExtractName} {row.medicationExtractUnprescribed && '- [non current]'}
                                </div>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    :
                    <div>Not available for limited users</div>
                }
                  </Table>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3} style={{ height: '100%' }}>
              <Card style={{ height: '100%' }}>
                <CardHeader
                  style={{ backgroundColor: '#e0e0e0', height: '10%' }}
                  titleTypographyProps={{ variant: 'h6' }}
                  title={`Condition (${this.state.displayConditions.length})`
                    // `Condition (${this.state.conditions.length})` : `Condition (${this.state.patient.conditions.length})`
                  }
                  action={
                  <IconButton aria-label="Show/Hide Inactive Condition" style={{padding: 0}} onClick={(e) => this.toggleDisplayCondition()}> {this.state.hideInactiveCondition ? <VisibilityOffIcon/> : <VisibilityIcon/>}</IconButton>
                  }
                />
                <CardContent style={{ padding: 0, height: '90%', overflowY: 'auto' }}>
                  <Table
                    style={{ width: '100%', whiteSpace: 'normal', wordWrap: 'break-word' }}
                    size="small"
                  >
                    { (FhtMain.GlobalContext.userRole != Configs.roles.LimitedTorchUser)?
                    <TableBody>
                        {this.state.displayConditions.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" style={{ paddingLeft: '5px', width: '100%' }}>
                              <Grid container>
                                <Grid item xs={2} style={{ textAlign: 'center' }}>
                                  {this.renderCalendar(row.conditionExtractDateFirstDiagnosed, row.conditionExtractResolved)}
                                </Grid>
                                <Grid item xs={9} style={{}}>
                                  <div style={{ marginLeft: '10px', fontSize: '12px' }}>
                                    {row.conditionExtractName} {row.conditionExtractResolved === 1 ? '- [resolved]' : ''}                                    
                                  </div>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      :
                      <div>Not available for limited users</div>
  }
                  </Table>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3} style={{ height: '100%' }}>
              <Card style={{ height: '100%' }}>
                <CardHeader
                  style={{ backgroundColor: '#e0e0e0', height: '10%' }}
                  titleTypographyProps={{ variant: 'h6' }}
                  title={
                    `MBS Item (${this.state.mbsItems.length})`
                  }
                />
                <CardContent style={{ padding: '0 0 10px 0', height: '90%', overflowY: 'auto' }}>
                  <Table
                    style={{ width: '100%', whiteSpace: 'normal', wordWrap: 'break-word' }}
                    size="small"
                  >
                     { (FhtMain.GlobalContext.userRole != Configs.roles.LimitedTorchUser)?
                    <TableBody>
                        {this.state.mbsItems.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" style={{ paddingLeft: '5px', width: '100%' }}>
                              <Grid container>
                                <Grid item xs={2} style={{ textAlign: 'center' }}>
                                  {this.renderCalendar(row.patientMbsExtractStartingDate)}
                                </Grid>
                                <Grid item xs={9} style={{}}>
                                  <div style={{ marginLeft: '10px', fontSize: '12px' }}>
                                  <b>
                                    {row.fhtMbsItemAlternative === undefined ? 
                                      Configs.mbsAlternative[row.patientMbsExtractItemCode.trim()] || row.patientMbsExtractItemCode.trim() :
                                      `${row.patientMbsExtractItemCode.trim()}${row.fhtMbsItemAlternative === null ? "" : `(${row.fhtMbsItemAlternative})`}`
                                    } : 
                                    </b> {row.fhtMbsItemDesc === undefined ? Configs.mbsDefinitions[row.patientMbsExtractItemCode.trim()] : row.fhtMbsItemDesc}
                                  </div>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      :
                      <div>Not available for limited users</div>
                      }
                  </Table>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          { (FhtMain.GlobalContext.userRole != Configs.roles.LimitedTorchUser)?
          this.renderPathologyResult()
        : (FhtMain.GlobalContext.userRole == Configs.roles.LimitedTorchUser 
          && this.props.context.componentParent.state.currentDiseaseID == Configs.diseaseIDs.IC3
          )?
            this.renderPathologyResult()
        :
          <div>Pathology results are not available for limited users</div>
        }
        </Grid>
        <div id="testcopy"></div>
      </div>
    );
  };

  renderPathologyResult = () => {
    const { classes } = this.props;
    let selectedItem = this.state.selectedItem.split(',').map(item => {
      return item.trim();
    });
    let data = {};
    let dataSets = {};

    Object.keys(this.state.pathologyMapping).map(s => {
      dataSets[s] = {
        na: true,
        title: this.state.pathologyMapping[s],
        data: []
      };
    });
    let measures = this.state.measures;
    measures.map(row => {
      if (dataSets[row.name]) {
        dataSets[row.name].na = false;
        dataSets[row.name].data.push({
          id: dataSets[row.name].data.length,
          calendar: this.formatDate(row.reportTime),
          value: row.value
        });
        if (dataSets[row.name].latestDate) {
          if (dataSets[row.name].latestDate < new Date(row.reportTime)) {
            dataSets[row.name].latestDate = new Date(row.reportTime);
            dataSets[row.name].latestValue = row.value;
          }
        } else {
          dataSets[row.name].latestDate = new Date(row.reportTime);
          dataSets[row.name].latestValue = row.value;
        }
      }
    });
    this.state.suggestions.map(s => {
      if (s.disabled) {
        for (let i = 0; i < s.items.value.length; i++) {
          if (dataSets[s.items.value[i]] && !dataSets[s.items.value[i]].na) {
            s.disabled = false;
            break;
          }
        }
      }
    });

    Object.keys(dataSets).map(key => {
      dataSets[key].calendar = this.formatDate(dataSets[key].latestDate);
    });

    selectedItem.map(s => {
      data[s] = dataSets[s].data;
    });

    let graphInfo = this.formatGraphData(data);
    let nivoData = graphInfo.dataSets;
    let isSinglePoint = graphInfo.singlePoint;
    let max = this.getMax(data);
    return (
      <Grid container item xs={12} spacing={2} style={{ height: '350px' }}>
        <Grid item xs={12} style={{ height: '100%' }}>
          <Card style={{ height: '100%' }}>
            <CardHeader
              style={{ backgroundColor: '#e0e0e0', height: '10%' }}
              titleTypographyProps={{ variant: 'h6' }}
              title="Pathology and clinical measures result"
            />
            <CardContent style={{ padding: '0 0 10px 0', height: '90%' }}>
              <Grid container spacing={0} style={{ height: '100%' }}>
                <Grid item xs={4} style={{ height: '100%', overflowY: 'auto' }}>
                  <List component="nav" aria-label="pathologies" dense={true}>
                    {this.state.suggestions.map(s => (
                      <div key={s.label}>
                        <MenuItem
                          button
                          onClick={this.handleItemClick.bind(this, s.items.value.toString())}
                          selected={this.state.selectedItem === s.label}
                          disabled={s.disabled}
                        >
                          <Grid container>
                            {s.items.value.map((i, index) => (
                              <Grid item xs={12} key={i}>
                                {index > 0 && <Divider></Divider>}
                                <Box display="flex" style={{ padding: '2px 0', fontSize: 'small' }}>
                                  <div style={{ width: '50%' }}>{s.items.title[index]}</div>
                                  <div>
                                    {dataSets[i].na ? (
                                      <Paper
                                        style={{
                                          padding: '2px 5px',
                                          textAlign: 'center',
                                          fontSize: 'small',
                                          backgroundColor: '#e0e0e0',
                                          color: '#fff'
                                        }}
                                      >
                                        <div>No result available</div>
                                      </Paper>
                                    ) : (
                                      <Paper
                                        style={{
                                          padding: '2px 5px',
                                          textAlign: 'center',
                                          fontSize: 'small',
                                          backgroundColor: '#3f51b5',
                                          color: '#fff'
                                        }}
                                      >
                                        <div>
                                          {dataSets[i].calendar.date} {dataSets[i].calendar.month}{' '}
                                          {dataSets[i].calendar.year} : {dataSets[i].latestValue}
                                        </div>
                                      </Paper>
                                    )}
                                  </div>
                                </Box>
                              </Grid>
                            ))}
                            
                            {' '}
                          </Grid>
                        </MenuItem>
                        <Divider />
                      </div>
                    ))}
                  </List>
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ height: '100%', overflowY: 'auto', paddingBottom: '10px' }}
                >
                  <Grid container>
                    <div style={{ width: '100%' }}>
                      {Object.keys(data).map(key => {
                        return (
                          <Grid item xs={12} key={key}>
                            {data[key].length > 0 && (
                              <div style={{ paddingLeft: '20px' }}>
                                <h3>{dataSets[key].title}</h3>
                              </div>
                            )}
                            <Table className={classes.table} size="small">
                              <TableBody>
                                {data[key].map(row => {
                                  return (
                                    <TableRow key={row.id}>
                                      <TableCell style={{ paddingLeft: '5px', width: '70%' }}>
                                        <Paper
                                          className={classes.paper}
                                          style={{
                                            padding: '5px 10px',
                                            textAlign: 'center',
                                            backgroundColor: '#e0e0e0'
                                          }}
                                        >
                                          <div className={classes.content}>
                                            {row.calendar.date} {row.calendar.month}{' '}
                                            {row.calendar.year}
                                          </div>
                                        </Paper>
                                      </TableCell>
                                      <TableCell>{row.value}</TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </Grid>
                        );
                      })}
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={5} style={{ height: '100%', paddingBottom: '5px' }}>
                  <div style={{ height: '95%' }}>
                    {nivoData.length > 0 && this.renderGraph(nivoData, max, isSinglePoint)}
                  </div>
                  <div
                    style={{ float: 'right', paddingRight: '20px', cursor: 'pointer' }}
                    onClick={this.handleExpandOpen.bind(this, nivoData, max, isSinglePoint)}
                  >
                    {nivoData.length > 0 && <AspectRatioIcon></AspectRatioIcon>}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };

  renderContent = () => {
    return (
      <div
        style={{
          mindWidth: '800px',
          width: '100%',
          height: '100%',
          minHeight: '600px',
          backgroundColor: 'white'
        }}
      >
        {this.renderPatientSummaryById()}
        {/* {this.renderPatientSummary()} */}
        {this.renderGridDetails()}
        {this.state.patient && this.state.open && this.renderProofDialog()}
        {this.state.expand && this.renderExpandGraph()}
        {this.state.actionOpen && this.renderRecommendationAction()}
        {this.state.queryListOpen && this.renderRecommendationQueryList()}
      </div>
    );
  };

  renderLoadingScreen = () => {
    return (
      <div className="loading">
        <h2>Loading...</h2>
        <svg
          version="1.2"
          height="100%"
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
          viewport="0 0 60 60"
          xmlns-xlink="http://www.w3.org/1999/xlink"
        >
          <path
            id="pulsar"
            stroke="rgba(0,155,155,1)"
            fill="none"
            strokeWidth="1"
            strokeLinejoin="round"
            d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210"
          />
        </svg>
      </div>
    );
  };

  renderErrorScreen = () => {
    return <div>Patient not found</div>;
  };

  render() {
    return (
      <div>
        {this.state.patient
          ? this.renderContent()
          : this.state.errorState
          ? this.renderErrorScreen()
          : this.renderLoadingScreen()}

      <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={this.state.openSnackbar}
            autoHideDuration={4000}
            onClose={() => this.setState({ openSnackbar: false })}
          >
            <SnackbarContentWrapper
              onClose={() => this.setState({ openSnackbar: false })}
              variant={this.snackbarVariant}
              message={this.snackbarMessage}
            />
          </Snackbar>    
      </div>
      
    );
  }
}
export default withStyles(useStyles)(PatientDetailScreen);
