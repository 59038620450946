import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
//import 'unfetch/polyfill';
//import 'abortcontroller-polyfill';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import FhtMain from './FhtMain';
//DO NOT USE - keep in mind there are other files importing FhtMain because of globalContext. Rename the fhtMain_new to fhtMain and fhtMain_old to fhtMain accordingly to swap between.
//import FhtMain from './FhtMain_new'
// import FhtMain_VersionTwo from "./FhtMain_VersionTwo"
//import * as serviceWorker from './serviceWorker';

import {LicenseManager} from "ag-grid-enterprise";
LicenseManager.setLicenseKey("University_of_Melbourne_future_health_today_1Devs_1Deployment_26_March_2020__MTU4NTE4MDgwMDAwMA==ed3f15ae8eaa1e9744cf8e0ee041a099");

ReactDOM.render(
  // Change the code here to VersionTwo to see the new menu and news screen.
  // <FhtMain_VersionTwo />,
  <FhtMain />,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
