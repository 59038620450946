import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import Select from '@material-ui/core/Select';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
}from '@material-ui/pickers/';

import Grid from '@material-ui/core/Grid';
import {CSVLink} from "react-csv";

import Configs from 'Configuration';
import FhtFetchUrl from 'Utils/FhtFetchUrl';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const DialogTitle = withStyles(theme => ({
	root: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	formDetail: {
		marginBottom: 20,
		width: '100%',
		textAlign: 'left'
	},
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);




class ApiAccessLogDialog extends React.Component {
	constructor(props) {
		super(props);
		this.csvLink = React.createRef();
		this.globalMessage = props.GlobalFunctionDisplaySnackbarMessage;
		this.columns = [
			{
				id: 'ApiAccessLogUserId',
				displayName: 'User Id'
			},
			{
				id: 'ApiAccessLogApiId',
				displayName: 'Api Id'
			},
			{
				id: 'ApiAccessLogEmrId',
				displayName: 'Emr Id'
			},
			{
				id: 'ApiAccessLogCreatedTime',
				displayName: 'Create Time'
			}
		];
	  this.maxDate = new Date();
		this.minDate = new Date("01/01/1900");
		this.useDate = false;
		this.periods = [
			{
				id: 0,
				title: "Last 6 months"
			},{
				id: 1,
				title: "Last year"
			},{
				id: 2,
				title: "All"
			},{
				id: 3,
				title: "Custom"
			}
		];
		this.state = {
			open: true,
			selectedPeriod: {},
			startDate: null,
			endDate: null,
			isReady: false,
			csvData: [],
		};
	}

	componentDidUpdate = () => {
		if(this.state.isReady){
			this.setState({isReady: false}, () => {
				this.csvLink.current.link.click();
			})
		}
	}

	handlePeriodChange = (e) => {
		this.setState({
			selectedPeriod: e.target.value,
			startDate: null,
			endDate: null,
			data: [],
		}, () => {
			let _end = new Date();
			let _start = new Date();
			switch(e.target.value.id){
				case 0 : 
					_start.setMonth(_start.getMonth() - 6);
					this.useDate = true;
					break;
				case 1 : 
					_start.setFullYear(_start.getFullYear() - 1);
					this.useDate = true;
				break;
				case 2 : 
					this.useDate = false;
				break;
				default: 
					this.useDate = true;
					_end = null;
					_start = null;
				break;
			}
			this.setState({
				startDate: _start,
				endDate: _end,
			})
		});
	} 

	handleStartDateChange = (date) => {
		this.minDate = date;
		this.setState({
			startDate: date,
		});
	}

	handleEndDateChange = (date) => {
		this.maxDate = date;
		this.setState({
			endDate: date,
		});
	}

	download = () => {
		console.log("download");
		if(this.state.selectedPeriod.id === 3 && (this.state.startDate === null || this.state.endDate === null)){
			this.globalMessage("Please select start and end date", Configs.snackbarVariants.error);
			return;
		}
		let _start = this.state.startDate;
		let _startDate = new Date(_start.getFullYear(), _start.getMonth(), _start.getDate());
		let _end = this.state.endDate;
		_end.setDate(_end.getDate() + 1);
		let _endDate = new Date(_end.getFullYear(), _end.getMonth(), _end.getDate());
		console.log(_startDate, _endDate);
		let obj = {
			startDate: _startDate,
			endDate: _endDate,
			useDate: this.useDate,
		};
		const api = FhtFetchUrl(Configs.downloadApiLog);
		api.post(obj).then(response => {
			console.log(response);
			if(response.length === 0){
				this.globalMessage("No API Access Log during selected period", Configs.snackbarVariants.error);
			} else {
				this.setState({
					csvData: response,
					isReady: true,
				});

			}
		});
	}

	render() {
		return (
			<div>
				<Dialog fullScreen={false} fullWidth={true}
						onClose={this.props.CallbackForAllDialogsClose}
						aria-labelledby="customized-dialog-title"
						open={true}>
					<DialogTitle id="customized-dialog-title" onClose={this.props.CallbackForAllDialogsClose}>
						Access Log
					</DialogTitle>
					<DialogContent>
						<FormControl variant="outlined" fullWidth>
							<InputLabel>Period</InputLabel>
							<Select label="Period"
								value={this.state.selectedPeriod} 
								onChange={this.handlePeriodChange}>
								{this.periods.map(s => (
									<MenuItem key={s.id} value={s}>{s.title}</MenuItem>
								))}
							</Select>
						</FormControl >
						{this.state.selectedPeriod.id === 3 && 
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<Grid container justify="space-around">
									<KeyboardDatePicker id="startDate" inputVariant="outlined" variant="inline" margin="normal" label="Start Date" value={this.state.startDate} maxDate={this.maxDate} format="dd/MM/yyyy" onChange={this.handleStartDateChange} />
									<KeyboardDatePicker id="endDate" inputVariant="outlined" variant="inline" margin="normal" label="End Date" value={this.state.endDate} minDate={this.minDate} format="dd/MM/yyyy" onChange={this.handleEndDateChange} />
								</Grid>
						</MuiPickersUtilsProvider>
						}
					</DialogContent>
					<DialogActions>

						{/* <CSVLink data={this.state.csvData} className="hidden" filename="accesslog.csv" ref={(r) => this.csvLink = r} target="_blank"></CSVLink> */}
						<CSVLink data={this.state.csvData} className="hidden" filename="accesslog.csv" ref={this.csvLink} target="_blank"></CSVLink>

						<Button color="primary" onClick={() => this.download()}>
								Download
						</Button>
						<Button onClick={this.props.CallbackForAllDialogsClose} color="primary">
								Close
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default withMobileDialog() (ApiAccessLogDialog);