//import Configs from "../Configuration";
import SiteSpecificConfig from '../SiteSpecificConfig';

export function AlgorithmSiteConfigurationContext() {
  let returnResult = {};
  /*
  var windowsUrl = window.location.href;
  for (var urlName in SiteSpecificConfig.SiteConfigurationMapping) {
    if (windowsUrl.includes(urlName)) {
        return (returnResult = SiteSpecificConfig.AlgorithmSiteMapping[SiteSpecificConfig.SiteConfigurationMapping[urlName]["AlgorithmType"]]);
    }
  }
*/
  var windowsUrl = window.location.hostname;
  for (var urlName in SiteSpecificConfig.SiteConfigurationMapping)
  {
    if (windowsUrl.includes("localhost") && windowsUrl.includes(urlName))
    {
      return (returnResult = SiteSpecificConfig.AlgorithmSiteMapping[SiteSpecificConfig.SiteConfigurationMapping[urlName]["AlgorithmType"]]);
    }
    else if (windowsUrl==urlName)
    {
      return (returnResult = SiteSpecificConfig.AlgorithmSiteMapping[SiteSpecificConfig.SiteConfigurationMapping[urlName]["AlgorithmType"]]);
    }  
  }


  return returnResult;
}

export default AlgorithmSiteConfigurationContext;
