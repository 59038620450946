import React, { Component } from "react";
import {
  createTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles/';
import QIActivityPdf from './QIActivityPdf';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const muiTheme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1em"
      }
    }
  }
});

const useStyles = theme => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundImage: 'linear-gradient(to right, rgb(39, 149, 148), rgb(72, 175, 104))'
  },
  buttonMargin: {
    marginLeft: '1REM'
  },
});

class QIActivityToolBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  parseSelectedCohortsToNames() {
    var cohortNamesString = "";
    var selectedCohortsLocalVar = JSON.parse(JSON.stringify(this.props.selectedCohortsForQIActivity))
    for (var i = 0; i < selectedCohortsLocalVar.length; i++) {
      cohortNamesString = cohortNamesString + selectedCohortsLocalVar[i].cohortName + "\n";
    }
    return cohortNamesString;
  }

  renderToolBar = () => {
    const { classes } = this.props;
    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <div style={{
            textAlign: 'center',
            width: '100%',
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
          >
            <Button variant="contained" onClick={() => this.props.saveActivity()}>Save QI Activity</Button>
            <Button className={classes.buttonMargin} variant="contained" onClick={() => this.props.handleCancelActivity()}>Cancel</Button>
            <QIActivityPdf
              data={this.props.activity}
              cohortName={this.parseSelectedCohortsToNames()}
              recommendations={this.props.selectedRecommendations}
              activeDiseaseIDs={this.props.diseaseDropdownbox}
              participants={this.props.selectedParticipants}
              actions={this.props.actions}
              logo="/images/fhtlogo.png"
              reflections={this.props.reflections}
            />
          </div>
        </Toolbar>
      </AppBar>
    );
  }

  render() {
    return (
      <div>
        {this.renderToolBar()}
      </div>
    );
  }
}


export default withStyles(useStyles)(QIActivityToolBar);