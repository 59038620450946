import React from 'react';
import Configs from '../Configuration';
import SiteConfigurationContext from "Utils/SiteConfigurationContext.jsx";
import FhtMain from 'FhtMain';

import FhtFetchUrl from '../Utils/FhtFetchUrl.jsx';

//Wrapper class to handle login logic from the POC - if successful it returns the appropriate form requested from the POC.
//sample token only
//example syntax: http://localhost:3000/POCAutoLogin/?accessToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjE3IiwiZ2l2ZW5fbmFtZSI6ImZodFBvaW50T2ZDYXJlIiwibmJmIjoxNjIzMjEyNTg2LCJleHAiOjE2MjMyOTg5ODYsImlhdCI6MTYyMzIxMjU4NiwiaXNzIjoiQWx0b25hTm9ydGgifQ.0vR1f1X-K754VQS3l36cnQHViZ-T37UfXKTIhNp0gFM&patientId=5&form=CreateCohortScreen&emrUser=dboyle
class POCAutoLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            siteConfigurationContext:SiteConfigurationContext(),
        };
        this.onLoadParseUrl();
    }
    onLoadParseUrl = () => {
        if (this.props.location.search != '' && this.props.location.search != null) {
          let searchParams = this.props.location.search.split('?')[1].split('&');
          //console.log("this.props.location:",this.props.location);
          
          //window.location.path
          let accessToken = -1; let patientId = -1; let form = 'na'; let emrUser = 'na';
          searchParams.forEach(s => {
            let keySearch = s.split('=')[0];
            let searchValue = s.split('=')[1];      
            if (keySearch === 'accessToken'){
                accessToken = searchValue;
            }
            else if (keySearch === 'patientId'){
                patientId = searchValue;
            }
            else if (keySearch === 'form'){
                form = searchValue;
            }
            else if (keySearch === 'emrUser'){
              //console.log("decodeURIComponent(searchValue):",decodeURIComponent(searchValue))
                emrUser = decodeURIComponent(searchValue);
            }
        });
        console.log("Requesting access for user:", emrUser);
        const api = FhtFetchUrl("/TokenLoginSwapUser", accessToken);
        let body = {
          EmrUser: emrUser
        };
        api
          .post(body)
          .then(response => {
            console.log('POCAutologin response from server:', response);
            if (response.claims === undefined)
            {
                throw response.message;
            }
            // eslint-disable-next-line
            if (response.claims.length == 0)
            {
                throw "User is not assigned any rights to access the system. Please contact your administrator.";
            }
            FhtMain.GlobalContext.userClaims = response.claims;
            FhtMain.GlobalContext.userRole = response.role;
            FhtMain.GlobalContext.userToken = response.token;
            FhtMain.GlobalContext.fullName = response.fullName;
            FhtMain.GlobalContext.userName = response.userName;
            localStorage.setItem(Configs.tokenInfo.userToken,response.token);
            var msg = response.fullName + " is logged in";
            this.props.CallbackLoginDialogSuccess(response.claims,form);
            this.props.GlobalFunctionDisplaySnackbarMessage( msg, Configs.snackbarVariants.success);
            console.log('changing to form:',form);
            this.props.history.push('/'+form);
          })
          .catch(error => {
            var msg = String(error);
            this.props.GlobalFunctionDisplaySnackbarMessage(msg, Configs.snackbarVariants.error);
            this.props.history.push('/');
          });
        }   
      };
    render() {

        return (            
            <div >

            </div>
        );
    }
}

export default (POCAutoLogin);
