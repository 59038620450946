import { ResponsiveLine } from '@nivo/line'
import { ResponsiveBar } from '@nivo/bar'
import React from 'react';
import Configs from 'Configuration';

const genericLineChartDimensions = { top: 10, right: 125, bottom: 75, left: 60 }

const genericLineChartLegend = [{
    anchor: 'bottom-right',
    direction: 'column',
    justify: false,
    translateX: 85,
    translateY: 70,
    itemsSpacing: 0,
    itemDirection: 'left-to-right',
    itemWidth: 80,
    itemHeight: 20,
    symbolSize: 8,
    symbolShape: 'circle',

    symbolBorderColor: 'rgba(0, 0, 0, .5)',
    effects: [{
        on: 'hover',
        style: {
            itemBackground: 'rgba(0, 0, 0, .03)',
            itemOpacity: 1
        }
    }]
}]

const genericLineChartThemes = {
    axis: {
        legend: {
            text: {
                fill: "black"
            }
        },
        ticks: {
            line: {
                stroke: "grey"
            },
            text: {
                fill: "black"
            }
        }
    },
    grid: {
        line: {
            stroke: "grey",
            strokeWidth: 1
        }
    },
    crosshair: {
        line: {
            stroke: "black",
            strokeWidth: 1
        },
    },
    legends: {
        fill: "black",

        text: {
            fontSize: 10,

        }
    }
}
function obtainActiveRecallDataFilters(dataToBeSplit, JSONDataToBeChanged) {

    var stringToArray = dataToBeSplit.split(",")

    var updatedArray = []
    var valueInArray = []
    var valueNotInArray = []
    for (var i = 0; i < JSONDataToBeChanged.length; i++) {
        for (var z = 0; z < stringToArray.length; z++) {
            if (String(JSONDataToBeChanged[i].id) == String(stringToArray[z])) {
                updatedArray.push(JSONDataToBeChanged[i])
                valueInArray.push(JSONDataToBeChanged[i].id)
            }
        }
    }

    if (stringToArray[0] == "") {
        // Do nothing
    } else {
        for (var i = 0; i < stringToArray.length; i++) {
            const index = valueInArray.indexOf(stringToArray[i]);
            if (index > -1) {
                // Do nothing
            } else {
                valueNotInArray.push(stringToArray[i])
                updatedArray.push({
                    id: stringToArray[i],
                    data: []
                })
            }
        }
    }

    var returnedArray = []
    if (updatedArray.length == 0) {
        returnedArray = []
    } else {
        returnedArray = updatedArray
    }
    return returnedArray
}

function obtainActiveRecommendationDataFiltersOnly(dataToBeSplit, JSONDataToBeChanged) {

    var stringToArray = dataToBeSplit.split(",")
    var updatedArray = []
    var valueInArray = []
    var valueNotInArray = []
    for (var i = 0; i < JSONDataToBeChanged.length; i++) {
        for (var z = 0; z < stringToArray.length; z++) {
            if (String(JSONDataToBeChanged[i].id) == String(stringToArray[z])) {
                updatedArray.push(JSONDataToBeChanged[i])
                valueInArray.push(JSONDataToBeChanged[i].id)
            }
        }
    }

    // This check is to make sure if the filters are empty, then we default back to 3 common recommendations.
    // This check also ensures if there is a recommendation chosen but there is no data, this will be displayed regardless.
    if (stringToArray[0] == "") {
        // Do nothing
    } else {
        for (var i = 0; i < stringToArray.length; i++) {
            const index = valueInArray.indexOf(stringToArray[i]);
            if (index > -1) {
                // Do nothing
            } else {
                valueNotInArray.push(stringToArray[i])
                updatedArray.push({
                    id: stringToArray[i],
                    data: []
                })
            }
        }
    }

    var returnedArray = []
    if (updatedArray.length == 0) {
        returnedArray = JSONDataToBeChanged
    } else {
        returnedArray = updatedArray
    }

    return returnedArray
}

// This function looks through the recommendations and only obtains the relevant recommendations
// that are already developed. 
// i.e If there are algorithms being developed, or "dummy algorithms" they will not be a part of the
// data.
// This function also updates the algorithm name into the short name (for front-end presentation).
function createArrayDataForAllRecommendations(JSONDataToBeParsed, shortDescriptions, algMappingToID) {
    var returnedArrayData = []
    var recommendationShortDescriptions = shortDescriptions
    var algMapToIDJSON = algMappingToID

    for (var key in JSONDataToBeParsed) {
        try {
            var label = ""
            var initialSplit = String(key).split('_')

            // These checks are to ensure we are only getting the relevant algorithms to show.
            if (initialSplit[0] == "Ckd" || initialSplit[0] == "PrC" || initialSplit[0] == "PltC") {
                for (var keyForShortDescriptions in recommendationShortDescriptions) {

                    if (recommendationShortDescriptions[keyForShortDescriptions].shortDescription == null) {
                        continue
                    } else {
                        // This conditional is to compare the alg name (key = PrC_Rec_RepeatPSAOrderFT) against it's ID 
                        // (PrC_Rec_RepeatPSAOrderFT = 19), via algMapToIDJSON, and then compare the ID against the DB 
                        // short hand description (19 = Repeat PSA), via recommendationShortDescriptions
                        if (parseInt(recommendationShortDescriptions[keyForShortDescriptions].algorithmID) == parseInt(algMapToIDJSON[key])) {
                            label = recommendationShortDescriptions[keyForShortDescriptions].shortDescription
                        }
                    }
                }
            } else {
                continue
            }

            if (label == "") {
                continue
            } else {
                returnedArrayData.push({
                    "id": String(label),
                    "data": JSON.parse(JSONDataToBeParsed[String(key)])
                })
            }

        } catch {
            continue
        }
    }
    return returnedArrayData
}

// This function loops through the Recalls data and parses it to ensure the keys are not null
// and push it into an appropriate array format.
function createArrayDataForRecalls(JSONDataToBeParsed) {
    var returnedArrayData = []
    for (var key in JSONDataToBeParsed) {
        if (key == undefined) {
            continue
        } else {
            returnedArrayData.push({
                "id": String(key),
                "data": JSON.parse(JSONDataToBeParsed[String(key)])
            })
        }
    }
    return returnedArrayData
}
export class DashboardPracticeAllRecommendationsLineChart extends React.Component {

    changeCurrentAllDiseasesValues = clickedValue => {
        console.log("clickedValue", clickedValue)
        this.props.parentHandleChildResultPatientRecommendationsChange(clickedValue.id, "All Recommendations")
    }

    render() {
        var arrayData = createArrayDataForAllRecommendations(
            this.props.parentAllRecommendationsData,
            this.props.allRecommendationShortDescriptions,
            this.props.parentJSONData.mappingAlgNameToIDToJSON)
        var nameOfData = this.props.AllDiseaseFiltersChosen
        var finalArray = obtainActiveRecommendationDataFiltersOnly(nameOfData, arrayData)
        // The purpose of this is to show the 3 most popular recommendations to users.
        // As there will be much more recommendation in the future, this ensures there aren't too
        // much data being show.
        var updatedArray = []
        var arrayDataToUse = []
        var initialRecommendationsToShow = this.props.defaultRecommendations
        if (this.props.AllDiseaseFiltersChosen == "") {
            for (var i = 0; i < finalArray.length; i++) {
                if (initialRecommendationsToShow.includes(finalArray[i].id)) {
                    updatedArray.push(finalArray[i])
                }
            }
            arrayDataToUse = updatedArray
        } else {
            arrayDataToUse = finalArray
        }

        // This is to ensure the x-axis dates are in ascending order.
        // Appears to be a bug in nivo where using the timescale date with format Month - Year
        // doesn't seem to be working (this works for Recall reasons per month by reason graph). 
        var sortedDataArray = arrayDataToUse.sort(function (firstData, secondData) {
            return secondData.data.length - firstData.data.length;
        });

        return (<
            ResponsiveLine data={
                sortedDataArray
            }

            margin={
                genericLineChartDimensions
            }
            xScale={
                { type: 'point' }
            }
            yScale={
                { type: 'linear', stacked: false, min: 0, max: 'auto' }
            }
            axisTop={null}
            axisRight={null}
            activeLineWidth={6}
            inactiveLineWidth={3}
            inactiveOpacity={0.15}
            axisBottom={
                {
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legendPosition: 'middle'
                }
            }

            axisLeft={
                {
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Number of Patients",
                    legendOffset: -40,
                    legendPosition: 'middle'
                }
            }

            theme={genericLineChartThemes}

            colors={
                { scheme: 'category10' }
            }
            pointSize={6}
            pointColor={{ from: 'color', modifiers: [] }}
            pointBorderWidth={2}
            pointBorderColor={
                { from: 'serieColor' }
            }
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            legends={
                [{
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 85,
                    translateY: 70,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    symbolSize: 8,
                    symbolShape: 'circle',

                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [{
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }],
                    onClick: this.changeCurrentAllDiseasesValues
                }]
            }
        />
        )
    }
}

export class DashboardPracticeTotalDeferralsPerMonthLineChart extends React.Component {
    render() {
        // The for loops are to update the dictionary keys to a better front-end legend look.
        var temporaryDeferJSONData = this.props.parentJSONData.totalDeferralsPerMonthJSON
        var updatedTemporaryDeferJSONData = []
        for (var i = 0; i < temporaryDeferJSONData.length; i++) {
            updatedTemporaryDeferJSONData.push({
                "Temporary deferrals": temporaryDeferJSONData[i].temporaryDeferrals,
                "Year": temporaryDeferJSONData[i].year
            })
        }

        var permanentdeferJSONData = this.props.parentJSONData.totalPermanentDeferralsPerMonth
        var updatedPermanentdeferJSONData = []
        for (var i = 0; i < permanentdeferJSONData.length; i++) {
            updatedPermanentdeferJSONData.push({
                "Permanent deferrals": permanentdeferJSONData[i].permanentDeferrals,
                "Year": permanentdeferJSONData[i].year
            })
        }

        // Combine all results into a key if applicable.
        var combinedResults = updatedTemporaryDeferJSONData.map(obj => {
            var data = updatedPermanentdeferJSONData.find(item => item.Year === obj.Year);
            return { ...obj, ...data }
        });

        // These two for loops then cycle through the JSONData arrays to identify keys that were 
        // not included when combining as above and adds in the results.
        for (var i = 0; i < updatedTemporaryDeferJSONData.length; i++) {
            if (combinedResults.some(item => item.Year == updatedTemporaryDeferJSONData[i].Year) == true) {
                continue
            } else {
                combinedResults.push(updatedTemporaryDeferJSONData[i])
            }
        }
        for (var i = 0; i < updatedPermanentdeferJSONData.length; i++) {
            if (combinedResults.some(item => item.Year == updatedPermanentdeferJSONData[i].Year) == true) {
                continue
            } else {
                combinedResults.push(updatedPermanentdeferJSONData[i])
            }
        }

        return (<
            ResponsiveBar

            data={
                combinedResults
            }

            keys={['Temporary deferrals', 'Permanent deferrals',]}
            indexBy="Year"
            margin={
                genericLineChartDimensions
            }
            xScale={
                { type: 'point' }
            }
            yScale={
                { type: 'linear', stacked: false, min: 0, max: 'auto' }
            }
            axisTop={null}
            axisRight={null}
            activeLineWidth={6}
            inactiveLineWidth={3}
            inactiveOpacity={0.15}
            axisBottom={
                {
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legendPosition: 'middle'
                }
            }

            axisLeft={
                {
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Number of Deferred Patients",
                    legendOffset: -40,
                    legendPosition: 'middle'
                }
            }

            theme={genericLineChartThemes}

            colors={
                { scheme: 'nivo' }
            }
            pointSize={6}
            pointColor={{ from: 'color', modifiers: [] }}
            pointBorderWidth={2}
            pointBorderColor={
                { from: 'serieColor' }
            }
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            legends={
                [{
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 85,
                    translateY: 70,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    symbolSize: 8,
                    symbolShape: 'circle',

                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [{
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }]
                }]
            }
        />
        )
    }
}

export class DashboardPracticeAverageDeferralLengthPerMonthLineChart extends React.Component {

    render() {
        var averageDeferralPerMonthJSONData = this.props.parentJSONData.averageDeferralForEachMonthJSON

        return (<
            ResponsiveLine data={
                [{
                    "id": "Average deferral",
                    "color": "hsl(101, 70%, 50%)",
                    "data": averageDeferralPerMonthJSONData
                }]
            }

            margin={
                genericLineChartDimensions
            }
            xScale={
                { type: 'point' }
            }
            yScale={
                { type: 'linear', stacked: false, min: 0, max: 'auto' }
            }
            axisTop={null}
            axisRight={null}
            activeLineWidth={6}
            inactiveLineWidth={3}
            inactiveOpacity={0.15}
            axisBottom={
                {
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legendPosition: 'middle'
                }
            }

            axisLeft={
                {
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Average Deferral Length (Months) ",
                    legendOffset: -40,
                    legendPosition: 'middle'
                }
            }

            theme={genericLineChartThemes}

            colors={
                { scheme: 'category10' }
            }
            pointSize={6}
            pointColor={{ from: 'color', modifiers: [] }}
            pointBorderWidth={2}
            pointBorderColor={
                { from: 'serieColor' }
            }
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            legends={
                genericLineChartLegend
            }
        />
        )
    }
}

export class DashboardPracticeCurrentDeferralAndEndDatesLineChart extends React.Component {

    render() {
        var deferralAndEndDateJSONData = this.props.parentJSONData.currentTemporaryDeferralAndEndDateJSON

        return (<
            ResponsiveLine data={
                [{
                    "id": "Deferral end dates",
                    "color": "hsl(101, 70%, 50%)",
                    "data": deferralAndEndDateJSONData
                }]
            }

            margin={
                genericLineChartDimensions
            }
            xScale={
                { type: 'point' }
            }
            yScale={
                { type: 'linear', stacked: false, min: 0, max: 'auto' }
            }
            axisTop={null}
            axisRight={null}
            activeLineWidth={6}
            inactiveLineWidth={3}
            inactiveOpacity={0.15}
            axisBottom={
                {
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legendPosition: 'middle'
                }
            }

            axisLeft={
                {
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Total Currently Issued Deferrals",
                    legendOffset: -40,
                    legendPosition: 'middle'
                }
            }

            theme={genericLineChartThemes}

            colors={
                { scheme: 'category10' }
            }
            pointSize={6}
            pointColor={{ from: 'color', modifiers: [] }}
            pointBorderWidth={2}
            pointBorderColor={
                { from: 'serieColor' }
            }
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            legends={
                genericLineChartLegend
            }
        />
        )
    }
}

export class DashboardPracticeAverageRecallsPerMonthLineChart extends React.Component {

    render() {
        var averageRecallPerMonthJSONData = this.props.parentJSONData.averageRecallEachMonthJSON

        return (<
            ResponsiveLine data={
                [{
                    "id": "Total Recalls",
                    "color": "hsl(101, 70%, 50%)",
                    "data": averageRecallPerMonthJSONData
                }]
            }

            margin={
                genericLineChartDimensions
            }
            xScale={
                { type: 'point' }
            }
            yScale={
                { type: 'linear', stacked: false, min: 0, max: 'auto' }
            }
            axisTop={null}
            axisRight={null}
            activeLineWidth={6}
            inactiveLineWidth={3}
            inactiveOpacity={0.15}
            axisBottom={
                {
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legendPosition: 'middle'
                }
            }

            axisLeft={
                {
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Total Recalls Per Month",
                    legendOffset: -40,
                    legendPosition: 'middle'
                }
            }

            theme={genericLineChartThemes}

            colors={
                { scheme: 'category10' }
            }
            pointSize={6}
            pointColor={{ from: 'color', modifiers: [] }}
            pointBorderWidth={2}
            pointBorderColor={
                { from: 'serieColor' }
            }
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            legends={
                genericLineChartLegend
            }
        />
        )
    }
}

export class DashboardPracticeRecallsAndReasonsLineChart extends React.Component {
    changeCurrentRecallValues = clickedValue => {
        console.log("clickedValue", clickedValue)
        this.props.parentHandleChildResultRecallChange(clickedValue.id, "Recall")
    }
    render() {
        var arrayData = createArrayDataForRecalls(this.props.parentRecallsPerMonthByReasonData)
        var nameOfData = this.props.recallFiltersChosen
        var finalArray = obtainActiveRecallDataFilters(nameOfData, arrayData)
        return (<
            ResponsiveLine

            data={
                finalArray
            }
            margin={
                genericLineChartDimensions
            }
            xScale={{
                type: "time",
                format: "%m - %Y"
            }}
            xFormat="time:%m - %Y"
            yScale={
                { type: 'linear', stacked: false, min: 0, max: 'auto' }
            }
            axisTop={null}
            axisRight={null}
            activeLineWidth={6}
            inactiveLineWidth={3}
            inactiveOpacity={0.15}
            axisBottom={
                {
                    orient: 'bottom',
                    format: "%B - %Y",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legendPosition: 'middle'
                }
            }

            axisLeft={
                {
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Total Number of Patients",
                    legendOffset: -40,
                    legendPosition: 'middle'
                }
            }

            theme={genericLineChartThemes}

            colors={
                { scheme: 'category10' }
            }
            pointSize={6}
            pointColor={{ from: 'color', modifiers: [] }}
            pointBorderWidth={2}
            pointBorderColor={
                { from: 'serieColor' }
            }
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            legends={
                [{
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 85,
                    translateY: 70,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    symbolSize: 8,
                    symbolShape: 'circle',

                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [{
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }],
                    onClick: this.changeCurrentRecallValues,
                }]
            }
        />
        )
    }
}
