import React, {useState} from 'react';
// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import MuiDialogTitle from '@material-ui/core/DialogTitle';

// import Fab from '@material-ui/core/Fab';
import MuiIconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Collapse from '@material-ui/core/Collapse';

import NestedMenuItem from "material-ui-nested-menu-item";
const IconButton = withStyles(theme => ({
    root: {
       padding:0,
    },
}))(MuiIconButton);



function GuidelineLink(props) {
  console.log(props);
  const ITEM_HEIGHT = 48;
  const[ anchorEl, setAnchorEl ] = useState(null);
  const[ selectedIndex, setSelectedIndex ] = useState(0);     
  const open = Boolean(anchorEl);
  const [profMenuOpen, setProfMenuOpen] = useState(true);
  const [patientMenuOpen, setPatientMenuOpen] = useState(true);
  const [menuPosition, setMenuPosition] = useState(null);

  const handleProfClick = () => {
    setProfMenuOpen(!profMenuOpen);
    console.log(profMenuOpen);
  }

  const handlePatientClick = () => {
    patientMenuOpen(!patientMenuOpen);
  }

  const handleMenuClick = () => {
    setMenuPosition(null);
  }

  const handleMainMenuClick = (event) => {
    if(menuPosition){
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX
    });
  }

  const profGuidelines = props.options.prof;
  const patientGuidelines = props.options.patient;
  console.log(profGuidelines);
  console.log(patientGuidelines);
  return(
    <div className={props.className}>
      <IconButton size="small"
        aria-label="More"
        aria-owns={open ? 'long-menu' : undefined}
        aria-haspopup="true"
        onClick={(event) => handleMainMenuClick(event)}
        >
        <MoreVertIcon />
      </IconButton>
      
        <Menu
        id="long-menu"
        open={!!menuPosition}
        onClose={() => setMenuPosition(null)}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}>

        <NestedMenuItem onClick={handleMenuClick} parentMenuOpen={!!menuPosition} label="Professional Guideline">
          
          {profGuidelines.map((value,index) => (
            <MenuItem key={index}
              onClick={() => {
                setMenuPosition(null);
                window.open(value.link, "_blank");
              }}
            >{value.title}</MenuItem>
          ))}
        </NestedMenuItem>

        <NestedMenuItem onClick={handleMenuClick} parentMenuOpen={patientGuidelines.length > 0 ? !!menuPosition : !menuPosition} label='Consumer resources'>
          
          {/* <MenuItem key={index}>menu2</MenuItem> */}
            {patientGuidelines.map((value,index) => (
              <MenuItem key={index}
                onClick={() => {
                  setMenuPosition(null);
                  window.open(value.link, "_blank");
                }}
              >{value.title}</MenuItem>
            ))}
          </NestedMenuItem>
         
      </Menu>
    </div>
  );
}

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(2),
    },
    guidelineLink: {
        position: 'absolute',
        right: theme.spacing(1.5),
        top: theme.spacing(1.8),
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, guidelineOptions } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {guidelineOptions && <GuidelineLink className={classes.guidelineLink} options={guidelineOptions} />}
        </MuiDialogTitle>
    );
});

class ProofDialog extends React.Component {
    render() {
        const {open, onClose, title, children, guidelineOptions} = this.props;
        return (<Dialog
            open={open}
            onClose={onClose}

            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title" guidelineOptions={guidelineOptions}>{title}</DialogTitle>
            <DialogContent
            >
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>);
    }
}

export default ProofDialog;