import React from 'react';
import PropTypes from 'prop-types';
import Configs from 'Configuration';
import { AgGridReact } from 'ag-grid-react';
//Style sheets
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import SiteSpecificConfig from 'SiteSpecificConfig';
import SiteConfigurationContext from 'Utils/SiteConfigurationContext.jsx';
import BatchSiteConfigurationContext from 'Utils/BatchSiteConfigurationContext.jsx';
import AlgorithmSiteConfigurationContext from 'Utils/AlgorithmSiteConfigurationContext.jsx';
import FhtMain from 'FhtMain';

import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import dashboardStyle from 'components/Card/dashboardStyle.jsx';

import CircularProgressbar from 'react-circular-progressbar';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'components/css/circularProgressStyle.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import PersonAddDisabled from '@material-ui/icons/PersonAddDisabled';
import PersonAdd from '@material-ui/icons/PersonAdd';
import FolderShared from '@material-ui/icons/FolderShared';
import Info from '@material-ui/icons/Info';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import DoneIcon from '@material-ui/icons/Done';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import ContactsIcon from '@material-ui/icons/Contacts';
import Fab from '@material-ui/core/Fab';
import FilterListIcon from '@material-ui/icons/FilterList';

import { BrowserRouter as Router, Route, Link, Redirect } from 'react-router-dom';
import * as _ from 'underscore';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';

import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

import FhtFetchUrl from 'Utils/FhtFetchUrl.jsx';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import { TrainOutlined } from '@material-ui/icons';

import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';

import LoadingScreen from 'react-loading-screen';
//import LoadingScreen from 'Views/SharedComponents/LoadingScreen';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const onCreateCohortLayoutChange = getFromLS('onCreateCohortLayoutChange') || {};
var colorGradient1 = '#279593';
var colorGradient2 = '#48AF68';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  completed: {
    display: 'inline-block'
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

class CreateCohortScreenReworkedUI extends React.Component {
  constructor(props) {
    super(props);
    this.siteConfiguration = SiteConfigurationContext();
    this.batchSiteConfigurationContext = BatchSiteConfigurationContext();
    this.algorithmSiteConfigurationContext = AlgorithmSiteConfigurationContext()
    //Callback event for coded diagnosis screen, recommendations, and mbs
    this.CallbackDiagnosisSelected = this.CallbackDiagnosisSelected.bind(this);
    this.CallbackRecommendationsSelected = this.CallbackRecommendationsSelected.bind(this);
    this.CallbackMbsSelected = this.CallbackMbsSelected.bind(this);

    this.state = {
      data: [],
      descriptionLoaded: 0,
      onCreateCohortLayoutChange: JSON.parse(JSON.stringify(onCreateCohortLayoutChange)),
      stepsCompleted: {},
      stepsCompleted: {},
      ageValue: [0, 120],
      RAGCPStatus: 0,
      cohortName: '',
      recomondationCount: 0,
      treatmentCount: 0,
      codedCount: 0,
      ATSIStatus: 0,
      fromAge: 0,
      toAge: 120,
      loading: true,

      cohortConfirmationDialog: false,
      genderTickboxes: {
        [Configs.genderTickboxMale]: { ticked: false },
        [Configs.genderTickboxFemale]: { ticked: false }
      },

      recommendationTickboxes: {},
      codedDiagnosisLeftTickboxes: {},
      diseaseDropdownbox: {},
      mbsItemTickboxes: {},
      selectedMbsItem: '',
      previewNewCohortTotal: 0,
      avgAge: 0,
      smokerRatio: 0,
      genderRatio: 0,
      AKIRatio: 0,
      avgBMI: 0,
      Atsicount: 0,

      currentDiseaseRecommendationSelected: 'diseaseTickboxCkd',
      currentDiseaseDropDownSelected: '1',
    };
    if (this.batchSiteConfigurationContext['IsTorch'] == 'true') {
      colorGradient1 = '#FC4E31';
      colorGradient2 = '#EE9D4A';
      console.log('IsTorch is true. Starting up torch.');
      document.title = 'TorchRecruit';
    } else {
      console.log('IsTorch is false. Starting up fht.');
      document.title = 'Future Health Today';
    }
    let dropdownDiseaseIds = this.algorithmSiteConfigurationContext['CreateCohortDropDownDiseaseUI'].split(',');
    if (dropdownDiseaseIds.length > 0)
      this.state.currentDiseaseDropDownSelected = dropdownDiseaseIds[0];
    else
      this.state.currentDiseaseDropDownSelected =this.algorithmSiteConfigurationContext['CreateCohortDropDownDiseaseUI'];

    this.populateUIDescriptions();
    //this.setMbsDescriptions();
    //Limits the call of the webapi to one every 50 miliseconds, and takes the last query (false flag at the end)

    this.handleThrottleCohortTotalQueryWebAPI = _.debounce(this.cohortTotalQueryWebAPI, 50, false);
    this.cohortTotalQueryWebAPI();
  }

  CallbackDiagnosisSelected = e => {
    console.log('CallbackDiagnosisSelected:', e);
  };
  CallbackRecommendationsSelected = e => {
    console.log('CallbackRecommendationsSelected:', e);
  };
  CallbackMbsSelected = e => {
    console.log('CallbackMbsSelected:', e);
  };

  setMbsDescriptions = () => {
    let mbs = this.state.mbsItemTickboxes;
    Object.keys(mbs).map(key => {
      mbs[key].desc = Configs.mbsDefinitions[key] ? Configs.mbsDefinitions[key] : '';
    });
    this.setState({ mbsItemTickboxes: mbs });
  };

  populateUIDescriptions = () => {
      let dropdownDiseaseIds = this.algorithmSiteConfigurationContext['CreateCohortDropDownDiseaseUI'].split(',');
      let codedDiagnosisIds = this.algorithmSiteConfigurationContext['CreateCohortCodedDiagnosisUI'].split(',');
      let api = FhtFetchUrl('/getAllCreateCohortUIDescriptions2');
      api
        .get()
        .then(response => {
          console.log('response from server:', response);
          let codedDiagnosisLeftTickboxes = {};
          let recommendationTickboxes = {};
          let mbsItemTickboxes = {};
          let diseaseDropdownbox = {};
          

          Object.keys(response.returnObj.uiCodedDiagnosisDict).map(key => {
            if (codedDiagnosisIds.includes(key)) {
              codedDiagnosisLeftTickboxes[key] = {
                ticked: false,
                total: 0,
                desc: response.returnObj.uiCodedDiagnosisDict[key]
              };
            }
          });

          //fhtEnabledAnalysisTestId
          Object.keys(response.returnObj.uiDropDownDiseaseDict).map(key => {
            if (dropdownDiseaseIds.includes(key)) {
            diseaseDropdownbox[key] =
              response.returnObj.uiDropDownDiseaseDict[key];
            }
          });

          var activatedAlgsIDsForSite = this.algorithmSiteConfigurationContext.FhtEnabledAnalysisTestIds.split(",").map(Number);
          Object.keys(response.returnObj.fhtEnabledAnalysisDict).map(key => {
            if (activatedAlgsIDsForSite.includes(response.returnObj.fhtEnabledAnalysisDict[key].fhtEnabledAnalysisTestId)) {
            recommendationTickboxes[key] = {
              ticked: false,
              total: 0,
              desc: response.returnObj.fhtEnabledAnalysisDict[key].fhtEnabledAnalysisDescription,
              diseaseId: response.returnObj.fhtEnabledAnalysisDict[key].fhtEnabledAnalysisDiseaseId
            };
          }
          });          
          
          Object.keys(response.returnObj.fhtMbsItemDict).map(key => {
            mbsItemTickboxes[key] = {
              code: response.returnObj.fhtMbsItemDict[key].fhtMbsItemCode,
              ticked: false,
              total: 0,
              desc: response.returnObj.fhtMbsItemDict[key].fhtMbsItemDesc,
              diseaseId: response.returnObj.fhtMbsItemDict[key].fhtMbsItemDiseaseid
            };
          });          
          this.setState({
            codedDiagnosisLeftTickboxes: codedDiagnosisLeftTickboxes,
            diseaseDropdownbox: diseaseDropdownbox,
            recommendationTickboxes: recommendationTickboxes,
            mbsItemTickboxes: mbsItemTickboxes,
            descriptionLoaded: 1
          });
        })
        .catch(error => {
          var msg = String(error);
          this.props.GlobalFunctionDisplaySnackbarMessage(msg, Configs.snackbarVariants.error);
        });
  };
  cohortPatientTotalOnlyWebAPI = () => {
    console.log('cohortPatientTotalOnlyWebAPI');
    let filterSettings = GenerateFilterSettings(this);
    let cohortIsGlobal = 1;
    let body = {
      name: this.state.cohortName,
      isGlobal: cohortIsGlobal,
      filterSettings: filterSettings
    };
    //const api = FhtFetchUrl(Configs.recalculateTotalCohortPatients);
    //const api = FhtFetchUrl('/RecalculateTotalCohortPatients');
    const api = FhtFetchUrl(Configs.calculateCreateCohortFiltersSummaryRefactored);
    this.setState({ loading: true });
    api
      .post(body)
      .then(response => {
        console.log('response from server:', response);
        console.log(this.state.mbsItemTickboxes);
        let mbsItemTickboxes = this.state.mbsItemTickboxes;
        Object.keys(response.mbsItemNumbers).map(key => {
          mbsItemTickboxes[key].total = response.mbsItemNumbers[key];
        });
        this.setState({
          previewNewCohortTotal: response.totalPatientInCohortNumber,
          mbsItemTickboxes: mbsItemTickboxes,
          /*
                              avgAge: response.avgAge,
                              smokerRatio: response.smokerRatio,
                              genderRatio: response.maletofemaleRatio,
                              AKIRatio: response.AKIRatio,
                              avgBMI: response.avgBMI,
                              Atsicount: response.Atsicount,
                              */
          loading: false
        });
      })
      .catch(error => {
        var msg = String(error);
        this.props.GlobalFunctionDisplaySnackbarMessage(msg, Configs.snackbarVariants.error);
      });
  };

  cohortTotalQueryWebAPI = () => {
    if (this.state.descriptionLoaded === 0) {
      setTimeout(
        function() {
          this.handleThrottleCohortTotalQueryWebAPI();
        }.bind(this),
        1000
      );
      return;
    }

    let filterSettings = GenerateFilterSettings(this);
    let cohortIsGlobal = 1;
    let body = {
      name: this.state.cohortName,
      isGlobal: cohortIsGlobal,
      filterSettings: filterSettings
    };
    const api = FhtFetchUrl(Configs.calculateCreateCohortFiltersSummaryRefactored);
    this.setState({ loading: true });
    api
      .post(body)
      .then(response => {
        console.log('response from server:', response);
        this.setState({
          previewNewCohortTotal: response.totalPatientInCohortNumber,
          /*
                              avgAge: response.avgAge,
                              smokerRatio: response.smokerRatio,
                              genderRatio: response.maletofemaleRatio,
                              AKIRatio: response.AKIRatio,
                              avgBMI: response.avgBMI,
                              Atsicount: response.Atsicount,
                              */
          loading: false
        });
        PopulateFilterNumbersRefactored(this, response);
      })
      .catch(error => {
        var msg = String(error);
        this.props.GlobalFunctionDisplaySnackbarMessage(msg, Configs.snackbarVariants.error);
      });
  };

  onCreateCohortLayoutChange(layout, onCreateCohortLayoutChange) {
    saveToLS('onCreateCohortLayoutChange', onCreateCohortLayoutChange);
    this.setState({ onCreateCohortLayoutChange });
  }

  handleStep() {
    console.log('step + 1');
  }

  handleRecommendationTickboxChange = name => event => {
    console.log('recommendation id:', name);
    let tempTickbox = this.state.recommendationTickboxes;
    tempTickbox[name]['ticked'] = event.target.checked;
    this.setState({ recommendationTickboxes: tempTickbox }, () => {
      console.log(this.state.recommendationTickboxes);
      //this.cohortTotalQueryWebAPI();
      this.handleThrottleCohortTotalQueryWebAPI();
    });
  };

  handleMbsTickboxChange = item => event => {
    console.log('====== Selected MBS ==========', item);
    let tmp = this.state.mbsItemTickboxes;
    tmp[item].ticked = event.target.checked;
    let selectedMbs = [];
    this.setState({ mbsItemTickboxes: tmp }, () => {
      console.log(this.state.mbsItemTickboxes);
      this.handleThrottleCohortTotalQueryWebAPI();
    });
  };

  handleCodedDiagnosisTickboxChange = name => event => {
    console.log('codeddiagnosis id:', name);
    let tempTickbox = this.state.codedDiagnosisLeftTickboxes;
    tempTickbox[name]['ticked'] = event.target.checked;
    this.setState({ codedDiagnosisLeftTickboxes: tempTickbox }, () => {
      console.log(this.state.codedDiagnosisLeftTickboxes);
      this.handleThrottleCohortTotalQueryWebAPI();
    });
  };

  onBtnSubmitCreateCohort = () => {
    console.log('submitting cohort creation');
    this.setState({ loading: true });
    let filterSettings = GenerateFilterSettings(this);
    let cohortIsGlobal = 1;
    let body = { isGlobal: cohortIsGlobal, filterSettings: filterSettings };
    const api = FhtFetchUrl(Configs.createCohortRefactored);
    api
      .post(body)
      .then(response => {
        console.log('response from server:', response);
        this.setState({
          message: response.message,
          cohortId: response.cohortId,
          loading: false
        });

        this.setState({ cohortConfirmationDialog: false });
        this.props.history.push(
          '/' +
            'standaloneDataGridScreen?cohortid=' +
            this.state.cohortId +
            '&cohortCreateMode=1&ignoreRecalledPatients=0'
        );
      })
      .catch(error => {
        var msg = String(error);
        this.props.GlobalFunctionDisplaySnackbarMessage(msg, Configs.snackbarVariants.error);
      });
  };

  handleFromAgechange = event => {
    if (event.target.value >= 0 && event.target.value < 120) {
      this.setState({ fromAge: event.target.value }, () => {
        this.handleThrottleCohortTotalQueryWebAPI();
      });
    } else if (event.target.value < 0 && event.target.value < 120) {
      var msg = 'Age range should be between 0 and 120';
      this.setState({ fromAge: 0 }, () => {
        this.handleThrottleCohortTotalQueryWebAPI();
      });
      this.props.GlobalFunctionDisplaySnackbarMessage(msg, Configs.snackbarVariants.info);
    }
  };

  handleToAgechange = event => {
    if (event.target.value >= 0 && event.target.value < 120) {
      this.setState({ toAge: event.target.value }, () => {
        this.handleThrottleCohortTotalQueryWebAPI();
      });
    } else if (event.target.value < 0 && event.target.value < 120) {
      var msg = 'Age range should be between 0 and 120';
      this.props.GlobalFunctionDisplaySnackbarMessage(msg, Configs.snackbarVariants.info);
    }
  };

  changeName = event => {
    this.setState({ cohortName: event.target.value });
  };
  //change event for genders

  handleGenderChk = name => event => {
    console.log('gender name:', name);
    let tempTickbox = this.state.genderTickboxes;
    tempTickbox[name]['ticked'] = event.target.checked;
    this.setState({ genderTickboxes: tempTickbox }, () => {
      this.handleThrottleCohortTotalQueryWebAPI();
    });
  };

  //handle ATSI Change
  handleATSIChange = event => {
    this.setState(
      { ATSIStatus: event.target.checked == true ? 1 : 0 },
      this.handleThrottleCohortTotalQueryWebAPI()
    );
  };

  //handle RACGP status change
  handleRACGPChange = event => {
    this.setState(
      { RAGCPStatus: event.target.checked == true ? 1 : 0 },
      this.handleThrottleCohortTotalQueryWebAPI()
    );
  };

  renderMbsFilterSection() {
    return (
      <div>
        <div style={{ marginTop: '2REM' }}>
          <FormControl component="fieldset" style={{ marginLeft: '2REM' }}>
            <FormLabel component="legend" style={{ fontSize: "110%", fontWeight: 'bolder', color: 'black' }}>
              Step 5 (Optional):&nbsp; Do you want to focus on available MBS item numbers:
            </FormLabel>
            <div>
              {Object.keys(this.state.mbsItemTickboxes).map(
                key =>
                  this.state.mbsItemTickboxes[key].diseaseId ==
                    this.state.currentDiseaseDropDownSelected && (
                    //key == 721 &&
                    <div key={key}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.mbsItemTickboxes[key].ticked}
                            onChange={this.handleMbsTickboxChange(key)}
                          />
                        }
                        label={
                          this.state.mbsItemTickboxes[key].code +
                          ' : ' +
                          this.state.mbsItemTickboxes[key].desc +
                          ' (' +
                          this.state.mbsItemTickboxes[key].total +
                          ')'
                        }
                      ></FormControlLabel>
                    </div>
                  )
              )}
            </div>
          </FormControl>
        </div>
      </div>
    )
  }

  handleDiseaseDropDownChange = event => {
    //Uncheck everything selected in recommendations so they don't overlap with one another
    var tempRecTickBoxes = this.state.recommendationTickboxes;
    Object.keys(tempRecTickBoxes).map(key => {
      tempRecTickBoxes[key].ticked = false;
    });

    this.setState({
      currentDiseaseDropDownSelected: event.target.value,
      recommendationTickboxes: tempRecTickBoxes,
      loading: true
    });
    this.handleThrottleCohortTotalQueryWebAPI();
  };

  render() {
    let ticked = 'ticked';
    let FHTGenderFilterSection = (
      <div>
        <div style={{ marginTop: 'none', flexDirection: 'row', display: 'flex' }}>
          {/* <FormLabel component="legend" style={{ fontWeight: 'bolder', color: 'black' }}>
            Gender
          </FormLabel> */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.genderTickboxes[Configs.genderTickboxMale[ticked]]}
                  onChange={this.handleGenderChk(Configs.genderTickboxMale)}
                  value="genderTickboxes[genderTickboxMale].ticked"
                />
              }
              label="Male"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.genderTickboxes[Configs.genderTickboxFemale[ticked]]}
                  onChange={this.handleGenderChk(Configs.genderTickboxFemale)}
                  value="genderTickboxes[genderTickboxFemale].ticked"
                />
              }
              label="Female"
            />
        </div>
      </div>
    );

    let FHTCodedDiagnosisFilterSection = (
      <div>
        <div style={{ marginTop: '2REM' }}></div>
        <FormControl component="fieldset" style={{ marginLeft: '2REM' }}>
          <FormLabel component="legend" style={{ fontSize: "110%", fontWeight: 'bolder', color: 'black' }}>
            Step 4 (Optional):&nbsp; Do you want to focus on patients with an additional diagnosed comorbidity:
          </FormLabel>
          <FormGroup style={{ marginTop: '0.5REM' }}>
            {Object.keys(this.state.codedDiagnosisLeftTickboxes).map(key => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.codedDiagnosisLeftTickboxes[key].ticked}
                      onChange={this.handleCodedDiagnosisTickboxChange(key)}
                    />
                  }
                  label={
                    this.state.codedDiagnosisLeftTickboxes[key].desc +
                    ' (' +
                    this.state.codedDiagnosisLeftTickboxes[key].total +
                    ')'
                  }
                ></FormControlLabel>
              );
            })}
          </FormGroup>
        </FormControl>
      </div>
    );

    let FHTDiseaseDropDownFilterSectionRefactored = (
      <div style = {{
            borderBottom: '2px solid #ccc',
            width: '100%',
            paddingBottom: '0.5REM',
          }}>
        <div> 
          <FormLabel style={{ fontSize: "110%", fontWeight: 'bolder', color: 'black' }}>
          Step 1:&nbsp; What condition area do you want to target:&nbsp;
          </FormLabel>
          <NativeSelect
            value={this.state.currentDiseaseDropDownSelected}
            onChange={this.handleDiseaseDropDownChange}
          >
            {Object.keys(this.state.diseaseDropdownbox).map(key => {
              return <option value={key}>{this.state.diseaseDropdownbox[key]}</option>;
            })}
          </NativeSelect>
          <span style={{ position: "absolute", right: "0px" }}>
            The numbers in brackets represents the number of patients in your practice that meet that criteria
          </span>
        </div>
      </div>
    );

    let FHTAgeFilterSection = (
      <div>
        <div>
          {/* <Typography
            id="range-slider"
            gutterBottom
            style={{ marginLeft: '2REM', fontWeight: 'bolder' }}
          >
            Age
          </Typography> */}
          <br/>
          <TextField
            id="fromAge"
            type="number"
            inputProps={{ min: '0', max: '120' }}
            variant="outlined"
            label="From"
            defaultValue="0"
            onChange={this.handleFromAgechange}
            style={{
              marginBottom: '1rem',
              width: '40%',
              height: '1px',
              maxHeight: '1px'
            }}
          ></TextField>
          <TextField
            id="toAge"
            type="number"
            inputProps={{ min: '0', max: '120' }}
            variant="outlined"
            label="To"
            defaultValue="120"
            onChange={this.handleToAgechange}
            style={{ 
              marginLeft: '1rem', 
              width: '40%', 
              height: '1px', 
              maxHeight: '1px' 
            }}
          ></TextField>
          <div
          style = {{
            borderBottom: '2px solid #ccc',
            width: '88%',
            paddingTop: '2.5REM',
          }}></div>
        </div> <br/>
      </div>
    );

    let FHTPatientActiveFilterSection = (
      <div>
        <div style={{ marginTop: '2REM' }}>
          <FormControl component="fieldset">
            {/* <FormLabel component="legend" style={{ fontWeight: 'bolder', color: 'black' }}>
              Patient is{' '}
            </FormLabel> */}
            <FormGroup style={{ marginTop: '0.5REM' }}>
              <FormControlLabel
                control={
                  <Checkbox checked={this.state.RAGCPStatus} onChange={this.handleRACGPChange} />
                }
                label="Active (Attended at least three times in last two years)"
              />
            </FormGroup>
          </FormControl>
        </div>
      </div>
    );

    let FHTAtsiFilterSection = (
      <div>
        <div style={{ marginTop: 'none' }}>
          <FormControl component="fieldset" >
            {/* <FormLabel component="legend" style={{fontWeight:"bolder", color:"black"}}>First Nation </FormLabel> */}
            <FormGroup style={{ marginTop: '2REM' }}>
              <FormControlLabel
                control={
                  <Checkbox checked={this.state.ATSIStatus} onChange={this.handleATSIChange} />
                }
                label="First Nations"
              />
            </FormGroup>
          </FormControl>
        </div>
      </div>
    );

    let FHTRecommendationFilterSection = <div></div>;

    FHTRecommendationFilterSection = (
      <div>
        <div style={{ marginTop: '2REM' }}></div>
        <FormControl component="fieldset" style={{ marginLeft: '2REM' }}>
          <FormLabel component="legend" style={{ fontSize: "110%", fontWeight: 'bolder', color: 'black' }}>
            Step 2:&nbsp; What recommendations will you use:
          </FormLabel>
          <FormGroup style={{ marginTop: '0.5REM' }}>
            {Object.keys(this.state.recommendationTickboxes).map(key => {
              if (
                this.state.recommendationTickboxes[key].diseaseId ==
                this.state.currentDiseaseDropDownSelected
              ) {
                return (
                  <FormControlLabel  style={{ paddingTop: '1REM' }} disabled={this.state.recommendationTickboxes[key].total<=0}
                    control={
                      <Checkbox
                        checked={this.state.recommendationTickboxes[key].ticked}
                        onChange={this.handleRecommendationTickboxChange(key)}
                      />
                    }
                    label={
                      this.state.recommendationTickboxes[key].desc +
                      ' (' +
                      this.state.recommendationTickboxes[key].total +
                      ')'
                    }
                  ></FormControlLabel>
                );
              }
            })}
          </FormGroup>
        </FormControl>
      </div>
    );

    let screenTitle = '';
    if (this.state.loading) {
      screenTitle = 'Create a Cohort (Recalculating...)';
    } else {
      screenTitle = 'Create a Cohort';
    }
    return (
      <div style={{ marginTop: '2REM' }}>
        <h3 style={{ 
              textAlign: 'center',
              marginTop: '0.5rem',  
              fontSize: '21',
              borderBottom: '2px solid #ccc',
              width: '100%',
              paddingBottom: '2REM'
            }}>
          <b>{screenTitle}</b>
        </h3>
        <ResponsiveReactGridLayout
          className="createCohortLayout"
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          rowHeight={30}
          layouts={this.state.globalSummaryNumberLayout}
          onLayoutChange={(layout, layouts) => this.onCreateCohortLayoutChange(layout, layouts)}
          onDrag={this.onDrag}
          onDragStop={this.onDragStop}
          onResizeStart={this.onResizeStart}
          onResizeStop={this.onResizeStop}
          isResizable={false}
        >
          <div
            key="viewkey"
            data-grid={{
              w: 24,
              h: 0,
              x: 0,
              y: 0,
              static: true
            }}
          >
            {FHTDiseaseDropDownFilterSectionRefactored}
          </div>

          <div
            key="middlefilterkey"
            data-grid={{
              w: 4,
              h: 30,
              x: 0,
              y: 1.5,
              static: true
            }}
          >
            <Paper style={{ minHeight: '60REM', maxHeight: 'none', paddingTop: '1REM' }}>
              {FHTRecommendationFilterSection}
            </Paper>
          </div>

          <div
            key="topfilterkey"
            data-grid={{
              w: 2,
              h: 30,
              x: 4,
              y: 1.5,
              static: true
            }}
          >
          <Paper style={{ minHeight: '60REM', maxHeight: 'none', paddingTop: '1REM' }}>
            <FormControl component="fieldset" style={{ marginLeft: '2REM',marginTop: '2REM' }}>
                <FormLabel component="legend" style={{ fontSize: "110%", fontWeight: 'bolder', color: 'black' }}>
                  Step 3 (Optional):&nbsp; Do you want to focus on certain patient demographics:
                </FormLabel>
                  {FHTAgeFilterSection}
                  {FHTGenderFilterSection}
                  {FHTAtsiFilterSection}
                  {FHTPatientActiveFilterSection}
              </FormControl>
            </Paper>
          </div>

          <div
            key="lhsfilterKey"
            data-grid={{
              w: 2,
              h: 30,
              x: 6,
              y: 1.5,
              static: true
            }}
          >
            <Paper style={{ minHeight: '60REM', maxHeight: 'none', paddingTop: '1REM' }}>
              {/*{FHTDiseaseFilterSection}*/}
              {FHTCodedDiagnosisFilterSection}
            </Paper>
          </div> 


            <div
              key="rhsfilterkey"
              data-grid={{
                w: 4,
                h: 30,
                x: 8,
                y: 1.5,
                static: true,
                minW: 3,
                maxW: 9
              }}
            >
              {this.batchSiteConfigurationContext['IsTorch'] == 'false' &&(
              <Paper style={{ minHeight: '60REM', maxHeight: 'none', paddingTop: '1REM' }}>
                {this.renderMbsFilterSection()}
              </Paper>
              )}
            </div>
        </ResponsiveReactGridLayout>

        <footer
        style = {{
          textAlign: 'center',
          position: 'fixed',
          fontSize: '125%',
          color: 'white',
          bottom: '0',
          width: '100%',
          paddingBottom: '0.5REM',
          paddingTop: '0.5REM', 
          background: 'linear-gradient(to right, '+colorGradient1+', '+colorGradient2+')'
        }}>
        <div style={{ fontSize: '100%' }}>
            <span style={{ position: "absolute", left: "0px", width: '25%', fontSize: '1vw', fontWeight: 'bold' }}>
              Total number of patients in current cohort: {this.state.previewNewCohortTotal}
            </span>
            <a
              href="javascript:;"
              style={{ fontSize: '1vw' }}
              onClick={this.onBtnSubmitCreateCohort}
            >   
              <b style={{ color: 'white' }}><u>View This Cohort</u></b>
            </a>
          </div>
        </footer>

        {this.state.loading ? (
          <div
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              bottom: '0',
              right: '0',
              margin: 'auto'
            }}
          >
            <Paper style={{ background: 'transparent' }}>
              <LoadingScreen
                loading={true}
                bgColor="rgba(1,1,1,0.5)"
                spinnerColor="#9ee5f8"
                textColor="#FFFFFF"
                logoSrc={'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/fhtlogomin.png'}
                text="Recalculating cohort"
              />
            </Paper>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem('rgl-8')) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      'rgl-8',
      JSON.stringify({
        [key]: value
      })
    );
  }
}

function PopulateFilterNumbersRefactored(params, data) {
  console.log('starting:PopulateFilterNumbersRefactored()', data);

  let codedDiagnosisLeftTickboxes = params.state.codedDiagnosisLeftTickboxes;
  let recommendationTickboxes = params.state.recommendationTickboxes;
  let mbsItemTickboxes = params.state.mbsItemTickboxes;

  Object.keys(data.codedDiagnosisNumbers).map(key => {
    if (codedDiagnosisLeftTickboxes[key] !== undefined)
    codedDiagnosisLeftTickboxes[key].total = data.codedDiagnosisNumbers[key];
  });
  Object.keys(data.recommendationNumbers).map(key => {
    if (recommendationTickboxes[key] !== undefined)
    recommendationTickboxes[key].total = data.recommendationNumbers[key];
  });
  Object.keys(data.mbsItemNumbers).map(key => {
    if (mbsItemTickboxes[key] !== undefined)
    mbsItemTickboxes[key].total = data.mbsItemNumbers[key];
  });
  params.setState({
    recommendationTickboxes: recommendationTickboxes,
    codedDiagnosisLeftTickboxes: codedDiagnosisLeftTickboxes,
    mbsItemTickboxes: mbsItemTickboxes
  });
}

function createFHTAgeDbQueryString(arrayOfAge) {
  return arrayOfAge[0] + '_rangeTo_' + arrayOfAge[1];
}

function GenerateFilterSettings(params) {
  let selectedRecommendationsArray = [];
  Object.keys(params.state.recommendationTickboxes).map(key => {
    if (params.state.recommendationTickboxes[key].ticked == true) {
      selectedRecommendationsArray.push(key);
    }
  });
  let selectedCodedDiagnosisArray = [];
  Object.keys(params.state.codedDiagnosisLeftTickboxes).map(key => {
    if (params.state.codedDiagnosisLeftTickboxes[key].ticked == true) {
      selectedCodedDiagnosisArray.push(key);
    }
  });

  let selectedMbsItemArray = [];
  Object.keys(params.state.mbsItemTickboxes).map(key => {
    if (params.state.mbsItemTickboxes[key].ticked == true) {
      selectedMbsItemArray.push(params.state.mbsItemTickboxes[key].code);
    }
  });

  let selectedCodedDiagnosisString = selectedCodedDiagnosisArray.join(',');
  let selectedRecommendationString = selectedRecommendationsArray.join(',');
  let selectedMbsItemString = selectedMbsItemArray.join(',');

  console.log('============================= ', selectedMbsItemString);
  let patientGenderString = ''; //otherwise M or F

  if (
    params.state.genderTickboxes.genderTickboxMale.ticked == true &&
    params.state.genderTickboxes.genderTickboxFemale.ticked == false
  )
    patientGenderString = 'M';
  else if (
    params.state.genderTickboxes.genderTickboxMale.ticked == false &&
    params.state.genderTickboxes.genderTickboxFemale.ticked == true
  )
    patientGenderString = 'F';
  else if (
    params.state.genderTickboxes.genderTickboxMale.ticked == false &&
    params.state.genderTickboxes.genderTickboxFemale.ticked == false
  )
    patientGenderString = 'M_andAlso_F';
  else if (
    params.state.genderTickboxes.genderTickboxMale.ticked == true &&
    params.state.genderTickboxes.genderTickboxFemale.ticked == true
  )
    patientGenderString = 'M_andAlso_F';

  let ageString = '';
  if (
    (params.state.fromAge >= 0 && params.state.fromAge <= 120) ||
    (params.state.toAge >= 0 && params.state.toAge <= 120)
  ) {
    ageString = params.state.fromAge + '_rangeTo_' + params.state.toAge; //createFHTAgeDbQueryString(this.state.ageValue);;//otherwise just a number
  } else {
    ageString = 0 + '_rangeTo_' + 120;
  }
  let activePatientsOnly = params.state.RAGCPStatus; //0 means do both active & inactive. 1 means do only active patients.
  let cohortName = params.state.cohortName.toString();
  let cohortIsGlobal = 1; //doesn't matter here
  let atsiString = params.state.ATSIStatus; //1 or 0

  let filterSettings = [];
  //filterSettings.push({ filterName: 'tickedTestIds', filterValue: rmMessageString.toString() });
  filterSettings.push({
    filterName: 'patientGender',
    filterValue: patientGenderString.toString()
  });
  filterSettings.push({ filterName: 'patientAge', filterValue: ageString.toString() });
  filterSettings.push({
    filterName: 'patientIsRACGPActive',
    filterValue: activePatientsOnly.toString()
  });
  filterSettings.push({ filterName: 'patientAtsi', filterValue: atsiString.toString() });
  // filterSettings.push({
  //   filterName: 'mbsItems',
  //   filterValue: params.state.selectedMbsItem.toString()
  // });

  filterSettings.push({
    filterName: 'selectedCodedDiagnosis',
    filterValue: selectedCodedDiagnosisString
  });
  filterSettings.push({
    filterName: 'selectedRecommendation',
    filterValue: selectedRecommendationString
  });
  filterSettings.push({
    filterName: 'selectedDropDownDisease',
    filterValue: params.state.currentDiseaseDropDownSelected
  });
  filterSettings.push({
    filterName: 'selectedMbsItem',
    filterValue: selectedMbsItemString
  });
  return filterSettings;
}

export default withStyles(dashboardStyle, useStyles)(CreateCohortScreenReworkedUI);
