import React from 'react';
import PropTypes from 'prop-types';
import Configs from 'Configuration';
//Style sheets
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SiteConfigurationContext from 'Utils/SiteConfigurationContext.jsx';
import FhtMain from 'FhtMain';
import BatchSiteConfigurationContext from 'Utils/BatchSiteConfigurationContext.jsx';
import AlgorithmSiteConfigurationContext from 'Utils/AlgorithmSiteConfigurationContext.jsx';
/*
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
*/
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

import dashboardStyle from 'components/Card/dashboardStyle.jsx';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'components/css/circularProgressStyle.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import CDialog from 'components/CDialog';
import * as _ from 'underscore';

import Box from '@material-ui/core/Box';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import FhtFetchUrl from 'Utils/FhtFetchUrl.jsx';

//nivo graphs
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';

import ReviewBarGraph from './ReviewBarGraph';
//import ActionRequiredLineGraph from './ActionRequiredLineGraph';
import ReviewLineGRaph from './ReviewLineGraph';

import ListItemText from '@material-ui/core/ListItemText';
import { CardContent } from '@material-ui/core';

//import { ChevronRight } from '@material-ui/icons';
import {Add} from '@material-ui/icons';
const ResponsiveReactGridLayout = WidthProvider(Responsive);
const onReviewCohortLayoutChange = getFromLS('onReviewCohortLayoutChange') || {};
const treatThisValueAsZero = 0.1;
const colorSchemeforGraphs = 'pastel1'; //spectral, nivo, pastel1, set3

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  completed: {
    display: 'inline-block'
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  cohortDescriptionList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  }
}));


class CohortReviewScreen extends React.Component {
  constructor(props) {
    super(props);
    this.batchSiteConfigurationContext = BatchSiteConfigurationContext();
    this.algorithmSiteConfigurationContext = AlgorithmSiteConfigurationContext()    
    this.state = {
      data: [],
      siteConfigurationContext: SiteConfigurationContext(),      
      onReviewCohortLayoutChange: JSON.parse(JSON.stringify(onReviewCohortLayoutChange)),
      cohortConfirmationDialog: false,
      cohortBeingReviewedName: Configs.noCohortSelected,
      cohortBeingReviewedId: -1,
      cohortNamesDictionary: {},
      showConfirmActionMessage: 'Delete cohort',
      showConfirmAction: false,
      toggleButtonValue: '0',
      totalPatients:0,
      totalPatientsWithMatchingRecommendations:0,
      totalPatientsNew: 0,
      cohortDisease:'',
      cohortRec:[],
    };
    this.onScreenInitialise();
  }

  onReviewCohortLayoutChange(layout, onReviewCohortLayoutChange) {
    saveToLS('onReviewCohortLayoutChange', onReviewCohortLayoutChange);
    this.setState({ onReviewCohortLayoutChange });
  }
  handleStep() {
    console.log('step + 1');
  }
  onDeleteCohortBtn = () => {
    console.log('Deleting cohort:', this.state.cohortBeingReviewedId);

    let body = { cohortId: this.state.cohortBeingReviewedId };
    const api = FhtFetchUrl(Configs.deleteCohort);
    api
      .post(body)
      .then(response => {
        if (response.message !== undefined) {
          this.props.history.push('/' + 'actionRequiredCohortScreen?cohortid=-1');
          this.setState({ showConfirmAction: false });
          this.props.GlobalFunctionDisplaySnackbarMessage(
            response.message,
            Configs.snackbarVariants.success
          );
          this.onScreenInitialise();
        } else {
          throw response.error;
        }
      })
      .catch(error => {
        var msg = String(error);
        this.props.GlobalFunctionDisplaySnackbarMessage(
          'Error:' + msg,
          Configs.snackbarVariants.error
        );
      });
  };
  handleTreatmentOptimisationTickboxChange = name => event => {
    console.log('treatment name:', name);
    let tempTickbox = this.state.treatmentOptimisationTickboxes;
    tempTickbox[name]['ticked'] = event.target.checked;
    this.setState({ treatmentOptimisationTickboxes: tempTickbox }, () => {
      this.cohortTotalQueryWebAPI();
    });
  };
  clickedStageAnalysisGraph(e) {
    console.log('clickedStageAnalysisGraph(e)', e);
  }
  onScreenInitialise = () => {
    //First we get the list of cohorts available in FHT for this user
    const api = FhtFetchUrl(Configs.getListOfUserCreatedCohorts);
    api
      .get(null)
      .then(response => {
        console.log('cohortDict response from API:', response.returnCohortDict);
        //eslint-disable-next-line
        this.setState({ cohortNamesDictionary: response.returnCohortDict }, () => {
          if (this.props.location.search != '' && this.props.location.search != null) {
            let searchParams = this.props.location.search.split('?')[1].split('&');

            let tempCohortIdValue = -1;
            searchParams.forEach(s => {
              let keySearch = s.split('=')[0];
              let searchValue = s.split('=')[1];
              if (keySearch === 'cohortid') {
                tempCohortIdValue = searchValue;
              }
            });
            if (tempCohortIdValue != -1) {
              //console.log('Dictionary:', this.state.cohortNamesDictionary);
              this.setState({
                cohortBeingReviewedName: this.state.cohortNamesDictionary[tempCohortIdValue]
                  .cohortName,
                cohortBeingReviewedId: tempCohortIdValue
              });
              //localStorage.setItem(FhtMain.GlobalContext.userName + ':LastUsedCohortId', tempCohortIdValue);
              this.props.history.push(
                '/' + 'actionRequiredCohortScreen?cohortid=' + tempCohortIdValue
              );
              this.updateDescriptions(tempCohortIdValue);
            }
          }
        });
      })
      .catch(error => {
        var msg = String(error);
        this.props.GlobalFunctionDisplaySnackbarMessage(
          'No user cohorts found. Error message:' + msg,
          Configs.snackbarVariants.error
        );
      });
  };

  //Required to detect params change in the form as react router does NOT reload the params for you automatically.
  //Hence, if actionrequired is called via the menu, and you call it again (different variant) by passing a different param, this function will detect that param change.
  componentDidUpdate = prevProps => {
    if (this.props.location.search !== prevProps.location.search) {
      this.onLoadParseUrl();
    }
  };
  onBtCancelUserActionDialog = () => {
    this.setState({ showConfirmAction: false });
  };
  onLoadParseUrl = () => {
    if (this.props.location.search != '' && this.props.location.search != null) {
      let searchParams = this.props.location.search.split('?')[1].split('&');

      let cohortBeingReviewedId = -1;
      searchParams.forEach(s => {
        let keySearch = s.split('=')[0];
        let searchValue = s.split('=')[1];
        if (keySearch === 'cohortid') {
          cohortBeingReviewedId = searchValue;
        }
      });
      if (cohortBeingReviewedId == -1)
        this.setState({
          cohortBeingReviewedName: Configs.noCohortSelected,
          cohortBeingReviewedId: cohortBeingReviewedId
        });
      else {
        this.setState({
          cohortBeingReviewedName: this.state.cohortNamesDictionary[cohortBeingReviewedId]
            .cohortName,
          cohortBeingReviewedId: cohortBeingReviewedId
        });
      }
    }
  };

  cohortChanged = e => {
    //we use react router to push the values in so that we can use the browser back history to change between different cohorts.
    this.props.history.push('/' + 'actionRequiredCohortScreen?cohortid=' + e.target.value);
    this.updateDescriptions(e.target.value);
  };

  updateDescriptions = (dropdownId) => {
    if (dropdownId !== -1) {

      const api = FhtFetchUrl(Configs.cohortReviewDescriptionsAndTotals);
      let body = { cohortId: dropdownId };
      api
        .post(body)
        .then(response => {
          let totalPatientsWithMatchingRecommendations = 0;
          let totalPatientsNew = 0;
          let totalPatients = 0;
          let cohortDisease = "";
          let cohortRec = [];
          Object.keys(response).forEach(function(key) {
            if (key === "matching_Rec") 
              totalPatientsWithMatchingRecommendations = response[key];
            else if (key === "total_Cohort")
              totalPatients = response[key];
            else if (key === "total_Cohort_New")
              totalPatientsNew = response[key];
            else if (key === "cohort_Disease")
              cohortDisease = response[key];
            else if (key === "cohort_Rec")
              cohortRec = response[key];
          });
          this.setState({
            totalPatientsWithMatchingRecommendations: totalPatientsWithMatchingRecommendations,
            totalPatients: totalPatients,
            totalPatientsNew: totalPatientsNew,
            cohortDisease: cohortDisease,
            cohortRec: cohortRec,
          });
        })
        .catch(error => {
          var msg = String(error);
          this.props.GlobalFunctionDisplaySnackbarMessage(
            msg,
            Configs.snackbarVariants.error
          );
        });
      }
  }

  handleSelectAllPatientsInCohort = () => {
    console.log("view all patients in cohort");
      this.props.history.push(
      '/' +
      'standaloneDataGridScreen?cohortid=' + this.state.cohortBeingReviewedId+ '&disableRecallDefer=0' + '&deferWorkflowId=-1' + '&title=1');
  }

  handleSelectNewPatientsInCohort = () => {
    console.log('view all patients in cohort');
    this.props.history.push(
      '/' +
        'standaloneDataGridScreen?cohortid=' +
        this.state.cohortBeingReviewedId + '&updateTrialRecruitment=1' + 
        '&disableRecallDefer=0' + '&hideExcluded=1' + '&hideNoConsent=0' + '&addNewPatientsToExistingCohort=1'
    );
  };

  QiLinkCohort = () => {
    console.log("QiLinkCohort");
    this.props.history.push(
      '/' +
      'qualityImprovementActivityform');
  }


  renderConfirmUserAction = () => {
    return <div>{this.state.showConfirmActionMessage}</div>;
  };
  handleTogglebuttonClick = (event) => {
    //console.log('toggle button value set to:', event);
    this.setState({ toggleButtonValue: event });
  };
  render() {
    let renderGraphLeft = <div></div>;
    let renderGraphRight = <div></div>;
    let renderClickButtonsFilter = <div></div>;
    let renderCohortDescriptions = <div></div>;
    let renderViewAllPatientsInCohort = <div></div>;
    let QiLinkCohort = <div></div>;
    let dropdownx = 0;
    let dropdowny = 0;
    //const classes = useStyles();
    const { classes } = this.props;
    let renderCohortRecommendationsRow = <div></div>;
    //style={{width:'100%', overflow: 'auto'}}
    if (this.state.cohortRec.length > 0) {
      renderCohortRecommendationsRow = <div  style={{maxHeight: '8REM', overflow: 'auto'}}>
        <List>

          {this.state.cohortRec.map((entry) => (
            <ListItemText style={{width:'100%'}}primary={entry} />
            ))
          } 
    </List>
    </div>;
    }
    if (this.state.cohortBeingReviewedName !== Configs.noCohortSelected) {
      renderCohortDescriptions = (
        <div
          key="cohortDetailsKey"
          data-grid={{
            w: 5.9,
            h: 3,
            x: 6,
            y: 0,
            static: true
          }}
        >
          <Paper style={{ height: '100%' }}>
            <div>
              <Button
                variant="outlined"
                color="inherit"
                style={{ float: 'right' }}
                onClick={e => this.setState({ showConfirmAction: true })}
              >
                <span style={{ paddingRight: '0.5REM' }}>
                  <DeleteIcon />
                </span>
                Delete cohort
              </Button>
            </div>
            <div style={{ textAlign: 'left' }}>
              <Typography
                id="cohortdiseasedescription"
                gutterBottom
                style={{ marginLeft: '2REM', fontWeight: 'bolder' }}
              >
                Cohort disease: {this.state.cohortDisease}
              </Typography>
            </div>
            <div style={{ textAlign: 'left' }}>
              <Typography
                id="cohortrecommendationdescription"
                gutterBottom
                style={{ marginLeft: '2REM', fontWeight: 'bolder' }}
              >
                Cohort Criteria: 
                
                  {/*}
                  <ListItem>
                  <ListItemText>test1</ListItemText>
                  <ListItemText>test2</ListItemText>
                  <ListItemText>test3</ListItemText>
                  <ListItemText>test4</ListItemText>
                  <ListItemText>test5</ListItemText>
                  </ListItem>
        */}
                  {renderCohortRecommendationsRow}
                
                
              </Typography>
            </div>
          </Paper>
        </div>
      );

      let cohortWithRecCardBackground = 'white';
      let cohortAllPatientsCardBackground = 'lightgray';
      let cohortNewPatientsCardBackground = 'white';

      if (this.state.toggleButtonValue == '1'){
        cohortWithRecCardBackground = 'lightgray';
        cohortAllPatientsCardBackground = 'white';
      } 
      var widthButtons ='30%'; 
      
      renderClickButtonsFilter = (
        <div
          key="filterNewButtons"
          data-grid={{
            w: 5.9,
            h: 1.75,
            x: 0,
            y: 3.1,
            static: true
          }}
        >
          <Box>
            <Card key="cohortwithrecCard" style={{width:widthButtons, minHeight:'6REM', marginBottom: '1rem', float: 'left', backgroundColor:cohortWithRecCardBackground}}>
              <CardActionArea style={{minHeight:'6REM'}} onClick={e => this.handleTogglebuttonClick('1')}>
                <Box
                  key='cohortwithrecLayout'
                  className="cohortwithrecLayout"
                  style={{ display: "flex", alignItems: "center" }}
                  paddingX={2}
                >
                  <Box key="cohortwithrecIcon">
                    <i class="big user md icon" ></i>
                  </Box>
                  <Box key="cohortwithrecNumber" paddingLeft={1}>
                    <b><font color="#1976d2" size="5">{this.state.totalPatientsWithMatchingRecommendations}</font></b>
                  </Box>
                  <Box key="cohortwithrecDescription" paddingLeft={1}>
                    Patients still with active cohort recommendations
                  </Box>
                </Box>
              </CardActionArea>
            </Card>
            <Card key="cohortallpatientsCard" style={{width:widthButtons, marginLeft:'2REM',minHeight:'6REM', marginBottom: '1rem', float:"left", backgroundColor:cohortAllPatientsCardBackground}}>
              <CardActionArea style={{minHeight:'6REM'}} onClick={e => this.handleTogglebuttonClick('0')}>
                <Box
                  key='reviewCohortLayout'
                  className="reviewCohortLayout"
                  style={{ display: "flex", alignItems: "center" }}
                  paddingX={2}
                >                 
                  <Box key="allPatientsIcon">
                    <i class="big medkit icon"></i>
                  </Box>
                  <Box key="allPatientsNumber" paddingLeft={1}>
                    <b><font color="#1976d2" size="5">{this.state.totalPatients}</font></b>
                  </Box>
                  <Box key="allPatientsDescription" paddingLeft={1}>
                    Patients in the entire cohort
                  </Box>
                </Box>
              </CardActionArea>
            </Card>
          </Box>
          <Box>
            <Card key="cohortnewpatientsCard" style={{ width:widthButtons, marginLeft:'2REM',minHeight:'6REM',float: 'left', backgroundColor:cohortNewPatientsCardBackground, display: "flex", alignItems: "center" }}>
              <CardActionArea style={{minHeight:'6REM'}} onClick={() => this.handleSelectNewPatientsInCohort()}>
                <Box
                  key='cohortnewpatientsLayout'
                  className="cohortnewpatientsLayout"
                  style={{ display: "flex", alignItems: "center" }}
                  paddingX={2}
                >
                  <Box key="cohortwithrecIcon">
                    <i class="big user plus icon" ></i>
                  </Box>
                  <Box key="cohortwithrecNumber" paddingLeft={1}>
                    <b><font color="#1976d2" size="5">{this.state.totalPatientsNew}</font></b>
                  </Box>
                  <Box key="cohortwithrecDescription" paddingLeft={1}>
                  </Box>
                  <Box flex="1" display="flex" flexDirection="row" justifyContent="flex-end">
                    Review and add newly identified patients to cohorts
                  </Box>
                </Box>
              </CardActionArea>
            </Card>
          </Box>
          
        </div>
      );
      renderViewAllPatientsInCohort = (
        <div
          key="viewAllPatientsInCohort"
          data-grid={{
            w: 5,
            h: 1,
            x: 0,
            y: 5,
            static: true
          }}
        >
          <div style={{ height: '100%' }}>
            <h5>
            Patient Status
            </h5>
            <h8>
              Select the appropriate category from above, and then click on the graph bars below to see the patients within that status, or click <a onClick={()=>this.handleSelectAllPatientsInCohort()}> <b style={{cursor:'pointer'}}>here</b> </a>to view all patients.
            </h8>
          </div>
        </div>
      );
      QiLinkCohort = (
        <div
          key="QILink"
          data-grid={{
            w: 5,
            h: 1,
            x: 0,
            y: 15,
            static: true
          }}
        >
          {this.batchSiteConfigurationContext['IsTorch']=='false' &&(
          <div style={{ height: '100%' }}>
            <h8>
              To create or modify a QI activity for this cohort, click <a onClick={()=>this.QiLinkCohort()}> <b style={{cursor:'pointer'}}>here.</b> </a>
            </h8>
          </div>
          )}
        </div>
      );      
      renderGraphLeft = (
        <div key="cohortStageAnalysis">
          <div style={{ height: '100%' }}>
            <ReviewBarGraph props={this} />
          </div>
        </div>
      );
      renderGraphRight = (
        <div
          key="createdCohortPreviewKey"
          data-grid={{
            w: 7,
            h: 18,
            x: 5,
            y: 3,
            static: true
          }}
        >
          <div style={{ height: '100%', width: '100%' }}>
            <ResponsiveReactGridLayout
                key='reviewCohortLayout2'            
              className="reviewCohortLayout2"
              cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
              rowHeight={30}
              onLayoutChange={(layout, layouts) => this.onReviewCohortLayoutChange(layout, layouts)}
              onDrag={this.onDrag}
              onDragStop={this.onDragStop}
              onResizeStart={this.onResizeStart}
              onResizeStop={this.onResizeStop}
              isResizable={false}
            >
              <div
                key="linegraph"
                data-grid={{
                  w: 12,
                  h: 17,
                  x: 0,
                  y: 0,
                  static: true
                }}
              >
                <ReviewLineGRaph props={this} />
              </div>
            </ResponsiveReactGridLayout>
          </div>
        </div>
      );
    }
    else{ //no cohort is selected
      dropdownx = 4;
      dropdowny = 0;
    }

    //console.log("this.state.cohortNamesDictionary",this.state.cohortNamesDictionary);

    var buildSelect = (
      <NativeSelect
        value={this.state.cohortBeingReviewedId}
        onChange={e => this.cohortChanged(e)}
        inputProps={{
          name: 'Cohort',
          id: 'age-native-helper'
        }}
      >
        <option value={-1}>{Configs.noCohortSelected}</option>
        {Object.keys(this.state.cohortNamesDictionary).map(key => (
          <option value={key}>{this.state.cohortNamesDictionary[key].cohortName}</option>
        ))}
      </NativeSelect>
    );
    let CohortDescription = 'No valid cohort selected';
    if (
      this.state.cohortBeingReviewedId !== -1 &&
      Object.keys(this.state.cohortNamesDictionary).length > 0 &&
      this.state.cohortNamesDictionary[this.state.cohortBeingReviewedId] !== undefined
    ) {
      CohortDescription =
        'Created on: ' +
        getDate(
          this.state.cohortNamesDictionary[this.state.cohortBeingReviewedId].cohortDateCreated
        );
    }
    const confirmUserActionDialogFooter = (
      <div>
        <Button
          variant="contained"
          fullWidth
          variant="contained"
          color="primary"
          style={{ margin: '24px 0px 16px' }}
          color="primary"
          onClick={e => this.onDeleteCohortBtn()}
        >
          Confirm
        </Button>
        <Button
          variant="contained"
          fullWidth
          variant="contained"
          color="primary"
          style={{ margin: '24px 0px 16px' }}
          color="primary"
          onClick={e => this.onBtCancelUserActionDialog()}
        >
          Cancel
        </Button>
      </div>
    );
    return (
      <div style={{ marginTop: '0REM' }}>
        <ResponsiveReactGridLayout
                key='reviewCohortLayout3'            
          className="reviewCohortLayout3"
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          rowHeight={45}
          //</div>layouts={this.state.globalSummaryNumberLayout}
          onLayoutChange={(layout, layouts) => this.onReviewCohortLayoutChange(layout, layouts)}
          onDrag={this.onDrag}
          onDragStop={this.onDragStop}
          onResizeStart={this.onResizeStart}
          onResizeStop={this.onResizeStop}
          isResizable={false}
        >
          <div
            key="filterKey"
            data-grid={{
              w: 5.9,
              h: 3,
              x: 0,
              y: 0,
              static: true
            }}
          >
            <Paper style={{ height: '100%' }}>
              <div style={{ textAlign: 'left' }}>
                <Typography
                  id="DropDownMenu"
                  gutterBottom
                  style={{ marginLeft: '2REM', fontWeight: 'bolder' }}
                >
                  Review Cohort
                </Typography>
              </div>
              <div style={{ textAlign: 'left', marginLeft: '2REM' }}>
                <FormControl>
                  <InputLabel htmlFor="age-native-helper">Cohort</InputLabel>
                  {buildSelect}
                  <FormHelperText>{CohortDescription}</FormHelperText>
                </FormControl>
              </div>
            </Paper>
          </div>

          {renderCohortDescriptions}
          {renderClickButtonsFilter}
          {renderViewAllPatientsInCohort}
          {/*}
          <div
            key="viewallpatients"
            data-grid={{
              w: 5.9,
              h: 2,
              x: 0,
              y: 3,
              static: true
            }}
          >
          {renderViewAllPatientsInCohort}
          </div>
          */}
          <div
            key="filterBarGraph"
            data-grid={{
              w: 5.9,
              h: 6,
              x: 0,
              y: 6,
              static: true
            }}
          >
            {renderGraphLeft}
          </div>
          {QiLinkCohort}
          <div
            key="filterLineGraph"
            data-grid={{
              w: 5.9,
              h: 5,
              x: 6,
              y: 3.1,
              static: true
            }}
          >
            
            {renderGraphRight}
          </div>
        </ResponsiveReactGridLayout>

        <CDialog
          header="Confirming Action"
          headerIcon={<DeleteIcon />}
          visible={this.state.showConfirmAction}
          footer={confirmUserActionDialogFooter}
          onHide={this.onBtCancelConfirmActionDialog}
        >
          <Typography variant="h6">{this.renderConfirmUserAction()}</Typography>
        </CDialog>
      </div>
    );
  }
}

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem('rgl-8')) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      'rgl-8',
      JSON.stringify({
        [key]: value
      })
    );
  }
}
function calculatePopulationFilterNumbers(params, data) {
  console.log('starting:calculatePopulationFilterNumbers()', data);
  let updatedRecommendedTickBoxTotals = params.state.recommendationTickboxes;
  let updatedTreatmentTickBoxTotals = params.state.treatmentOptimisationTickboxes;
  let total = 'total';
  updatedRecommendedTickBoxTotals[Configs.atRiskTickbox][total] =
    data[Configs.RecommendationCategoriesRiskMapping.Ckd_Rec_AtRisk];
  updatedRecommendedTickBoxTotals[Configs.possibleTickbox][total] =
    data[Configs.RecommendationCategoriesRiskMapping.Ckd_Rec_Possible];
  updatedRecommendedTickBoxTotals[Configs.codedDiagnosisTickbox][total] =
    data[Configs.RecommendationCategoriesRiskMapping.Ckd_Rec_CdCkd];
  updatedTreatmentTickBoxTotals[Configs.initiateAceArbTickbox][total] =
    data[Configs.RecommendationCategoriesRiskMapping.Ckd_Rec_InitiateAceArb];
  updatedTreatmentTickBoxTotals[Configs.intensifyAceArbTickbox][total] =
    data[Configs.RecommendationCategoriesRiskMapping.Ckd_Rec_IntensifyAceArb];
  updatedTreatmentTickBoxTotals[Configs.initiateStatinTickbox][total] =
    data[Configs.RecommendationCategoriesRiskMapping.Ckd_Rec_InitiateStatin];
  updatedTreatmentTickBoxTotals[Configs.initiateAceArbBpInRangeTickbox][total] =
    data[Configs.RecommendationCategoriesRiskMapping.Ckd_Rec_InitiateAceArbBpOk];
  params.setState({
    recommendationTickboxes: updatedRecommendedTickBoxTotals,
    treatmentOptimisationTickboxes: updatedTreatmentTickBoxTotals
  });
}

function createFHTAgeDbQueryString(arrayOfAge) {
  return arrayOfAge[0] + '_rangeTo_' + arrayOfAge[1];
}
function getDate(params) {
  // eslint-disable-next-line
  if (params == null || params == undefined) return '';
  let arr = params.split('T')[0].split('-');
  let convertDate = `${arr[2]}-${arr[1]}-${arr[0]}`;
  // return params.split("T")[0];
  return convertDate;
}
export default withStyles(dashboardStyle, useStyles)(CohortReviewScreen);
