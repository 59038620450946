import React, { Component } from "react";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers/';
import DeleteIcon from '@material-ui/icons/Delete';
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  createTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles/';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import FhtFetchUrl from "Utils/FhtFetchUrl";
import Configs from "Configuration";
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const muiTheme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1em"
      }
    }
  }
});

const useStyles = theme => ({
  formDetail: {
    width: '100%',
    marginTop: '2REM'
  },
  centerComponents: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

class QIActivityRecommendations extends Component {
  constructor(props) {
    super(props);
    this.recommendations = [];
    this.state = {
      diseaseDropdownbox: this.props.diseaseDropdownbox,
      selectedDiseaseDropdown: this.props.selectedDiseaseDropdown,
      recommendationCheckbox: {},
      selectedRecommendations: []
    }
    this.getRecommendations = this.getRecommendations.bind(this);
    this.getActivityRecommendation = this.getActivityRecommendation.bind(this);
  }
  componentDidMount() {
    this.getRecommendations();
  }

  getRecommendations = () => {
    var recommendationCheckbox = {}
    const api = FhtFetchUrl(Configs.getQiRecommendations);
    api.get().then(response => {
      this.recommendations = response;
      for (var i = 0; i < response.length; i++) {
        if (response[i].fhtEnabledAnalysisDiseaseId in recommendationCheckbox == false) {
          recommendationCheckbox[response[i].fhtEnabledAnalysisDiseaseId] = [{
            recommendationDiseaseID: response[i].fhtEnabledAnalysisDiseaseId,
            recommendationTestID: response[i].fhtEnabledAnalysisTestId,
            recommendationDescription: response[i].fhtEnabledAnalysisDescription,
            checkBox: false
          }]
        } else {
          recommendationCheckbox[response[i].fhtEnabledAnalysisDiseaseId].push({
            recommendationDiseaseID: response[i].fhtEnabledAnalysisDiseaseId,
            recommendationTestID: response[i].fhtEnabledAnalysisTestId,
            recommendationDescription: response[i].fhtEnabledAnalysisDescription,
            checkBox: false
          })
        }
      }
      this.setState({
        recommendationCheckbox: recommendationCheckbox
      }, () => {
        this.getActivityRecommendation(this.props.qiActivityId)
      });
    });
  }
  // Copied from QIActivityScreenMainPage for now.
  fetchData = async (urlToQuery) => {
    const api = FhtFetchUrl(urlToQuery);
    const fetched = await api.get();
    return fetched;
  }

  getActivityRecommendation = async (activityId) => {
    const response = await this.fetchData(`${Configs.getActivityRecommendations}/${activityId}`);
    let tmp = [];
    response.map(r => {
      let item = this.recommendations.find(obj => obj.fhtEnabledAnalysisTestId === r.qiActivityRecommendationAnalysisId);
      // If item is undefined, the recommendation has been removed from FhtEnabledAnalysis.
      if (item != undefined)
        tmp.push(item);
    });

    // Turn all recommendationCheckbox to false by default
    var deepCopyRecommendationCheckBox = JSON.parse(JSON.stringify(this.state.recommendationCheckbox));
    Object.keys(deepCopyRecommendationCheckBox).map(key => {
      for (var i = 0; i < deepCopyRecommendationCheckBox[key].length; i++) {
        deepCopyRecommendationCheckBox[key][i].checkBox = false
      }
    });

    // Set selected recommendations for QI to true.
    for (var i = 0; i < tmp.length; i++) {
      if (tmp[i].fhtEnabledAnalysisDiseaseId in deepCopyRecommendationCheckBox == true) {
        deepCopyRecommendationCheckBox[tmp[i].fhtEnabledAnalysisDiseaseId]
          .filter(x => x.recommendationDescription == tmp[i].fhtEnabledAnalysisDescription)[0]
          .checkBox = true;
      }
    }

    this.setState({
      recommendationCheckbox: deepCopyRecommendationCheckBox,
      selectedRecommendations: tmp
    }, () => {
      // Update parent state if nothing selected
      this.props.updateParentSelectedRecommendations(false, tmp);
    })
  }

  handleRecommendationCheckbox = (event, algorithmSelected) => {
    var deepCopyRecommendationCheckBox = JSON.parse(JSON.stringify(this.state.recommendationCheckbox))
    deepCopyRecommendationCheckBox[algorithmSelected.recommendationDiseaseID]
      .filter(x => x.recommendationDescription == algorithmSelected.recommendationDescription)[0]
      .checkBox = event;
    this.setState({
      recommendationCheckbox: deepCopyRecommendationCheckBox
    })

    var allSelectedRecommendations = [];
    for (var key in deepCopyRecommendationCheckBox) {
      for (var i = 0; i < deepCopyRecommendationCheckBox[key].length; i++) {
        if (deepCopyRecommendationCheckBox[key][i].checkBox == true) {
          var selectecRecommendationData = this.recommendations.filter(x => x.fhtEnabledAnalysisDescription == deepCopyRecommendationCheckBox[key][i].recommendationDescription)[0]
          allSelectedRecommendations.push(selectecRecommendationData)
        }
      }
    }
    var recommendationChanged = true;
    this.props.updateParentSelectedRecommendations(recommendationChanged, allSelectedRecommendations);
    this.setState({
      selectedRecommendations: allSelectedRecommendations
    })
  }

  handleDiseaseDropdownbox = (event) => {
    var selectedDiseaseName = event.target.value;
    var selectedDiseaseID = 0;
    {
      Object.keys(this.state.diseaseDropdownbox).map((key, index) => (
        this.state.diseaseDropdownbox[key] == selectedDiseaseName ?
          selectedDiseaseID = key : null
      ))
    }
    this.setState({
      selectedDiseaseDropdown: selectedDiseaseID
    })
  }

  renderDiseaseDropdownChoice() {
    const { classes } = this.props;
    if (Object.keys(this.state.diseaseDropdownbox).length == 0) {
      return (
        <div className={classes.centerComponents}>
          Loading...
          <CircularProgress />
        </div>
      )
    } else {
      return (
        <FormControl className={classes.formDetail} variant="outlined">
          <InputLabel id="conditionsToTarget">Conditions to target</InputLabel>
          <Select labelId="conditionsToTarget" label="conditionsToTarget"
            value={this.state.diseaseDropdownbox[this.state.selectedDiseaseDropdown]}
            onChange={this.handleDiseaseDropdownbox}
            MenuProps={MenuProps}>
            {Object.keys(this.state.diseaseDropdownbox).map((key, index) => (
              <MenuItem key={index} value={this.state.diseaseDropdownbox[key]}>{this.state.diseaseDropdownbox[key]}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    }
  }

  renderRecommendationCheckbox() {
    const { classes } = this.props;
    if (Object.keys(this.state.recommendationCheckbox).length == 0) {
      return (
        <div className={classes.centerComponents}>
          Loading...
          <CircularProgress />
        </div>
      )
    } else {
      return (
        <div>
          {this.state.recommendationCheckbox[this.state.selectedDiseaseDropdown].map((algo) => {
            return (
              <Grid key={algo.recommendationTestID} item xs>
                <FormControlLabel
                  style={{ display: "table" }}
                  control={
                    <div style={{ display: "table-cell" }}>
                      <Checkbox
                        checked={algo.checkBox}
                        onChange={(event) => {
                          this.handleRecommendationCheckbox(event.target.checked, algo)
                        }}
                      /></div>}
                  label={
                    algo.recommendationDescription
                  }
                />
              </Grid>
            )
          })}
        </div>
      )
    }
  }

  renderSelectedRecommendations() {
    var selectedRecommendationRender = [];
    for (var i = 0; i < this.state.selectedRecommendations.length; i++) {
      var recData = this.state.selectedRecommendations[i]
      if (recData.fhtEnabledAnalysisDiseaseId in this.state.diseaseDropdownbox) {
        selectedRecommendationRender.push(
          <div key={recData.fhtEnabledAnalysisTestId}>
            {recData.fhtEnabledAnalysisDescription}
          </div>
        )
      }
    }

    return (
      <div>
        {selectedRecommendationRender}
      </div>
    )
  }

  render() {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {this.renderDiseaseDropdownChoice()}
            {this.renderRecommendationCheckbox()}
          </Grid>
          <Grid item xs={6}>
            <div style={{ paddingTop: "3REM" }}>
              <InputLabel id="recommendation">Selected recommendations</InputLabel>
              {this.renderSelectedRecommendations()}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}


export default withStyles(useStyles)(QIActivityRecommendations);