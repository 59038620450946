import React from 'react';
import {
  createTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles/';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import Tooltip from '@material-ui/core/Tooltip';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers/';

import Configs from 'Configuration';
import FhtFetchUrl from 'Utils/FhtFetchUrl';
import AlgorithmSiteConfigurationContext from 'Utils/AlgorithmSiteConfigurationContext.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';

import QIActivityReflections from './QIActivityComponents/QIActivityReflections';
import QIActivityActions from './QIActivityComponents/QIActivityActions';
import QIActivityCohorts from './QIActivityComponents/QIActivityCohorts';
import QIActivityRecommendations from './QIActivityComponents/QIActivityRecommendations';
import QIActivityParticipants from './QIActivityComponents/QIActivityParticipants';
import QIActivityFacilitator from './QIActivityComponents/QIActivityFacilitator';
import QIActivityToolBar from './QIActivityComponents/QIActivityToolBar';

const defaultTheme = createTheme();
const muiTheme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1em"
      }
    }
  }
});

const useStyles = theme => ({
  centerComponents: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  subContainer: {
    textAlign: 'center',
    padding: '2REM 3REM'
  },
  formDetail: {
    width: '100%',
    marginTop: '2REM'
  },
  commonTopMargin: {
    marginTop: '2REM',
    textAlign: 'center'
  },
  formContainer: {
    marginTop: '2REM',
    padding: '3REM'
  },
  inputText: {
    textAlign: 'left'
  },
  tip: {
    fontSize: 'smaller',
    textAlign: 'left',
    marginTop: '1REM'
  },
});

class QIActivityScreenDetail extends React.Component {
  constructor(props) {
    super(props);
    this.algorithmSiteConfigurationContext = AlgorithmSiteConfigurationContext()
    this.globalMessage = props.GlobalFunctionDisplaySnackbarMessage;
    this.myRef = React.createRef();
    this.pdfData = {};
    this.successRate = { 0: 'Successful', 1: 'Unsuccessful', 2: 'Unsure' };
    this.status = ['In Planning', 'In Progress', 'Completed', 'Cancelled', 'Proposed'];
    this.cohortObjs = {};
    this.participants = [];
    this.cohorts = [];
    this.actionChanged = false;
    this.recommendationChanged = false;
    this.participantChanged = false;
    this.standardActions = [];
    this._activity = {};
    this._reflections = [];
    this.pageOptions = {
      pageSize: 10,
      pageSizeOptions: [10, 20, 30]
    };
    this._participantReady = false;
    this._recommendationReady = false;
    this._reflectionReady = false;
    this._actionReady = false;
    this.state = {
      summary: true,
      participantColumns: [
        { title: 'Name', field: 'qiPaticipantEnabledName', lookup: {} },
        { title: "Email", field: "qiPaticipantEnabledEmail", editable: 'never' },
        { title: "Role", field: "qiPaticipantEnabledRoleName", editable: 'never' }
      ],
      standardActionColumns: [
        { title: "Description", field: 'qiActivityActionDescriptionId', lookup: {}, width: '35%' },
        { title: "Comment", field: 'qiActivityActionComment', width: '35%' },
        { title: "Champion", field: 'qiActivityActionChampionName', lookup: {}, width: '10%' },
        { title: "Delegate", field: 'qiActivityActionDelegateName', lookup: {}, width: '10%' },
        // { title: "Success Rate", field: 'successRate',  lookup: this.successRate, width: '10%' },
      ],
      customActionColumns: [
        { title: "Description", field: 'qiActivityActionDescription', width: '30%' },
        { title: "Comment", field: 'qiActivityActionComment', width: '30%' },
        { title: "Champion", field: 'qiActivityActionChampionName', lookup: {}, width: '15%' },
        { title: "Delegate", field: 'qiActivityActionDelegateName', lookup: {}, width: '15%' },
        // { title: "Success Rate", field: 'successRate', lookup: this.successRate , width: '10%' },
      ],
      pdfReady: false,
      activity: this.props.selectedActivity,
      actions: {
        standard: [],
        custom: []
      },
      selectedParticipants: [],
      selectedRecommendations: [],
      selectedCohortsForQIActivity: [],
      reflections: [],
      title: "",
      participantReady: false,
      actionReady: false,
      reflectionReady: false,
      recommendationReady: false,
      activityReady: false,
      diseaseDropdownbox: {},
      selectedDiseaseDropdown: 0,
      recommendationCheckbox: {},
      diseaseDropdownReady: false,
      finishedLoadingData: false,
    };
    this.getActiveCohortsForCurrentActivity = this.getActiveCohortsForCurrentActivity.bind(this);
    this.getActivityParticipants = this.getActivityParticipants.bind(this);
    this.getActivityReflections = this.getActivityReflections.bind(this);
    this.getActivityActions = this.getActivityActions.bind(this);
    this.getSelectedDiseaseDropdownData = this.getSelectedDiseaseDropdownData.bind(this);
  }

  componentDidMount() {
    // Order of these fetch functions matters, as subsequent ones may require
    // data from previous fetches.
    this.getEnabledParticipants();
    this.getCohorts();
    this.getActivityReflections(this.props.selectedActivity.qiActivityId);
    this.getActivityActions(this.props.selectedActivity.qiActivityId);
    this.getSelectedDiseaseDropdownData();
    this.getStandardActions();
  }

  getCohorts = () => {
    const api = FhtFetchUrl(Configs.getCohortForQi);
    api.get().then(response => {
      let cohort = {};
      response.map(r => {
        cohort[r.cohortId] = r.cohortName;
      });
      this.setState({
        cohorts: response,
        cohortObjs: cohort
      }, () => {
        this.getActiveCohortsForCurrentActivity(this.props.selectedActivity.qiActivityId);
      });
    });
  }

  getEnabledParticipants = () => {
    const api = FhtFetchUrl(Configs.getParticipants);
    let participants = {};
    let activeParticipants = {};
    let actionPaticipants = {};
    actionPaticipants[0] = "-";
    api.get().then(response => {
      response.map(r => {
        participants[r.qiPaticipantEnabledId] = (r.qiPaticipantEnabledActive === 1) ? r.qiPaticipantEnabledName : `${r.qiPaticipantEnabledName}[Inactive]`;
        actionPaticipants[r.qiPaticipantEnabledId] = (r.qiPaticipantEnabledActive === 1) ? r.qiPaticipantEnabledName : `${r.qiPaticipantEnabledName}[Inactive]`;
        r.qiPaticipantEnabledRoleName = this.props.roleObjs[r.qiPaticipantEnabledRoleId];
      });
      this.participantObjs = participants;
      this.participants = response;
      let columns = this.state.participantColumns;
      columns[0].lookup = participants;
      let standardActionColumn = this.state.standardActionColumns;
      standardActionColumn[2].lookup = actionPaticipants;
      standardActionColumn[3].lookup = actionPaticipants;
      let customActionColumn = this.state.customActionColumns;
      customActionColumn[2].lookup = actionPaticipants;
      customActionColumn[3].lookup = actionPaticipants;
      this.getActivityParticipants(this.props.selectedActivity.qiActivityId, response);
    })
  }

  getStandardActions = () => {
    const api = FhtFetchUrl(Configs.getStandardActions);
    try {
      api.get().then(response => {
        this.standardActions = response;
        console.log("getStandardActions", response);
        let actions = {};
        response.map(r => {
          actions[r.qiActivityStandardActionId] = r.qiActivityStandardActionDesc;
        });
        let standardActionColumn = this.state.standardActionColumns;
        standardActionColumn[0].lookup = actions;
        this.setState({ standardActionColumns: standardActionColumn });
      });
    } catch (error) {
      console.log("getStandardActions error", error)
    }
  }

  getSelectedDiseaseDropdownData() {
    // Portion of code obtained from CreateCohortScreenReworkedUI
    // Ensures we only show the diseaseIDs that are shown for this site.
    let dropdownDiseaseIds = this.algorithmSiteConfigurationContext['CreateCohortDropDownDiseaseUI'].split(',');
    const api2 = FhtFetchUrl("/getAllCreateCohortUIDescriptions2");
    api2.get().then(response => {
      console.log("getRecommendations2 response", response)
      let diseaseDropdownbox = {};
      var allDiseaseIDsForSite = [];
      //fhtEnabledAnalysisTestId
      Object.keys(response.returnObj.uiDropDownDiseaseDict).map(key => {
        if (dropdownDiseaseIds.includes(key)) {
          allDiseaseIDsForSite.push(key)
          diseaseDropdownbox[key] = response.returnObj.uiDropDownDiseaseDict[key];
        }
      });
      console.log("getRecommendations2 diseaseDropdownbox", diseaseDropdownbox)
      this.setState({
        diseaseDropdownbox: diseaseDropdownbox,
        selectedDiseaseDropdown: allDiseaseIDsForSite[0],
        diseaseDropdownReady: true,
        finishedLoadingData: true
      })
    });
  }

  getActivityActions = (activityId) => {
    const api = FhtFetchUrl(`${Configs.getActivityActions}/${activityId}`);
    api.get().then(response => {
      this.setActivityActions(response);
    });
  }

  getActiveCohortsForCurrentActivity(qiActivityId) {
    // If we have a qiActivityId associated with Cohorts then this try works.
    // If the dictionary does not have the qiActivityId we catch it and do nothing,
    // leaving the selectedCohortsForQIActivity as a blank array.
    try {
      var currentQIActivityAndCohortsIDDictionary = this.props.currentQIActivityAndCohortsID
      var cohortsForCurrentActivity = currentQIActivityAndCohortsIDDictionary[qiActivityId]
      var allCohortsArray = this.state.cohorts
      var arrayForAllCohorts = []
      for (var i = 0; i < cohortsForCurrentActivity.length; i++) {
        for (var j = 0; j < allCohortsArray.length; j++) {
          if (allCohortsArray[j].cohortId == cohortsForCurrentActivity[i].qiActivityToCohortsCohortsID) {
            arrayForAllCohorts.push(allCohortsArray[j])
          }
        }
      }
      this.setState({
        selectedCohortsForQIActivity: arrayForAllCohorts
      })
    } catch (error) {
      console.log("getActiveCohortsForCurrentActivity error", error)
    }
  }

  getActivityParticipants = (activityId, activityParticipants) => {
    const api = FhtFetchUrl(`${Configs.getActivityParticipants}/${activityId}`);
    let tmp = [];
    api.get().then(response => {
      response.map(r => {
        try {
          let item = {};
          let obj = activityParticipants.find(x => x.qiPaticipantEnabledId === r.qiActivityParticipantEnabledId);
          item.qiPaticipantEnabledRoleName = this.props.roleObjs[obj.qiPaticipantEnabledRoleId] || "";
          item.qiPaticipantEnabledId = r.qiActivityParticipantEnabledId;
          item.qiPaticipantEnabledName = r.qiActivityParticipantEnabledId.toString();
          item.qiPaticipantEnabledEmail = obj.qiPaticipantEnabledEmail || "";
          item.qiPaticipantEnabledFullName = obj.qiPaticipantEnabledName;
          item.qiPaticipantEnabledAccrmId = obj.qiPaticipantEnabledAccrmId;
          item.qiPaticipantEnabledRACGPNumber = obj.qiPaticipantEnabledRACGPNumber;
          item.qiActivityParticipantId = r.qiActivityParticipantId;
          tmp.push(item);
        } catch {
          // Do nothing, unable to find participant returning null value.
          // Prevents page from becoming unclickable if this occurs.
        }
      });
      this.setState({ selectedParticipants: tmp });
      // this.setState({ selectedParticipants: tmp }, () => { this.setState({ participantReady: true }) });
    })
  }

  getActivityReflections = (activityId) => {
    const api = FhtFetchUrl(`${Configs.getActivityReflections}/${activityId}`);
    api.get().then(response => {
      this.setReflections(response);
    });
  }

  setCurrentActiveCohorts = (activity) => {
    let cohortObj = this.cohorts.find(c => {
      return c.cohortId === activity.qiActivityCohortId;
    });
    return cohortObj;
  }

  setActivityCohort = (activity) => {
    let cohortObj = this.cohorts.find(c => {
      return c.cohortId === activity.qiActivityCohortId;
    });
    return cohortObj || {};
  }

  saveActivity = () => {
    this.setState({ activityReady: false });
    const title = this._activity.qiActivityTitle.value;
    if (title == undefined || title == null || title.trim() === "") {
      this.globalMessage("Please enter activity title", Configs.snackbarVariants.error);
      return;
    }
    let tmp = this.state.activity;
    tmp.qiActivityTitle = title;
    tmp.qiActivityDescription = this._activity.qiActivityDescription != undefined ? this._activity.qiActivityDescription.value : "";
    tmp.qiActivityGoal = this._activity.qiActivityGoal != undefined ? this._activity.qiActivityGoal.value : "";
    tmp.qiActivityAim = this._activity.qiActivityAim != undefined ? this._activity.qiActivityAim.value : "";
    tmp.qiActivityFacilitator = this.state.activity.facilitatorObj ? this.state.activity.facilitatorObj.qiPaticipantEnabledId : 0;
    if (tmp.qiActivityId != undefined || tmp.qiActivityId != null) {
      this.updateActivity(tmp);
    } else {
      this.createActivity(tmp);
    }
  }

  // These functions are required to ensure child components state updates will also
  // update parent states. Parent state updates is required for the PDF download
  // feature as the parent (QIActivityScreenDetail) will collate all states.
  updateParentReflections = (_updatedReflections, updatedReflections) => {
    this._reflections = _updatedReflections;
    this.setState({
      reflections: updatedReflections
    })
  }

  updateParentActions = (actionChangedBool, actionReadyBool, updatedActions) => {
    this.actionChanged = actionChangedBool;
    this.setState({
      actions: updatedActions,
      actionReady: actionReadyBool,
    })
  }

  updateParentSelectedCohorts = (updatedSelectedCohorts) => {
    this.setState({
      selectedCohortsForQIActivity: updatedSelectedCohorts,
    })
  }

  updateParentSelectedParticipants = (participantChangedBool, updatedSelectedParticipants) => {
    this.participantChanged = participantChangedBool;
    this.setState({
      selectedParticipants: updatedSelectedParticipants,
    })
  }

  updateParentSelectedRecommendations = (recommendationChangedBool, updatedSelectedRecommendations) => {
    this.recommendationChanged = recommendationChangedBool;
    this.setState({
      selectedRecommendations: updatedSelectedRecommendations,
    })
  }

  updateParentFacilitator = (updatedFacilitator) => {
    let tmp = this.state.activity;
    tmp.facilitatorObj = updatedFacilitator;
    this.setState({ activity: tmp });
  }

  // Copied and pasted.
  // QIActivityScreenAllActivitiesTable and ScreenDetail use same function.
  setActivityFacilitator = (activity) => {
    let facilitatorObj = this.participants.find(obj => {
      return obj.qiPaticipantEnabledId === activity.qiActivityFacilitator;
    });
    return facilitatorObj || {};
  }

  updateActivity = (obj) => {
    const api = FhtFetchUrl(Configs.updateActivity);
    api.put(obj.qiActivityId, obj).then(response => {
      this.globalMessage("QI Activity saved", Configs.snackbarVariants.success);
      let sd = response.qiActivityStartDate ? new Date(response.qiActivityStartDate) : null;
      let ed = response.qiActivityEndDate ? new Date(response.qiActivityEndDate) : null;
      // response.cohortObj = this.setActivityCohort(response);
      response.facilitatorObj = this.setActivityFacilitator(response);
      // response.cohortName = this.cohortObjs[response.qiActivityCohortId];
      // response.cohortName = " ";
      response.title = response.qiActivityTitle == null ? " " : response.qiActivityTitle;
      response.description = response.qiActivityDescription == null ? " " : response.qiActivityDescription;
      response.goal = response.qiActivityGoal === null ? " " : response.qiActivityGoal;
      response.aim = response.qiActivityAim === null ? " " : response.qiActivityAim;
      response.startDate = sd != null ? sd.getDate() + "/" + (sd.getMonth() + 1) + "/" + sd.getFullYear() : "";
      response.endDate = ed != null ? ed.getDate() + "/" + (ed.getMonth() + 1) + "/" + ed.getFullYear() : "";
      this.updateQIActivityAndCohorts(response.qiActivityId);
      this.updateParticipants(response.qiActivityId);
      this.updateReflections(response.qiActivityId);
      this.updateRecommendations(response.qiActivityId);
      this.updateAction(response.qiActivityId);
      this.setState({ activity: response }, () => {
        this.setState({ activityReady: true });
      });
    });
  }

  updateAction = (activityId) => {
    if (this.actionChanged) {
      this.setState({ actionReady: false });
      let actions = this.state.actions.standard.concat(this.state.actions.custom);
      actions.map(item => {
        item.qiActivityActionActivityId = activityId;
        item.qiActivityActionComment = item.qiActivityActionComment || "";
        item.qiActivityActionChampionId = parseInt(item.qiActivityActionChampionName, 10) || 0;
        item.qiActivityActionDelegateId = parseInt(item.qiActivityActionDelegateName, 10) || 0;
        item.qiActivityActionSuccessRate = this.successRate[parseInt(item.successRate, 10)] || "";
        if (item.qiActivityActionDescriptionId) {
          item.qiActivityActionDescription = this.standardActions.find(x => x.qiActivityStandardActionId === parseInt(item.qiActivityActionDescriptionId, 10)).qiActivityStandardActionDesc;
          item.qiActivityActionStandardId = parseInt(item.qiActivityActionDescriptionId, 10);
        }
      });
      const api = FhtFetchUrl(Configs.updateActivityActions);
      api.put(activityId, actions).then(response => {
        this.actionChanged = false;
        this.setActivityActions(response);
      });
    } else {
      this.setState({ actionReady: true });
    }
  }

  setActivityActions = (data) => {
    let standard = [];
    let custom = [];
    data.map(d => {
      let item = d;
      let champion = this.participants.find(x => x.qiPaticipantEnabledId === d.qiActivityActionChampionId) || {};
      let delegate = this.participants.find(x => x.qiPaticipantEnabledId === d.qiActivityActionDelegateId) || {};
      item.qiActivityActionChampion = champion;
      item.qiActivityActionDelegate = delegate;
      item.qiActivityActionChampionName = item.qiActivityActionChampionId > 0 ? item.qiActivityActionChampionId.toString() : "";
      item.qiActivityActionDelegateName = item.qiActivityActionDelegateId > 0 ? item.qiActivityActionDelegateId.toString() : "";
      item.successRate = Object.keys(this.successRate).find(key => this.successRate[key] === item.qiActivityActionSuccessRate);
      if (d.qiActivityActionStandardId > 0) {
        item.isStandardAction = true;
        item.qiActivityActionDescriptionId = item.qiActivityActionStandardId.toString();
        item.qiActivityActionDesc = this.standardActions.find(x => x.qiActivityStandardActionId === d.qiActivityActionStandardId);
        standard.push(item);
      } else {
        custom.push(item);
      }
    });
    let action = { standard, custom }
    this.setState({ actions: action }, () => { this.setState({ actionReady: true }) });
  }

  updateRecommendations = (activityId) => {
    if (this.recommendationChanged) {
      this.setState({ recommendationReady: false });
      let tmp = [];
      this.state.selectedRecommendations.map(r => {
        let item = {};
        item.qiActivityRecommendationActivityId = activityId;
        item.qiActivityRecommendationAnalysisId = r.fhtEnabledAnalysisTestId;
        tmp.push(item);
      });
      const api = FhtFetchUrl(Configs.saveActivityRecommendations);
      api.put(activityId, tmp).then(response => {
        this.recommendationChanged = false;
        this.setState({ recommendationReady: true });
      });
    } else {
      this.setState({ recommendationReady: true });
    }
  }

  updateReflections = (activityId) => {
    this.setState({ reflectionReady: false });
    if (this.state.reflections.length > 0) {
      let tmp = this.state.reflections;
      this._reflections.map((r, index) => {
        tmp[index].qiActivityReflectionActivityId = activityId;
        tmp[index].qiActivityReflectionComment = r.qiActivityReflectionComment != undefined ? r.qiActivityReflectionComment.value : "";
        tmp[index].qiActivityReflectionImprovedMeasure = r.qiActivityReflectionImprovedMeasure != undefined ? r.qiActivityReflectionImprovedMeasure.value : "";
        tmp[index].qiActivityReflectionNotImprovedMeasure = r.qiActivityReflectionNotImprovedMeasure != undefined ? r.qiActivityReflectionNotImprovedMeasure.value : "";
        tmp[index].qiActivityReflectionEnablersBarries = r.qiActivityReflectionEnablersBarries != undefined ? r.qiActivityReflectionEnablersBarries.value : "";
        tmp[index].qiActivityReflectionFailureFactor = r.qiActivityReflectionFailureFactor != undefined ? r.qiActivityReflectionFailureFactor.value : "";
        tmp[index].qiActivityReflectionSuccessFactor = r.qiActivityReflectionSuccessFactor != undefined ? r.qiActivityReflectionSuccessFactor.value : "";
        tmp[index].qiActivityReflectionProposedPracticeChanges = r.qiActivityReflectionProposedPracticeChanges != undefined ? r.qiActivityReflectionProposedPracticeChanges.value : "";
        tmp[index].qiActivityReflectionMBR = r.qiActivityReflectionMBR != undefined ? r.qiActivityReflectionMBR.value : "";
        tmp[index].qiActivityReflectionFutureImprovements = r.qiActivityReflectionFutureImprovements != undefined ? r.qiActivityReflectionFutureImprovements.value : "";
      });
      const api = FhtFetchUrl(Configs.updateReflection);
      api.post(tmp).then(response => {
        this.setReflections(response);
      });
    } else {
      this.setState({ reflectionReady: true });
    }
  }

  setReflections = response => {
    let _reflection = [];
    response.map(r => {
      r.date = r.qiActivityReflectionMeetingDate;
      r.comment = r.qiActivityReflectionComment == null ? " " : r.qiActivityReflectionComment;
      r.improved = r.qiActivityReflectionImprovedMeasure == null ? " " : r.qiActivityReflectionImprovedMeasure;
      r.notImproved = r.qiActivityReflectionNotImprovedMeasure == null ? " " : r.qiActivityReflectionNotImprovedMeasure;
      r.enabler = r.qiActivityReflectionEnablersBarries == null ? " " : r.qiActivityReflectionEnablersBarries;
      r.failure = r.qiActivityReflectionFailureFactor == null ? " " : r.qiActivityReflectionFailureFactor;
      r.success = r.qiActivityReflectionSuccessFactor == null ? " " : r.qiActivityReflectionSuccessFactor;
      r.proposed = r.qiActivityReflectionProposedPracticeChanges == null ? " " : r.qiActivityReflectionProposedPracticeChanges;
      r.future = r.qiActivityReflectionFutureImprovements == null ? " " : r.qiActivityReflectionFutureImprovements;
      r.mbr = r.qiActivityReflectionMBR == null ? " " : r.qiActivityReflectionMBR;
      _reflection.push({});
    });
    this._reflections = _reflection;
    this.setState({ reflections: response }, () => { this.setState({ reflectionReady: true }) });
  }

  updateParticipants = (activityId) => {
    if (this.participantChanged) {
      // this.setState({ participantReady: false });
      let tmp = [];
      this.state.selectedParticipants.map(t => {
        let item = {};
        item.qiActivityParticipantId = t.qiActivityParticipantId;
        item.qiActivityParticipantActivityId = activityId;
        item.qiActivityParticipantEnabledId = t.qiPaticipantEnabledId
        tmp.push(item);
      })
      const api = FhtFetchUrl(Configs.updateActivityParticipants);
      api.put(activityId, tmp).then(response => {
        this.participantChanged = false;
        // this.setState({ participantReady: true });
      });
    } else {
      // this.setState({ participantReady: true });
    }
  }

  updateQIActivityAndCohorts(qiActivityId) {
    var allSelectedCohortsArray = this.state.selectedCohortsForQIActivity;
    var selectedCohortIDArray = []
    for (var i = 0; i < allSelectedCohortsArray.length; i++) {
      selectedCohortIDArray.push(allSelectedCohortsArray[i].cohortId);
    }
    console.log("updateQIActivityAndCohorts selectedCohortIDArray", selectedCohortIDArray);

    let body = {
      qiActivityID: qiActivityId,
      selectedCohortIDArray: selectedCohortIDArray
    };
    const api = FhtFetchUrl(Configs.updateQIActivityAndCohorts);
    api.post(body).then(response => {
      console.log(response);
    });
  }

  createActivity = (obj) => {
    const api = FhtFetchUrl(Configs.createNewActivity);
    api.post(obj).then(response => {
      this.globalMessage("QI Activity saved", Configs.snackbarVariants.success);
      let sd = response.qiActivityStartDate ? new Date(response.qiActivityStartDate) : null;
      let ed = response.qiActivityEndDate ? new Date(response.qiActivityEndDate) : null;
      // response.cohortObj = this.setActivityCohort(response);
      // response.cohortObj = " ";
      response.facilitatorObj = this.setActivityFacilitator(response);
      // response.cohortName = this.cohortObjs[response.qiActivityCohortId];
      // response.cohortName = " ";
      response.title = response.qiActivityTitle == null ? " " : response.qiActivityTitle;
      response.description = response.qiActivityDescription == null ? " " : response.qiActivityDescription;
      response.goal = response.qiActivityGoal === null ? " " : response.qiActivityGoal;
      response.aim = response.qiActivityAim === null ? " " : response.qiActivityAim;
      response.startDate = sd != null ? sd.getDate() + "/" + (sd.getMonth() + 1) + "/" + sd.getFullYear() : "";
      response.endDate = ed != null ? ed.getDate() + "/" + (ed.getMonth() + 1) + "/" + ed.getFullYear() : "";
      this.updateParticipants(response.qiActivityId);
      this.updateReflections(response.qiActivityId);
      this.updateRecommendations(response.qiActivityId);
      this.updateAction(response.qiActivityId);
      this.setState({ activity: response }, () => {
        this.setState({ activityReady: true });
      });
    });
  }

  handleStartDateChange = (date) => {
    let tmp = this.state.activity;
    tmp.qiActivityStartDate = date;
    tmp.minDate = date;
    if (date != null && date != undefined) {
      tmp.startDate = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    } else {
      tmp.startDate = null;
    }
    this.setState({ activity: tmp });
  }

  handleEndDateChange = (date) => {
    let tmp = this.state.activity;
    tmp.qiActivityEndDate = date;
    tmp.maxDate = date;
    if (date != null && date != undefined) {
      tmp.endDate = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    } else {
      tmp.endDate = null;
    }
    this.setState({ activity: tmp });
  }

  handleActivityStatusChange = (e) => {
    let tmp = this.state.activity;
    tmp.qiActivityStatus = e.target.value;
    this.setState({ activity: tmp });
  }

  handleCancelActivity = () => {
    this.props.setSummaryTable();
    this.props.getAllData();
  }

  renderQIActivityDetail() {
    const { classes } = this.props;
    return (
      <div ref={this.myRef}>
        <Grid className={classes.subContainer}>
          <div className={classes.tip}>
            <span>
              <strong>Tip 1 : </strong>Set up your participant list before you start this form. Go to <a href="/manageParticipantList">Maintain Practice QI Participant List</a> to do this
            </span>
          </div>
          <Paper className={classes.formContainer}>

            <h2>Quality Improvement Activity Form</h2>
            <div className={classes.commonTopMargin}>
              <Grid container justify="space-around" spacing={2}>
                <Grid item xs={8}>
                  <Tooltip arrow disableFocusListener disableTouchListener
                    title="Provide a activity title for this QI activity. This is a mandatory section.">
                    <TextField className={classes.formDetail} label="Activity Title" variant="outlined" defaultValue={this.state.activity.title} inputRef={input => (this._activity.qiActivityTitle = input)} />
                  </Tooltip>
                </Grid>
                <Grid item xs={4}>
                  <FormControl className={classes.formDetail} variant="outlined">
                    <InputLabel id="activityStatus">QI Activity Status</InputLabel>
                    <Select className={classes.inputText} labelId="activityStatus" label="QI Activity Status"
                      value={this.state.activity.qiActivityStatus}
                      onChange={this.handleActivityStatusChange}>
                      {this.status.map(status => (
                        <MenuItem key={status} value={status}>{status}</MenuItem>
                      ))}
                    </Select>
                  </FormControl >
                </Grid>
              </Grid>
              <div className={classes.tip}>
                <span>
                  <strong>Tip 2 : </strong>Keep your activity description clear, simple, and precise. Use SMART rules.
                </span>
              </div>
              <Tooltip arrow disableFocusListener disableTouchListener
                title="Provide a short description of the activity you are undertaking.">
                <TextField className={classes.formDetail} label="Activity Description" variant="outlined" multiline rows={5} defaultValue={this.state.activity.description} inputRef={input => (this._activity.qiActivityDescription = input)} />
              </Tooltip>
              <TextField className={classes.formDetail} label="Aims" variant="outlined" multiline rows={5} defaultValue={this.state.activity.aim} inputRef={input => (this._activity.qiActivityAim = input)} />
              <Tooltip arrow disableFocusListener disableTouchListener
                title="What are your SMART goals to achieve your aims?">
                <TextField className={classes.formDetail} label="Goals" variant="outlined" multiline rows={5} defaultValue={this.state.activity.goal} inputRef={input => (this._activity.qiActivityGoal = input)} />
              </Tooltip>

              <QIActivityRecommendations
                diseaseDropdownbox={this.state.diseaseDropdownbox}
                selectedDiseaseDropdown={this.state.selectedDiseaseDropdown}
                updateParentSelectedRecommendations={this.updateParentSelectedRecommendations}
                qiActivityId = {this.props.selectedActivity.qiActivityId}
              />

              <QIActivityParticipants
                participantColumns={this.state.participantColumns}
                selectedParticipants={this.state.selectedParticipants}
                participants={this.participants}
                updateParentSelectedParticipants={this.updateParentSelectedParticipants}
                globalMessage={this.globalMessage}
              />

              <QIActivityFacilitator
                qiPaticipantEnabledId={this.state.activity.facilitatorObj.qiPaticipantEnabledId}
                participants={this.participants}
                updateParentFacilitator={this.updateParentFacilitator}
              />

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker id="startDate" inputVariant="outlined" variant="inline" margin="normal" label="Start Date" value={this.state.activity.qiActivityStartDate} maxDate={this.state.activity.maxDate} format="dd/MM/yyyy" onChange={this.handleStartDateChange} />
                  <KeyboardDatePicker id="endDate" inputVariant="outlined" variant="inline" margin="normal" label="End Date" value={this.state.activity.qiActivityEndDate} minDate={this.state.activity.minDate} format="dd/MM/yyyy" onChange={this.handleEndDateChange} />
                </Grid>
              </MuiPickersUtilsProvider>

              <QIActivityActions
                standardActionColumns={this.state.standardActionColumns}
                actions={this.state.actions}
                customActionColumns={this.state.customActionColumns}
                updateParentActions={this.updateParentActions}
                globalMessage={this.globalMessage}
              />

              <QIActivityCohorts
                getCohorts={this.getCohorts}
                cohorts={this.state.cohorts}
                selectedCohortsForQIActivity={this.state.selectedCohortsForQIActivity}
                updateParentSelectedCohorts={this.updateParentSelectedCohorts}
              />

              <QIActivityReflections
                reflections={this.state.reflections}
                _reflections={this._reflections}
                status={this.status}
                activity={this.state.activity}
                updateParentReflections={this.updateParentReflections}
              />

              {/* Due to styling QIActivityToolBar can be moved anywhere as it will always
                  position to the bottom of the page */}
              <QIActivityToolBar
                activity={this.state.activity}
                selectedRecommendations={this.state.selectedRecommendations}
                diseaseDropdownbox={this.state.diseaseDropdownbox}
                selectedParticipants={this.state.selectedParticipants}
                actions={this.state.actions}
                reflections={this.state.reflections}
                selectedCohortsForQIActivity={this.state.selectedCohortsForQIActivity}
                saveActivity={this.saveActivity}
                handleCancelActivity={this.handleCancelActivity}
              />
            </div>
          </Paper>
        </Grid>
      </div>
    );
  }
  render() {
    const { classes } = this.props;
    // Required as selectedDiseaseDropdown is essential for mapping functions/render to load.
    if (!this.state.finishedLoadingData) {
      return (
        <div className={classes.centerComponents}>
          Loading activity
          <CircularProgress />
        </div>
      )
    } else {
      return (
        <div>
          {this.renderQIActivityDetail()}
        </div>
      )
    }
  }
}

export default withStyles(useStyles)(QIActivityScreenDetail);