import React from 'react';
import {
	withStyles
} from '@material-ui/core/styles/';
import QIActivityScreenDetail from './QIActivityScreenDetail';
import QIActivityScreenAllActivitiesTable from './QIActivityScreenAllActivitiesTable';
import Configs from 'Configuration';
import FhtFetchUrl from 'Utils/FhtFetchUrl';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = theme => ({
	root: {
		textAlign: 'center'
	},
	centerComponents: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
});

// Purpose of this page is to switch between QIActivityScreenAllActivitiesTable and QIActivityScreenDetail
// Ensures we unmount each component when switching, "emptying" states to prevent memory issues.
class QIActivityScreenMainPage extends React.Component {
	constructor(props) {
		super(props);
		this.roleObjs = {};
		this.state = {
			summary: true,
			selectedActivity: {},
			currentQIActivityAndCohortsID: {},
			cohorts: [],
			preLoadedDataFinished: false,
		};
		this.setSummaryTable = this.setSummaryTable.bind(this);
		this.setQIActivityData = this.setQIActivityData.bind(this);
	}

	componentDidMount() {
		this.getAllData();
	}

	// Pass this function into QIActivityScreenDetail to update data/activities 
	// if any changes when "cancelling" activity.
	getAllData = async () => {
		// Need this data to be fetched before rendering page.
		this.setState({
			preLoadedDataFinished: false,
		})

		const rolesResult = await this.fetchData(Configs.getQiRoles);
		rolesResult.map(r => {
			this.roleObjs[r.qiActivityRoleId] = r.qiActivityRoleName;
		});

		const qiActivitiesResult = await this.fetchData(Configs.getQIActivitiesAndCohortsForQi);
		this.setState({
			currentQIActivityAndCohortsID: qiActivitiesResult
		})

		const cohortResult = await this.fetchData(Configs.getCohortForQi);
		let cohort = {};
		cohortResult.map(r => {
			cohort[r.cohortId] = r.cohortName;
		});
		this.setState({
			cohorts: cohortResult,
			cohortObjs: cohort
		})

		this.setState({
			preLoadedDataFinished: true,
		})
	}

	fetchData = async (urlToQuery) => {
		const api = FhtFetchUrl(urlToQuery);
		const fetched = await api.get();
		return fetched;
	}

	setSummaryTable() {
		var showSummaryTable = !this.state.summary
		this.setState({ summary: showSummaryTable });
	}
	
	setQIActivityData(qiActivityData) {
		this.setState({ selectedActivity: qiActivityData });
	}

	render() {
		const { classes } = this.props;

		// 3REM as QIActivityScreenAllActivitiesTable has
		// 2REM (main render) + 1REM (for heading)
		if (!this.state.preLoadedDataFinished) {
			return (
				<div style={{ padding: "3REM" }}>
					<h2 className={classes.root}>Quality Improvement Activities</h2>
					<div className={classes.centerComponents}>
						Loading...
						<CircularProgress />
					</div>
				</div >
			)
		}

		if (this.state.summary) {
			return (
				<div className={classes.root}>
					<QIActivityScreenAllActivitiesTable
						setSummaryTable={this.setSummaryTable}
						setQIActivityData={this.setQIActivityData}
						GlobalFunctionDisplaySnackbarMessage={
							this.props.GlobalFunctionDisplaySnackbarMessage
						}
						roleObjs={this.roleObjs}
						currentQIActivityAndCohortsID={this.state.currentQIActivityAndCohortsID}
						cohorts={this.state.cohorts}
						cohortObjs={this.cohortObjs}
					/>
				</div>
			)
		} else {
			return (
				<div className={classes.root}>
					{/* {this.renderDetails()} */}
					<QIActivityScreenDetail
						selectedActivity={this.state.selectedActivity}
						setSummaryTable={this.setSummaryTable}
						GlobalFunctionDisplaySnackbarMessage={
							this.props.GlobalFunctionDisplaySnackbarMessage
						}
						roleObjs={this.roleObjs}
						currentQIActivityAndCohortsID={this.state.currentQIActivityAndCohortsID}
						getAllData={this.getAllData}
					/>
				</div>
			)
		}
	}
}

export default withStyles(useStyles)(QIActivityScreenMainPage);