import React, { Component } from "react";
import Configs from 'Configuration';
import * as _ from 'underscore';
import Button from '@material-ui/core/Button';
import {
  DashboardPracticeAllRecommendationsLineChart,
  DashboardPracticeTotalDeferralsPerMonthLineChart,
  DashboardPracticeAverageDeferralLengthPerMonthLineChart,
  DashboardPracticeCurrentDeferralAndEndDatesLineChart,
  DashboardPracticeAverageRecallsPerMonthLineChart,
  DashboardPracticeRecallsAndReasonsLineChart
} from "./DashboardPracticeLineGraphs"
import SiteConfigurationContext from "Utils/SiteConfigurationContext.jsx";
import { Dropdown } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import RGL, { WidthProvider } from 'react-grid-layout';
import './DashboardPracticeCSS.css';
import { LineScale } from 'react-pure-loaders';
// import DatePickerChooser from "./DatePicker";
import Paper from '@material-ui/core/Paper';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import Dialog from '@material-ui/core/Dialog';
import Modal from '@material-ui/core/Modal';
import FhtFetchUrl from 'Utils/FhtFetchUrl.jsx';
import Select, { createFilter } from 'react-select'

const ReactGridLayout = WidthProvider(RGL);

class DashboardPracticeScreen extends Component {

  constructor(props) {
    super(props);
    const api = FhtFetchUrl(Configs.getPracticeDashBoard);
    api
      .get()
      .then((response) => {
        console.log("summary response:", response);
        this.changeJSONWebAPIResponse(response);
        this.createFilterOptionsForAllRecommendationsGraph(this.state.jsonWebAPIResponse.shortenedAlgorithmDescriptionToJSON);
        this.createFilterOptionsForRecallGraph(this.state.jsonWebAPIResponse.queryAllRecallMessagesToJSON, "Recall");
        this.setState({
          totalPermanentDeferrals: this.state.jsonWebAPIResponse.currentPermanentMaxCreateEndDate,
          allRecommendationsData: this.state.jsonWebAPIResponse.holdAllCKDandDates
        })
      })
      .catch((e) => {
        console.log("dashboardSummaryCKDNumbers returned errors.", e);
      }
      );

    this.state = {
      // Initialising strings.
      jsonWebAPIResponse: "",

      // A dictionary that each key holds a array value.
      // This is used for easier expansion of filter options available.
      options: {
        // All Recommendations will house recommendation options.
        "All Recommendations": [],
        // Recall will hold any filter options for recall.
        "Recall": []
      },

      // These strings hold all "filter options" a user chooses.
      // Helps to change what data is displayed on the graph.
      currentlyChosenAllRecommendationFilters: "",
      allRecommendationsData: [],

      currentlyChosenRecallFilters: "",

      totalPermanentDeferrals: "",

      currentlyChosenGraphs: "showRecommendationsOnly",

      // The DropdownValue arrays essentially "hard code" what filters are currently selected by the user.
      // These values are changed when users choose new filters, and are reflected in the react Select component
      // for active filters.
      allRecommendationsShowDropdownValue: [],
      allRecallsShowDropdownValue: [],

      // As All Recommendations graph uses 3 recommendations as defaults, this variable is used to record the default
      // three data sets and used if a user removes all filters, for a "reset".
      defaultChosenAllRecommendationsGraphFilters: [],

      // When a user clicks the legend on the recall per month graph, it calls the API to obtain new data dependent on
      // what they have clicked. The setState will use this variable to update the state and graph as required.
      recallsPerMonthByReasonData: [],

      // These variables determine if a graph needs to be expanded.
      isGraphExpanded: false,
      whichGraphToExpand: "",
    };

    // Generic calling of functions.
    console.log("incoming props for aggrid:", this.props);
    //this.props.GlobalFunctionDisplaySnackbarMessage("This is the feedback screen.", Configs.snackbarVariants.success);
    this.changeJSONWebAPIResponse = this.changeJSONWebAPIResponse.bind(this);
    this.ifLoadingJSONWebAPI = this.ifLoadingJSONWebAPI.bind(this);
    this.createFilterOptionsForRecallGraph = this.createFilterOptionsForRecallGraph.bind(this);
    this.createFilterOptionsForAllRecommendationsGraph = this.createFilterOptionsForAllRecommendationsGraph.bind(this);
    this.handleFilterUpdatesChange = this.handleFilterUpdatesChange.bind(this);
    this.handleExpansionOfGraph = this.handleExpansionOfGraph.bind(this);
    this.handleExitingOfGraph = this.handleExitingOfGraph.bind(this);
    this.handleRecallChange = this.handleRecallChange.bind(this);
    this.handleAllRecommendationChange = this.handleAllRecommendationChange.bind(this);

  }

  // This is the "filter options" that are created for the drop down menu.
  createFilterOptionsForAllRecommendationsGraph(dictionaryToParse) {
    var dictionaryToJSON = dictionaryToParse
    var counter = 0
    var defaultGraphFiltersLabelValueArray = []
    var defaultGraphFiltersTextOnlyArray = []
    for (var dictionaryEntry in dictionaryToJSON) {
      if (
        dictionaryToJSON[dictionaryEntry].shortDescription == null
        ||
        dictionaryToJSON[dictionaryEntry].shortDescription == ""
      ) {
        continue
      } else {
        // We create all the drop down options here.
        this.state.options["All Recommendations"].push({
          key: dictionaryToJSON[dictionaryEntry].shortDescription,
          label: dictionaryToJSON[dictionaryEntry].shortDescription,
          value: dictionaryToJSON[dictionaryEntry].shortDescription
        })

        // We create only 3 default recommendations.
        if (counter == 3) {
          // Do nothing
        } else {
          defaultGraphFiltersLabelValueArray.push({
            key: dictionaryToJSON[dictionaryEntry].shortDescription,
            label: dictionaryToJSON[dictionaryEntry].shortDescription,
            value: dictionaryToJSON[dictionaryEntry].shortDescription
          })

          defaultGraphFiltersTextOnlyArray.push(dictionaryToJSON[dictionaryEntry].shortDescription)
          counter = counter + 1
        }
      }
    }
    console.log("this.state.options[All Recommendations]", this.state.options["All Recommendations"])
    this.setState({
      defaultChosenAllRecommendationsGraphFilters: defaultGraphFiltersLabelValueArray,
      currentlyChosenAllRecommendationFilters: defaultGraphFiltersTextOnlyArray + "",
      allRecommendationsShowDropdownValue: defaultGraphFiltersLabelValueArray
    })
  }

  createFilterOptionsForRecallGraph(dictionaryToParse, dictionaryKey) {
    var dictionaryToJSON = dictionaryToParse
    for (var i = 0; i < dictionaryToJSON.length; i++) {
      if (dictionaryToJSON[i] == undefined) {
        continue
      } else {
        //this.state.options[String(dictionaryKey)].push({ key: String(stringToBeParsed), text: String(diseaseType) + ": " + String(stringToBeParsed), value: String(diseaseType) + ": " + String(combinedString) }
        this.state.options[String(dictionaryKey)].push({
          key: i,
          label: String(dictionaryToJSON[i]),
          value: String(dictionaryToJSON[i])
        })
      }
    }
  }

  renderDashboardPracticeAllRecommendationsLineChart(renderExpandButton) {
    return (
      <div style={{ height: 650 }}>
        {renderExpandButton == true &&
          <div
            style={{ float: 'right', paddingRight: '20px', cursor: 'pointer' }}
            onClick={() => { this.handleExpansionOfGraph("DashboardPracticeAllRecommendationsLineChart") }}
          >
            <AspectRatioIcon></AspectRatioIcon>
          </div>}
        <div style={{ height: 550, width: "95%" }}>
          <p align="center"> Patient recommendations over time </p>
          <DashboardPracticeAllRecommendationsLineChart
            parentJSONData={this.state.jsonWebAPIResponse}
            parentAllRecommendationsData={this.state.allRecommendationsData}
            AllDiseaseFiltersChosen={this.state.currentlyChosenAllRecommendationFilters}
            allRecommendationShortDescriptions={this.state.jsonWebAPIResponse.shortenedAlgorithmDescriptionToJSON}
            parentHandleChildResultPatientRecommendationsChange={this.handleFilterUpdatesChange}
            defaultRecommendations={this.state.defaultChosenAllRecommendationsGraphFilters}
          />
          <Select
            isMulti
            onChange={this.handleAllRecommendationChange}
            options={this.state.options["All Recommendations"]}
            value={this.state.allRecommendationsShowDropdownValue}
          />
        </div>
      </div>
    )
  }

  renderDashboardPracticeTotalDeferralsPerMonthLineChart(renderExpandButton, heightWhenNotExpanded) {
    var updatedHeightWhenExpanded = 0
    if (renderExpandButton == false) {
      updatedHeightWhenExpanded = heightWhenNotExpanded - 50
    } else {
      updatedHeightWhenExpanded = heightWhenNotExpanded
    }
    return (
      <div style={{ height: heightWhenNotExpanded }}>
        {renderExpandButton == true &&
          <div
            style={{ float: 'right', paddingRight: '20px', cursor: 'pointer' }}
            onClick={() => { this.handleExpansionOfGraph("DashboardPracticeTotalDeferralsPerMonthLineChart") }}
          >
            <AspectRatioIcon></AspectRatioIcon>
          </div>}
        <div style={{ height: updatedHeightWhenExpanded, width: "95%" }}>
          <p align="center"> Patient deferrals per month </p>
          <DashboardPracticeTotalDeferralsPerMonthLineChart
            parentJSONData={this.state.jsonWebAPIResponse}
          />
        </div>
      </div>
    )
  }

  renderDashboardPracticeAverageDeferralLengthPerMonthLineChart(renderExpandButton, heightWhenNotExpanded) {
    var updatedHeightWhenExpanded = 0
    if (renderExpandButton == false) {
      updatedHeightWhenExpanded = heightWhenNotExpanded - 50
    } else {
      updatedHeightWhenExpanded = heightWhenNotExpanded
    }
    return (
      <div style={{ height: heightWhenNotExpanded }}>
        {renderExpandButton == true &&
          <div
            style={{ float: 'right', paddingRight: '20px', cursor: 'pointer' }}
            onClick={() => { this.handleExpansionOfGraph("DashboardPracticeAverageDeferralLengthPerMonthLineChart") }}
          >
            <AspectRatioIcon></AspectRatioIcon>
          </div>}
        <div style={{ height: updatedHeightWhenExpanded, width: "95%" }}>
          <p align="center"> Average deferral period (in months) per month </p>
          <DashboardPracticeAverageDeferralLengthPerMonthLineChart parentJSONData={this.state.jsonWebAPIResponse} />
        </div>
      </div>
    )
  }
  renderDashboardPracticeCurrentDeferralAndEndDatesLineChart(renderExpandButton, heightWhenNotExpanded) {
    var updatedHeightWhenExpanded = 0
    if (renderExpandButton == false) {
      updatedHeightWhenExpanded = heightWhenNotExpanded - 50
    } else {
      updatedHeightWhenExpanded = heightWhenNotExpanded
    }
    return (
      <div style={{ height: heightWhenNotExpanded }}>
        {renderExpandButton == true &&
          <div
            style={{ float: 'right', paddingRight: '20px', cursor: 'pointer' }}
            onClick={() => { this.handleExpansionOfGraph("DashboardPracticeCurrentDeferralAndEndDatesLineChart") }}
          >
            <AspectRatioIcon></AspectRatioIcon>
          </div>}
        <div style={{ height: updatedHeightWhenExpanded, width: "95%" }}>
          <p align="center"> Current deferral end dates </p>
          <DashboardPracticeCurrentDeferralAndEndDatesLineChart parentJSONData={this.state.jsonWebAPIResponse} />
        </div>
      </div>
    )
  }
  renderDashboardPracticeAverageRecallsPerMonthLineChart(renderExpandButton, heightWhenNotExpanded) {
    var updatedHeightWhenExpanded = 0
    if (renderExpandButton == false) {
      updatedHeightWhenExpanded = heightWhenNotExpanded - 50
    } else {
      updatedHeightWhenExpanded = heightWhenNotExpanded
    }
    return (
      <div style={{ height: heightWhenNotExpanded }}>
        {renderExpandButton == true &&
          <div
            style={{ float: 'right', paddingRight: '20px', cursor: 'pointer' }}
            onClick={() => { this.handleExpansionOfGraph("DashboardPracticeAverageRecallsPerMonthLineChart") }}
          >
            <AspectRatioIcon></AspectRatioIcon>
          </div>}
        <div style={{ height: updatedHeightWhenExpanded, width: "95%" }}>
          <p align="center"> Total Patient recalls per month </p>
          <DashboardPracticeAverageRecallsPerMonthLineChart parentJSONData={this.state.jsonWebAPIResponse} />
        </div>
      </div>
    )
  }

  Option(props) {
    const { onMouseMove, onMouseOver, ...newInnerProps } = props.innerProps;
    return (
      <div {...newInnerProps} className="autoselect-options">
        {props.children}
      </div>
    );
  }

  renderDashboardPracticeRecallsAndReasonsLineChart(renderExpandButton, heightWhenNotExpanded) {
    var updatedHeightWhenExpanded = 0
    if (renderExpandButton == false) {
      updatedHeightWhenExpanded = heightWhenNotExpanded - 100
    } else {
      updatedHeightWhenExpanded = heightWhenNotExpanded
    }

    return (
      <div style={{ height: heightWhenNotExpanded }}>
        {renderExpandButton == true &&
          <div
            style={{ float: 'right', paddingRight: '20px', cursor: 'pointer' }}
            onClick={() => { this.handleExpansionOfGraph("DashboardPracticeRecallsAndReasonsLineChart") }}
          >
            <AspectRatioIcon></AspectRatioIcon>
          </div>}
        <div style={{ height: updatedHeightWhenExpanded, width: "95%" }}>
          <p align="center"> Patient recalls per month by reason </p>
          <DashboardPracticeRecallsAndReasonsLineChart
            parentJSONData={this.state.jsonWebAPIResponse}
            parentRecallsPerMonthByReasonData={this.state.recallsPerMonthByReasonData}
            recallFiltersChosen={this.state.currentlyChosenRecallFilters}
            parentHandleChildResultRecallChange={this.handleFilterUpdatesChange}
          />
          <Select
            isMulti
            onChange={this.handleRecallChange}
            value={this.state.allRecallsShowDropdownValue}
            options={this.state.options["Recall"]}
            filterOption={createFilter({ ignoreAccents: false })}
          />
        </div>
      </div>
    )
  }

  renderExpandedGraph() {
    var graphToExpand = this.state.whichGraphToExpand
    console.log("graphToExpand", graphToExpand)
    if (graphToExpand == "DashboardPracticeAllRecommendationsLineChart") {
      return (
        <div>
          <Dialog
            fullWidth={true}
            maxWidth={"xl"}
            onClose={this.handleExitingOfGraph}
            aria-labelledby="simple-dialog-title"
            open={this.state.isGraphExpanded}
          >
            <div style={{ paddingLeft: "5%", width: "100%" }}>
              {this.renderDashboardPracticeAllRecommendationsLineChart(false)}
            </div>
          </Dialog>
        </div>
      )
    } else if (graphToExpand == "DashboardPracticeTotalDeferralsPerMonthLineChart") {
      return (
        <div>
          <Dialog
            fullWidth={true}
            maxWidth={"xl"}
            onClose={this.handleExitingOfGraph}
            aria-labelledby="simple-dialog-title"
            open={this.state.isGraphExpanded}
          >
            <div style={{ paddingLeft: "5%", width: "100%" }}>
              {this.renderDashboardPracticeTotalDeferralsPerMonthLineChart(false, 650)}
            </div>
          </Dialog>
        </div>
      )
    } else if (graphToExpand == "DashboardPracticeAverageDeferralLengthPerMonthLineChart") {
      return (
        <div>
          <Dialog
            fullWidth={true}
            maxWidth={"xl"}
            onClose={this.handleExitingOfGraph}
            aria-labelledby="simple-dialog-title"
            open={this.state.isGraphExpanded}
          >
            <div style={{ paddingLeft: "5%", width: "100%" }}>
              {this.renderDashboardPracticeAverageDeferralLengthPerMonthLineChart(false, 650)}
            </div>
          </Dialog>
        </div>
      )
    } else if (graphToExpand == "DashboardPracticeCurrentDeferralAndEndDatesLineChart") {
      return (
        <div>
          <Dialog
            fullWidth={true}
            maxWidth={"xl"}
            onClose={this.handleExitingOfGraph}
            aria-labelledby="simple-dialog-title"
            open={this.state.isGraphExpanded}
          >
            <div style={{ paddingLeft: "5%", width: "100%" }}>
              {this.renderDashboardPracticeCurrentDeferralAndEndDatesLineChart(false, 650)}
            </div>
          </Dialog>
        </div>
      )
    } else if (graphToExpand == "DashboardPracticeAverageRecallsPerMonthLineChart") {
      return (
        <div>
          <Dialog
            fullWidth={true}
            maxWidth={"xl"}
            onClose={this.handleExitingOfGraph}
            aria-labelledby="simple-dialog-title"
            open={this.state.isGraphExpanded}
          >
            <div style={{ paddingLeft: "5%", width: "100%" }}>
              {this.renderDashboardPracticeAverageRecallsPerMonthLineChart(false, 650)}
            </div>
          </Dialog>
        </div>
      )
    } else if (graphToExpand == "DashboardPracticeRecallsAndReasonsLineChart") {
      return (
        <div>
          <Dialog
            fullWidth={true}
            maxWidth={"xl"}
            onClose={this.handleExitingOfGraph}
            aria-labelledby="simple-dialog-title"
            open={this.state.isGraphExpanded}
          >
            <div style={{ paddingLeft: "5%", width: "100%" }}>
              {this.renderDashboardPracticeRecallsAndReasonsLineChart(false, 650)}
            </div>
          </Dialog>
        </div>
      )
    }
  }
  handleExitingOfGraph() {
    console.log("closing box")
    this.setState({
      isGraphExpanded: false
    })
  }
  handleExpansionOfGraph(graphToExpand) {
    console.log("opening dialog box")
    console.log("graphToExpand", graphToExpand)
    this.setState({
      isGraphExpanded: true,
      whichGraphToExpand: graphToExpand
    })
  }

  // This displays all the graphs. It uses an if else conditional encase the
  // data is not fully loaded. A "loading screen" is used until it is.
  ifLoadingJSONWebAPI() {
    if (this.state.jsonWebAPIResponse == "") {
      return <div> <LineScale color={'black'} loading={true} /> </div>
    } else {
      if (this.state.currentlyChosenGraphs == "showRecommendationsOnly") {
        return <div align="center">
          <ReactGridLayout className="layout" cols={12} rowHeight={75} width={3800}>
            <div className="searchBar" key="aba" data-grid={{ x: 0, y: 0, w: 12, h: 5.2, static: true, isResizable: false }}>
              <Paper style={{ minHeight: '650px', maxHeight: 'none', paddingTop: '10px' }}>
                {this.renderDashboardPracticeAllRecommendationsLineChart(true)}
              </Paper>
            </div>
          </ReactGridLayout>
        </div>
      } else if (this.state.currentlyChosenGraphs == "showDeferralsOnly") {
        return <div align="center">
          <ReactGridLayout className="layout" cols={12} rowHeight={75} width={1900}>
            <div key="aca" data-grid={{ x: 0, y: 0, w: 6, h: 5, static: true, isResizable: false }}>
              <Paper style={{ minHeight: '450px', maxHeight: 'none', paddingTop: '10px' }}>
                {this.renderDashboardPracticeTotalDeferralsPerMonthLineChart(true, 350)}
              </Paper>
            </div>
            <div key="acb" data-grid={{ x: 6, y: 0, w: 6, h: 5, static: true, isResizable: false }}>
              <Paper style={{ minHeight: '450px', maxHeight: 'none', paddingTop: '10px' }}>
                {this.renderDashboardPracticeAverageDeferralLengthPerMonthLineChart(true, 350)}
              </Paper>
            </div>

            <div key="acc" data-grid={{ x: 0, y: 6, w: 6, h: 5, static: true, isResizable: false }}>
              <Paper style={{ minHeight: '450px', maxHeight: 'none', paddingTop: '10px' }}>
                {this.renderDashboardPracticeCurrentDeferralAndEndDatesLineChart(true, 350)}
              </Paper>
            </div>
          </ReactGridLayout>
        </div>
      } else if (this.state.currentlyChosenGraphs == "showRecallsOnly") {
        return <div align="center">
          <ReactGridLayout className="layout" cols={12} rowHeight={75} width={1900}>
            <div key="ada" data-grid={{ x: 0, y: 0, w: 6, h: 5, static: true, isResizable: false }}>
              <Paper style={{ minHeight: '450px', maxHeight: 'none', paddingTop: '10px' }}>
                {this.renderDashboardPracticeAverageRecallsPerMonthLineChart(true, 350)}
              </Paper>
            </div>
            <div className="searchBar" key="adb" data-grid={{ x: 6, y: 0, w: 6, h: 5, static: true, isResizable: false }}>
              <Paper style={{ minHeight: '450px', maxHeight: 'none', paddingTop: '10px' }}>
                {this.renderDashboardPracticeRecallsAndReasonsLineChart(true, 350)}
              </Paper>
            </div>
          </ReactGridLayout>
        </div>
      }
    }
  }

  // This is called to change the jsonWebAPIResponse of the parent.
  changeJSONWebAPIResponse(newJSONResponse) {
    this.setState({
      jsonWebAPIResponse: newJSONResponse
    })
  }

  // These two handleChanges take in the values that the user chooses in the drop down menu.
  handleAllRecommendationChange(value) {
    console.log("handleAllRecommendationChange", value)
    console.log("handleAllRecommendationChange", value.target)

    this.apiQueryAllRecommendationMessages(value)
  };

  handleRecallChange(value) {
    var allRecallValuesSelectedArray = []
    for (var i = 0; i < value.length; i++) {
      allRecallValuesSelectedArray.push(value[i].label)
    }

    this.apiQueryRecallMessages(allRecallValuesSelectedArray)
  };

  // This handle is passed into the child graph components to manipulate the parent filters.
  // This allows for the legend to be clickable to manipulate the displayed data.
  handleFilterUpdatesChange(value, filterToUpdate) {
    // Possible users will choose no filters but then click the legend.
    // This empty string conditional accounts for this and will remove the clicked legend.
    var chosenFilterToUpdate = ""

    // Delay is required to obtain the most recent filter and base next filters shown off it.
    if (filterToUpdate == "All Recommendations") {
      chosenFilterToUpdate = this.state.currentlyChosenAllRecommendationFilters
    } else if (filterToUpdate == "Recall") {
      chosenFilterToUpdate = this.state.currentlyChosenRecallFilters
    } else if (filterToUpdate == "Deferral") {
      chosenFilterToUpdate = this.state.currentlyChosenDeferFilters
    }

    var chosenFiltersArray = chosenFilterToUpdate.split(",")

    var updatedFiltersTextOnlyArray = []
    var updatedFiltersJSONArray = []
    var updatedFilters = []
    for (var i = 0; i < chosenFiltersArray.length; i++) {
      if (chosenFiltersArray[i] == value) {
        continue
      } else {
        updatedFilters.push(chosenFiltersArray[i])
        updatedFiltersTextOnlyArray.push(chosenFiltersArray[i])
        updatedFiltersJSONArray.push({
          key: chosenFiltersArray[i],
          value: chosenFiltersArray[i],
          label: chosenFiltersArray[i]
        })
      }
    }

    // This determines what happens when all filters are gone.
    // Either show all graph data (using the for loop).
    // Or showing some default common values (eg. All Diseases defaulting to an array).
    if (updatedFilters.length == 0) {
      if (filterToUpdate == "All Recommendations") {
        var textOnly = []
        for (var i = 0; i < this.state.defaultChosenAllRecommendationsGraphFilters.length; i++) {
          textOnly.push(this.state.defaultChosenAllRecommendationsGraphFilters[i].label)
        }
        this.setState({
          currentlyChosenAllRecommendationFilters: textOnly + '',
          allRecommendationsShowDropdownValue: this.state.defaultChosenAllRecommendationsGraphFilters
        });
      } else if (filterToUpdate == "Recall") {
        // For recall, reset everything back to default blank string or array.
        this.setState({
          currentlyChosenRecallFilters: "",
          allRecallsShowDropdownValue: []
        });
      }
    } else {
      if (filterToUpdate == "All Recommendations") {
        this.setState({
          currentlyChosenAllRecommendationFilters: updatedFiltersTextOnlyArray + '',
          allRecommendationsShowDropdownValue: updatedFiltersJSONArray
        });
      } else if (filterToUpdate == "Recall") {
        this.setState({
          currentlyChosenRecallFilters: updatedFiltersTextOnlyArray + '',
          allRecallsShowDropdownValue: updatedFiltersJSONArray
        });
      }
    }
  };

  // When a user clicks a recall legend, it calls this function to update the data as required.
  apiQueryRecallMessages(currentlyChosenRecalls) {
    let body = {
      chosenRecalls: JSON.stringify(currentlyChosenRecalls),
    };
    const api = FhtFetchUrl(Configs.getPracticeDashBoardRecallsPerMonth);
    api
      .post(body)
      .then((response) => {
        console.log("summary response:", response);
        var responseToJSON = response.holdAllRecallReasonAndDataToJSON
        var currentlyChosenRecommendationsTextArray = []
        var currentlyChosenRecommendationsDropDownArray = []

        for (var key in responseToJSON) {
          console.log("key", key)
          currentlyChosenRecommendationsTextArray.push(String(key))

          currentlyChosenRecommendationsDropDownArray.push({
            id: String(key),
            label: String(key),
            value: String(key)
          })
        }
        this.setState({
          recallsPerMonthByReasonData: responseToJSON,
          currentlyChosenRecallFilters: currentlyChosenRecommendationsTextArray + '',
          allRecallsShowDropdownValue: currentlyChosenRecommendationsDropDownArray
        })
      })
      .catch((e) => {
        console.log("dashboardSummaryCKDNumbers returned errors.", e);
      }
      );
  }

  // When a user clicks a all recommendations legend, it calls this function to update the data as required.
  apiQueryAllRecommendationMessages(currentlyChosenRecommendations) {
    let siteConfiguration = SiteConfigurationContext();
    let url;
    url = siteConfiguration[Configs.SiteConfigurationMappingKeys.serverapi] + Configs.getPracticeDashBoardAllRecommendations;

    // This filters what data we want to query for in the DB.
    // Essentially if the data is not currently in the data we have (i.e null), then we query for that one.
    var currentlyChosenRecommendationsArray = []
    for (var i = 0; i < currentlyChosenRecommendations.length; i++) {
      var algID = 0
      for (var j = 0; j < this.state.jsonWebAPIResponse.shortenedAlgorithmDescriptionToJSON.length; j++) {
        if (currentlyChosenRecommendations[i].value == this.state.jsonWebAPIResponse.shortenedAlgorithmDescriptionToJSON[j].shortDescription) {
          algID = this.state.jsonWebAPIResponse.shortenedAlgorithmDescriptionToJSON[j].algorithmID
          var algKey = Object.keys(this.state.jsonWebAPIResponse.mappingAlgNameToIDToJSON).find(algKey => this.state.jsonWebAPIResponse.mappingAlgNameToIDToJSON[algKey] === algID);
          if (this.state.allRecommendationsData[algKey] == undefined) {
            currentlyChosenRecommendationsArray.push(currentlyChosenRecommendations[i].value)
            break
          }
        }
      }
    }

    // allRecommendationsData
    let urlObj = Configs.urlGetObj;

    let dataForServer = {
      chosenRecommendations: JSON.stringify(currentlyChosenRecommendationsArray),
    };

    let manualUrlObj = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'cache-control': 'no-cache',
        // Authorization: 'Bearer ' + FhtMain.GlobalContext.userToken
        // Authorization: 'Bearer ' + AppSequential.GlobalContext.userToken
      },
      body: JSON.stringify(dataForServer)
    };

    fetch(url, manualUrlObj).then(r => r.json())
      .then((response) => {
        console.log("summary response:", response);
        var previousAllRecommendationsData = this.state.allRecommendationsData
        var keyOfResponse = Object.keys(response.holdAllRecommendations)[0]
        var valueOfResponse = response.holdAllRecommendations[keyOfResponse]
        previousAllRecommendationsData[keyOfResponse] = valueOfResponse

        this.setState({
          allRecommendationsData: previousAllRecommendationsData
        })

        var allRecommendationlValuesSelectedArray = []
        var currentlyChosenRecommendationTextOnlyArray = []
        for (var i = 0; i < currentlyChosenRecommendations.length; i++) {
          allRecommendationlValuesSelectedArray.push({
            key: currentlyChosenRecommendations[i].label,
            label: currentlyChosenRecommendations[i].label,
            value: currentlyChosenRecommendations[i].label
          })
          currentlyChosenRecommendationTextOnlyArray.push(currentlyChosenRecommendations[i].label)
        }

        this.setState({
          currentlyChosenAllRecommendationFilters: currentlyChosenRecommendationTextOnlyArray + '',
          allRecommendationsShowDropdownValue: allRecommendationlValuesSelectedArray
        });

        // Introduce a small delay to check current filters. If we remove the three common recommendations the 
        // currentlyChosenAllRecommendationFilters will be blank, and render no current filters. 
        // We set it back to the default values to prevent this.
        setTimeout(function () {
          // Always default back to the three common recommendations.
          if (this.state.currentlyChosenAllRecommendationFilters == "") {
            var onlyRecommendationFilterTextArray = []
            for (var key in this.state.defaultChosenAllRecommendationsGraphFilters) {
              onlyRecommendationFilterTextArray.push(this.state.defaultChosenAllRecommendationsGraphFilters[key].label)
            }
            this.setState({
              currentlyChosenAllRecommendationFilters: onlyRecommendationFilterTextArray + '',
              allRecommendationsShowDropdownValue: this.state.defaultChosenAllRecommendationsGraphFilters
            });
          }
        }.bind(this), 100)
      })
      .catch((e) => {
        console.log("dashboardSummaryCKDNumbers returned errors.", e);
      }
      );
  }

  // This renders the Practice Dashboard Screen
  render() {
    return (
      <div className="practiceDashboardBackgroundColor" align="center">
        {this.renderExpandedGraph()}
        <ReactGridLayout className="layout" cols={12} rowHeight={30} width={1900}>
          <div key="a" data-grid={{ x: 0, y: 0, w: 12, h: 3, static: true, isResizable: true }}>
            <Paper style={{ minHeight: '100px', maxHeight: 'none', paddingTop: '10px' }}>
              <h1> Practice Reports </h1>
              {/* <Button onClick={e => this.setState({ currentlyChosenGraphs: "showAllGraphs" })} variant="outlined" color="primary">Show All Graphs</Button> */}
              <Button onClick={e => this.setState({ currentlyChosenGraphs: "showRecommendationsOnly" })} variant="outlined" color="primary">Recommendations Analysis</Button>
              <Button onClick={e => this.setState({ currentlyChosenGraphs: "showDeferralsOnly" })} variant="outlined" color="primary">Deferral Analysis</Button>
              <Button onClick={e => this.setState({ currentlyChosenGraphs: "showRecallsOnly" })} variant="outlined" color="primary">Recall Analysis</Button>
            </Paper>
          </div>
          <div align="center" key="b" data-grid={{ x: 0, y: 3, w: 12, h: 30, static: true, autoSize: true }}>
            {this.ifLoadingJSONWebAPI()}
          </div>
        </ReactGridLayout>
      </div>
    );
  }
}

export default (DashboardPracticeScreen);