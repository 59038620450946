import React, { Component } from "react";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers/';
import DeleteIcon from '@material-ui/icons/Delete';
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  createTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles/';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import FhtFetchUrl from "Utils/FhtFetchUrl";
import Configs from "Configuration";
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';

const muiTheme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1em"
      }
    }
  }
});

const useStyles = theme => ({
  formDetail: {
    width: '100%',
    marginTop: '2REM'
  },
  tip: {
    fontSize: 'smaller',
    textAlign: 'left',
    marginTop: '1REM'
  },
  inputText: {
    textAlign: 'left'
  },
  chip: {
    margin: 2
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  buttonMargin: {
    marginLeft: '1REM'
  },
});

class QIActivityCohorts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedCohortsForQIActivity: this.props.selectedCohortsForQIActivity,
    }
  }

  handleCohortChange = (e) => {
    this.setState({
      selectedCohortsForQIActivity: e.target.value
    });
    this.props.updateParentSelectedCohorts(e.target.value)
  }

  renderSelectingCohorts() {
    const { classes } = this.props;
    if (this.props.cohorts == undefined) {
      return (
        <div className={classes.centerComponents}>
          Loading...
          <CircularProgress />
        </div>
      )
    } else {
      return (
        <Tooltip arrow disableFocusListener disableTouchListener
          title="Choose from a list of cohorts that you have created that will be assigned to this form.">
          <FormControl className={classes.formDetail} variant="outlined">
            <InputLabel id="cohort">Cohort</InputLabel>
            <Select multiple className={classes.inputText} labelId="cohort" label="Cohort"
              value={this.state.selectedCohortsForQIActivity}
              onChange={this.handleCohortChange}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value.cohortId} label={value.cohortName} className={classes.chip} />
                  ))}
                </div>
              )}>
              {this.props.cohorts.map((r, cIndex) => (
                <MenuItem key={cIndex} value={r}>{r.cohortName}</MenuItem>
              ))}
            </Select>
          </FormControl >
        </Tooltip>
      )
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.tip}>
          <span>
            <strong>Tip 3 : </strong>Set up your <a target="_blank" href="/createCohortScreen">Cohort</a> using the recommendations selected for your QI activity as your filter.
          </span>
        </div>
        {this.renderSelectingCohorts()}
        <div className={classes.formDetail}>
          <Button variant="outlined" target="_blank" href="/createCohortScreen">create new cohort</Button>
          <Button className={classes.buttonMargin} variant="outlined" onClick={() => this.props.getCohorts()}>refresh cohort list</Button>
        </div>
      </div>
    );
  }
}


export default withStyles(useStyles)(QIActivityCohorts);