import React from 'react';
import { withStyles } from '@material-ui/core/styles/';
import MaterialTable from 'material-table';


import Configs from 'Configuration';
import FhtFetchUrl from 'Utils/FhtFetchUrl';

const useStyles = theme => ({
	root:{
		margin: '2REM',
		textAlign: 'center'
	},
	table: {
		margin: '2REM'
	}
});


class QIManagePaticipant extends React.Component {
	constructor(props) {
		super(props);
		this.users = [];
		this.state = {
			tableColumn: [
				{ title: 'Name', field: 'qiPaticipantEnabledName' },
        { title: "Email", field: "qiPaticipantEnabledEmail" },
        { title: "Role", field: "qiPaticipantEnabledRoleId", lookup: {}},
				{ title: "FHT User ID", field: "qiPaticipantEnabledUserId", lookup: {}},
        { title: "RACGP ID", field: "qiPaticipantEnabledRACGPNumber" },
				{ title: "ACCRM ID", field: "qiPaticipantEnabledAccrmId" },
				{ title: "Active", field: "qiPaticipantEnabledActive", lookup: {0: 'No', 1: 'Yes'}}
			], 
			participants: [],
			roles: {},
			pageOptions: {
				pageSize: 10,
				pageSizeOptions: [10, 20, 30]
			}
		}
		this.getQiRole();
		this.getUsers();
		this.getParticipants();
	}

	componentDidMount() {
	}

	getRoleIndex = id => {
		return Object.keys(this.state.roles).find(key => this.state.roles[key] === id);
	}

	getParticipants = () => {
		const api = FhtFetchUrl(Configs.getParticipants);
		api.get().then(response => {
			response.map(r => {
				r.qiPaticipantEnabledEmail = r.qiPaticipantEnabledEmail || "";
				r.qiPaticipantEnabledMobile = r.qiPaticipantEnabledMobile || "";
				r.qiPaticipantEnabledUserId = r.qiPaticipantEnabledUserId > 0 ? r.qiPaticipantEnabledUserId : "";  
				r.qiPaticipantEnabledRoleId = r.qiPaticipantEnabledRoleId > 0 ? r.qiPaticipantEnabledRoleId : "";  
				r.qiPaticipantEnabledRACGPNumber = r.qiPaticipantEnabledRACGPNumber || "";
				r.qiPaticipantEnabledAccrmId = r.qiPaticipantEnabledAccrmId || "";
			});
			this.setState({participants: response});
		});
	}

	getQiRole = () => {
		const api = FhtFetchUrl(Configs.getQiRoles);
		api.get().then(response => {
			let columns = this.state.tableColumn;
			let roles = {};
			roles[0] = "-";
			response.map((r, index) => {
				roles[r.qiActivityRoleId] = r.qiActivityRoleName;
			});
			columns[2].lookup = roles;
			this.setState({tableColumn: columns});
			this.setState({roles: roles});
		});
	}

	getUsers = () => {
		const api = FhtFetchUrl(Configs.getUsers);
		api.get().then(response => {
			console.log(response);
			this.users = response.data;
			let columns = this.state.tableColumn;
			let user = {};
			user[0] = "-";
			response.data.map(r => {
				user[r.id] = `${r.id}: ${r.username}`;
			});
			columns[3].lookup = user;
			this.setState({tableColumn: columns});
		})
	}

	handleRowUpdate = data => {
		let target = {};
		console.log(data);
		const api = FhtFetchUrl(Configs.updateEnablePaticipant);
		Object.assign(target, data);
		if(data.qiPaticipantEnabledUserId === "" || data.qiPaticipantEnabledUserId === "0"){
			target.qiPaticipantEnabledUserId = 0;
			data.qiPaticipantEnabledUserId = "";
		}
		if(data.qiPaticipantEnabledRoleId === "" || data.qiPaticipantEnabledRoleId === "0"){
			target.qiPaticipantEnabledRoleId = 0;
			data.qiPaticipantEnabledRoleId = "";
		}
	  target.qiPaticipantEnabledActive = parseInt(target.qiPaticipantEnabledActive);
		console.log(target);
		api.put(target.qiPaticipantEnabledId, target)
		.then(response => {
			console.log(response);
		});
	}

	handleRowAdd = data => {
		let target = {};
		const api = FhtFetchUrl(Configs.createEnablePaticipant);
		Object.assign(target, data);
		if(data.qiPaticipantEnabledUserId === "" || data.qiPaticipantEnabledUserId === "0"){
			data.qiPaticipantEnabledUserId = "";
		}
		if(data.qiPaticipantEnabledRoleId === "" || data.qiPaticipantEnabledRoleId === "0"){
			data.qiPaticipantEnabledRoleId = "";
		}
		// console.log(data.qiPaticipantEnabledActive == undefined);
		if(data.qiPaticipantEnabledActive == undefined){
			data.qiPaticipantEnabledActive = 1;
			target.qiPaticipantEnabledActive = 1;
		}
		let ind = this.state.participants.findIndex(p => p.tableData.id === data.tableData.id);
		api.post(target).then(response => {
			let tmp = this.state.participants;
			tmp[ind].qiPaticipantEnabledId = response.qiPaticipantEnabledId;
			this.setState({participants: tmp}, () => {});
		});
	}

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<h2>Practice QI Participant List</h2>
				<div className={classes.table}>
					<MaterialTable 
						columns={this.state.tableColumn} 
						data={this.state.participants} 
						title="Participants"
						editable={{
							onRowAdd: newData => 
								new Promise((resolve, reject) => {
									setTimeout(() => {
										const data = this.state.participants.map(x => Object.assign({}, x));
										if(newData.qiPaticipantEnabledName == undefined || newData.qiPaticipantEnabledName.trim() === "" || newData.qiPaticipantEnabledName == null){
											this.props.GlobalFunctionDisplaySnackbarMessage(
												"Please enter name", Configs.snackbarVariants.error
											);
											reject();
											return;
										}
										resolve();
										data.push(newData);
										this.setState({ participants: data }, () => {
											this.handleRowAdd(newData);
										});
									}, 500)
							}),
							onRowUpdate: (newData, oldData) => 
								new Promise((resolve, reject) => {
									setTimeout(() => {
										if(newData.qiPaticipantEnabledName == undefined || newData.qiPaticipantEnabledName.trim() === "" || newData.qiPaticipantEnabledName == null){
											this.props.GlobalFunctionDisplaySnackbarMessage(
												"Please enter name", Configs.snackbarVariants.error
											);
											return;
										}
										resolve();
										const data = this.state.participants.slice();
										const index = data.indexOf(oldData);
										data[index] = newData;
										this.setState({ participants: data }, () => {
											this.handleRowUpdate(newData);
										});
									}, 500);
								})
						}}
						options= {this.state.pageOptions}
					/>
				</div>
			</div>
		);
	}
}

export default withStyles(useStyles)(QIManagePaticipant);