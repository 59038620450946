import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { TextField, Divider, Box } from '@material-ui/core';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Configs from 'Configuration';
import FhtFetchUrl from 'Utils/FhtFetchUrl.jsx';
import CommentsRenderer from 'Views/StandAlonePatientApprovalPOC/CommentsRendererPOC';
import { Editor } from '@tinymce/tinymce-react';
import CommentsRendererPOC from 'Views/StandAlonePatientApprovalPOC/CommentsRendererPOC';

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

class CommentDialogPOC extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addedComment: "",
            comments: null,
            parentContext: { componentParent: this },
            editComment: {
                commentId: 0,
                userName: "",
                comment: ""
            },
            btnText: 'Add comment',
            showCancel: false,
            showOverlay: false,
           
        }
        this.scrollDiv = React.createRef();
        // this.addComment();
    }

    componentDidMount() {
        //this.loadComments();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.open && this.props.open) {           
            this.loadComments();
        }
    }

    onCommentButtonClick = () => {
        var editComment = this.state.editComment;
        if(editComment.commentId > 0) {
            this.updateComment();
        }
        else {
            this.addComment();
        }
    }

    loadComments = () => {
        let body = { PatientID: parseInt(this.props.patientId), UserId: parseInt(this.props.POCUserID) };
     
        const api = FhtFetchUrl(Configs.getCommentsPOC);
        api
        .post(body)
        .then(response => {
            console.log('comments parent: ' + response);
            this.setState({comments: response});
        })
        .catch(error => {
            var msg = String(error);
            this.props.showMessages(
                msg,
                Configs.snackbarVariants.error
            );
        });
    }

    addComment = () => {
        if(this.state.addedComment != "") {
            let body = { PatientID: parseInt(this.props.patientId), CohortId: parseInt(this.props.cohortId),UserId: parseInt(this.props.POCUserID), Comments: this.state.addedComment };
        
            const api = FhtFetchUrl(Configs.saveCommentPOC);
            api
            .post(body)
            .then(response => {
                console.log(response);
                if(response && response.commentId != 0) {
                    this.setState({ addedComment: '' });
                    var _comments = this.state.comments;
                    _comments.push(response);
                    this.setState({comments: _comments});
                    this.props.showMessages(
                        "Comment added successfully",
                        Configs.snackbarVariants.success
                    );
                }
                else {
                    this.props.showMessages(
                        response,
                        Configs.snackbarVariants.error
                    );
                }
            })
            .catch(error => {
                var msg = String(error);
                this.props.showMessages(
                    msg,
                    Configs.snackbarVariants.error
                );
            });    
        }
        else{
            this.props.showMessages(
                "Please add a comment",
                Configs.snackbarVariants.error
            );
        }   
    };

    editComment = (commentId, userName, comment) => {
        this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' });
        this.props.showMessages(
            "Please edit comment in the editor",
            Configs.snackbarVariants.info
        );
        this.setState({
            editComment: {
                commentId: commentId,
                userName: userName,
                
                comment: ''
            }
        });
        this.setState({addedComment: comment});
        // this.btnInput.current.focus();
        this.setState({btnText: 'Edit comment'});
        this.setState({showCancel: true})
        this.setState({showOverlay: true})
    }

    updateComment = () => {
        let editedComment = this.state.editComment;
        editedComment.comment = this.state.addedComment;
        if(editedComment.comment != "") {
            let body = { UserName: editedComment.userName, NotesId: editedComment.commentId, UserId: parseInt(this.props.POCUserID),Comments: editedComment.comment };
           
            const api = FhtFetchUrl(Configs.editCommentPOC);
            api
            .post(body)
            .then(response => {
                console.log(response);
                if(response && response.message) {
                    this.props.showMessages(
                        response.message,
                        Configs.snackbarVariants.error
                    );
                }
                else if(response && response.indexOf("Edited") > -1) {
                    this.closeCommentDlg();
                    var _comments = this.state.comments;
                    _comments.find(cmt => cmt.commentId == editedComment.commentId).comments = editedComment.comment
                    _comments.find(cmt => cmt.commentId == editedComment.commentId).updatedDate = new Date();
                    this.setState({comments: _comments});
                    this.props.showMessages(
                        "Comment updated successfully",
                        Configs.snackbarVariants.success
                    );
                    this.setState({
                        editComment: {
                            commentId: 0,
                            userName: '',
                            comment: ''
                        }
                    });
                    this.setState({addedComment: ''});
                }
                else {
                    this.props.showMessages(
                        response,
                        Configs.snackbarVariants.error
                    );
                }
            })
            .catch(error => {
                var msg = String(error);
                this.props.showMessages(
                    msg,
                    Configs.snackbarVariants.error
                );
            });    
        }
        else{
            this.props.showMessages(
                "Please add a comment",
                Configs.snackbarVariants.error
            );
        }
    }

    removeComment = (commentId, userName) => {
        let body = { NotesId: parseInt(commentId), UserName: userName,UserId: parseInt(this.props.POCUserID) };
        const api = FhtFetchUrl(Configs.deleteCommentPOC);
        api
        .post(body)
        .then(response => {
            console.log('comments parent: ' + response);
            if(response && response.message) {
                this.props.showMessages(
                    response.message,
                    Configs.snackbarVariants.error
                );
            }
            else if(response && response.indexOf("Deleted") > -1) {
                let _comments = this.state.comments.filter(el => el.commentId != commentId);
                this.setState({comments: _comments});
                this.props.showMessages(
                    "Comment deleted successfully",
                    Configs.snackbarVariants.success
                );
            }
            else {
                this.props.showMessages(
                    response,
                    Configs.snackbarVariants.error
                ); 
            }
        })
        .catch(error => {
            var msg = String(error);
            this.props.showMessages(
                msg,
                Configs.snackbarVariants.error
            );
        });
    }
    
    closeCommentDlg = () => {
        this.setState({addedComment: ''});
        this.setState({
            editComment: {
                commentId: 0,
                userName: '',
                comment: ''
            }
        });
        this.setState({btnText: 'Add comment'});
        this.setState({showCancel: false});
        this.setState({showOverlay: false})
    }

    render() {
        return (
            <Dialog fullScreen={false}
                fullWidth={true}
                maxWidth="md"
                onClose={this.props.onHide}
                aria-labelledby="customized-dialog-title"
                open={this.props.visible}
                onExit={this.closeCommentDlg}
            >
                <DialogTitle id="customized-dialog-title"  onClose={this.props.onHide}>
                    Comments
                </DialogTitle>
                <DialogContent>
                    <CommentsRendererPOC 
                        key={this.props.NotesId}
                        Comments={this.state.comments}                        
                        patientId={this.props.patientId}
                        cohortId={this.props.cohortId}
                        POCUserID={this.props.POCUserID}
                        context={this.state.parentContext}
                        overlay={this.state.showOverlay}
                    />                   
                    <Divider variant="fullWidth" style={{ margin: "12px 12px 0 12px" }} />
                    <Box style={{padding: 14, width: "100%"}}>
                        {/* <TextField
                            id="add-comment"
                            label="Add comment"
                            multiline
                            rows="4"
                            fullWidth
                            required
                            value={this.state.addedComment}
                            onChange={e => this.setState({ addedComment: e.target.value })}
                            margin="normal"
                            variant="outlined"
                        />   */}
                         <div ref={this.scrollDiv}>
                        <Editor
                         tinymceScriptSrc="tinymce/tinymce.js"
                            initialValue=""
                            init={{
                            height: 130,
                            content_style: 'div { margin: 10px; border: 5px solid red; padding: 3px; }',
                            menubar: false,
                            //Add the placeholder                      
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks',
                                'insertdatetime media table paste code wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | removeformat'
                            }}
                            value={this.state.addedComment}
                            onEditorChange={(content, editor) => { this.setState({ addedComment: content })}}
                        />
                        </div>
                        <Button
                            variant="outlined"
                            color="secondary"
                            style={this.state.showCancel ? {paddingRight: '1REM', float: 'right', marginTop: '10px', marginLeft: '5px'} : { display: 'none' }}
                            onClick={this.closeCommentDlg.bind(this)}
                            // ref={this.btnInput}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            style={{ paddingRight: '1REM', float: 'right', marginTop: '10px' }}
                            onClick={this.onCommentButtonClick.bind(this)}
                            // ref={this.btnInput}
                        >
                            {this.state.btnText}
                        </Button>
                    </Box>                    
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={this.props.onHide} color="primary">
                        Close
                    </Button>
                </DialogActions> */}
            </Dialog>
        );
    }
}

export default withMobileDialog()(CommentDialogPOC);
