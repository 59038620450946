import React, { Component } from "react";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers/';
import DeleteIcon from '@material-ui/icons/Delete';
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  createTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles/';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import FhtFetchUrl from "Utils/FhtFetchUrl";
import Configs from "Configuration";
import { confirmAlert } from 'react-confirm-alert';

const muiTheme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1em"
      }
    }
  }
});

const useStyles = theme => ({
  formDetail: {
    width: '100%',
    marginTop: '2REM'
  },
  tip: {
    fontSize: 'smaller',
    textAlign: 'left',
    marginTop: '1REM'
  },
  card: {
    width: '100%',
    padding: '2REM'
  },
  inputText: {
    textAlign: 'left'
  },
});

class QIActivityReflections extends Component {

  constructor(props) {
    super(props);
    this._reflections = this.props._reflections;
    this.state = {
      reflections: this.props.reflections,
    }
  }

  handleReflectionTextChange = (e, ind) => {
    let item = e.target.id;
    let tmp = this.state.reflections;
    tmp[ind][item] = e.target.value;
    this.setState({ reflections: tmp });
    this.props.updateParentReflections(this._reflections, tmp)
  }

  handleAddReflection = () => {
    let len = this.state.reflections.length;
    const reflection = {
      id: len,
      qiActivityReflectionActivityId: this.props.activity.qiActivityId || 0,
      qiActivityReflectionMeetingDate: null,
      date: "",
      qiActivityReflectionMeetingStatus: "",
      qiActivityReflectionComment: "",
      qiActivityReflectionImprovedMeasure: "",
      qiActivityReflectionNotImprovedMeasure: "",
      qiActivityReflectionEnablersBarries: "",
      qiActivityReflectionFailureFactor: "",
      qiActivityReflectionSuccessFactor: "",
      qiActivityReflectionProposedPracticeChanges: "",
      qiActivityReflectionMBR: "",
      qiActivityReflectionFutureImprovements: ""
    };
    let tmp = this.state.reflections;
    tmp.push(reflection);
    this._reflections.push({});
    this.setState({ reflections: tmp });
    this.props.updateParentReflections(this._reflections, tmp)
  }
  
  deleteReflection = (ind) => {
    this.setState({ reflectionReady: false });
    let tmp = this.state.reflections;
    this._reflections.map((r, index) => {
      let _comment = r.qiActivityReflectionComment != undefined ? r.qiActivityReflectionComment.value : '';
      let _improved = r.qiActivityReflectionImprovedMeasure != undefined ? r.qiActivityReflectionImprovedMeasure.value : '';
      let _notImproved = r.qiActivityReflectionNotImprovedMeasure != undefined ? r.qiActivityReflectionNotImprovedMeasure.value : '';
      let _enablers = r.qiActivityReflectionEnablersBarries != undefined ? r.qiActivityReflectionEnablersBarries.value : '';
      let _failure = r.qiActivityReflectionFailureFactor != undefined ? r.qiActivityReflectionFailureFactor.value : '';
      let _success = r.qiActivityReflectionSuccessFactor != undefined ? r.qiActivityReflectionSuccessFactor.value : '';
      let _propose = r.qiActivityReflectionProposedPracticeChanges != undefined ? r.qiActivityReflectionProposedPracticeChanges.value : '';
      let _mbr = r.qiActivityReflectionMBR != undefined ? r.qiActivityReflectionMBR.value : '';
      let _future = r.qiActivityReflectionFutureImprovements != undefined ? r.qiActivityReflectionFutureImprovements.value : '';
      tmp[index].qiActivityReflectionComment = _comment;
      tmp[index].qiActivityReflectionImprovedMeasure = r._improved;
      tmp[index].qiActivityReflectionNotImprovedMeasure = _notImproved;
      tmp[index].qiActivityReflectionEnablersBarries = _enablers;
      tmp[index].qiActivityReflectionFailureFactor = _failure;
      tmp[index].qiActivityReflectionSuccessFactor = _success;
      tmp[index].qiActivityReflectionProposedPracticeChanges = _propose;
      tmp[index].qiActivityReflectionMBR = _mbr;
      tmp[index].qiActivityReflectionFutureImprovements = _future;
      tmp[index].comment = _comment;
      tmp[index].improved = _improved;
      tmp[index].notImproved = _notImproved;
      tmp[index].enabler = _enablers;
      tmp[index].failure = _failure;
      tmp[index].success = _success;
      tmp[index].proposed = _propose;
      tmp[index].mbr = _mbr;
      tmp[index].future = _future;
    });
    let reflection = this.state.reflections[ind];
    if (reflection.qiActivityReflectionId > 0 && reflection.qiActivityReflectionId !== undefined) {
      const api = FhtFetchUrl(Configs.deleteReflection);
      api.del(reflection.qiActivityReflectionId).then(response => {
        console.log('DELETE', response);
      });
    }
    tmp.splice(ind, 1);
    this.setState({ reflections: [] }, () => {
      this._reflections.splice(ind, 1);
      this.props.updateParentReflections(this._reflections.splice(ind, 1), tmp)
      this.setState({ reflections: tmp }, () => {
        this.setState({ reflectionReady: true });
      });
    });
  }
  
  handleMeetingDateChange = (e, ind) => {
    let tmp = this.state.reflections;
    tmp[ind].qiActivityReflectionMeetingDate = e;
    if (e != null && e != undefined) {
      tmp[ind].date = e.getDate() + "/" + (e.getMonth() + 1) + "/" + e.getFullYear();
    } else {
      tmp[ind].date = null;
    }
    this.setState({ reflections: tmp });
    this.props.updateParentReflections(this._reflections, tmp)
  }
  
  handleReflectionStatusChange = (e, ind) => {
    let tmp = this.state.reflections;
    tmp[ind].qiActivityReflectionMeetingStatus = e.target.value;
    this.setState({ reflections: tmp });
    this.props.updateParentReflections(this._reflections, tmp)
  }

  // Function isn't used but could be useful in the future for deleting QI activities.
  handleDeleteClick = (ind, type) => {
    confirmAlert({
      title: 'Confirm deletion',
      message: `This ${type} will be deleted immediately and permanently, please confirm you want to delete this ${type}.`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            if (type === 'reflection') {
              this.deleteReflection(ind);
            } else {
              // Function not defined in code.
              // this.deleteAction(ind);
            }
          }
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    })
  }

  renderReflections = () => {
    const { classes } = this.props;
    return (
      <div className={classes.formDetail}>
        <Card variant="outlined" className={classes.card}>
          <div className={classes.tip}>
            <span>
              <strong>Tip 4 : </strong>This is where you record your PDSA idea and progress
            </span>
          </div>
          <MuiThemeProvider theme={muiTheme}>
            <Tooltip arrow disableFocusListener disableTouchListener
              title="Comment on, or review progress of your cohort outcome measures, including any enablers, barriers and system changes implemented/to be implemented.">
              <h4>Reflections</h4>
            </Tooltip>
          </MuiThemeProvider>
          <div><Button variant="outlined" onClick={() => this.handleAddReflection()}>Add new reflections</Button></div>
          <div className={classes.reflection}>
            {this.state.reflections.map((row, index) => (
              <ExpansionPanel key={index}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <h5>Refection {index + 1} </h5>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div style={{ width: '100%' }}>
                    <div>
                      <Button variant="contained" onClick={() => this.handleDeleteClick(index, 'reflection')} startIcon={<DeleteIcon />}> Delete</Button>
                    </div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker inputVariant="outlined"
                        variant="inline"
                        margin="normal"
                        label="Meeting Date"
                        value={row.qiActivityReflectionMeetingDate}
                        format="dd/MM/yyyy"
                        onChange={(e) => this.handleMeetingDateChange(e, index)} />
                    </MuiPickersUtilsProvider>
                    <FormControl className={classes.formDetail} variant="outlined">
                      <InputLabel>Current Status</InputLabel>
                      <Select className={classes.inputText} label="Current Status"
                        value={row.qiActivityReflectionMeetingStatus}
                        onChange={(e) => this.handleReflectionStatusChange(e, index)}>
                        {this.props.status.map(status => (
                          <MenuItem key={status} value={status}>{status}</MenuItem>
                        ))}
                      </Select>
                    </FormControl >
                    <TextField className={classes.formDetail} label="Comments" variant="outlined" defaultValue={row.comment} multiline rows={5} inputRef={input => (this._reflections[index].qiActivityReflectionComment = input)} />
                    <TextField className={classes.formDetail} label="Success Factors" variant="outlined" defaultValue={row.success} multiline rows={5} inputRef={input => (this._reflections[index].qiActivityReflectionSuccessFactor = input)} />
                    <TextField className={classes.formDetail} label="Enablers/Barriers" variant="outlined" defaultValue={row.enabler} multiline rows={5} inputRef={input => (this._reflections[index].qiActivityReflectionEnablersBarries = input)} />
                    {/* <TextField className={classes.formDetail} label="Failure Factors" variant="outlined" defaultValue={row.failure} multiline rows={5} inputRef={input => (this._reflections[index].qiActivityReflectionFailureFactor = input)}/> */}
                    <TextField className={classes.formDetail} label="Cohort Outcome Measures that improved" variant="outlined" defaultValue={row.improved} multiline rows={5} inputRef={input => (this._reflections[index].qiActivityReflectionImprovedMeasure = input)} />
                    <TextField className={classes.formDetail} label="Cohort Outcome Measures that did not improve" variant="outlined" defaultValue={row.notImproved} multiline rows={5} inputRef={input => (this._reflections[index].qiActivityReflectionNotImprovedMeasure = input)} />
                    <TextField className={classes.formDetail} label="Proposed Practice System Changes" variant="outlined" defaultValue={row.proposed} multiline rows={5} inputRef={input => (this._reflections[index].qiActivityReflectionProposedPracticeChanges = input)} />
                    <TextField className={classes.formDetail} label="Cohort Outcome Measures" variant="outlined" defaultValue={row.mbr} multiline rows={5} inputRef={input => (this._reflections[index].qiActivityReflectionMBR = input)} />
                    <TextField className={classes.formDetail} label="Future Improvement" variant="outlined" defaultValue={row.future} multiline rows={5} inputRef={input => (this._reflections[index].qiActivityReflectionFutureImprovements = input)} />
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}

          </div>
        </Card>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderReflections()}
      </div>
    );
  }
}


export default withStyles(useStyles)(QIActivityReflections);