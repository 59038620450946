import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';


import withMobileDialog from '@material-ui/core/withMobileDialog';
import TextField from '@material-ui/core/TextField';


import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/VpnKey';
import Container from '@material-ui/core/Container';

import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import CircularProgress from '@material-ui/core/CircularProgress';

import Configs from 'Configuration';
import SiteConfigurationContext from "Utils/SiteConfigurationContext.jsx";

import FhtMain from "FhtMain";

const DialogTitle = withStyles(theme => ({
    root: {
        // borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        // padding: theme.spacing(2),
    },
    closeButton: {
        // position: 'absolute',
        position: 'fixed',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    closeButtonAbs: {
        position: 'absolute',
        // position: 'fixed',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}))(props => {
    const { classes, onClose, fullScreen } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            {/* <Typography variant="h6">{children}</Typography> */}
            {onClose ? (
                <IconButton aria-label="Close" className={fullScreen ? classes.closeButton : classes.closeButtonAbs} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        //   marginTop: theme.spacing(4),
        marginBottom: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    buttonProgress: {
        // color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -8,
        marginLeft: -12,
      },
      wrapper: {
        position: 'relative',
      },
});

class ChangePasswordDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true, password: "", retypePassword: "", newPassword: "", username: "",
            showNewPassword: false,
            showRetypePassword: false,
            loading: false,
            siteConfigurationContext:SiteConfigurationContext(),
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClickShowNewPassword = this.handleClickShowNewPassword.bind(this);
        this.handleClickShowRetypePassword = this.handleClickShowRetypePassword.bind(this);
    }

    abortController = new AbortController();

    handleSubmit(event) {
        event.preventDefault();
        this.handleSavePassword();
    }


    handleClickShowNewPassword() {
        this.setState({ showNewPassword: !this.state.showNewPassword});
    }

    handleClickShowRetypePassword() {
        this.setState({ showRetypePassword: !this.state.showRetypePassword});
    }

    handleSavePassword() {

        if (!this.state.loading) {
            this.setState({loading:true});
        }
        if (this.state.newPassword !== this.state.retypePassword) {
            this.props.GlobalFunctionDisplaySnackbarMessage("New & retype password did not match", Configs.snackbarVariants.error);
        } else {
            // post change password
            // var urlHeader = Object.assign({'Authorization':'Bearer ' + localStorage.getItem('tokenInfo')},Configs.urlHeader.headers);
            // urlObj = Object.assign({headers:urlHeader},urlObj);
            var badResponse = false;
            var params = { Password: this.state.password, NewPassword: this.state.newPassword, RetypePassword: this.state.retypePassword };
            fetch(this.state.siteConfigurationContext[Configs.SiteConfigurationMappingKeys.serverapi] + Configs.changePassword, {
                signal: this.abortController.signal,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'cache-control': 'no-cache',
                    'Authorization': 'Bearer ' + FhtMain.GlobalContext.userToken
                },
                body: JSON.stringify(params)
            })
            .then(response =>{
                console.log(response);
                // eslint-disable-next-line
                if (response.statusText != "OK"){
                    badResponse = true;
                }
                return response.json();
           })
           .then(response=>{
                if (this.state.loading) {
                    this.setState({loading:false});
                }                  
                // eslint-disable-next-line
                if (badResponse == true)
                {
                   console.log("Password change is unsuccessful.");
                   throw (response.message);
                }
                else
                {
                    this.props.GlobalFunctionDisplaySnackbarMessage(response.message, Configs.snackbarVariants.success);
                }
           })               
                .catch((error) => {
                    console.log(error);
                    if (this.state.loading) {
                        this.setState({loading:false});
                    }
                    this.props.GlobalFunctionDisplaySnackbarMessage(String(error), Configs.snackbarVariants.error);
                })
        }
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Dialog fullScreen={false}
                    onClose={this.props.CallbackForAllDialogsClose}
                    aria-labelledby="customized-dialog-title"
                    open={true}
                    fullWidth={true}
                >
                    <DialogTitle id="customized-dialog-title" fullScreen={false} onClose={this.props.CallbackForAllDialogsClose}>
                    </DialogTitle>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <div className={classes.paper}>
                            <Avatar className={classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Change Password
                                </Typography>
                            <form name="changePasswordForm" className={classes.form} onSubmit={this.handleSubmit}>
                                <input hidden type="text" name="username" autoComplete="username" value={localStorage.getItem('username')} readOnly />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Current Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    autoFocus
                                    value={this.state.password}
                                    disabled={this.state.loading}
                                    onChange={(event) => this.setState({ password: event.target.value })}
                                />

                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="new-password"
                                    label="New Password"
                                    type={this.state.showNewPassword ? 'text' : 'password'}
                                    id="new-password"
                                    autoComplete="new-password"
                                    value={this.state.newPassword}
                                    disabled={this.state.loading}
                                    onChange={(event) => this.setState({ newPassword: event.target.value })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    aria-label="Toggle password visibility"
                                                    onClick={this.handleClickShowNewPassword}
                                                >
                                                    {this.state.showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="retype-password"
                                    label="Re-type New Password"
                                    type={this.state.showRetypePassword ? 'text' : 'password'}
                                    id="retype-password"
                                    autoComplete="retype-password"
                                    value={this.state.retypePassword}
                                    disabled={this.state.loading}
                                    onChange={(event) => this.setState({ retypePassword: event.target.value })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    aria-label="Toggle password visibility"
                                                    onClick={this.handleClickShowRetypePassword}
                                                >
                                                    {this.state.showRetypePassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <div className={classes.wrapper}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        disabled={this.state.loading}
                                    >
                                        Save
                                    </Button>
                                    {this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </form>
                        </div>

                    </Container>
                </Dialog>
            </div>
        );
    }
}

export default withMobileDialog()(withStyles(styles)(ChangePasswordDialog));