import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor'

import './Guideline.css'

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);



class GuidelineDialog extends React.Component {
    //constructor(props) {
    //     super(props);   
    //}

    componentDidMount() {
        configureAnchors({offset: 0, scrollDuration: 10})
      }

    renderConsumerInformation() {
        return(
            <div id="guidelines">
            <ScrollableAnchor id={'section2c'} label = "section2c">
                <div style={{ paddingLeft: "20%", paddingRight: "20%" }} >
                    <br/>
                    <div align="center" style={{ borderBottom: "solid", borderBottomWidth: "1px" }}>
                        <b style={{ fontSize: "24px" }} > Chronic kidney disease </b> <br /><br />
                        <div className="row">
                            <div className="columnImage">
                                
                            </div>
                            <div className="columnText">
                                <p><a target="_blank" rel="noopener noreferrer" href="https://kidney.org.au/your-kidneys/detect/kidney-disease">Kidney Health Australia: Consumer Information </a></p>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </ScrollableAnchor>

            <ScrollableAnchor id={'section4c'} label = "section4c">
                <div style={{ paddingLeft: "20%", paddingRight: "20%" }} >
                    <br/>
                    <div align="center" style={{ borderBottom: "solid", borderBottomWidth: "1px" }}>
                        <b style={{ fontSize: "24px" }} > Cardiovascular disease </b> <br /><br />
                        <div className="row">
                            <div className="columnImage">
                                
                            </div>
                            <div className="columnText">
                                <p>Heart Foundation: Absolute cardiovascular risk (including guidance on lipid management for primary prevention)</p>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/your-heart/know-your-risks">Consumer information</a><br/><br/>
                                <p>Heart Foundation: Hypercholesterolaemia and Familial hypercholesterolaemia</p>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/getmedia/7edd455f-1b3b-4e30-b20c-487720be68e7/familial-hypercholesterolaemia.pdf">Consumer information: Familial hypercholesterolaemia</a><br/>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/healthy-eating/food-and-nutrition/fats-and-cholesterol">Consumer information: Fats and cholesterol</a><br/><br/>                              
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </ScrollableAnchor>

            <ScrollableAnchor id={'section5c'} label = "section5c">
                <div style={{ paddingLeft: "20%", paddingRight: "20%" }} >
                    <br />
                    <div align="center" style={{ borderBottom: "solid", borderBottomWidth: "1px" }}>
                        <b style={{ fontSize: "24px" }} > Type 2 diabetes </b> <br /><br />
                        <div className="row">
                            <div className="columnImage">
                                
                            </div>
                            <div className="columnText">
                                <p><a target="_blank" rel="noopener noreferrer" href="https://www.diabetesaustralia.com.au/living-with-diabetes">Diabetes Australia: Consumer resources: Living with diabetes </a></p>
                                <p><a target="_blank" rel="noopener noreferrer" href="https://www.ndss.com.au/forms">NDSS forms (Registration, medication changes, blood glucose strip 6 month extension)</a></p>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </ScrollableAnchor>
        </div>
        )
    }

    renderHealthProfessionalInformation() {
        return(
            <div id="guidelines">
                <ScrollableAnchor id={'section1'} label = "section1">
                    <div style={{ paddingLeft: "20%", paddingRight: "20%" }} >
                        <br/>
                        <div align="center" style={{ borderBottom: "solid", borderBottomWidth: "1px" }}>
                            <b style={{ fontSize: "24px" }} > General guidelines </b> <br /><br />
                            <div className="row">
                                {/*
                                <div className="columnImage">
                                    
                                </div>
                                */}
                                <div className="columnText">
                                    <p><a target="_blank" rel="noopener noreferrer" href="https://www.tg.org.au">Therapeutic Guidelines </a></p>
                                    <p><a target="_blank" rel="noopener noreferrer" href="https://amhonline.amh.net.au">Australian Medicines Handbook </a></p>
                                    <p><a target="_blank" rel="noopener noreferrer" href="https://melbourne.healthpathways.org.au">Health Pathways Melbourne </a>  (username: connected password: healthcare)</p>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </ScrollableAnchor>

                <ScrollableAnchor id={'section2'} label = "section2">
                    <div style={{ paddingLeft: "20%", paddingRight: "20%" }} >
                        <br/>
                        <div align="center" style={{ borderBottom: "solid", borderBottomWidth: "1px" }}>
                            <b style={{ fontSize: "24px" }} > Chronic kidney disease </b> <br /><br />
                            <div className="row">
                                <div className="columnImage">
                                    
                                </div>
                                <div className="columnText">
                                    <p><a target="_blank" rel="noopener noreferrer" href="https://kidney.org.au/health-professionals/prevent/chronic-kidney-disease-management-handbook">Kidney Health Australia: Chronic Kidney Disease Management Handbook </a></p>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </ScrollableAnchor>

                <ScrollableAnchor id={'section3'} label = "section3">
                    <div style={{ paddingLeft: "20%", paddingRight: "20%" }} >
                        <br/>
                        <div align="center" style={{ borderBottom: "solid", borderBottomWidth: "1px" }}>
                            <b style={{ fontSize: "24px" }} > Preventive Health </b> <br /><br />
                            <div className="row">
                                <div className="columnImage">
                                
                                </div>
                                <div className="columnText">
                                    <p><a target="_blank" rel="noopener noreferrer" href="https://www.racgp.org.au/clinical-resources/clinical-guidelines/key-racgp-guidelines/view-all-racgp-guidelines/red-book/preamble/i-introduction">RACGP: The Red Book </a></p>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </ScrollableAnchor>

                <ScrollableAnchor id={'section4'} label = "section4">
                    <div style={{ paddingLeft: "20%", paddingRight: "20%" }} >
                        <br/>
                        <div align="center" style={{ borderBottom: "solid", borderBottomWidth: "1px" }}>
                            <b style={{ fontSize: "24px" }} > Cardiovascular disease </b> <br /><br />
                            <div className="row">
                                <div className="columnImage">
                                    
                                </div>
                                <div className="columnText">
                                    <p>Heart Foundation: Hypertension</p>
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/Conditions/Hypertension">Full guideline </a><br/><br/>
                                    <p>Heart Foundation: Absolute cardiovascular risk (including guidance on lipid management for primary prevention)</p>
                                        <a target="_blank" rel="noopener noreferrer" href="http://www.cvdcheck.org.au/">CVD risk calculator</a><br/>
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/Conditions/FP-Absolute-CVD-Risk-Clinical-Guidelines">Full guideline</a><br/><br />
                                    <p>Heart Foundation: Heart failure</p>
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/Conditions/Heart-failure-for-professionals">Full guideline</a><br/>
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/getmedia/2d5ec756-8c20-4217-914f-c5ac0a2bc284/Medicine_today_-_HF_-_permission_granted_July_2019.pdf">Heart Failure Guidelines A concise summary for the GP </a><br/><br/>
                                    <p>
                                        <a target="_blank" rel="noopener noreferrer" href="http://t2dgr.bakeridi.edu.au">Baker IDI Heart and Diabetes Institute: Secondary prevention of cardiovascular disease in type 2 diabetes</a>
                                    </p>
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.ahajournals.org/doi/10.1161/CIR.0000000000000678">2019 ACC/AHA Guideline on the Primary Prevention of Cardiovascular Disease</a><br/><br/>
                                    <p>
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/Conditions/Fp-Lipid-management"> Heart Foundation: Secondary prevention of coronary heart disease </a>
                                    </p>                                
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </ScrollableAnchor>

                <ScrollableAnchor id={'section5'} label = "section5">
                    <div style={{ paddingLeft: "20%", paddingRight: "20%" }} >
                        <br />
                        <div align="center" style={{ borderBottom: "solid", borderBottomWidth: "1px" }}>
                            <b style={{ fontSize: "24px" }} > Type 2 diabetes </b> <br /><br />
                            <div className="row">
                                <div className="columnImage">
                                    
                                </div>
                                <div className="columnText">
                                    <p><a target="_blank" rel="noopener noreferrer" href="https://www.racgp.org.au/clinical-resources/clinical-guidelines/key-racgp-guidelines/view-all-racgp-guidelines/management-of-type-2-diabetes/introduction/introduction">RACGP: General Practice management of type 2 diabetes</a></p>
                                    <p><a target="_blank" rel="noopener noreferrer" href="http://t2d.diabetessociety.com.au/plan/">Australian Diabetes Society: Type 2 Diabetes Treatment Algorithm </a></p>
                                    <p><a target="_blank" rel="noopener noreferrer" href="http://t2dgr.bakeridi.edu.au">Baker IDI Heart and Diabetes Institute: Secondary prevention of cardiovascular disease in type 2 diabetes</a></p>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </ScrollableAnchor>

                <ScrollableAnchor id={'section6'} label = "section6">
                    <div style={{ paddingLeft: "20%", paddingRight: "20%" }} >
                        <br />
                        <div align="center" style={{ borderBottom: "solid", borderBottomWidth: "1px" }}>
                            <b style={{ fontSize: "24px" }} > Medicare Benefits Scheme </b> <br /><br />
                            <div className="row">
                                <div className="columnImage">
                                    
                                </div>
                                <div className="columnText">
                                    <p><a target="_blank" rel="noopener noreferrer" href="http://www.mbsonline.gov.au/internet/mbsonline/publishing.nsf/Content/Home">MBS Online</a></p>
                                    <p><a target="_blank" rel="noopener noreferrer" href="https://www.nbmphn.com.au/Resources/Programs-Services/Primary-Care-Support/Desktop-Guide-to-Frequently-Used-MBS-Item-Numbers.aspx">Frequently used general practice MBS Item numbers</a></p>
                                    <p><a target="_blank" rel="noopener noreferrer" href="https://www.ausdoc.com.au/sites/default/files/mbs_quick_guide_july20.pdf">MBS Quick Guide</a></p>
                                    <p><a target="_blank" rel="noopener noreferrer" href="https://www.ausdoc.com.au/sites/default/files/mbs_quick_guide-jul20_telehealthupdate.pdf">COVID telehealth items</a></p>                              
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </ScrollableAnchor>

                <ScrollableAnchor id={'section7'} label = "section7"> 
                    <div style={{ paddingLeft: "20%", paddingRight: "20%" }} >
                        <br/>
                        <div align="center" style={{ paddingTop: "25px", borderBottom: "solid", borderBottomWidth: "1px" }}>
                            <b style={{ fontSize: "24px" }} > Pharmaceutical benefits scheme </b> <br /><br />
                            <div className="row">
                                <div className="columnImage">
                                    
                                </div>
                                <div className="columnText">
                                    <p><a target="_blank" rel="noopener noreferrer" href="http://www.pbs.gov.au/pbs/home">PBS</a></p>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </ScrollableAnchor>

                {/* <ScrollableAnchor id={'section8'} label = "section8">
                    <div style={{ paddingLeft: "20%", paddingRight: "20%" }} >
                        <br/>
                        <div align="center" style={{ borderBottom: "solid", borderBottomWidth: "1px" }}>
                            <b style={{ fontSize: "24px" }} > Training Videos (N/A) </b> <br /><br />
                            <div className="row">
                                <div className="columnImage">
                                    
                                </div>
                                <div className="columnText">
                                    <p><a target="_blank" rel="noopener noreferrer" href="www.youtube.com">Video 1: How to create a cohort.</a></p>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </ScrollableAnchor> */}
            </div>
        )
    }

    

    render() {
        return (
            <div>
                <h1 style={{ paddingLeft: "20%", paddingRight: "20%"  }} align="center" > Guidelines <hr /> </h1>
                    <div class="row">                                    
                        <div class="column">
                        <h4>Information for Health Professionals</h4>
                            {this.renderHealthProfessionalInformation()}
                        </div>
                        <div class="column">
                        <h4>Information for Consumers</h4> 
                            {this.renderConsumerInformation()}
                        </div>
                    </div>
            </div>
        );
    }
}

export default withMobileDialog()(GuidelineDialog);