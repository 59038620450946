import React from 'react';
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveLine } from '@nivo/line'
import { string } from 'prop-types';
import { LineScale } from 'react-pure-loaders';
const genericLineChartDimensions = { top: 10, right: 150, bottom: 110, left: 60 }

const genericLineChartLegend = [{
    anchor: 'bottom-right',
    direction: 'column',
    justify: false,
    translateX: 100,
    translateY: 0,
    itemsSpacing: 0,
    itemDirection: 'left-to-right',
    itemWidth: 80,
    itemHeight: 20,
    symbolSize: 12,
    symbolShape: 'circle',
    itemTextColor: "#FFFFFF",
    symbolBorderColor: 'rgba(0, 0, 0, .5)',
    effects: [{
        on: 'hover',
        style: {
            itemBackground: 'rgba(0, 0, 0, .03)',
            itemOpacity: 1
        }
    }]
}]


function parseDataIntoJSONFormat(dataToBeSplit, JSONDataToBeChanged) {

    var stringToArray = dataToBeSplit.split(",")
    var updatedArray = []

    for (var i = 0; i < JSONDataToBeChanged.length; i++) {
        for (var z = 0; z < stringToArray.length; z++) {
            if (String(JSONDataToBeChanged[i].id) == String(stringToArray[z])) {
                updatedArray.push(JSONDataToBeChanged[i])
            }
        }
    }

    var returnedArray = []

    if (updatedArray.length == 0) {
        returnedArray = JSONDataToBeChanged
    } else {
        returnedArray = updatedArray
    }

    return returnedArray
}

function createArrayData(JSONDataToBeParsed, prefixIDLabel) {
    const returnedArrayData = []

    console.log("Looping through dictionary")
    for (var key in JSONDataToBeParsed) {
        console.log(key)

        var initialSplit = String(key).split(',')
        var combinedString = initialSplit.join(' + ')

        returnedArrayData.push({
            "id": String(prefixIDLabel) + ": " + String(combinedString),
            "data": JSON.parse(JSONDataToBeParsed[String(key)])
        })
    }

    return returnedArrayData
}
const TopTick = ({ textAnchor, textBaseline, value, x, y }) => {
    const MAX_LINE_LENGTH = 16;
    const MAX_LINES = 2;
    const LENGTH_OF_ELLIPSIS = 3;
    const TRIM_LENGTH = MAX_LINE_LENGTH * MAX_LINES - LENGTH_OF_ELLIPSIS;
    const trimWordsOverLength = new RegExp(`^(.{${TRIM_LENGTH}}[^\\w]*).*`);
    const groupWordsByLength = new RegExp(
        `([^\\s].{0,${MAX_LINE_LENGTH}}(?=[\\s\\W]|$))`,
        'gm',
    );
    const splitValues = value
        .replace(trimWordsOverLength, '$1...')
        .match(groupWordsByLength)
        .slice(0, 2)
        .map((val, i) => ( <tspan key = { val }
            dy = { 12 * i }
            x = {-10 }
            style = {
                { fontFamily: 'sans-serif', fontSize: '11px', fill: "white", padding: "100px" } } >
            { val } </tspan>
        ));
    return ( <g transform = { `translate(${x-10},${y-20})` } >
        <text alignmentBaseline = { textBaseline }
        textAnchor = { textAnchor } > { splitValues } </text> </g>
    );
};

const genericLineChartThemes = {
    axis: {
        legend: {
            text: {
                fill: "white"
            }
        },
        ticks: {
            line: {
                stroke: "white"
            },
            text: {
                fill: "white"
            }
        }
    },
    grid: {
        line: {
            stroke: "white",
            strokeWidth: 2
        }
    },
    crosshair: {
        line: {
            stroke: "white",
            strokeWidth: 2
        },
    },
    legends: {
        fill: "white",

        text: {
            fontSize: 14,

        }
    }
}

function splitArray(constantStageNames, latestCohortNames, jsonToBeParsed, originalJSON, originalCohortNamesForUse) {

    var arrayCohortNames
    if (typeof latestCohortNames == 'string') {
        arrayCohortNames = JSON.parse(latestCohortNames)
    } else if (typeof latestCohortNames == 'object') {
        arrayCohortNames = JSON.parse(JSON.stringify(latestCohortNames))
    }

    // console.log(arrayCohortNames)
    // //var arrayCohortNames = JSON.parse(this.props.cohortNames)
    
    // console.log("TYPE OF HOLDALLCOHORTANDCURRENTSTAGE")
    // console.log(jsonToBeParsed.holdAllCohortAndCurrentStage)
    var setValue  = jsonToBeParsed.holdAllCohortAndCurrentStage

    
    // console.log("TYPE OF VALUE")
    // console.log(setValue)
    // console.log(typeof setValue)
    // console.log(JSON.stringify(setValue))
    var returnedData = []
    var result = [];
    var arrayHold = [];

    for (var i = 0; i < arrayCohortNames.length; i++) {
        //console.log(arrayCohortNames[i])


        var changeData = JSON.parse(setValue[arrayCohortNames[i]])

         
        result.push({StageName : arrayCohortNames[i]})

        Object.keys(changeData).forEach(function(key) {
            result.push({[key] : changeData[key]});
            arrayHold.push(key)
        });

        for (var z = 0; z < constantStageNames.length; z++) {
            if (arrayHold.indexOf(constantStageNames[z]) == -1) {
                //console.log("Value not in here!")
                result.push({[constantStageNames[z]] : 0});
            }
        }

        const obj = Object.assign({}, ...result)
        returnedData.push(obj)
    }

    //console.log(returnedData)

    let resultData = [];

    try {
        Object.keys(returnedData[0]).forEach((key, idx) => {
            if (idx !== 0) {
                let resultUnit = {
                    Status: key, 
                };
                returnedData.forEach(item => {
                    return resultUnit = {
                    ...resultUnit,
                    [item.StageName]: item[key],
                    }
                })
                resultData.push(resultUnit);
            }
            })
    } catch {
        console.log("CATCHING NOW")
        var newArrayCohortNames
        if (typeof originalCohortNamesForUse == 'string') {
            newArrayCohortNames = JSON.parse(originalCohortNamesForUse)
        } else if (typeof originalCohortNamesForUse == 'object') {
            newArrayCohortNames = JSON.parse(JSON.stringify(latestCohortNames))
        }
    
        console.log("NEW ARRAY COHORT NAMES")
        console.log(newArrayCohortNames)
        //var newArrayCohortNames = JSON.parse(this.props.cohortNames)
        
        console.log("ORIGINAL JSON")
        console.log(originalJSON)
        console.log(originalJSON.holdAllCohortAndCurrentStage)
        var newSetValue  = originalJSON.holdAllCohortAndCurrentStage
    
        
        // console.log("TYPE OF VALUE")
        // console.log(setValue)
        // console.log(typeof setValue)
        // console.log(JSON.stringify(setValue))
        var newReturnedData = []
        var newResult = [];
        var newArrayHold = [];
    
        for (var i = 0; i < newArrayCohortNames.length; i++) {
            //console.log(newArrayCohortNames[i])
    
    
            var newChangeData = JSON.parse(newSetValue[newArrayCohortNames[i]])
    
             
            newResult.push({StageName : newArrayCohortNames[i]})
    
            Object.keys(newChangeData).forEach(function(key) {
                newResult.push({[key] : newChangeData[key]});
                newArrayHold.push(key)
            });
    
            for (var z = 0; z < constantStageNames.length; z++) {
                if (newArrayHold.indexOf(constantStageNames[z]) == -1) {
                    //console.log("Value not in here!")
                    newResult.push({[constantStageNames[z]] : 0});
                }
            }
    
            const obj = Object.assign({}, ...newResult)
            newReturnedData.push(obj)
        }
    
        //console.log(returnedData)
    
        let newResultData = [];
    
            Object.keys(newReturnedData[0]).forEach((key, idx) => {
                if (idx !== 0) {
                    let newResultUnit = {
                        Status: key, 
                    };
                    newReturnedData.forEach(item => {
                        return newResultUnit = {
                        ...newResultUnit,
                        [item.StageName]: item[key],
                        }
                    })
                    newResultData.push(newResultUnit);
                }
                })

        return newResultData
    }


    return resultData
}

export class DashboardFeedbackCohortCurrentStageGraph extends React.Component {

    //////////////////////////////////////////////
// TAG: COHORT DATA
// TAG: FUNCTION TO ADD IN ZEROS TO DATA IF ITS NOT PRESENT IN DATABASE
// THIS IS THE ARRAY THAT WILL HOLD ALL STAGE TYPES
// const data = ["testOne", "testTwo", "testThree"]

// This is the LINQ QUERY RETURNED DATA
// const newData = [{
//   "testOne" : 1},
//   {
//   "testTwo" : 3
//   }
  
// ]

// const returnedData = []

// for (var z = 0; z < data.length; z ++) {
  
// for (var i = 0 ; i < newData.length; i ++) {

//   if (Object.keys(newData[i]) == data[z]) {
//     console.log("Test")
//     returnedData.push(newData[i])
//     break
//   } else if (i == newData.length - 1) {
//     returnedData.push({[data[z]] : 0})
//     console.log("Pushing variable not there")
//   }
// }
// }
// console.log(returnedData)
//////////////////////////////////////////////

    render() {
        const constantStageNames = ["Available for Review", "Awaiting Review", "Awaiting Approval", "Awaiting Recall", "Awaiting Consultation", "Action in Progress", "Deferral"]
        const originalJSON = this.props.originalParentJSON
        const jsonToBeParsed = this.props.parentJSONData
        const latestCohortNames = this.props.cohortNames
        const originalCohortNames = this.props.originalCohortNamesForUse
        var resultData = splitArray(constantStageNames, latestCohortNames, jsonToBeParsed, originalJSON, originalCohortNames)


        //console.log("RESULT DATA")
        //console.log(resultData)
        return (
            <ResponsiveBar
            data= {
                resultData
            }
            theme = { genericLineChartThemes }
            keys={[ "2019 Oct CKD/T2D At-risk Practice-wide Focus","Mental Health Watch List","Indigenous Youth 2019 March Focus Group","70+ Aged Care Home Focus group June 2019"]}
            indexBy="Status"
            margin={{ top: 10, right: 200, bottom: 115, left: 60 }}
            padding={0.3}
            colors={{ scheme: 'nivo' }}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -45,
                legendOffset: -45
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Current Stage',
                legendPosition: 'middle',
                legendOffset: -40
            }}

            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 12,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    renderTick: TopTick,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}

            labelSkipWidth={12}
            labelSkipHeight={12}
            
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            isInteractive={true}
        />
        )
    }
}

export class DashboardCohortAllChangeInStatusLineChart extends React.Component {

    render() {
        
            var originalJSONData = JSON.parse(JSON.stringify(this.props.secondOriginalParentJSON))
            var updatingJSONData = []
            
            if (this.props.updatedParentJSONData == undefined) {
                updatingJSONData.length = 0
            } else {
                updatingJSONData = JSON.parse(JSON.stringify(this.props.updatedParentJSONData))
            }

            var returnedValue
            if (updatingJSONData.length == 0) {
                returnedValue  = originalJSONData
            } else if (updatingJSONData.length > 0 && updatingJSONData.length < originalJSONData.length) {
                returnedValue  = updatingJSONData
            } else if (updatingJSONData.length == originalJSONData.length) {
                returnedValue  = originalJSONData
            }
                return ( <
                    ResponsiveLine data = {
                        returnedValue
                    }
        
                    margin = {
                        genericLineChartDimensions
                    }
                    xScale = {
                        { type: 'point' }
                    }
                    yScale = {
                        { type: 'linear', stacked: false, min: 0, max: 'auto' }
                    }
                    axisTop = { null }
                    axisRight = { null }
                    activeLineWidth = { 6 }
                    inactiveLineWidth = { 3 }
                    inactiveOpacity = { 0.15 }
                    axisBottom = {
                        {
                            orient: 'bottom',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: -45,
                            legendPosition: 'middle'
                        }
                    }
        
                    axisLeft = {
                        {
                            orient: 'left',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: "Number of Patients",
                            legendOffset: -40,
                            legendPosition: 'middle'
                        }
                    }
        
                    theme = { genericLineChartThemes }
        
                    colors = {
                        { scheme: 'set1' }
                    }
                    pointSize = { 10 }
                    pointColor = {
                        { theme: 'background' }
                    }
                    pointBorderWidth = { 2 }
                    pointBorderColor = {
                        { from: 'serieColor' }
                    }
                    pointLabel = "y"
                    pointLabelYOffset = {-12 }
                    useMesh = { true }
                    legends = {
                        genericLineChartLegend
                    }
                    />
                )
            
            } 
}

export class DashboardMonitoringRmStatusEachMonthLineChart extends React.Component {

    render() {

        var originalJSONData = JSON.parse(JSON.stringify(this.props.originalParentJSON))
        //console.log("ORIGINAL JSON DATA")
        //console.log(originalJSONData)
        var updatingJSONData = []



        if (this.props.updatedParentJSONData == undefined) {
            updatingJSONData.length = 0
        } else {
            updatingJSONData = JSON.parse(JSON.stringify(this.props.updatedParentJSONData))
        }

        //console.log("UPDATING JSON NEW GRAPH")
        //console.log(updatingJSONData)

        var returnedValue
        if (updatingJSONData.length == 0) {
            returnedValue  = originalJSONData
        } else if (updatingJSONData.length > 0 && updatingJSONData.length < originalJSONData.length) {
            returnedValue  = updatingJSONData
        } else if (updatingJSONData.length == originalJSONData.length) {
            returnedValue  = originalJSONData
        }
                return ( <
                    ResponsiveLine data = {
                        returnedValue
                    }
        
                    margin = {
                        genericLineChartDimensions
                    }
                    xScale = {
                        { type: 'point' }
                    }
                    yScale = {
                        { type: 'linear', stacked: false, min: 0, max: 'auto' }
                    }
                    axisTop = { null }
                    axisRight = { null }
                    activeLineWidth = { 6 }
                    inactiveLineWidth = { 3 }
                    inactiveOpacity = { 0.15 }
                    axisBottom = {
                        {
                            orient: 'bottom',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: -45,
                            legendPosition: 'middle'
                        }
                    }
        
                    axisLeft = {
                        {
                            orient: 'left',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: "Number of Patients",
                            legendOffset: -40,
                            legendPosition: 'middle'
                        }
                    }
        
                    theme = { genericLineChartThemes }
        
                    colors = {
                        { scheme: 'set1' }
                    }
                    pointSize = { 10 }
                    pointColor = {
                        { theme: 'background' }
                    }
                    pointBorderWidth = { 2 }
                    pointBorderColor = {
                        { from: 'serieColor' }
                    }
                    pointLabel = "y"
                    pointLabelYOffset = {-12 }
                    useMesh = { true }
                    legends = {
                        genericLineChartLegend
                    }
                    />
                )
            
            } 
        }