import React from 'react';

class Education extends React.Component {
    //constructor(props) {
    //     super(props);   
    //}

    renderNurseEducationalResources() {
        return(
            <div style={{ paddingLeft: "20%", paddingRight: "20%" }} >
                <br/>
                <div align="center" style={{ borderBottom: "solid", borderBottomWidth: "1px" }}>
                    <b style={{ fontSize: "24px" }} > Improving data quality in your clinical system  </b> <br /><br />
                    <div className="row">
                        <div className="columnImage">
                            
                        </div>
                        <div className="columnText">
                            <p><a target="_blank" rel="noopener noreferrer" href="https://www.apna.asn.au/product/detail/ff440063-e988-ea11-80d9-005056be66b1">Improving data in Communicare</a></p>
                            <p><a target="_blank" rel="noopener noreferrer" href="https://www.apna.asn.au/product/detail/43122fab-eb88-ea11-80d9-005056be66b1">Improving data in Bp Premier</a></p>
                            <p><a target="_blank" rel="noopener noreferrer" href="https://www.apna.asn.au/product/detail/bf796209-e788-ea11-80d9-005056be66b1">Improving data in MedicalDirector</a></p>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
        )
    }   

    render() {
        return (
            <div>
                <h1 style={{ paddingLeft: "20%", paddingRight: "20%"  }} align="center" > Education <hr /> </h1>
                    {/* <h4 style = {{align: "centre"}}>Educational resources for Nurses</h4>  */}
                    {this.renderNurseEducationalResources()}
            </div>
        );
    }
}

export default Education;