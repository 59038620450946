import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import Configs from 'Configuration';
import SiteConfigurationContext from "Utils/SiteConfigurationContext.jsx";

export default class ChildMessageRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    siteConfigurationContext:SiteConfigurationContext(),
    }
    this.invokeParentMethod = this.invokeParentMethod.bind(this);
  }

  invokeParentMethod() {

    // console.log('Patient id: ' + this.props.data.patientExtractId);
    if(this.props.data != null)
    {
    this.props.context.componentParent.onBtnOpenCommentDialog(this.props.data.patientExtractId);
    }
    
  }

  render() {
    return (
      
      <span>
        
        <IconButton onClick={this.invokeParentMethod}>
            <CommentIcon/>
        </IconButton> 
      </span>
    
    
    );
  }
}