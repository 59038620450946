import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import BatchSiteConfigurationContext from 'Utils/BatchSiteConfigurationContext.jsx';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

class ContactDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      batchSiteConfigurationContext: BatchSiteConfigurationContext()
    };
  }

  render() {
    return (
      <div>
        {this.state.batchSiteConfigurationContext['IsTorch'] == 'false' && (
          <Dialog
            fullScreen={false}
            onClose={this.props.CallbackForAllDialogsClose}
            aria-labelledby="customized-dialog-title"
            open={true}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={this.props.CallbackForAllDialogsClose}
            >
              About
            </DialogTitle>
            <DialogContent style={{}}>
              <Typography gutterBottom>
                Future Health Today is used for research and educational purposes only. Clinicians
                must make their own independent assessment before suggesting a diagnosis or
                recommending or instituting a course of treatment, and are responsible for final
                decisions regarding the most appropriate clinical care.
              </Typography>

              <h3>CONTACT US</h3>
              <p>
                Future Health Today
                <br />
                Department of General Practice
                <br />
                The University of Melbourne
                <br />
                Level 3, 780 Elizabeth Street, Carlton
                <br />
                Victoria, 3053 Australia
              </p>

              <ul style={{ marginLeft: '0em', paddingInlineStart: '20px' }}>
                <li>
                  <b>Phone:</b> 03 8344 1371
                </li>
                <li>
                  <b>Email:</b>{' '}
                  <a href="mailto:contact-fht@unimelb.edu.au?subject=FHT%20Feedback">
                    contact-fht@unimelb.edu.au
                  </a>
                </li>
              </ul>
              <p>
                Future Health Today is a collaborative project between The University of Melbourne
                and Western Health.
                <div style={{ display: 'flex' }}>
                  <a
                    href="https://www.unimelb.edu.au/"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="The University of Melbourne"
                  >
                    <img
                      src="https://d2glwx35mhbfwf.cloudfront.net/v4.10.0/logo-with-padding.svg"
                      alt="The University of Melbourne"
                      title="The University of Melbourne"
                      width="60%"
                      style={{ margin: '1em auto', display: 'block' }}
                    />
                  </a>
                  <a
                    href="http://www.westernhealth.org.au/Pages/default.aspx"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Western Health"
                  >
                    <img
                      src="https://medicine.unimelb.edu.au/__data/assets/image/0007/2985901/Western-Health.png"
                      alt="Western Health"
                      title="Western Health"
                      width="60%"
                      style={{ margin: '1em auto', display: 'block' }}
                    />
                  </a>
                </div>
              </p>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.CallbackForAllDialogsClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }
}

export default withMobileDialog()(ContactDialog);
