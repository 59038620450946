

function ProposedActionCellRenderer(result) {
  return {
    ProposedActionCellRenderer(params) {
      if (params.value === undefined || params.value.name === undefined)
        return result;
      else
        return params.value.name;
    }
  }
}

export default ProposedActionCellRenderer;
