import React from 'react';
import Configs from 'Configuration';
import SiteConfigurationContext from 'Utils/SiteConfigurationContext.jsx';
import FhtMain from 'FhtMain';
import dashboardStyle from 'components/Card/dashboardStyle.jsx';
import BatchSiteConfigurationContext from 'Utils/BatchSiteConfigurationContext.jsx';
import AlgorithmSiteConfigurationContext from 'Utils/AlgorithmSiteConfigurationContext.jsx';

import { Responsive, WidthProvider } from 'react-grid-layout';
import 'components/css/circularProgressStyle.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'underscore';
import FhtFetchUrl from 'Utils/FhtFetchUrl.jsx';

import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';

//nivo graphs
import { ResponsiveBar, Bar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const onReviewCohortLayoutChange = getFromLS('onReviewCohortLayoutChange') || {};
const onLeftGraphLayoutChange = getFromLS('onLeftGraphLayoutChange') || {};
const treatThisValueAsZero = 0.1;
const colorSchemeforGraphs = 'nivo'; //spectral, nivo, pastel1, set3

const BarComponent = (props, thisComponent) => {
  let renderBars = <div></div>;
  let propsDataValue = props.data.value;
  let tempNewDisplay = 'Not assigned';
  let longDescription = false;
  if (props.data.indexValue == 'no_Action') {
    tempNewDisplay = 'Action complete. Currently no recommendations.';
    longDescription = true;
  }
  //Another hack required. The users wanted a long description with fullstop.
  else tempNewDisplay = props.data.indexValue.replace(/_/g, ' ');

  tempNewDisplay = tempNewDisplay.charAt(0).toUpperCase() + tempNewDisplay.slice(1);

  //special hack to make this design work
  if (props.data.value === treatThisValueAsZero) propsDataValue = 0;

  let startingXTextNotInGraph = props.width * 2 + 200;

  let defaultWidth = 120;
  if (longDescription == true) {
    defaultWidth = 350;
    startingXTextNotInGraph = props.width * 2;

    // Ratio to determine how much the bar takes the screen.
    // If less than <33% of the graph screen, attach text to end of bar.
    // >33%, attach to start of bar graph.
    if (props.width / (window.innerWidth / 2) < 0.33) startingXTextNotInGraph = defaultWidth - 50;
    else startingXTextNotInGraph = startingXTextNotInGraph - props.width;
  }
  if (props.width != null && props.width > defaultWidth) {
    renderBars = (
      <g
        transform={`translate(${props.x},${props.y})`}
        onClick={e => openDataGridForStage(e, thisComponent)}
        style={{ cursor: 'pointer' }}
        id={Configs.cohortBargraphMapname[tempNewDisplay]}
      >
        <rect x={-3} y={7} width={props.width} height={props.height} fill="rgba(0, 0, 0, .07)" />
        <rect width={props.width} height={props.height} fill={props.color} />

        <text
          x={props.width / 2}
          y={props.height / 2}
          textAnchor="middle"
          dominantBaseline="central"
          fill="black"
          style={{
            fontWeight: 900,
            fontSize: 15
          }}
        >
          {tempNewDisplay + '    (' + propsDataValue + ')'}
        </text>
      </g>
    );
  } else {
    //this is the zero value
    renderBars = (
      <g
        transform={`translate(${props.x},${props.y})`}
        onClick={e => openDataGridForStage(e, thisComponent)}
        style={{ cursor: 'pointer' }}
        id={Configs.cohortBargraphMapname[tempNewDisplay]}
      >
        <rect x={-3} y={7} width={props.width} height={props.height} fill="rgba(0, 0, 0, .07)" />
        <rect width={props.width} height={props.height} fill={props.color} />

        <text
          x={startingXTextNotInGraph}
          y={props.height / 2}
          textAnchor="middle"
          dominantBaseline="central"
          fill="black"
          style={{
            fontWeight: 900,
            fontSize: 15
          }}
        >
          {tempNewDisplay + '    (' + propsDataValue + ')'}
        </text>
      </g>
    );
  }
  return renderBars;
};
function openDataGridForStage(e, thisComponent) {
  if (IsTorch) return;
  //example: http://localhost:3000/standaloneDataGridScreen?cohortid=2&workflowStageId=5
  //Unfortunately some forms are still referring to the old forms because the unified/standardised datagrid form is not ready yet.
  if (
    e.currentTarget.id === undefined ||
    e.currentTarget.id === null ||
    e.currentTarget.id === ''
  ) {
    console.log(thisComponent.props);
    thisComponent.props.props.props.GlobalFunctionDisplaySnackbarMessage(
      'Selected graph value is invalid. Please contact the developer.',
      Configs.snackbarVariants.error
    );
  } else if (e.currentTarget.id == 6) {
    thisComponent.props.props.props.history.push(
      '/' + 'defer?cohortid=' + thisComponent.props.props.state.cohortBeingReviewedId
    );
    /*
        'standaloneDataGridScreen?cohortid=' +
        thisComponent.props.props.state.cohortNamesDictionary[
          thisComponent.props.props.state.cohortBeingReviewedName
        ] +
        '&deferWorkflowId=1'
        */
    //deferworkflowid = 1 == deferred.
  } else if (e.currentTarget.id == 7) {
    thisComponent.props.props.props.history.push(
      '/' + 'deferRecommendation?cohortid=' + thisComponent.props.props.state.cohortBeingReviewedId
    );
  } else if (e.currentTarget.id == 2) {
    thisComponent.props.props.props.history.push(
      '/' + 'approve?cohortid=' + thisComponent.props.props.state.cohortBeingReviewedId
    );
      if (thisComponent.props.props.state.toggleButtonValue == 1) {
        thisComponent.props.props.props.history.push(
          '/' +
            'recall?cohortid=' +
            thisComponent.props.props.state.cohortBeingReviewedId +
            '&activeRec=' +
            thisComponent.props.props.state.toggleButtonValue
        );
      } else {
        thisComponent.props.props.props.history.push(
          '/' + 'recall?cohortid=' + thisComponent.props.props.state.cohortBeingReviewedId
        );
      }
  } else {
    thisComponent.props.props.props.history.push(
      '/' +
        'standaloneDataGridScreen?cohortid=' +
        thisComponent.props.props.state.cohortBeingReviewedId +
        '&workflowStageId=' +
        e.currentTarget.id +
        '&deferWorkflowId=0' +
        '&existingRec=' +
        thisComponent.props.props.state.toggleButtonValue +
        '&disableRecallDefer=0'
    );
  }
}
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  completed: {
    display: 'inline-block'
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));
var IsTorch = false;
class ReviewBarGraph extends React.Component {
  constructor(props) {
    super(props);
    this.batchSiteConfigurationContext = BatchSiteConfigurationContext();
    this.algorithmSiteConfigurationContext = AlgorithmSiteConfigurationContext();
    this.state = {
      data: [],
      siteConfigurationContext: SiteConfigurationContext(),
      onLeftGraphLayoutChange: JSON.parse(JSON.stringify(onLeftGraphLayoutChange)),
      PatientFlowData: [],

      cohortConfirmationDialog: false,
      cohortBeingReviewedName: Configs.noCohortSelected,
      ReceivedToggleButtonValue: -1,
      tickValues: [0, 1]
    };
    this.handleGetReviewCohortStagesSummaryAPIThrottled = _.debounce(
      getReviewCohortStagesSummaryAPI,
      100,
      true
    );
    this.initialiseTickValues();
    console.log('ReviewBarGraph construction triggered');
    if (this.batchSiteConfigurationContext['IsTorch'] == 'true') {
      IsTorch = true;
    }
  }

  //Hack to make the graph display the numbers correctly on the X-axis
  initialiseTickValues = () => {
    var tempArray = [];
    console.log('initialising tick values');
    for (var i = 2; i < 200; i++) {
      tempArray.push(i);
    }
    //eslint-disable-next-line
    this.state.tickValues = tempArray;
  };

  onReviewCohortLayoutChange(layout, onReviewCohortLayoutChange) {
    saveToLS('onReviewCohortLayoutChange', onReviewCohortLayoutChange);
    this.setState({ onReviewCohortLayoutChange });
  }
  onLeftGraphLayoutChange(layout, onLeftGraphLayoutChange) {
    saveToLS('onLeftGraphLayoutChange', onLeftGraphLayoutChange);
    this.setState({ onLeftGraphLayoutChange });
  }

  handleTreatmentOptimisationTickboxChange = name => event => {
    console.log('treatment name:', name);
    let tempTickbox = this.state.treatmentOptimisationTickboxes;
    tempTickbox[name]['ticked'] = event.target.checked;
    this.setState({ treatmentOptimisationTickboxes: tempTickbox }, () => {
      this.cohortTotalQueryWebAPI();
    });
  };
  render() {
    console.log('ReviewBarGraph render triggered');
    let cohortName = this.props.props.state.cohortBeingReviewedName;
    let toggledButtonValue = this.props.props.state.toggleButtonValue;
    if (
      (this.state.ReceivedCohortName !== cohortName && cohortName !== Configs.noCohortSelected) ||
      this.state.ReceivedToggleButtonValue !== toggledButtonValue
    ) {
      this.setState(
        { ReceivedCohortname: cohortName, ReceivedToggleButtonValue: toggledButtonValue },
        () => {
          this.handleGetReviewCohortStagesSummaryAPIThrottled(this);
        }
      );
    }
    let colorData = [
      {
        //color: '#8886ad'
        color: '#e9692c'
      },
      {
        color: '#7F7F7F'
      },
      {
        color: '#00B050'
      },
      {
        //"color": "#002060"
        color: '#5b75ab'
      },
      {
        color: '#00B0F0'
      },
      {
        color: '#F6FC96'
      },
      {
        color: '#FFC000'
      }
    ];
    return (
      <div>
        <ResponsiveReactGridLayout
          className="leftGraphLayout"
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          rowHeight={50}
          //</div>layouts={this.state.globalSummaryNumberLayout}
          onLayoutChange={(layout, layouts) => this.onLeftGraphLayoutChange(layout, layouts)}
          onDrag={this.onDrag}
          onDragStop={this.onDragStop}
          onResizeStart={this.onResizeStart}
          onResizeStop={this.onResizeStop}
          isResizable={false}
        >
          {/*}
          <div
            key="leftGraphTitle"
            data-grid={{
              w: 5,
              h: 1,
              x: 0,
              y: 0,
              static: true
            }}
          >
           <h2 style={{ textAlign: 'center' }}>Patient status</h2>
          </div>
          */}
          <div
            key="leftGraphContent"
            data-grid={{
              w: 10,
              h: 8,
              x: 0,
              y: 0,
              static: true
            }}
          >
            <ResponsiveBar
              //several responsive issues - color changes while being stretched/shrunk to a certain size
              layout="horizontal"
              margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
              data={this.state.PatientFlowData}
              indexBy="id"
              keys={['value']}
              //colors={{ scheme: colorSchemeforGraphs }}
              //colorBy="indexValue"
              colors={colorData.map(c => c.color)}
              colorBy="index"
              borderColor={{ from: 'color', modifiers: [['darker', 2.6]] }}
              enableGridX
              enableGridY={false}
              axisTop={{
                type: 'number',
                format: '.0f',
                tickValues: 2 //very buggy, manual values don't work as it clumps the numbers together in one spot.
              }}
              axisBottom={{
                type: 'number',
                format: '.0f',
                tickValues: 2
                //tickValues: [0,1,2,3]
              }}
              axisLeft={null}
              padding={0.3}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.4]] }}
              isInteractive={false}
              barComponent={e => BarComponent(e, this)}
              motionStiffness={170}
              motionDamping={26}
              onClick={(e, event) => this.clickedStageAnalysisGraph(e, event)}
            />
          </div>
        </ResponsiveReactGridLayout>
      </div>
    );
  }
}

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem('rgl-8')) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      'rgl-8',
      JSON.stringify({
        [key]: value
      })
    );
  }
}
function getReviewCohortStagesSummaryAPI(param) {
  let cohortName = param.props.props.state.cohortBeingReviewedName;
  var localBatchConfigContext = BatchSiteConfigurationContext();
  const shouldIncludeDeferRecommendation = localBatchConfigContext['CohortRecallDefer'] === 'true';
  console.log('getReviewCohortStagesSummaryAPI called');
  if (cohortName === Configs.noCohortSelected) {
    console.log('No cohort selected');
  } else {
    // eslint-disable-next-line
    param.setState({ ReceivedCohortName: cohortName });
    let body = {
      name: cohortName,
      matchingRecommendations: param.props.props.state.toggleButtonValue,
      useCohortRecallDefer: shouldIncludeDeferRecommendation
    };
    const api = FhtFetchUrl(Configs.getReviewCohortStagesSummary);
    api
      .post(body)
      .then(response => {
        var formattedFlowData = [];
        Object.keys(response).forEach(function(key) {
          if (!shouldIncludeDeferRecommendation && key === 'defer_Recommendation')
          {
            return;
          }
          let formattedValue = 0.1;
          if (response[key] != 0) {
            formattedValue = response[key];
          }
          formattedFlowData.push({ id: key, value: formattedValue });
        });
        console.log('formattedFlowData:', formattedFlowData);
        // eslint-disable-next-line
        param.setState({ PatientFlowData: formattedFlowData });
      })
      .catch(error => {
        var msg = String(error);
        param.props.props.props.GlobalFunctionDisplaySnackbarMessage(
          msg,
          Configs.snackbarVariants.error
        );
      });
  }
}
export default withStyles(dashboardStyle, useStyles)(ReviewBarGraph);
