import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import TextField from '@material-ui/core/TextField';

import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Configs from '../Configuration';
import SiteConfigurationContext from 'Utils/SiteConfigurationContext.jsx';
import BatchSiteConfigurationContext from 'Utils/BatchSiteConfigurationContext.jsx';
import FhtMain from 'FhtMain';

//import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
//import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles/MuiThemeProvider';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FhtFetchUrl from '../Utils/FhtFetchUrl.jsx';
import { string } from 'prop-types';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

class LoginDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      siteConfigurationContext: SiteConfigurationContext(),
      batchSiteConfigurationContext: BatchSiteConfigurationContext(),
      openResetPasswordDialog: false,
      openRegisterAccountDialog: false,

      open: true,
      username: '',
      registerUsername: '',
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      confirmpassword: '',
      formErrors: { email: '', password: '', confirmpassword: '' },
      emailValid: true,
      passwordValid: true,
      formValid: false,
      showRePassword: false,
      showPassword: false,
      userData: []
    };
    this.apiData();
  }
  apiData() {

      console.log('Autoaccount enabled');
      let api = FhtFetchUrl('/ListOfActiveUsers');

      if (
        this.state.batchSiteConfigurationContext['IsTorch'] == 'true' 
      ) {
        api = FhtFetchUrl('/ListOfActiveTorchUsers'); //This includes LimitedTorchUsers
      }

      api
        .get()
        .then(response => {
          if (this.state.batchSiteConfigurationContext['IsTorch'] == 'true') {
            var index = response.message.indexOf('fhtsuperuser');
            if (index != -1) {
              response.message.splice(index, 1);
            }
          }
          if (this.state.batchSiteConfigurationContext['IsTorch'] == 'false') {
            var index = response.message.indexOf('torchsuperuser');
            if (index != -1) {
              response.message.splice(index, 1);
            }
          }
          console.log('ListOfActiveUsers response from server:', response);
          this.setState({ userData: response.message, loading: false });
        })
        .catch(error => {
          //var msg = String(error);
          this.props.GlobalFunctionDisplaySnackbarMessage(
            'Unable to retrieve a list of FHT users. Please contact support',
            Configs.snackbarVariants.error
          );
          console.log('ListOfActiveUsers:' + error);
        });
  }

  render() {
    return (
      <div>
        {this.renderLoginDialog()}
        {this.state.openResetPasswordDialog && this.renderResetPasswordDialog()}
        {this.state.openRegisterAccountDialog && this.renderRegisterAccountDialog()}
      </div>
    );
  }
  renderLoginDialog = () => {
    let vSpace = '5REM';
    let vSmallSpace = '1REM';
    return (
      <Dialog
        fullScreen={this.props.fullScreen}
        onClose={this.handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        fullWidth={true}
      >
        <form onSubmit={e => this.onBtnSubmitLogin(e)}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div
              style={{
                marginBottom: vSpace,
                marginTop: vSpace,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Avatar style={{ backgroundColor: '#f50057' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography
                style={{ marginBottom: vSmallSpace, marginTop: vSmallSpace }}
                component="h1"
                variant="h5"
              >
                Please log in
              </Typography>


              <FormControl variant="outlined" required fullWidth>
                <InputLabel id="label">Username</InputLabel>
                <Select
                  labelId="label"
                  id="select"
                  label="Username"
                  onChange={event => this.setState({ username: event.target.value })}
                  disabled={this.state.loading}
                >
                  {this.state.userData.map(row => {
                    return <MenuItem value={row}>{row}</MenuItem>;
                  })}
                </Select>{' '}
              </FormControl>

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                disabled={this.state.loading}
                value={this.state.password || ''}
                onChange={event => this.setState({ password: event.target.value })}
              />
              <div>
                <Button
                  style={{ marginBottom: vSmallSpace, marginTop: vSmallSpace }}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={this.state.loading}

                  //onClick={(e)=>this.onBtnSubmitLogin()}
                >
                  Sign In
                </Button>
                <Grid container style={{ marginBottom: vSmallSpace }}>
                  <Grid item xs style={{ marginRight: vSpace }}>
                    <Link
                      variant="body2"
                      disabled={this.state.loading}
                      style={{ cursor: 'pointer' }}
                      onClick={this.onBtnSubmitForgotPassword}
                    >
                      Reset password
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      variant="body2"
                      disabled={this.state.loading}
                      style={{ cursor: 'pointer' }}
                      onClick={this.onBtnSubmitCreateAccount}
                    >
                      Register account
                    </Link>
                  </Grid>
                </Grid>

                {this.state.loading && <CircularProgress size={24} />}
              </div>
              <Box mt={0} mb={5}>
                <Disclaimer />
              </Box>
            </div>
          </Container>
        </form>
      </Dialog>
    );
  };

  renderResetPasswordDialog = () => {
    let vSpace = '5REM';
    let vSmallSpace = '1REM';

    return (
      <Dialog
        fullScreen={false} // onClose={this.props.onHandleClose}
        aria-labelledby="customized-dialog-title"
        open={this.state.openResetPasswordDialog}
        fullWidth={true}
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div
            style={{
              marginBottom: vSpace,
              marginTop: vSpace,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Avatar>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Forgot Password
            </Typography>
            <form name="ForgotPasswordForm" onSubmit={this.onBtnSubmitSendResetEmail}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="email"
                label="Email"
                type="text"
                id="email"
                autoComplete="current-email"
                autoFocus
                disabled={this.state.loading}
                value={this.state.email}
                onChange={event => this.setState({ email: event.target.value })}
              />
              <div style={{ marginTop: vSmallSpace }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={this.state.loading}
                >
                  Send Reset Email
                </Button>
                {this.state.loading && <CircularProgress size={24} />}
              </div>
              <div style={{ marginTop: vSmallSpace }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={this.state.loading}
                  onClick={() => this.setState({ openResetPasswordDialog: false })}
                >
                  Cancel
                </Button>
              </div>
            </form>
            <Box style={{ marginTop: vSmallSpace }}>
              <PasswordResetInfo />
            </Box>
          </div>
        </Container>
      </Dialog>
    );
  };

  renderRegisterAccountDialog = () => {
    let vSpace = '5REM';
    let vSmallSpace = '1REM';
    return (
      <Dialog
        fullScreen={false}
        aria-labelledby="customized-dialog-title"
        open={this.state.openRegisterAccountDialog}
        fullWidth={true}
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div
            style={{
              marginBottom: vSpace,
              marginTop: vSpace,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Avatar>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            {/*<form onSubmit={this.onBtnSubmitSignup}>*/}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="firstName"
                  label="First Name"
                  id="firstName"
                  autoComplete="firstName"
                  value={this.state.firstname}
                  autoFocus
                  onChange={event => {
                    this.setState({ firstname: event.target.value });
                  }}
                  disabled={this.state.loading}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="lastName"
                  label="Last Name"
                  id="lastName"
                  autoComplete="lastName"
                  value={this.state.lastname}
                  onChange={event => {
                    this.setState({ lastname: event.target.value });
                  }}
                  disabled={this.state.loading}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="registerUsername"
                  label="registerUsername"
                  id="registerUsername"
                  autoComplete="registerUsername"
                  value={this.state.registerUsername}
                  onChange={event => {
                    this.setState({ registerUsername: event.target.value });
                  }}
                  disabled={this.state.loading}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  error={this.state.formErrors.email.length === 0 ? false : true}
                  helperText={this.state.formErrors.email}
                  name="email"
                  label="Email"
                  id="email"
                  autoComplete="email"
                  value={this.state.email}
                  onChange={this.handleUserInput}
                  disabled={this.state.loading}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  error={this.state.formErrors.password.length === 0 ? false : true}
                  helperText={this.state.formErrors.password}
                  autoComplete="current-password"
                  name="password"
                  label="Password"
                  id="password"
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={this.state.password}
                  onChange={this.handleUserInput}
                  disabled={this.state.loading}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="Toggle password visibility"
                          onClick={() => {
                            this.setState({ showPassword: !this.state.showPassword });
                          }}
                        >
                          {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  error={this.state.formErrors.confirmpassword.length === 0 ? false : true}
                  helperText={this.state.formErrors.confirmpassword}
                  name="confirmpassword"
                  label="Confirm Password"
                  type={this.state.showRePassword ? 'text' : 'password'}
                  id="confirmpassword"
                  autoComplete="current-password"
                  value={this.state.confirmpassword}
                  onChange={this.handleUserInput}
                  disabled={this.state.loading}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="Toggle password visibility"
                          onClick={() => {
                            this.setState({ showRePassword: !this.state.showRePassword });
                          }}
                        >
                          {this.state.showRePassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: vSmallSpace }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={this.state.formValid === false || this.state.loading}
                onClick={() => this.onBtnSubmitSignup()}
              >
                Sign Up
              </Button>
              {this.state.loading && <CircularProgress size={24} />}
            </div>
            <div style={{ marginTop: vSmallSpace }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={this.state.loading}
                onClick={() => this.setState({ openRegisterAccountDialog: false })}
              >
                Cancel
              </Button>
            </div>

            {/*</form>*/}
          </div>
        </Container>
      </Dialog>
    );
  };

  onBtnSubmitLogin = e => {
    e.preventDefault();

    let body = { Username: this.state.username, Password: this.state.password };
    console.log('Attempting to login:', body);
    const api = FhtFetchUrl(Configs.userLogin);
    api
      .post(body)
      .then(response => {
        console.log('response from server1:', response);
        if (response == undefined || response == null) {
          throw 'No response from FHT server';
        } else if (response.error != undefined || response.error != null) {
          throw response.error;
        } else {
          if (response.claims === undefined) {
            throw response.message;
          }
          // eslint-disable-next-line
          /*
          if (response.claims.length == 0) {
            throw 'User is not assigned any rights to access the system. Please contact your administrator.';
          }
*/
          FhtMain.GlobalContext.userClaims = response.claims;
          FhtMain.GlobalContext.userRole = response.role;
          FhtMain.GlobalContext.userToken = response.token;
          FhtMain.GlobalContext.fullName = response.fullName;
          FhtMain.GlobalContext.userName = response.userName;
          localStorage.setItem(Configs.tokenInfo.userToken, response.token);

          if (response.role == Configs.roles.LimitedTorchUser) {
              //Store what cohorts the user can see. currently exists as a comma separated string.
              if (response.viewableCohortIds == null || FhtMain.GlobalContext.viewableCohortIds == ''){
                this.props.GlobalFunctionDisplaySnackbarMessage("No cohorts assigned to user. The user will be unable to access any cohorts.", Configs.snackbarVariants.error);
              }
              FhtMain.GlobalContext.viewableCohortIds = response.viewableCohortIds;
              console.log ("Viewable cohorts:" + response.viewableCohortIds)
              FhtMain.GlobalContext.cohortDiseaseId = response.cohortDiseaseId;
          }
          else
          {
            console.log ("Not limited torch user");
          }
          this.setState({ loading: false });
          this.props.CallbackLoginDialogSuccess(response.claims);
          var msg = response.fullName + ' is logged in';
          this.props.GlobalFunctionDisplaySnackbarMessage(msg, Configs.snackbarVariants.success);
          //sessionStorage.setItem("commentUser", this.state.username);

          // var fullName = response.fullName;
          // sessionStorage.setItem("commentFullname",fullName);
        }
      })
      .catch(error => {
        console.log('login error:', error);
        var msg = String(error);
        this.props.GlobalFunctionDisplaySnackbarMessage(msg, Configs.snackbarVariants.error);
      });
  };

  handle2FA() {
    // post token to server to validate
    var params = {
      Username: this.state.username,
      Token: this.state.code,
      HashToken: this.state.secret
    };
    fetch(
      this.state.siteConfigurationContext[Configs.SiteConfigurationMappingKeys.serverapi] +
        Configs.validate2FAToken,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'cache-control': 'no-cache'
        },
        body: JSON.stringify(params)
      }
    )
      .then(r => r.json())
      .then(response => {
        if (response.ok) {
          this.props.onHandleSuccess(this.state.username, response.json, true);
        } else {
          throw response.json.message;
        }
      })
      .catch(error => {
        this.props.onHandleError(String(error));
      });
  }

  onBtnSubmitLogin2 = () => {
    console.log('Attempting to log in');
  };

  onBtnSubmitForgotPassword = () => {
    this.setState({ openResetPasswordDialog: true });
  };

  onBtnSubmitSendResetEmail = e => {
    e.preventDefault();

    const api = FhtFetchUrl(Configs.forgotPassword);

    let params = { Email: this.state.email, WebURL: window.location.href };
    let badResponse = false;

    api
      .post(params)
      .then(response => {
        console.log(response);

        if (response == undefined || response == null) {
          throw 'No response from FHT server';
        } else if (response.error != undefined || response.error != null) {
          throw response.error;
        }

        if (response.message == 'Email is invalid') {
          throw response.message;
        }

        this.props.GlobalFunctionDisplaySnackbarMessage(
          response.message,
          Configs.snackbarVariants.success
        );

        this.setState({ openResetPasswordDialog: false });
      })
      .catch(error => {
        if (this.state.loading) {
          this.setState({ loading: false });
        }
        this.props.GlobalFunctionDisplaySnackbarMessage(
          String(error),
          Configs.snackbarVariants.error
        );
      });
  };

  onBtnSubmitCreateAccount = () => {
    this.setState({ openRegisterAccountDialog: true });
  };

  onBtnSubmitSignup = () => {
    var params = {
      Username: this.state.registerUsername,
      Firstname: this.state.firstname,
      Lastname: this.state.lastname,
      Email: this.state.email,
      Password: this.state.password,
      ConfirmPassword: this.state.confirmpassword
    };
    //var badResponse = false;
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    console.log('submitting create user params', params);

    const api = FhtFetchUrl(Configs.userRegistration);
    api
      .post(params)
      .then(response => {
        if (this.state.loading) {
          this.setState({ loading: false });
        }
        console.log('response:', response);
        if (response.errorMessage !== undefined) {
          console.log('error message received:', response.errorMessage);
          throw response.errorMessage;
        } else {
          this.props.GlobalFunctionDisplaySnackbarMessage(
            response.message,
            Configs.snackbarVariants.success
          );
          this.setState({ openRegisterAccountDialog: false });
          //Go to homepage
          //this.props.history.push('/');
        }
      })
      .catch(error => {
        if (this.state.loading) {
          this.setState({ loading: false });
        }
        this.props.GlobalFunctionDisplaySnackbarMessage(
          String(error),
          Configs.snackbarVariants.error
        );
      });
  };
  handleUserInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;
    let passwordMatch = false;
    switch (fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : 'Email is invalid';
        break;
      case 'password':
        let passwordlength = value.length >= 6;
        passwordMatch = this.state.confirmpassword === value;
        passwordValid = passwordlength && passwordMatch;
        fieldValidationErrors.password = passwordlength
          ? passwordMatch
            ? ''
            : 'Passwords not matching'
          : ' is too short';
        fieldValidationErrors.confirmpassword = passwordMatch ? '' : 'Passwords not matching';
        break;
      case 'confirmpassword':
        passwordMatch = this.state.password === value;
        passwordValid = passwordMatch;
        fieldValidationErrors.confirmpassword = passwordMatch ? '' : 'Passwords not matching';
        fieldValidationErrors.password = passwordMatch ? '' : 'Passwords not matching';
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailValid: emailValid,
        passwordValid: passwordValid
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({ formValid: this.state.emailValid && this.state.passwordValid });
  }
}

function Disclaimer() {
  var localBatchConfigContext = BatchSiteConfigurationContext();
  if (localBatchConfigContext['IsTorch'] == 'true') {
    return (
      <div>
        <Typography
          variant="body2"
          color="textSecondary"
          align="left"
          style={{ fontSize: 'small' }}
        >
          <b>Disclaimer:</b>{' '}
          {
            'Torch is used for research and educational purposes only. Clinicians must make their own independent assessment before suggesting a diagnosis or recommending or instituting a course of treatment, and are responsible for final decisions regarding the most appropriate clinical care '
          }
        </Typography>
      </div>
    );
  } else {
    return (
      <div>
        <Typography
          variant="body2"
          color="textSecondary"
          align="left"
          style={{ fontSize: 'small' }}
        >
          <b>Disclaimer:</b>{' '}
          {
            'FHT is used for research and educational purposes only. Clinicians must make their own independent assessment before suggesting a diagnosis or recommending or instituting a course of treatment, and are responsible for final decisions regarding the most appropriate clinical care'
          }
        </Typography>
      </div>
    );
  }
}

function PasswordResetInfo() {
  var localBatchConfigContext = BatchSiteConfigurationContext();
  if (localBatchConfigContext['IsTorch'] == 'true') {
    return (
      <div>
        <Typography
          variant="body2"
          color="textSecondary"
          align="left"
          style={{ fontSize: 'small' }}
        >
          <b>Note:</b>{' '}
          {
            'Password reset emails can only be sent to verified email addresses. If your email address has not been verified you will first be sent an email verification email from Amazon Web Services. Once you have verified your address, you can re-request a password reset email from Torch Recruit.'
          }
        </Typography>
      </div>
    );
  } else {
    return (
      <div>
        <Typography
          variant="body2"
          color="textSecondary"
          align="left"
          style={{ fontSize: 'small' }}
        >
          <b>Note:</b>{' '}
          {
            'Password reset emails can only be sent to verified email addresses. If your email address has not been verified you will first be sent an email verification email from Amazon Web Services. Once you have verified your address, you can re-request a password reset email from Future Health Today.'
          }
        </Typography>
      </div>
    );
  }
}

export default withMobileDialog()(LoginDialog);
