import React from 'react';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers/';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import FhtMain from 'FhtMain';
import FhtFetchUrl from 'Utils/FhtFetchUrl.jsx';
import Configs from 'Configuration';

export default class DeferCohortRecommendationsScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRecTestIDsToDefer: [],
            deferReason: "",
            deferUntilDate: null,
            activeRecommendationsRender: [],
        };
        this.onSubmitDeferCohortRecommendations = this.onSubmitDeferCohortRecommendations.bind(this);
    }

    componentDidMount() {
        var selectedRows = this.props.selectedRows;
        var algTestIDsToDescriptionDict = this.props.algTestIDsToDescriptionDict

        // Filter to only have FHT recommendations.
        var filteredAlgTestIDsToDescriptionDict = Object.fromEntries(Object.entries(algTestIDsToDescriptionDict)
                                                                    .filter(([k,v]) => v.fhtEnabledAnalysisSoftwareType != "2"));
        var activeRecommendations = []
        var localActiveRecommendationsRender = [
            <div key={"deferText"} style={{ paddingBottom: "1REM" }}>
                Please select the recommendations you would like to defer
            </div>
        ]

        // Get all active recommendations for selected patients
        for (var i = 0; i < selectedRows.length; i++) {
            try {
                var patientRecommendations = selectedRows[i].fhtRecommendationView.fhtPatientAnalysisMultiTestId.split(",")
                // Only add TestIDs if they are not already in our activeRecommendations array.
                for (var j = 0; j < patientRecommendations.length; j++) {
                    if (activeRecommendations.includes(patientRecommendations[j]) || patientRecommendations[j] == null)
                        continue;
                    activeRecommendations.push(patientRecommendations[j]);
                }
            } catch (e) {                 
                console.log(e)
            }
        }

        // Loop through all active recommendations for selected patients.
        for (var i = 0; i < activeRecommendations.length; i++) {
            try {
                var activeRecTestIDs = Number(activeRecommendations[i])

                // Ensure the TestID is in our filteredAlgTestIDsToDescriptionDict.
                if (activeRecTestIDs in filteredAlgTestIDsToDescriptionDict) {
                    // Skip any TestIDs if they are in the dictionary but do not have a valid description.
                    if (filteredAlgTestIDsToDescriptionDict[activeRecTestIDs].fhtEnabledAnalysisDescription == null
                        || filteredAlgTestIDsToDescriptionDict[activeRecTestIDs].fhtEnabledAnalysisDescription == "")
                        continue;

                    // Push the description with styling into our array render.
                    localActiveRecommendationsRender.push(
                        <div style={{ padding: "0.5REM" }} key={i}>
                            <Grid container>
                                <Grid item xs={1}>
                                    <Checkbox
                                        value={filteredAlgTestIDsToDescriptionDict[activeRecTestIDs].fhtEnabledAnalysisTestId}
                                        onClick={(e) => this.handleUserActionMenuDeferCohortRecommendation(
                                            e,
                                            this.state.selectedRecTestIDsToDefer,
                                        )
                                        }
                                    />
                                </Grid>
                                <Grid item xs={11}>
                                    {filteredAlgTestIDsToDescriptionDict[activeRecTestIDs].fhtEnabledAnalysisDescription}
                                </Grid>
                            </Grid>
                        </div>
                    )
                }
            } catch (e) {
                console.log(e)
            }
        }
        this.setState({
            activeRecommendationsRender: localActiveRecommendationsRender
        })
    }

    onSubmitDeferCohortRecommendations() {
        let selectedRows = this.props.selectedRows;
        var patientRecommendationDeferralData = []
        const utcDateToday = new Date().toUTCString();
        for (var i = 0; i < selectedRows.length; i++) {
            try {
                // Skip if any selectedRows have invalid fhtPatientAnalysisMultiTestId
                if (selectedRows[i].fhtRecommendationView.fhtPatientAnalysisMultiTestId == ""
                    || selectedRows[i].fhtRecommendationView.fhtPatientAnalysisMultiTestId == null)
                    continue;

                // Get all unique testIDs for current patient.
                var currentPatientRecommendations = Array.from(new Set(selectedRows[i].fhtRecommendationView.fhtPatientAnalysisMultiTestId.split(',')))

                // Loop through all selectedRecTestIDsToDefer.
                for (var j = 0; j < this.state.selectedRecTestIDsToDefer.length; j++) {

                    // Generate the Recommendation defer if the patient does have this recommendation from fhtPatientAnalysisMultiTestId.
                    if (currentPatientRecommendations.includes(this.state.selectedRecTestIDsToDefer[j])) {
                        patientRecommendationDeferralData.push({
                            recommendationPatientId: selectedRows[i].patientData.patientExtractId,
                            recommendationTestId: this.state.selectedRecTestIDsToDefer[j],
                            // Defer recommendations, set this as 2.
                            recommendationStatus: 2,                            
                            recommendationTriageExtractId: selectedRows[i].triageData.triageExtractId,
                            recommendationUpdateBy: FhtMain.GlobalContext.userName,
                            recommendationUpdateDate: utcDateToday,
                            // Defer recommendations, set this as 0.
                            recommendationPatientAnalysisId: 0,
                            recommendationReason: this.state.deferReason,
                            recommendationEndDate: this.state.deferUntilDate,
                        })
                    }
                }
            } catch (e) {
                console.log(e)
            }
        }

        const api = FhtFetchUrl(Configs.deferCohortRecommendationsSelectedPatients);
        api.post(patientRecommendationDeferralData).then(r => {
            this.props.GlobalFunctionDisplaySnackbarMessage(
                "Successfully deferred recommendation(s) for selected patient(s)",
                Configs.snackbarVariants.success
            );
            this.props.onBtCancelDeferCohortRecUserActionDialog()
        }).catch(error => {
            var msg = String(error);
            this.props.GlobalFunctionDisplaySnackbarMessage(
                "Error deferring recommendation(s) for selected patient(s). Error: " + msg,
                Configs.snackbarVariants.error
            );
        })
    };

    handleUserActionMenuDeferCohortRecommendation(
        event,
        selectedRecTestIDsToDefer,
    ) {
        var deepCopyDeferCohortRecsSelectedTestIDs = JSON.parse(JSON.stringify(selectedRecTestIDsToDefer))

        // Add to selectedRecTestIDsToDefer if check box ticked
        // and selectedRecTestIDsToDefer does not contain ID.
        if (event.target.checked && !deepCopyDeferCohortRecsSelectedTestIDs.includes(event.target.value)) {
            deepCopyDeferCohortRecsSelectedTestIDs.push(event.target.value)
            this.handleDeferCohortRecsSelectedTestIDs(deepCopyDeferCohortRecsSelectedTestIDs)
        }

        // Remove from selectedRecTestIDsToDefer if check box not ticked
        // and selectedRecTestIDsToDefer does contain ID.
        if (!event.target.checked && deepCopyDeferCohortRecsSelectedTestIDs.includes(event.target.value)) {
            this.handleDeferCohortRecsSelectedTestIDs(deepCopyDeferCohortRecsSelectedTestIDs.filter(x => x !== event.target.value))
        }
    }

    handleDeferCohortRecsSelectedTestIDs(updatedSelectedTestIDs) {
        this.setState({
            selectedRecTestIDsToDefer: updatedSelectedTestIDs
        })
    }
    handleDeferReasonChange(e) {
        this.setState({
            deferReason: e.target.value
        })
    }
    handleDeferDateChange(e) {
        this.setState({
            deferUntilDate: e
        })
    }

    render() {
        return (
            <div>
                {this.state.activeRecommendationsRender}
                <div style={{ paddingTop: "1REM" }}>
                    <div>
                        <TextField
                            style={{ width: "100%" }}
                            variant="outlined" multiline
                            minRows={10}
                            placeholder="Defer Reason"
                            onChange={(e) => {
                                this.handleDeferReasonChange(e);
                            }}
                        />
                    </div>
                    <div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker inputVariant="outlined"
                                variant="inline"
                                margin="normal"
                                label="Defer Until Date"
                                format="dd/MM/yyyy"
                                value={this.state.deferUntilDate}
                                minDate={new Date()}
                                onChange={(e) => {
                                    this.handleDeferDateChange(e);
                                }} />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div>
                        You can also defer individual recommendations via the Patient Summary Recommendations submenu.
                        <div>
                        When the required data above are populated, the confirm button will be enabled.
                        </div>
                    </div>
                    <Button
                        variant="contained"
                        fullWidth
                        style={{ margin: '24px 0px 16px' }}
                        color="primary"
                        disabled={
                            this.state.deferReason == ""
                            || this.state.deferUntilDate == null
                            || this.state.selectedRecTestIDsToDefer.length == 0
                        }
                        onClick={() => this.onSubmitDeferCohortRecommendations()}
                    >
                        Confirm
                    </Button>
                    <small>Provide a defer reason, defer date and select recommendation(s) to defer recommendations.</small>
                    <Button
                        variant="contained"
                        fullWidth
                        style={{ margin: '24px 0px 16px' }}
                        color="primary"
                        onClick={() => this.props.onBtCancelDeferCohortRecUserActionDialog()}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        );
    }
}
