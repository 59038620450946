import React from 'react';
//import Configs from 'Configuration';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
//import SiteConfigurationContext from "Utils/SiteConfigurationContext.jsx";
//import FhtMain from "FhtMain";
import withStyles from '@material-ui/core/styles/withStyles';
import Pagination from '@material-ui/lab/Pagination';
//import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Link, Redirect } from 'react-router-dom';
import * as _ from 'underscore';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
//import { useHistory } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import PhoneIcon from '@material-ui/icons/Phone';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';

import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialTable from 'material-table';
import Configs from '../../Configuration';

import './NavigationPageScreenCSS.css';
import zIndex from '@material-ui/core/styles/zIndex';

// import TableContainer from "@material-ui/core/TableContainer";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import TextLoop from 'react-text-loop';
import FhtFetchUrl from 'Utils/FhtFetchUrl.jsx';
import SiteConfigurationContext from 'Utils/SiteConfigurationContext.jsx';
import BatchSiteConfigurationContext from 'Utils/BatchSiteConfigurationContext.jsx';

import { Responsive, WidthProvider } from 'react-grid-layout';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Collapse from '@material-ui/core/Collapse';

import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import { green } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
//import ReactGA from "react-ga4";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const positiveHealthFacts = [
  <span style={{ whiteSpace: 'normal', display: 'block', width: '600px', wordWrap: 'break-word' }}>
    NEWS PAGE
  </span>
];
const useStyles = theme => ({
  card: {
    maxWidth: false,
    height: '20REM'
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    width: '80%'
  },
  indicator: {
    backgroundColor: '#006563'
  },
  root: {
    maxWidth: 345
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: green[200]
  }
});

const DummyCaption = ({ caption }) => (
  <div
    style={{
      textAlign: 'center',
      fontSize: 24,
      padding: 20,
      border: 'solid 1px'
    }}
  >
    {caption}
  </div>
);

var colorGradientFaint1 = '#279593';
var colorGradientFaint2 = '#489594';
var colorGradientFaintMouseover = '#889593';

class NavigationPageScreenNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      //siteConfigurationContext:SiteConfigurationContext(),
      tabValue: 0,

      isDefaultNavigationPageClicked: false,

      jsonData: [],

      isCheckBoxClicked: false,

      tabOne: { backgroundColor: colorGradientFaintMouseover },
      tabTwo: { backgroundColor: colorGradientFaintMouseover },
      tabThree: { backgroundColor: colorGradientFaintMouseover },

      combinedHealthInfo: '',

      newsJSONData: '',
      currentPage: '',
      expanded: false,
      batchSiteConfigurationContext: BatchSiteConfigurationContext(),
    };
    this.apiData();
    this.siteConfiguration = SiteConfigurationContext();

    this.setState({
      combinedHealthInfo: positiveHealthFacts.join(' ')
    });
  }
  apiData = () => {};
  handleTabChange = (event, newValue) => {
    console.log('handleTabChange clicked');
    this.setState({ tabValue: newValue });
  };
  onBtnSubmitNavigatePage = () => {
    console.log('navigating to page based on value.');
    // This doesn't seem to be working.
    console.log(this.props.userNameOfUser);
    //const history = useHistory();

    if (this.state.isDefaultNavigationPageClicked == false) {
      var dictionary = { userID: 5, isNavigationPageDefault: false };
      localStorage.setItem('checkNavigationPageSetting', JSON.stringify(dictionary));
    } else if (this.state.isDefaultNavigationPageClicked == true) {
      var dictionary = { userID: 5, isNavigationPageDefault: true };
      localStorage.setItem('checkNavigationPageSetting', JSON.stringify(dictionary));
    }

    if (this.state.tabValue == 0) {
      this.props.history.push('/indCohortScreen');
    } else if (this.state.tabValue == 1) {
      this.props.history.push('/approve');
    } else if (this.state.tabValue == 2) {
      this.props.history.push('/recall');
    }

    console.log(JSON.parse(localStorage.getItem('checkNavigationPageSetting')));
  };

  checkDefaultNavigationPage() {
    if (this.state.isDefaultNavigationPageClicked == false) {
      this.setState({
        isDefaultNavigationPageClicked: true
      });
    } else if (this.state.isDefaultNavigationPageClicked == true) {
      this.setState({
        isDefaultNavigationPageClicked: false
      });
    }

    // This is to double check the state is being changed.
    // Need timer because delay is required.
    setTimeout(
      function() {
        //Start the timer
        console.log(this.state.isDefaultNavigationPageClicked); //After 1 second, set render to true
      }.bind(this),
      1000
    );
  }

  createData(text, date, title, image, index) {
    return { text, date, title, image, index };
  }

  toggleTabOneOnEnterHover() {
    this.setState({
      tabOne: { backgroundColor: colorGradientFaintMouseover }
    });
  }
  toggleTabOneOnExit() {
    this.setState({
      tabOne: { backgroundColor: 'transparent' }
    });
  }

  toggleTabTwoOnEnterHover() {
    this.setState({
      tabTwo: { backgroundColor: colorGradientFaintMouseover }
    });
  }
  toggleTabTwoOnExit() {
    this.setState({
      tabTwo: { backgroundColor: 'transparent' }
    });
  }

  toggleTabThreeOnEnterHover() {
    this.setState({
      tabThree: { backgroundColor: colorGradientFaintMouseover }
    });
  }
  toggleTabThreeOnExit() {
    this.setState({
      tabThree: { backgroundColor: 'transparent' }
    });
  }

  changeTabValue(number) {
    this.setState({
      tabValue: number
    });
  }

  changePage(e) {
    console.log('CHANING PAGE');

    this.setState({
      currentPage: 'showNews'
    });
  }


  onClickApproveActions = () => {
    this.props.history.push('/approve');
  };
  onClickCreateCohortScreen = () => {
    this.props.history.push('/createCohortScreen');
  };
  onClickActionRequiredCohortScreen = () => {
    this.props.history.push('/actionRequiredCohortScreen');
  };
  onClickRecall = () => {
    this.props.history.push('/recall');
  };
  handleCardClick = e => {
    console.log('link clicked:', e);
  };
  render() {
    const { classes } = this.props;
    let newsPanel = <div></div>;
    
    if (this.state.batchSiteConfigurationContext["fhtTrialNews"] === "true")
    {
      newsPanel = (
        <Card className={classes.root}>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                New
              </Avatar>
            }
            title="News for FHT"
            subheader="December 8, 2021"
          />
          <CardContent>
            <Typography variant="header1" color="textPrimary" component="p">
            Coming up to accreditation?
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
            FHT can help you with meeting accreditation requirement of participating in QI activities. This includes the FHT QI webform which can help administer, manage, and document your QI work. Feel free to explore it from the QI menu. Welcome to contact your clinical coordinator for more information: contact-FHT@unimelb.edu.au
            </Typography>
            <br />
            <br />
            <Typography variant="header1" color="textPrimary" component="p">
            Check out the FHT Resource Library
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
            The resource menu provides a library of chronic disease guidelines and resources for practitioners and consumers.
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" href={'mailto:FHT@unimelb.edu.au?subject=FHT%20Information'}>Email clinical coordinator </Button>
            
          </CardActions>
        </Card>
      );      
    }
   
    
    return (
      <div>
        {/* <div><a href="mailto:FHT@unimelb.edu.au?subject=FHT%20Information%20Education"></a>FHT@unimelb.edu.au</div> */}
        <div 
          style={{ 
            backgroundImage: `url("https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FHTBackgroundApril.jpg")`, 
            height:'100%',
            width:'100%',
            position: "absolute",
            top: "0",
            backgroundPosition: "center center", 
            backgroundRepeat: "no-repeat", 
            backgroundSize: "cover",
            zIndex: "-1000",
        }}></div>

        <div className={classes.root}></div>
        <ResponsiveReactGridLayout
          className="reviewCohortLayout"
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          rowHeight={25}
          //</div>layouts={this.state.globalSummaryNumberLayout}
          onDrag={this.onDrag}
          onDragStop={this.onDragStop}
          onResizeStart={this.onResizeStart}
          onResizeStop={this.onResizeStop}
          isResizable={false}
        >
          <div
            key="newspanelKey"
            style={{}}
            data-grid={{ x: 0, y: 1, w: 4, h: 2, static: true, isResizable: false }}
          >
            {newsPanel}
          </div>
          <div
            key="navigationAreaKey"
            style={{}}
            data-grid={{ x: 3, y: 8, w: 6, h: 8, static: true, isResizable: false }}
          >
            <Paper>
              <AppBar>
                <Tabs
                  value={this.state.tabValue}
                  onChange={this.handleTabChange}
                  indicatorColor="primary"
                  classes={{ indicator: classes.indicator }}
                  variant="fullWidth"
                  style={{
                    backgroundImage:
                      'linear-gradient(to right, ' +
                      colorGradientFaint1 +
                      ', ' +
                      colorGradientFaint2 +
                      ')',
                    color: 'white'
                  }}
                >
                 
                 
                  <Tab
                    onMouseEnter={this.changeTabValue.bind(this, 0)}
                    className="onHover"
                    style={{ color: 'white', textTransform: 'none', fontSize: '16px' }}
                    //href = "/createCohortScreen"
                    onClick={e => this.onClickCreateCohortScreen()}
                    label="Create New Cohort"
                    icon={
                      <AddCircleOutlineIcon style={{ verticalAlign: 'middle', fontSize: 24 }} />
                    }
                  />
                  

               
                              
                  <Tab
                    onMouseEnter={this.changeTabValue.bind(this, 1)}
                    className="onHover"
                    style={{ color: 'white', textTransform: 'none', fontSize: '16px' }}
                    //href = "/actionRequiredCohortScreen"
                    onClick={e => this.onClickActionRequiredCohortScreen()}
                    label="
                Review My Cohort"
                    icon={<AssignmentIndIcon style={{ verticalAlign: 'middle', fontSize: 24 }} />}
                  />
                  
                  {/*
                  <Tab
                    onMouseEnter={this.changeTabValue.bind(this, 2)}
                    className="onHover"
                    style={{ color: 'white', textTransform: 'none', fontSize: '16px' }}
                    onClick={e => this.onClickApproveActions()}
                    label="Approve Proposed Actions"
                    icon={
                      <CheckCircleOutlineIcon style={{ verticalAlign: 'middle', fontSize: 24 }} />
                    }
                  />
                  */}
                  { (this.state.batchSiteConfigurationContext['CohortRecallDefer'] == 'false') &&
                  <Tab
                    onMouseEnter={this.changeTabValue.bind(this, 3)}
                    className="onHover"
                    style={{ color: 'white', textTransform: 'none', fontSize: '16px' }}
                    //href = "/recall"
                    onClick={e => this.onClickRecall()}
                    label="Recall Patients"
                    icon={<PhoneIcon style={{ verticalAlign: 'middle', fontSize: 24 }} />}
                  />
                  }
                </Tabs>
              </AppBar>
              <TabPanel
                style={{ marginTop: '5REM', minHeight: 150, maxHeight: '100%', paddingTop: '1%' }}
                value={this.state.tabValue}
                index={0}
              >
                Click here to create a new cohort of patients within your practice. You can then use
                the cohort for QI activities, and monitor the progress of the cohort through the
                cohort dashboard. You can also add and remove patients from the cohort if need be
                over time.
              </TabPanel>

              <TabPanel
                style={{ marginTop: '5REM', minHeight: 150, maxHeight: '100%', paddingTop: '1%' }}
                value={this.state.tabValue}
                index={1}
              >
                This review function allows you to review your cohorts to help see and decide what
                action you need to proceed with. You can also see how the cohort has progressed over
                time.
              </TabPanel>
              <TabPanel
                style={{ marginTop: '5REM', minHeight: 150, maxHeight: '100%', paddingTop: '1%' }}
                value={this.state.tabValue}
                index={2}
              >
                Click here to display all patients that have been proposed for recall or deferral
                but require your approval before they can proceed. This function is typically for
                GPs and will display all patients that have you recorded as their “Usual GP” and
                waiting for approval, irrespective of which cohort they might be in. Once approved,
                they will proceed to the Recall screen for the authorized person to undertake the
                recall, or to the deferred screen if they were proposed for deferral.
              </TabPanel>
              <TabPanel
                style={{ marginTop: '5REM', minHeight: 150, maxHeight: '100%', paddingTop: '1%' }}
                value={this.state.tabValue}
                index={3}
              >
                Click here to display all patients, irrespective of which cohort they might be in,
                that have been approved and waiting for recall. Once they have been marked as having
                their recall organised, the patient will move to the ‘Patients Awaiting
                consultation’ status, and can be reviewed within the Cohorts drop down menu option.
              </TabPanel>
              <TabPanel
                style={{ marginTop: '5REM', minHeight: 150, maxHeight: '100%', paddingTop: '1%' }}
                value={this.state.tabValue}
                index={4}
              >
                Click here to create a new cohort of patients within your practice. You can then use
                the cohort for QI activities, and monitor the progress of the cohort through the
                cohort dashboard. You can also add and remove patients from the cohort if need be
                over time.
              </TabPanel>
            </Paper>
          </div>
        </ResponsiveReactGridLayout>

        <div
          style={{
            position: 'absolute',
            right: '0',
            bottom: '0',
            marginBottom: '4REM',
            marginRight: '4REM'
          }}
        >
         
          <Paper style={{ background: 'transparent' }} square={false}>
            <img src={'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/fhtlogomin.png'} style={{ height: '7REM', width: '7REM' }}></img>
          </Paper>
          
         
        </div>
      </div>
    );
  }
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
export default withStyles(useStyles)(NavigationPageScreenNews);
