import React, { Component } from "react";
import {
  createTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles/';
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const muiTheme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1em"
      }
    }
  }
});

const useStyles = theme => ({
  formDetail: {
    width: '100%',
    marginTop: '2REM'
  },
  table: {
    margin: '2REM'
  },
  inputText: {
    textAlign: 'left'
  },
});

class QIActivityFacilitator extends Component {
  constructor(props) {
    super(props);
    this.participants = this.props.participants
    this.state = {
      qiPaticipantEnabledId: this.props.qiPaticipantEnabledId
    }
  }

  handleFacilitatorChange = (event) => {
    var selectedFacilitator = this.participants.filter(x => x.qiPaticipantEnabledId == event.target.value)[0];
    this.setState({ qiPaticipantEnabledId: selectedFacilitator.qiPaticipantEnabledId });
    this.props.updateParentFacilitator(selectedFacilitator)
  }

  renderActivityFacilitator() {
    const { classes } = this.props;
    return (
      <Tooltip arrow disableFocusListener disableTouchListener
        title="Choose the Person who will be the Activity Facilitator">
        <FormControl className={classes.formDetail} variant="outlined">
          <InputLabel id="facilitator">Activity Facilitator</InputLabel>
          <Select className={classes.inputText} labelId="facilitator" label="Activity Facilitator"
            value={this.state.qiPaticipantEnabledId}
            onChange={this.handleFacilitatorChange}>
            {this.participants.map(r => (
              <MenuItem key={r.qiPaticipantEnabledId} value={r.qiPaticipantEnabledId}>{r.qiPaticipantEnabledName} {r.qiPaticipantEnabledActive === 0 ? '[Inactive]' : ''}</MenuItem>
            ))}
          </Select>
        </FormControl >
      </Tooltip>
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.formDetail}>
        {this.renderActivityFacilitator()}
      </div>
    );
  }
}


export default withStyles(useStyles)(QIActivityFacilitator);