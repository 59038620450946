import React from 'react';

import Configs from 'Configuration';
//import Jwt_decode from "jwt-decode";

import { AgGridReact } from 'ag-grid-react';

//utils
import ProposedActionCellRenderer from 'Utils/ProposedActionCellRenderer.utils.jsx';

//Style sheets
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Print from '@material-ui/icons/Print';
import SaveAlt from '@material-ui/icons/SaveAlt';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import SiteConfigurationContext from 'Utils/SiteConfigurationContext.jsx';
import FhtMain from 'FhtMain';
import FhtFetchUrl from 'Utils/FhtFetchUrl.jsx';
import CDialog from 'components/CDialog';
import PersonAddDisabled from '@material-ui/icons/PersonAddDisabled';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ChildMessageRenderer from './ChildMessageRenderer.jsx';
import CommentDialog from 'FhtMainComponents/CommentDialog.jsx';
import BatchSiteConfigurationContext from 'Utils/BatchSiteConfigurationContext.jsx';

const paddingTop = {
  padding: '2REM 2REM'
};

const smallPaddingRight = {
  paddingRight: '0.5REM'
};

class PatientRecallScreenSequential extends React.Component {
  constructor(props) {
    super(props);
    this.batchSiteConfigurationContext = BatchSiteConfigurationContext();
    this.state = {
      data: [],
      loading: true,
      categoryTriage: null,
      displayTutorial: false,
      deferMonths: 1,
      deferReason: 'N/A',
      deferDialogVisible: false,
      deferPermanentChecked: false,
      disableSpinDefer: false,
      isChecked: false,
      width: window.innerWidth,
      height: window.innerHeight * 0.78,
      siteConfigurationContext: SiteConfigurationContext(),
      forWebAPIFilterSearchCohortId: -1,
      forWebAPIFilterWorkflowId: -1,
      forWebAPIDeferWorkflowId: -1,
      forWebAPIExistingRec: null,
      aggcontext: { componentParent: this },
      currentPatientId: 0,
      showCommentDialog: false,
      comments: null,
    };
    console.log('In PatientRecallScreenSequential');
    //this.apiData();
  }

  apiData() {
    console.log('Getting recall list for cohort');
    let body = { CohortId: this.state.forWebAPIFilterSearchCohortId, Name: 'Ignore',ExistingRec: this.state.forWebAPIExistingRec };
    const api = FhtFetchUrl(Configs.getRecallListForCohort);
    api
      .post(body)
      .then(response => {
        console.log(response);
        this.setState({ data: response.data, loading: false });
      })
      .catch(error => {
        var msg = String(error);

        this.props.GlobalFunctionDisplaySnackbarMessage(
          'Error:' + msg,
          Configs.snackbarVariants.error
        );

        this.setState({ data: [], loading: false });
      });

    /*
    let url = `${this.state.siteConfigurationContext[Configs.SiteConfigurationMappingKeys.serverapi]}${Configs.triageAppointmentScreen}`;
    let urlObj = Configs.urlGetObj;
    let urlHeader = Object.assign({Authorization:`Bearer ${FhtMain.GlobalContext.userToken}`}, Configs.urlHeader.headers);
    urlObj = Object.assign({headers: urlHeader}, urlObj);
    fetch(url, urlObj)
      .then(r => r.json())
      .then(response => {
        this.setState({data: response.data});
        this.setState({loading: false});
      })
      .catch(e => {
        this.setState({data: []});
        this.setState({loading: false});
      })
      */
  }

  onGridReady = params => {
    console.log('On grid ready');
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (this.props.location.search != '' && this.props.location.search != null) {
      let searchParams = new URLSearchParams(this.props.location.search);
      let tempExistingRec = -1;
      let tempCohortIdValue = -1;
      let tempWorkflowStageId = -1;
      let tempDeferWorkflowId = -1;
      if (searchParams.has('cohortid')) {
          tempCohortIdValue = searchParams.get('cohortid');
      }
      if (searchParams.has('activeRec')) {
          tempExistingRec = searchParams.get('activeRec');
      }
      if (tempCohortIdValue !== -1) {
        this.setState(
          {
            forWebAPIFilterSearchCohortId: tempCohortIdValue,
            forWebAPIFilterWorkflowId: tempWorkflowStageId,
            forWebAPIDeferWorkflowId: tempDeferWorkflowId,
            forWebAPIExistingRec: tempExistingRec,
          },
          () => {
            this.apiData();
          }
        );
      }
    } else this.apiData();
    this.closeToolPanel();
  };

  onColumnResized(event) {
    if (event.finished) {
      this.gridApi.resetRowHeights();
    }
  }

  closeToolPanel = () => {
    this.gridApi.closeToolPanel();
  };

  onBtPrint = () => {
    this.setPrinterFriendly();
    setTimeout(() => {
      window.print();
      this.setNormal();
    }, 2000);
  };

  setPrinterFriendly = () => {
    let eGridDiv = document.querySelector('.my-grid');
    eGridDiv.style.width = '';
    eGridDiv.style.height = '';
    this.gridApi.setDomLayout('print');
  };

  setNormal = () => {
    let eGridDiv = document.querySelector('.my-grid');
    eGridDiv.style.height = '800px';
    this.gridApi.setDomLayout(null);
  };

  onBtExport = () => {
    let options = {
      fileName: 'Recall Patient'
    };
    this.gridApi.exportDataAsCsv(options);
  };

  onSubmitRecallForApproval = () => {
    let sRows = this.gridApi.getSelectedRows();
    let holdAllTempRecords = [];
    //let messageId = 1;
    //var messageId = [row.recallRecallReason];
    sRows.forEach(row => {
      console.log('Row:', row);
      if (row.patientExtractId != null) {
        holdAllTempRecords.push({
          patientId: row.patientExtractId,
          messageId: [row.recallRecallReason]
        });
      }
    });
    let TriageForServer = { triageItems: holdAllTempRecords };
    let url = `${
      this.state.siteConfigurationContext[Configs.SiteConfigurationMappingKeys.serverapi]
    }${Configs.submitAwaitingConsultation}`;
    let manualUrlObj = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'cache-control': 'no-cache',
        Authorization: 'Bearer ' + FhtMain.GlobalContext.userToken
      },
      body: JSON.stringify(TriageForServer)
    };

    fetch(url, manualUrlObj)
      .then(r => r.json())
      .then(response => {
        // this.setState({data: response.data});
        this.apiData();
      })
      .catch(e => {
        console.log('error');
        console.log(e);
      });
  };

  onSelectionChanged = () => {
    this.setState({
      isChecked: this.gridApi.getSelectedRows().length > 0
    });
  };

  blurFilters = () => {
    return this.state.displayTutorial ? { filter: 'blur(8px)' } : {};
  };

  defaultColDef = () => {
    return {
      editable: false,
      width: 150,
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      filterParams: {}
    };
  };
  onDeferPermanentChecked = () => {
    this.setState({
      deferPermanentChecked: !this.state.deferPermanentChecked,
      disableSpinDefer: !this.state.disableSpinDefer
    });
  };
  onBtnOpenCommentDialog = (patientId) => {
    console.log('patientId, this.state.forWebAPIFilterSearchCohortId')
      this.setState({ currentPatientId: patientId});
      this.setState({showCommentDialog:true});
  }
  showMessagesFromComment = (msg, type) => {
    this.props.GlobalFunctionDisplaySnackbarMessage(msg, type);
  };

  aggridColumns = () => {
    return [
      {
        field: 'patientExtractId',
        headerName: 'Id',
        filter: 'agNumberColumnFilter',
        width: 300,
        hide: true
      },
      {
        field: '',
        headerName: 'Comments',
        //filter: 'agTextColumnFilter',
        width: 200,
        cellRendererFramework: ChildMessageRenderer,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        //hide: true
      },
      
      
      {
        field: 'patientSurname',
        headerName: 'Surname',
        filter: 'agTextColumnFilter',
        width: 300,
       // checkboxSelection: true,
        //headerCheckboxSelection: true,
        //headerCheckboxSelectionFilteredOnly: true
      },
      
      {
        field: 'patientFirstname',
        headerName: 'Firstname',
        filter: 'agTextColumnFilter',
        width: 300
      },
      { field: 'patientAge', headerName: 'Age', filter: 'agNumberColumnFilter' },
      { field: 'patientGender', headerName: 'Gender', filter: 'agTextColumnFilter' },
      //no workflow status here because it is not applicable - we are in the awaiting approval screen.
      { field: 'preferredDoctor', headerName: 'Usual doctor', filter: 'agTextColumnFilter' },
      {
        field: 'patientNextAppointment',
        headerName: 'Next Appointment',
        filter: 'agDateColumnFilter',
        valueGetter: function getDateGetter(params) {
          if (params.data != null) return getDate(params.data.patientNextAppointment);
          return '';
        }
      },
      {
        field: 'patientLastAppointment',
        headerName: 'Last Appointment',
        filter: 'agDateColumnFilter',
        valueGetter: function getDateGetter(params) {
          if (params.data != null) return getDate(params.data.patientLastAppointment);
          return '';
        }
      },
      {
        field: 'patientAtsi',
        headerName: 'First Nation',
        filter: 'agTextColumnFilter',
        valueGetter: function yesNoGetter(params) {
          if (params.data != null) return getYesNo(params.data.patientAtsi);
          return '';
        }
      },
      {
        field: 'patientSmoker',
        headerName: 'Smoker',
        filter: 'agTextColumnFilter',
        valueGetter: function yesNoGetter(params) {
          if (params.data != null) return getYesNo(params.data.patientSmoker);
          return '';
        }
      },

      {
        field: 'patientMobileNumber',
        headerName: 'Contact Number',
        filter: 'agTextColumnFilter',
        width: 300
      },
      { field: 'patientAddress', headerName: 'Address', filter: 'agTextColumnFilter', width: 300 },
      { field: 'patientEmail', headerName: 'Email', filter: 'agTextColumnFilter', width: 300 },
      { field: 'recallRecallReason', headerName: 'Recall Reason', filter: 'agTextColumnFilter' },
      {
        field: 'recallDateCreated',
        headerName: 'Created date',
        filter: 'agDateColumnFilter',
        width: 200,
        valueGetter: function getDateGetter(params) {
          if (params.data != null) return getDate(params.data.recallDateCreated);
          return '';
        }
      }
    ];
  };

  componentsDef = () => {
    return { ProposedActionCellRenderer: ProposedActionCellRenderer('N/A') };
  };
  onApprovalDeferredPatient = () => {
    this.setState({ deferDialogVisible: true });
  };

  //Render Functions
  renderAgGrid = () => {

    return (
      <AgGridReact
        onGridReady={this.onGridReady}
        defaultColDef={this.defaultColDef()}
        rowSelection="multiple"
        onSelectionChanged={this.onSelectionChanged}
        floatingFilter={true}
        singleClickEdit={true}
        columnDefs={this.aggridColumns()}
        groupSelectsChildren={true}
        sideBar={true}
        groupIncludeFooter={true}
        rowData={this.state.data}
        headerHeight={40}
        components={this.componentsDef()}
        masterDetail={false}
        detailCellRenderer={this.state.detailCellRenderer}
        detailRowHeight={800}
        detailRowWidth={1880}
        groupDefaultExpanded={0}
        frameworkComponents={this.state.frameworkComponents}
        context={this.state.aggcontext}
        onColumnResized={this.onColumnResized.bind(this)}
        //onColumnResized={this.onColumnResized}>
      ></AgGridReact>
    );
  };
  onBtnSubmitDefer = () => {
    //Retrieve marked records from the datagrid.
    console.log('Submit defer');
    let sRows = this.gridApi.getSelectedRows();
    let holdAllTempRecords = [];
    let monthsDeferred = 0;
    if (this.state.deferPermanentChecked) {
      monthsDeferred = 9999;
    } else {
      monthsDeferred = this.state.deferMonths;
    }
    for (let prop in sRows) {
      if (sRows[prop].patientExtractId != null) {
        holdAllTempRecords.push({
          PatientId: sRows[prop].patientExtractId,
          DeferLength: monthsDeferred,
          DeferReason: this.state.deferReason,
          DeferType: 1
        });
      }
    }

    let url = Configs.submitPatientDeferral;
    let TriageForServer = { DeferPatientItems: holdAllTempRecords };
    let body = TriageForServer;
    console.log('Before fhtFetchURL');
    const api = FhtFetchUrl(url);
    console.log('After fhtFetchURL');
    api
      .post(body)
      .then(response => {
        this.gridApi.deselectAll();
        this.apiData();
        this.setState({ deferDialogVisible: false });
        this.props.GlobalFunctionDisplaySnackbarMessage(response, Configs.snackbarVariants.success);
      })
      .catch(error => {
        var msg = String(error);
        this.props.GlobalFunctionDisplaySnackbarMessage(msg, Configs.snackbarVariants.error);
      });
  };
  render() {
    const deferDialogFooter = (
      <Button
        variant="contained"
        fullWidth
        variant="contained"
        color="primary"
        style={{ margin: '24px 0px 16px' }}
        color="primary"
        onClick={this.onBtnSubmitDefer}
      >
        Defer
      </Button>
    );    
    return (
      <div>
        <div className="grid-container" style={this.blurFilters()}>
          <Paper>
            <div style={paddingTop}>
              <span style={{ textAlign: 'left', fontSize: 18, fontWeight: 'bold' }}>
                Patients to be recalled
              </span>
              <span style={{ width: '5REM', height: 'auto', display: 'inline-block' }}></span>
              <Button
                variant="outlined"
                color="inherit"
                style={{ paddingRight: '1REM', paddingLeft: '1REM' }}
                onClick={this.onSubmitRecallForApproval}
                disabled={!this.state.isChecked}
              >
                <span style={smallPaddingRight}>
                  <CheckCircleOutline />
                </span>
                Mark as Recalled
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                style={{ paddingRight: '1REM', paddingLeft: '1REM' }}
                onClick={(e)=>this.setState({deferDialogVisible: true})}
                disabled={!this.state.isChecked}
              >
                <span style={smallPaddingRight}>
                  <CheckCircleOutline />
                </span>
                Defer
              </Button>              
              <Button
                variant="outlined"
                color="inherit"
                style={{ paddingRight: '1REM', float: 'right' }}
                onClick={this.onBtPrint}
              >
                <span style={smallPaddingRight}>
                  <Print />
                </span>
                Print
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                style={{ paddingRight: '1REM', float: 'right' }}
                onClick={this.onBtExport}
              >
                <span style={smallPaddingRight}>
                  <SaveAlt />
                </span>
                Export to CSV
              </Button>
            </div>
          </Paper>
          <Paper style={{ backgroundColor: 'transparent', height: '100%' }}>
            <div
              id="agRecallGrid"
              className="ag-theme-material my-grid"
              style={{ height: `${this.state.height}px` }}
            >
              {this.renderAgGrid()}
            </div>
          </Paper>
        </div>
        <CDialog
          header="Defer Patient"
          headerIcon={<PersonAddDisabled />}
          visible={this.state.deferDialogVisible}
          footer={deferDialogFooter}
          onSubmit={e => this.onBtnSubmitDefer()}
          onHide={this.deferOnHide}
          maxWidth="xs"
        >
          <TextField
            fullWidth
            id="month"
            label="Month"
            value={this.state.deferMonths}
            onChange={e => this.setState({ deferMonths: e.target.value })}
            type="number"
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{ min: '1', max: '24', step: '1' }}
            margin="normal"
            variant="outlined"
            required
            autoFocus
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.deferPermanentChecked}
                onChange={e => this.onDeferPermanentChecked()}
                value={this.state.deferPermanentChecked}
              />
            }
            label="Defer permanently"
          />
          <TextField
            id="outlined-multiline-flexible"
            label="Reason"
            multiline
            rows="4"
            fullWidth
            required
            value={this.state.deferReason}
            onChange={e => this.setState({ deferReason: e.target.value })}
            margin="normal"
            variant="outlined"
          />
        </CDialog>
        <CommentDialog header=""
          visible={this.state.showCommentDialog}
          //footer={deferDialogFooter}
          //onSubmit={e => this.onBtnSubmitDefer()}
          onHide={e => this.setState({showCommentDialog: false})}
          maxWidth="false"
          open={this.state.showCommentDialog}
          patientId={this.state.currentPatientId}
          cohortId={this.state.forWebAPIFilterSearchCohortId}
          showMessages={this.showMessagesFromComment}
        >
        </CommentDialog>

      </div>
    );
  }
}
function getDate(params) {
  // eslint-disable-next-line
  if (params == null || params == undefined) return '';
  let arr = params.split('T')[0].split('-');
  let convertDate = `${arr[2]}-${arr[1]}-${arr[0]}`;
  // return params.split("T")[0];
  return convertDate;
}

function getYesNo(integerValue) {
  let result = 'No';
  if (integerValue === 1) result = 'Yes';
  return result;
}
export default PatientRecallScreenSequential;
