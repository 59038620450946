import React from 'react';

import Configs from 'Configuration';
//import Jwt_decode from "jwt-decode";

import { AgGridReact } from 'ag-grid-react';

//utils
import ProposedActionCellRenderer from 'Utils/ProposedActionCellRenderer.utils.jsx';

//Style sheets
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Print from '@material-ui/icons/Print';
import SaveAlt from '@material-ui/icons/SaveAlt';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import SiteConfigurationContext from 'Utils/SiteConfigurationContext.jsx';
import FhtMain from 'FhtMain';
import FhtFetchUrl from 'Utils/FhtFetchUrl.jsx';
import ChildMessageRenderer from './ChildMessageRenderer.jsx';
import CommentDialog from 'FhtMainComponents/CommentDialog.jsx';

const paddingTop = {
  padding: '2REM 2REM'
};

const smallPaddingRight = {
  paddingRight: '0.5REM'
};

class PatientDeferScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      categoryTriage: null,
      displayTutorial: false,
      deferMonths: 1,
      deferReason: 'N/A',
      deferDialogVisible: false,
      deferPermanentChecked: false,
      disableSpinDefer: false,
      isChecked: false,
      width: window.innerWidth,
      height: window.innerHeight * 0.78,
      siteConfigurationContext: SiteConfigurationContext(),
      forWebAPIFilterSearchCohortId: -1,
      forWebAPIFilterWorkflowId: -1,
      forWebAPIDeferWorkflowId: -1,
      aggcontext: { componentParent: this },
      currentPatientId: 0,
      showCommentDialog: false,
      comments: null,
    };
    console.log('In PatientDeferScreen');
    //this.apiData();
  }

  apiData() {
    console.log('Getting defer list for cohort');
    let body = { CohortId: this.state.forWebAPIFilterSearchCohortId, Name: 'Ignore' };
    const api = FhtFetchUrl(Configs.getDeferListForCohort);
    api
      .post(body)
      .then(response => {
        console.log('response:', response);
        this.setState({ data: response.data, loading: false });
      })
      .catch(error => {
        var msg = String(error);

        this.props.GlobalFunctionDisplaySnackbarMessage(
          'Error:' + msg,
          Configs.snackbarVariants.error
        );

        this.setState({ data: [], loading: false });
      });

    /*
    let url = `${this.state.siteConfigurationContext[Configs.SiteConfigurationMappingKeys.serverapi]}${Configs.triageAppointmentScreen}`;
    let urlObj = Configs.urlGetObj;
    let urlHeader = Object.assign({Authorization:`Bearer ${FhtMain.GlobalContext.userToken}`}, Configs.urlHeader.headers);
    urlObj = Object.assign({headers: urlHeader}, urlObj);
    fetch(url, urlObj)
      .then(r => r.json())
      .then(response => {
        this.setState({data: response.data});
        this.setState({loading: false});
      })
      .catch(e => {
        this.setState({data: []});
        this.setState({loading: false});
      })
      */
  }

  onGridReady = params => {
    console.log('On grid ready');
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (this.props.location.search != '' && this.props.location.search != null) {
      let searchParams = this.props.location.search.split('?')[1].split('&');

      let tempCohortIdValue = -1;
      let tempWorkflowStageId = -1;
      let tempDeferWorkflowId = -1;
      searchParams.forEach(s => {
        let keySearch = s.split('=')[0];
        let searchValue = s.split('=')[1];
        if (keySearch === 'cohortid') {
          tempCohortIdValue = searchValue;
        }
      });
      if (tempCohortIdValue !== -1) {
        this.setState(
          {
            forWebAPIFilterSearchCohortId: tempCohortIdValue,
            forWebAPIFilterWorkflowId: tempWorkflowStageId,
            forWebAPIDeferWorkflowId: tempDeferWorkflowId
          },
          () => {
            this.apiData();
          }
        );
      }
    } else this.apiData();
    this.closeToolPanel();
  };

  onColumnResized(event) {
    if (event.finished) {
      this.gridApi.resetRowHeights();
    }
  }

  closeToolPanel = () => {
    this.gridApi.closeToolPanel();
  };

  onBtPrint = () => {
    this.setPrinterFriendly();
    setTimeout(() => {
      window.print();
      this.setNormal();
    }, 2000);
  };

  setPrinterFriendly = () => {
    let eGridDiv = document.querySelector('.my-grid');
    eGridDiv.style.width = '';
    eGridDiv.style.height = '';
    this.gridApi.setDomLayout('print');
  };

  setNormal = () => {
    let eGridDiv = document.querySelector('.my-grid');
    eGridDiv.style.height = '800px';
    this.gridApi.setDomLayout(null);
  };

  onBtExport = () => {
    let options = {
      fileName: 'Recall Patient'
    };
    this.gridApi.exportDataAsCsv(options);
  };
  onBtnOpenCommentDialog = (patientId) => {
    debugger;
    console.log('patientId, this.state.forWebAPIFilterSearchCohortId')
    debugger
      this.setState({ currentPatientId: patientId});
      this.setState({showCommentDialog:true});
  }
  showMessagesFromComment = (msg, type) => {
    this.props.GlobalFunctionDisplaySnackbarMessage(msg, type);
  };


  onSelectionChanged = () => {
    this.setState({
      isChecked: this.gridApi.getSelectedRows().length > 0
    });
  };

  blurFilters = () => {
    return this.state.displayTutorial ? { filter: 'blur(8px)' } : {};
  };

  defaultColDef = () => {
    return {
      editable: false,
      width: 150,
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      filterParams: {}
    };
  };

  aggridColumns() {
    return [
      {
        field: '',
        headerName: 'Comments',
        //filter: 'agTextColumnFilter',
        width: 150,
        cellRendererFramework: ChildMessageRenderer,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
       
        //hide: true
      },
      {
        field: 'patientExtractSurname',
        headerName: 'Surname',
        filter: 'agTextColumnFilter',
        width: 300,
        //checkboxSelection: true,
        //headerCheckboxSelection: true,
        //headerCheckboxSelectionFilteredOnly: true
      },
      {
        field: 'patientExtractId',
        headerName: 'Id',
        filter: 'agNumberColumnFilter',
        width: 150,
        hide: true
      },
      {
        field: 'patientExtractFirstname',
        headerName: 'Firstname',
        filter: 'agTextColumnFilter',
        width: 300
      },
      { field: 'patientExtractAge', headerName: 'Age', filter: 'agNumberColumnFilter' },
      { field: 'patientExtractGender', headerName: 'Gender', filter: 'agTextColumnFilter' },
      {
        field: 'patientExtractUsualDoctor',
        headerName: 'Usual Doctor',
        filter: 'agTextColumnFilter',
        width: 200
      },
      {
        field: 'patientExtractNextAppointment',
        headerName: 'Next Appointment',
        filter: 'agDateColumnFilter',
        width: 200,
        valueGetter: function getDateGetter(params) {
          if (params.data != null) return getDate(params.data.patientExtractNextAppointment);
          return '';
        }
      },
      {
        field: 'patientExtractLastAppointment',
        headerName: 'Last Appointment',
        filter: 'agDateColumnFilter',
        width: 200,
        valueGetter: function getDateGetter(params) {
          if (params.data != null) return getDate(params.data.patientExtractLastAppointment);
          return '';
        }
      },
      {
        field: 'patientExtractAtsi',
        headerName: 'First Nation',
        filter: 'agTextColumnFilter',
        valueGetter: function yesNoGetter(params) {
          if (params.data != null) return getYesNo(params.data.patientExtractAtsi);
          return '';
        }
      },
      {
        field: 'patientExtractSmoker',
        headerName: 'Smoker',
        filter: 'agTextColumnFilter',
        valueGetter: function yesNoGetter(params) {
          if (params.data != null) return getYesNo(params.data.patientExtractSmoker);
          return '';
        }
      },
      {
        field: 'patientDeferReason',
        headerName: 'Defer Reason',
        filter: 'agTextColumnFilter',
        width: 300
      },
      {
        field: 'patientDeferDateCreated',
        headerName: 'Created Date',
        filter: 'agDateColumnFilter',
        width: 200,
        valueGetter: function getDateGetter(params) {
          if (params.data != null) return getDate(params.data.patientDeferDateCreated);
          return '';
        }
      },
      {
        field: 'patientDeferDateEnd',
        headerName: 'Defer End Date',
        filter: 'agDateColumnFilter',
        width: 200,
        valueGetter: function getDateGetter(params) {
          if (params.data != null) return getDate(params.data.patientDeferDateEnd);
          return '';
        }
      }
    ];
  }

  componentsDef = () => {
    return { ProposedActionCellRenderer: ProposedActionCellRenderer('N/A') };
  };

  cancelDefer = () => {
    let undeferPatients = [];
    let selectedPatients = this.gridApi.getSelectedRows();

    selectedPatients.map(patient => {
      undeferPatients.push({
        PatientId: patient.patientExtractId
      });
    });
    let body = { patientsDeferredList: undeferPatients };
    const api = FhtFetchUrl(Configs.submitPatientUndefer);
    api
      .post(body)
      .then(response => {
        console.log(response);
        //this.setState({data: response.data, loading: false});
        this.apiData();
      })
      .catch(error => {
        var msg = String(error);

        this.props.GlobalFunctionDisplaySnackbarMessage(
          'Error:' + msg,
          Configs.snackbarVariants.error
        );

        this.setState({ data: [], loading: false });
      });
  };

  //Render Functions
  renderAgGrid = () => {
    return (
      <AgGridReact
        onGridReady={this.onGridReady}
        defaultColDef={this.defaultColDef()}
        rowSelection="multiple"
        onSelectionChanged={this.onSelectionChanged}
        floatingFilter={true}
        singleClickEdit={true}
        columnDefs={this.aggridColumns()}
        groupSelectsChildren={true}
        sideBar={true}
        groupIncludeFooter={true}
        rowData={this.state.data}
        headerHeight={40}
        components={this.componentsDef()}
        masterDetail={false}
        detailCellRenderer={this.state.detailCellRenderer}
        detailRowHeight={800}
        detailRowWidth={1880}
        groupDefaultExpanded={0}
        frameworkComponents={this.state.frameworkComponents}
        context={this.state.aggcontext}
        onColumnResized={this.onColumnResized.bind(this)}
        //onColumnResized={this.onColumnResized}>
      ></AgGridReact>
    );
  };

  render() {
    return (
      <div>
        <div className="grid-container" style={this.blurFilters()}>
          <Paper>
            <div style={paddingTop}>
              <span style={{ textAlign: 'left', fontSize: 18, fontWeight: 'bold' }}>
                Deferred Patients
              </span>
              <span style={{ width: '5REM', height: 'auto', display: 'inline-block' }}></span>
              <Button
                variant="outlined"
                color="inherit"
                style={{ paddingRight: '1REM', paddingLeft: '1REM' }}
                onClick={this.cancelDefer}
                disabled={!this.state.isChecked}
              >
                <span style={smallPaddingRight}>
                  <CheckCircleOutline />
                </span>
                Undefer Patient
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                style={{ paddingRight: '1REM', float: 'right' }}
                onClick={this.onBtPrint}
              >
                <span style={smallPaddingRight}>
                  <Print />
                </span>
                Print
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                style={{ paddingRight: '1REM', float: 'right' }}
                onClick={this.onBtExport}
              >
                <span style={smallPaddingRight}>
                  <SaveAlt />
                </span>
                Export to CSV
              </Button>
            </div>
          </Paper>
          <CommentDialog header=""
          visible={this.state.showCommentDialog}
          //footer={deferDialogFooter}
          //onSubmit={e => this.onBtnSubmitDefer()}
          onHide={e => this.setState({showCommentDialog: false})}
          maxWidth="false"
          open={this.state.showCommentDialog}
          patientId={this.state.currentPatientId}
          cohortId={this.state.forWebAPIFilterSearchCohortId}
          showMessages={this.showMessagesFromComment}
        >
        </CommentDialog>
          <Paper style={{ backgroundColor: 'transparent', height: '100%' }}>
            <div
              id="agRecallGrid"
              className="ag-theme-material my-grid"
              style={{ height: `${this.state.height}px` }}
            >
              {this.renderAgGrid()}
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}
function getDate(params) {
  // eslint-disable-next-line
  if (params == null || params == undefined) return '';
  let arr = params.split('T')[0].split('-');
  let convertDate = `${arr[2]}-${arr[1]}-${arr[0]}`;
  // return params.split("T")[0];
  return convertDate;
}

function getYesNo(integerValue) {
  let result = 'No';
  if (integerValue === 1) result = 'Yes';
  return result;
}
export default PatientDeferScreen;
