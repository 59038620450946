import React, { Component } from 'react';
import SiteConfigurationContext from 'Utils/SiteConfigurationContext.jsx';
import BatchSiteConfigurationContext from 'Utils/BatchSiteConfigurationContext.jsx';
import AlgorithmSiteConfigurationContext from 'Utils/AlgorithmSiteConfigurationContext.jsx';

class CurrentFiltersStatusBarComponent extends Component {
  constructor(props) {
    super(props);    
    this.siteConfiguration = SiteConfigurationContext();
    this.batchSiteConfigurationContext = BatchSiteConfigurationContext();
    this.algorithmSiteConfigurationContext = AlgorithmSiteConfigurationContext();
    this.state = {
      currentFilters: "",
    }
  }

  handleRemovalOfFilter = (event) => {
    event.preventDefault();
    var rawFilterToRemove = event.target.textContent.split(":")
    var parsedFilterToRemove = rawFilterToRemove[0]
    let filterState = this.props.api.getFilterModel();
    var updatedFilterState = {}
    var filterKeyToRemove = this.convertFilterToFilterStateKey(parsedFilterToRemove)
    for (var key in filterState) {
      if (String(key) != String(filterKeyToRemove)) {
        updatedFilterState[key] = filterState[key]
      }
      if (filterKeyToRemove == "fhtRecommendationView.fhtPatientAnalysisMultiTestId") {
        updatedFilterState[key] = {
          filterType: "set",
          values: []
        }
      } else if (filterKeyToRemove == "patientMbsEligibilityData.patientMbsEligibilityExtractList") {
        updatedFilterState[key] = {
          filterType: "set",
          values: []
        }
      } else if (String(key) == String(filterKeyToRemove)) {
        continue
      }
      
    }
    this.props.api.setFilterModel(updatedFilterState)
  }
  
  updateCurrentFilter() {
    try {
      var allCurrentFiltersArray = []
      var filterModelKeys = Object.keys(this.props.api.getFilterModel());
      for (var i = 0; i < filterModelKeys.length; i++) {
        var individualFilterModelKey = filterModelKeys[i];
        if (this.props.api.getModel().filterManager.allFilters[individualFilterModelKey].column.filterActive == false) {
          // do nothing
        } else {
          var filterStringToRender = this.returnFilterStringName(individualFilterModelKey, this.props.api.getFilterModel())
          allCurrentFiltersArray.push(
            <div key={i} style={{ display: "inline-block" }}>
              <div style={{ display: "inline-block", cursor: "pointer" }}>
                <p
                  style={{ color: "#0000EE" }}
                  onClick={e => this.handleRemovalOfFilter(e, filterStringToRender)}
                >
                  {filterStringToRender}
                </p>
              </div><span>&nbsp;</span>
            </div>)
        }
      }
      this.setState({
        currentFilters: allCurrentFiltersArray
      })
    } catch {
      // Do nothing , this will be called again when API components are available
    }
  }

  returnFilterStringName(providedFilterKey, filterModelDictionary) {
    if (providedFilterKey == "patientData.patientExtractSurname") {
      return "Surname: " + filterModelDictionary["patientData.patientExtractSurname"].filter + " [X]"
    } else if (providedFilterKey == "patientData.patientExtractFirstname") {
      return "Firstname: " + filterModelDictionary["patientData.patientExtractFirstname"].filter + " [X]"
    } else if (providedFilterKey == "patientData.patientExtractAge") {
      return "Age: " + filterModelDictionary["patientData.patientExtractAge"].filter + " [X]"
    } else if (providedFilterKey == "patientData.patientExtractUsualDoctor") {
      return "Usual doctor: " + filterModelDictionary["patientData.patientExtractUsualDoctor"].filter + " [X]"
    } else if (providedFilterKey == "patientExtractNextVisit") {
      return "Next Appointment: " + filterModelDictionary["patientExtractNextVisit"].dateFrom + " [X]"
    } else if (providedFilterKey == "patientExtractLastVisit") {
      return "Last Appointment: " + filterModelDictionary["patientExtractLastVisit"].dateFrom + " [X]"
    } else if (providedFilterKey == "ATSIId") {
      return "First Nations: " + filterModelDictionary["ATSIId"].filter + " [X]"
    } else if (providedFilterKey == "SmokerId") {
      return "Smoker: " + filterModelDictionary["SmokerId"].filter + " [X]"
    } else if (providedFilterKey == "fhtRecommendationView.fhtPatientAnalysisMultiTestId") {
      if (filterModelDictionary["fhtRecommendationView.fhtPatientAnalysisMultiTestId"].values.length == 0) {
        // Return no string since no filters are actually chosen.
        return ""
      } else {
        return "FHT Recommendations: " + filterModelDictionary["fhtRecommendationView.fhtPatientAnalysisMultiTestId"].values.length + " [X]"
      }    
    } else if (providedFilterKey == "DiabetesId") {
      return "Diabetes: " + filterModelDictionary["DiabetesId"].filter + " [X]"
    } else if (providedFilterKey == "HypertensionId") {
      return "Hypertension Classification: " + filterModelDictionary["HypertensionId"].filter + " [X]"
    } else if (providedFilterKey == "triageExtractBmi") {
      return "Body Mass Index: " + filterModelDictionary["triageExtractBmi"].filter + " [X]"
    } else if (providedFilterKey == "triageExtractAcvdr") {
      return "# Total Risks: " + filterModelDictionary["triageExtractAcvdr"].filter + " [X]"
    } else if (providedFilterKey == "CVDRiskLevelId") {
      return "CVD Risk Level: " + filterModelDictionary["CVDRiskLevelId"].filter + " [X]"
    } else if (providedFilterKey == "triageData.triageExtractTotalRiskFactors") {
      return "CVD Risk#: " + filterModelDictionary["triageData.triageExtractTotalRiskFactors"].filter + " [X]"
    } else if (providedFilterKey == "CodedCVDId") {
      return "Coded CVD: " + filterModelDictionary["CodedCVDId"].filter + " [X]"
    } else if (providedFilterKey == "triageData.triageExtractCkdStageAssessment") {
      return "CKD Stage: " + filterModelDictionary["triageData.triageExtractCkdStageAssessment"].filter + " [X]"
    } else if (providedFilterKey == "AKIId") {
      return "Acute Kidney Injury: " + filterModelDictionary["AKIId"].filter + " [X]"
    } else if (providedFilterKey == "patientMbsEligibilityData.patientMbsEligibilityExtractList") {
      if (filterModelDictionary["patientMbsEligibilityData.patientMbsEligibilityExtractList"].values.length == 0) {
        // Return no string since no filters are actually chosen.
        return ""
      } else {
        return "Eligible MBS Items: " + filterModelDictionary["patientMbsEligibilityData.patientMbsEligibilityExtractList"].values.length + " [X]"
      }    
    } else {
      if (providedFilterKey == "patientData.patientExtractCurrentStatus") {
        return "Current status: " + filterModelDictionary["patientData.patientExtractCurrentStatus"].filter + " [X]"
      }
    }
  }

  convertFilterToFilterStateKey(parsedFilterToRemove) {
    if (parsedFilterToRemove == "Surname") {
      return "patientData.patientExtractSurname"
    } else if (parsedFilterToRemove == "Firstname") {
      return "patientData.patientExtractFirstname"
    } else if (parsedFilterToRemove == "Usual doctor") {
      return "patientData.patientExtractUsualDoctor"
    } else if (parsedFilterToRemove == "Age") {
      return "patientData.patientExtractAge"
    } else if (parsedFilterToRemove == "Next Appointment") {
      return "patientExtractNextVisit"
    } else if (parsedFilterToRemove == "Last Appointment") {
      return "patientExtractLastVisit"
    } else if (parsedFilterToRemove == "First Nations") {
      return "ATSIId"
    } else if (parsedFilterToRemove == "Smoker") {
      return "SmokerId"
    } else if (parsedFilterToRemove == "FHT Recommendations") {
      return "fhtRecommendationView.fhtPatientAnalysisMultiTestId"
    } else if (parsedFilterToRemove == "Diabetes") {
      return "DiabetesId"
    } else if (parsedFilterToRemove == "Hypertension Classification") {
      return "HypertensionId"
    } else if (parsedFilterToRemove == "Body Mass Index") {
      return "triageExtractBmi"
    } else if (parsedFilterToRemove == "# Total Risks") {
      return "triageExtractAcvdr"
    } else if (parsedFilterToRemove == "CVD Risk Level") {
      return "CVDRiskLevelId"
    } else if (parsedFilterToRemove == "CVD Risk#") {
      return "triageData.triageExtractTotalRiskFactors"
    } else if (parsedFilterToRemove == "Coded CVD") {
      return "CodedCVDId"
    } else if (parsedFilterToRemove == "CKD Stage") {
      return "triageData.triageExtractCkdStageAssessment"
    } else if (parsedFilterToRemove == "Acute Kidney Injury") {
      return "AKIId"
    } else if (parsedFilterToRemove == "Eligible MBS Items") {
      return "patientMbsEligibilityData.patientMbsEligibilityExtractList"
    } else { 
      if (parsedFilterToRemove == "Current status") {
        return "patientData.patientExtractCurrentStatus"
      }
    }
  }

  render() {
    return (
      <div className="ag-status-name-value">
        <span>
          Currently active filters - <div style={{ display: "inline-block" }}>{this.state.currentFilters}</div>
        </span>
      </div>
    );
  }
}

export default CurrentFiltersStatusBarComponent;